
export default class TableState {
  static setColumnState(tableId, state) {
    sessionStorage.setItem(tableId, JSON.stringify(state));
  }

  static getColumnState(tableId) {
    return JSON.parse(sessionStorage.getItem(tableId));    
  }

  static clearColumnState(tableId) {
    sessionStorage.removeItem(tableId);
  }

  static clearAllColumnStates() {
    const gridIds = Object.keys(sessionStorage).filter(key => key.includes('grid'));
    gridIds.forEach( tableId => 
      sessionStorage.removeItem(tableId) 
    );
  }
}
