import React from 'react';
import LoadingSuspense from 'common/LoadingSuspense';

const CollateralGradeComponent = React.lazy(() => import('./CollateralGrade'));

export const CollateralGradePage = (props) => {
  return (
    <LoadingSuspense>
      <CollateralGradeComponent {...props} />
    </LoadingSuspense>
  );
};

const CollateralGradeDayOnDayComponent = React.lazy(() => import('./CollateralGradeDayOnDay'));

export const CollateralGradeDayOnDayPage = (props) => {
  return (
    <LoadingSuspense>
      <CollateralGradeDayOnDayComponent {...props} />
    </LoadingSuspense>
  );
};