import * as svc from "./services";

export const actionTypes = {
  FETCH_SCHEDULE_MANAGER_SUCCESS: "ccs/scheduleManager/FETCH_SCHEDULE_MANAGER_SUCCESS",
  FETCH_SCHEDULE_TYPES_SUCCESS: "ccs/scheduleManager/FETCH_SCHEDULE_TYPE_SUCCESS",
  FETCH_SCHEDULE_BUILDER_FORM_SUCCESS: "ccs/scheduleManager/FETCH_SCHEDULE_BUILDER_FORM_SUCCESS",
  CLEAR_SCHEDULE_BUILDER_FORM: "ccs/scheduleManager/CLEAR_SCHEDULE_BUILDER_FORM",
  SAVE_SCHEDULE_BUILDER_FORM_SUCCESS: "ccs/scheduleManager/SAVE_SCHEDULE_BUILDER_FORM_SUCCESS",
  SAVE_SCHEDULE_BUILDER_FORM_ERROR: "ccs/scheduleManager/SAVE_SCHEDULE_BUILDER_FORM_ERROR",
  FETCH_PROFILE_SUMMARY_SUCCESS: "ccs/scheduleManager/FETCH_PROFILE_SUMMARY_SUCCESS",
  FETCH_RULE_SUMMARIES_SUCCESS: "ccs/scheduleManager/FETCH_RULE_SUMMARIES_SUCCESS",
  CLEAR_RULE_SUMMARIES: "ccs/scheduleManager/CLEAR_RULE_SUMMARIES",
  FETCH_RULE_SUCCESS: "ccs/scheduleManager/FETCH_RULE_SUCCESS",
  FETCH_BASE_RULE_TYPES_SUCCESS: "ccs/scheduleManager/FETCH_BASE_RULE_TYPES_SUCCESS",
  FETCH_LIMIT_TYPES_SUCCESS: "css/scheduleManager/FETCH_LIMIT_TYPES_SUCCESS"
};

function fetchScheduleManagerSuccess(payload) {
  return {
    type: actionTypes.FETCH_SCHEDULE_MANAGER_SUCCESS,
    payload,
  };
}

function fetchScheduleTypeSuccess(payload) {
  return {
    type: actionTypes.FETCH_SCHEDULE_TYPES_SUCCESS,
    payload,
  };
}

function saveScheduleBuilderFormSuccess(payload) {
  return {
    type: actionTypes.SAVE_SCHEDULE_BUILDER_FORM_SUCCESS,
    payload,
  };
}

function saveScheduleBuilderFormError(payload) {
  return {
    type: actionTypes.SAVE_SCHEDULE_BUILDER_FORM_ERROR,
    payload,
  };
}


function fetchProfileSummarySuccess(payload) {
  return {
    type: actionTypes.FETCH_PROFILE_SUMMARY_SUCCESS,
    payload,
  };
}

function fetchRuleSummariesSuccess(payload) {
  return {
    type: actionTypes.FETCH_RULE_SUMMARIES_SUCCESS,
    payload,
  };
}

function fetchRuleSuccess(payload) {
  return {
    type: actionTypes.FETCH_RULE_SUCCESS,
    payload,
  };
}

function fetchLimitTypesSuccess(payload) {
  return {
    type: actionTypes.FETCH_LIMIT_TYPES_SUCCESS,
    payload,
  };
}

export function clearRuleSummaries() {
  return {
    type: actionTypes.CLEAR_RULE_SUMMARIES,
  };
}

export function fetchBaseRuleTypesSuccess(payload) {
  return {
    type: actionTypes.FETCH_BASE_RULE_TYPES_SUCCESS,
    payload,
  };
}

/**
 *
 * Async actions
 *
 */

export function publishSchedule(params) {
  return dispatch => svc.publishSchedule(dispatch, params);
}

export function fetchScheduleManagerData(params) {
  return (dispatch) => {
    svc.fetchCollateralSummaries(dispatch, params)
      .then(payload => {
        dispatch(fetchScheduleManagerSuccess(payload));
      });
  };
}

export function fetchScheduleTypes() {
  return (dispatch) => {
    svc.fetchScheduleTypes(dispatch)
      .then(payload => {
        dispatch(fetchScheduleTypeSuccess(payload));
      });
  };
}

export function deleteSchedule(params) {
  return dispatch => svc.deleteSchedule(dispatch, params);
}

export function copySchedule(params) {
  return dispatch => svc.copySchedule(dispatch, params);
}

export function fetchScheduleBuilderForm(params) {
  return (dispatch) => {
    const fetchForm = params.profileId ? svc.fetchEditScheduleBuilderFormWithLimits : svc.fetchScheduleBuildFormWithLimits;
    return fetchForm(dispatch, params);
  };
}

export function saveScheduleBuilderForm(params) {
  return (dispatch) => {
    return svc.saveScheduleBuilderFormWithLimits(dispatch, params)
      .then(payload => {
        return dispatch(saveScheduleBuilderFormSuccess(payload));
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        return dispatch(saveScheduleBuilderFormError(e));
      });
  };
}


export function fetchProfileSummary(params) {
  return (dispatch) => {
    return svc.fetchProfileSummary(dispatch, params)
      .then(payload => {
        dispatch(fetchProfileSummarySuccess(payload));
      });
  };
}

export function fetchRuleTypes() {
  return (dispatch) => svc.fetchRuleTypes(dispatch);
}

export function fetchBaseRuleTypes(params) {
  return (dispatch) => {
    return svc.fetchBaseRuleTypes(dispatch, params)
      .then(payload => {
        dispatch(fetchBaseRuleTypesSuccess(payload));
      });
  };
}

export function fetchLogicalOperators() {
  return (dispatch) => svc.fetchLogicalOperators(dispatch);
}

export function fetchRuleValues(params) {
  return (dispatch) => {
    return svc.fetchRuleValues(dispatch, params)
      .then(payload => payload);
  };
}

export function saveRule(params) {
  return (dispatch) => svc.saveRule(dispatch, params);
}

export function editRuleName(params) {
  return (dispatch) => svc.editRuleName(dispatch, params);
}

export function fetchRuleSummaries(params) {
  return (dispatch) => {
    return svc.fetchRuleSummaries(dispatch, params)
      .then(payload => {
        return dispatch(fetchRuleSummariesSuccess(payload));
      });
  };
}

export function fetchRule(params) {
  return (dispatch) => {
    return svc.fetchRule(dispatch, params)
      .then(payload => {
        return dispatch(fetchRuleSuccess(payload));
      });
  };
}

export function addNewRule(params) {
  return (dispatch) => {
    return svc.addNewRule(dispatch, params)
      .then(payload => {
        return dispatch(fetchRuleSummariesSuccess(payload));
      });
  };
}

export function deleteRule(params) {
  return (dispatch) => svc.deleteRule(dispatch, params);
}

export function copyRule(params) {
  return (dispatch) => svc.copyRule(dispatch, params);
}

export function fetchLimitTypes(params) {
  return (dispatch) => {
    return svc.fetchLimitTypes(dispatch, params)
      .then(payload => {
        return dispatch(fetchLimitTypesSuccess(payload));
      });
  };
}

export function fetchLimitOptions(params) {
  return (dispatch) => svc.fetchLimitOptions(dispatch, params);
}
