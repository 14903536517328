import React from 'react';
import { ReactElement } from "react";
import * as styledComponens from './styles';
import { ERROR_RED } from 'styles/colors/brandColors';

export enum EventActions {
  Issue = 'issue',
  Mismatch = 'mismatch',
  Match = 'match',
  Allege = 'allege',
  Unmatch = 'unmatch',
  Reject = 'reject',
}

export interface ActionConfig {
  id: EventActions;
  icon: ReactElement;
  colour: string;
  title: string;
  enabled: boolean;
  hoverText?: string;
}

export interface Action {
  icon: ReactElement;
  colour: string;
  title: string;
  isSubscriberAction: boolean;
  isNonSubscriberAction: boolean;
}

const EVENT_ACTION: Record<EventActions, Action> = {
  [EventActions.Issue]: {
    icon: <styledComponens.IssueIcon />,
    colour: '#488DCA',
    title: 'Issue',
    isSubscriberAction: true,
    isNonSubscriberAction: true,
  },
  [EventActions.Mismatch]: {
    icon: <styledComponens.IconQueryIcon />,
    colour: ERROR_RED[100],
    title: 'Mismatch',
    isSubscriberAction: true,
    isNonSubscriberAction: true,
  },
  [EventActions.Unmatch]: {
    icon: <styledComponens.RejectIcon />,
    colour: ERROR_RED[100],
    title: 'Unmatch',
    isSubscriberAction: true,
    isNonSubscriberAction: false,
  },
  [EventActions.Allege]: {
    icon: <styledComponens.RejectIcon />,
    colour: ERROR_RED[100],
    title: 'Allege',
    isSubscriberAction: false,
    isNonSubscriberAction: true,
  },
  [EventActions.Reject]: {
    icon: <styledComponens.CircleBackslashIcon />,
    colour: ERROR_RED[100],
    title: 'Reject',
    isSubscriberAction: true,
    isNonSubscriberAction: true,
  },
  [EventActions.Match]: {
    icon: <styledComponens.MatchIcon />,
    colour: '#74B66A',
    title: 'Match',
    isSubscriberAction: true,
    isNonSubscriberAction: true,
  },
};

export default EVENT_ACTION;
