import * as svc from '../services';

export const types = {
  FETCH_DASHBOARD_BREAKS_LATEST_SUCCESS: 'FETCH_DASHBOARD_BREAKS_LATEST_SUCCESS'
};

const fetchBreaksLatestSuccess = payload => ({
  type: types.FETCH_DASHBOARD_BREAKS_LATEST_SUCCESS,
  payload
});

export function fetchBreaksLatest(fetchCount, islaGroup) {
  return (dispatch) => {
    return svc.fetchBreaksLatest(dispatch, fetchCount, islaGroup)
      .then((payload) => {
        dispatch(fetchBreaksLatestSuccess(payload));
      });
  };
}