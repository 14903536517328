import BaseBreaksAdapter from './BaseBreaksAdapter';

class BreaksCounterpartyAdapter extends BaseBreaksAdapter {

  parseResponseData(apiResponse) {
    /**
     * Data parsing into more convienient data set for Chart
     */
    const { data, rec } = apiResponse;

    const chartData = Object.keys(data).reduce((acc, key) => {
      acc[key] = data[key].map(it => ({
        rec: it.rec,
        uid: 'b'+it.rec.replace(/ /, '_')+'_'+key,
        label: rec[it.rec].label,
        rec_id: rec[it.rec].rec_id,
        count: it.break_count,
        value: it.value,
        pcnt: it.break_pcnt,
        days: it.days,
      }));
      return acc;
    }, {});

    apiResponse.data = chartData;

    return super.parseResponseData(apiResponse);
  }

}

export default BreaksCounterpartyAdapter;
