import services from 'services/services';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';
import { prepareFiltersRequest, parseFilters } from 'utils/parseUtils';

const prepareRequest = (params) => {
  const filterBy = prepareFiltersRequest(params);
  return { filterBy };
};


export function fetchAnalyticsSettings(dispatch) {
  const request = {};
  return services.query(apiNames.ELIGIBILITY_ANALYTICS, apiOperatonIds.ELIGIBILITY_ANALYTICS_SETTINGS)(dispatch, { request });
}
export function fetchAnalyticsResults(dispatch, params) {
  const { inventorySelection, shortsSelection, shortFlowsSelection, settings, adjustments } = params;
  const request = { inventorySelection, shortsSelection, shortFlowsSelection, settings, adjustments };
  return services.query(apiNames.ELIGIBILITY_ANALYTICS, apiOperatonIds.ELIGIBILITY_ANALYTICS_RESULTS)(dispatch, { request });
}
export function fetchAnalyticsShorts(dispatch, params) {
  const { adjustments } = params;
  const request = { ...prepareRequest(params), adjustments };
  return services.query(apiNames.ELIGIBILITY_ANALYTICS, apiOperatonIds.ELIGIBILITY_ANALYTICS_SHORTS)(dispatch, { request })
    .then(result => ({ ...result, ...parseFilters(result.filterByFields) }));
}
export function fetchAnalyticsShortsFilters(dispatch, params) {
  return services.query(apiNames.ELIGIBILITY_ANALYTICS, apiOperatonIds.ELIGIBILITY_ANALYTICS_SHORTS_FILTERS)(dispatch, { request: prepareRequest(params) })
    .then(result => ({ ...result, ...parseFilters(result.filterByFields) }));
}
export function fetchAnalyticsInventory(dispatch, params) {
  const { adjustments } = params;
  const request = { ...prepareRequest(params), adjustments };
  return services.query(apiNames.ELIGIBILITY_ANALYTICS, apiOperatonIds.ELIGIBILITY_ANALYTICS_INVENTORY)(dispatch, { request })
    .then(result => ({ ...result, ...parseFilters(result.filterByFields) }));
}
export function fetchAnalyticsCustomInventory(dispatch, params) {
  const { inventorySelection } = params;
  const request = { inventorySelection };
  return services.query(apiNames.CUSTOM_PORTFOLIO, apiOperatonIds.APPLY_CUSTOM_PORTFOLIO)(dispatch, { request })
    .then(result => ({ ...result, ...parseFilters(result.filterByFields) }));
}
export function fetchAnalyticsInventoryFilters(dispatch, params) {
  return services.query(apiNames.ELIGIBILITY_ANALYTICS, apiOperatonIds.ELIGIBILITY_ANALYTICS_INVENTORY_FILTERS)(dispatch, { request: prepareRequest(params) })
    .then(result => ({ ...result, ...parseFilters(result.filterByFields) }));
}
export function downloadAllocationReport(dispatch, params) {
  const { inventorySelection, shortsSelection, shortFlowsSelection, settings, adjustments } = params;
  const request = { inventorySelection, shortsSelection, shortFlowsSelection, settings, adjustments };
  return services.query(apiNames.ELIGIBILITY_ANALYTICS, apiOperatonIds.ELIGIBILITY_ANALYTICS_RESULTS_FAILURE)(dispatch, { request });
}