import faker from "@faker-js/faker";

import { ALLOWED_CLIENT_IDS } from "mocks/allowedClientIDs";

const userInfo = {
  user_id: faker.datatype.number(),
  home_entity: faker.datatype.number(),
  email: faker.internet.email(),
  shortname: faker.internet.userName(),
  name: faker.name.findName(),
  telephone: faker.phone.phoneNumber(),
  department: faker.commerce.department(),
  timezone: faker.address.timeZone(),
  base_ccy: faker.finance.currencyCode(),
  agree_terms_at: faker.datatype.datetime(),
  is_nonsubscribed: false,
  is_report_only: true,
  be: "",
};

const clientGrouped = {
  groups: [
    {
      parentGroupName: faker.company.companyName(),
      clients: [
        {
          clientId: faker.datatype.number(),
          clientCode: faker.finance.accountName(),
          clientDescription: faker.finance.transactionDescription(),
          bankId: faker.datatype.number(),
        },
        {
          clientId: faker.datatype.number(),
          clientCode: faker.finance.accountName(),
          clientDescription: faker.finance.transactionDescription(),
          bankId: faker.datatype.number(),
        },
      ],
    },
  ],
};

const menuTree = {
  menuTree: {
    viewId: "PirumConnect",
    title: "PirumConnect",
    enabled: true,
    allowedClientIds: ALLOWED_CLIENT_IDS,
    isMultiClient: true,
    children: [
      {
        viewId: "collateralConnect",
        title: "CollateralConnect",
        enabled: false,
        allowedClientIds: [],
        isMultiClient: true,
      },
      {
        viewId: "mis",
        title: "MIS",
        enabled: false,
        allowedClientIds: [],
        isMultiClient: true,
      },
      {
        viewId: "regulatory",
        title: "RegConnect",
        enabled: true,
        allowedClientIds: [ALLOWED_CLIENT_IDS[0], ALLOWED_CLIENT_IDS[1]],
        isMultiClient: false,
        defaultChildId: "sftr",
        children: [
          {
            viewId: "sftr",
            title: "SFTR",
            enabled: true,
            allowedClientIds: [ALLOWED_CLIENT_IDS[0], ALLOWED_CLIENT_IDS[1]],
            isMultiClient: false,
            defaultChildId: "sftrTrade",
            children: [
              {
                viewId: "sftrTrade",
                title: "Trade",
                enabled: true,
                allowedClientIds: [
                  ALLOWED_CLIENT_IDS[0],
                  ALLOWED_CLIENT_IDS[1],
                ],
                isMultiClient: false,
              },
              {
                viewId: "sftrNetExpCollateral",
                title: "Collateral",
                enabled: true,
                allowedClientIds: [
                  ALLOWED_CLIENT_IDS[0],
                  ALLOWED_CLIENT_IDS[1],
                ],
                isMultiClient: false,
              },
              {
                viewId: "sftrMarginLending",
                title: "Margin Lending",
                enabled: true,
                allowedClientIds: [
                  ALLOWED_CLIENT_IDS[0],
                  ALLOWED_CLIENT_IDS[1],
                ],
                isMultiClient: false,
              },
              {
                viewId: "sftrAudit",
                title: "Audit",
                enabled: true,
                allowedClientIds: [
                  ALLOWED_CLIENT_IDS[0],
                  ALLOWED_CLIENT_IDS[1],
                ],
                isMultiClient: false,
              },
            ],
          },
        ],
      },
      {
        viewId: "configConnect",
        title: "ConfigConnect",
        enabled: true,
        allowedClientIds: [ALLOWED_CLIENT_IDS[1]],
        isMultiClient: false,
        defaultChildId: "configViewer",
        children: [
          {
            viewId: "configViewer",
            title: "Home",
            enabled: true,
            allowedClientIds: [ALLOWED_CLIENT_IDS[1]],
            isMultiClient: false,
          },
          {
            viewId: "configAudit",
            title: "Audit",
            enabled: true,
            allowedClientIds: [ALLOWED_CLIENT_IDS[1]],
            isMultiClient: false,
          },
        ],
      },
      {
        viewId: "fos",
        title: "Front Office Services",
        enabled: false,
        allowedClientIds: [],
        isMultiClient: false,
      },
      {
        viewId: "repo",
        title: "RepoConnect",
        enabled: true,
        allowedClientIds: [ALLOWED_CLIENT_IDS[1]],
        isMultiClient: false,
        defaultChildId: "repoMatching",
        children: [
          {
            viewId: "repoDashboard",
            title: "Dashboard",
            enabled: true,
            allowedClientIds: [ALLOWED_CLIENT_IDS[1]],
            isMultiClient: false,
          },
          {
            viewId: "repoMatching",
            title: "Matching",
            enabled: true,
            allowedClientIds: [ALLOWED_CLIENT_IDS[1]],
            isMultiClient: false,
          },
        ],
      },
    ],
  },
};

const table = {
  table: {
    columnDefs: [
      {
        field: "checkbox",
        headerName: { title: "" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: true,
        openByDefault: false,
        checkboxSelection: true,
        checkboxSelectAllByDefault: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        pinned: "left",
        sortable: true,
      },
      {
        field: "counterparty",
        headerName: { title: "CPARTY" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "SET_TEXT",
        openByDefault: false,
        pinned: "left",
        sortable: true,
        widthKey: "L",
      },
      {
        field: "event",
        headerName: { title: "EVENT" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "eventDate",
        headerName: { title: "EVENT DATE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "DATE",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "contractId",
        headerName: { title: "CONTRACT ID" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "tradeRef",
        headerName: { title: "TRADE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "direction",
        headerName: { title: "DIRECTION" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "tradeDate",
        headerName: { title: "TRADE DATE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "DATE",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "startDate",
        headerName: { title: "START DATE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "DATE",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "endDate",
        headerName: { title: "END DATE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "DATE",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "securityId",
        headerName: { title: "SEC CODE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "settlementMarket",
        headerName: { title: "SETT MKT" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "securityDescription",
        headerName: { title: "SECURITY" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "SET_TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "nominal",
        headerName: { title: "NOMINAL" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "VALUE_NO_DECIMAL",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "price",
        headerName: { title: "PRICE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "VALUE",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "startCash",
        headerName: { title: "START CASH" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "VALUE_NO_DECIMAL",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "endCash",
        headerName: { title: "END CASH" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "VALUE_NO_DECIMAL",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "contractCurrency",
        headerName: { title: "CCY" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "rateTypeField",
        headerName: { title: "RATE TYPE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "SET_TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "rebateRateField",
        headerName: { title: "RATE" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "PERCENTAGE",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "indexName",
        headerName: { title: "INDEX" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "rateSpread",
        headerName: { title: "SPREAD" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "haircutPctRate",
        headerName: { title: "HAIRCUT" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "VALUE",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "createdBy",
        headerName: { title: "CREATED BY" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        sortable: true,
      },
      {
        field: "conversation",
        headerName: { title: "COMMENTS" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        pinned: "right",
        sortable: true,
      },
      {
        field: "status",
        headerName: { title: "STATUS" },
        rowGroup: false,
        hide: false,
        suppressToolPanel: false,
        styleKey: "TEXT",
        openByDefault: false,
        pinned: "right",
        sortable: true,
      },
    ],
    rows: [
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 1.4e8 },
          endDate: { value: "09-Dec-21", numValue: 1.639008e12 },
          tradeDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
          event: { value: "New" },
          haircutPctRate: { numValue: 5.0 },
          price: { numValue: 1.42857143 },
          endCash: { numValue: 1.999233333333e8 },
          startCash: { numValue: -2.0e8 },
          rebateRateField: { numValue: -0.6 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
          status: { value: "matched", arrayValue: ["matched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23038.0 },
          settlementMarket: { value: "NL" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "3", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23038:non_grouping"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 1.5e7 },
          endDate: { value: "09-Dec-21", numValue: 1.639008e12 },
          tradeDate: { value: "28-Oct-21", numValue: 1.6353792e12 },
          event: { value: "Increase" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 8.66666667 },
          endCash: { numValue: 1.2998375e8 },
          startCash: { numValue: -1.3e8 },
          rebateRateField: { numValue: -0.75 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "matched", arrayValue: ["matched", "mismatched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23024.0 },
          settlementMarket: { value: "ES" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "01-Dec-21", numValue: 1.6383168e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "9", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23024:parent"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 1.5e7 },
          endDate: { value: "09-Dec-21", numValue: 1.639008e12 },
          tradeDate: { value: "28-Oct-21", numValue: 1.6353792e12 },
          event: { value: "New" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 8.66666667 },
          endCash: { numValue: 1.2998375e8 },
          startCash: { numValue: -1.3e8 },
          rebateRateField: { numValue: -0.75 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "matched", arrayValue: ["matched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23024.0 },
          settlementMarket: { value: "ES" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "01-Dec-21", numValue: 1.6383168e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "9", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23024:parent", "23024"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 7.5e7 },
          endDate: { value: "OPEN" },
          tradeDate: { value: "16-Nov-21", numValue: 1.6370208e12 },
          event: { value: "New" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.01 },
          endCash: {},
          startCash: { numValue: -7.575e7 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "awaitingIssue", arrayValue: ["awaitingIssue"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23014.0 },
          settlementMarket: { value: "NL" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
        },
        context: [
          { fieldValue: "oneSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "3", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23015:parent", "23014"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 7.5e7 },
          endDate: { value: "OPEN" },
          tradeDate: { value: "28-Oct-21", numValue: 1.6353792e12 },
          event: { value: "Decrease" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.01 },
          endCash: {},
          startCash: { numValue: -7.575e7 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "matched", arrayValue: ["matched", "mismatched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23034.0 },
          settlementMarket: { value: "LU" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "false", fieldName: "hasNewComment" },
          { fieldValue: "3", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23034:parent"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 7.5e7 },
          endDate: { value: "OPEN" },
          tradeDate: { value: "28-Oct-21", numValue: 1.6353792e12 },
          event: { value: "New" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.01 },
          endCash: {},
          startCash: { numValue: -7.575e7 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "matched", arrayValue: ["matched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23034.0 },
          settlementMarket: { value: "LU" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "2", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23034:parent", "23034"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 1.0e7 },
          endDate: { value: "01-Dec-21", numValue: 1.6383168e12 },
          tradeDate: { value: "19-Oct-21", numValue: 1.6346016e12 },
          event: { value: "Close" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 11.5 },
          endCash: { numValue: 1.149490486111e8 },
          startCash: { numValue: -1.15e8 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "mismatched", arrayValue: ["mismatched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23022.0 },
          settlementMarket: { value: "ES" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "21-Oct-21", numValue: 1.6347744e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "false", fieldName: "hasNewComment" },
          { fieldValue: "0", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23024:parent", "23022"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 1.0e8 },
          endDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          tradeDate: { value: "16-Nov-21", numValue: 1.6370208e12 },
          event: { value: "Decrease" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.01 },
          endCash: { numValue: 1.009645097222e8 },
          startCash: { numValue: -1.01e8 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: {
            value: "awaitingIssue",
            arrayValue: ["awaitingIssue", "awaitingIssue"],
          },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23015.0 },
          settlementMarket: { value: "NL" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "16-Nov-21", numValue: 1.6370208e12 },
        },
        context: [
          { fieldValue: "oneSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "3", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23015:parent"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 1.0e8 },
          endDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          tradeDate: { value: "16-Nov-21", numValue: 1.6370208e12 },
          event: { value: "Close" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.01 },
          endCash: { numValue: 1.009645097222e8 },
          startCash: { numValue: -1.01e8 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "awaitingIssue", arrayValue: ["awaitingIssue"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23015.0 },
          settlementMarket: { value: "NL" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "16-Nov-21", numValue: 1.6370208e12 },
        },
        context: [
          { fieldValue: "oneSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "0", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23015:parent", "23015"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 1.0e8 },
          endDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          tradeDate: { value: "28-Oct-21", numValue: 1.6353792e12 },
          event: { value: "Close" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.01 },
          endCash: { numValue: 1.009645097222e8 },
          startCash: { numValue: -1.01e8 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
          status: { value: "mismatched", arrayValue: ["mismatched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23032.0 },
          settlementMarket: { value: "LU" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "01-Nov-21", numValue: 1.6357248e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "5", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23034:parent", "23032"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 5.2e7 },
          endDate: { value: "OPEN" },
          tradeDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
          event: { value: "Substitution" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.0 },
          endCash: {},
          startCash: { numValue: -5.2e7 },
          rebateRateField: { numValue: 0.6854 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: -0.2 },
          eventDate: { value: "03-Dec-21", numValue: 1.6384896e12 },
          status: { value: "matched", arrayValue: ["matched", "matched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: { value: "EONIA" },
          createdBy: {},
          itemId: { numValue: 23030.0 },
          settlementMarket: { value: "IT" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "03-Dec-21", numValue: 1.6384896e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "6", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23030:parent"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 5.2e7 },
          endDate: { value: "OPEN" },
          tradeDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
          event: { value: "New" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.0 },
          endCash: {},
          startCash: { numValue: -5.2e7 },
          rebateRateField: { numValue: 0.6854 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: -0.2 },
          eventDate: { value: "03-Dec-21", numValue: 1.6384896e12 },
          status: { value: "matched", arrayValue: ["matched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: { value: "EONIA" },
          createdBy: {},
          itemId: { numValue: 23030.0 },
          settlementMarket: { value: "IT" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "03-Dec-21", numValue: 1.6384896e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "2", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23030:parent", "23030"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 5.0e7 },
          endDate: { value: "03-Dec-21", numValue: 1.6384896e12 },
          tradeDate: { value: "23-Nov-21", numValue: 1.6376256e12 },
          event: { value: "Close" },
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.0 },
          endCash: { numValue: 5.00047597222e7 },
          startCash: { numValue: -5.0e7 },
          rebateRateField: { numValue: 0.6854 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: -0.2 },
          eventDate: { value: "03-Dec-21", numValue: 1.6384896e12 },
          status: { value: "matched", arrayValue: ["matched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: { value: "EONIA" },
          createdBy: {},
          itemId: { numValue: 23028.0 },
          settlementMarket: { value: "IT" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "25-Nov-21", numValue: 1.6377984e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "false", fieldName: "hasNewComment" },
          { fieldValue: "8", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23030:parent", "23028"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 7.5e7 },
          endDate: { value: "20-Dec-21", numValue: 1.6399584e12 },
          tradeDate: { value: "08-Nov-21", numValue: 1.6363296e12 },
          event: {},
          haircutPctRate: { numValue: 2.0 },
          price: { numValue: 1.13333333 },
          endCash: { numValue: 8.49701319444e7 },
          startCash: { numValue: -8.5e7 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: {},
          status: { value: "matched", arrayValue: ["matched"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23020.0 },
          settlementMarket: { value: "DE" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "10-Nov-21", numValue: 1.6365024e12 },
        },
        context: [
          { fieldValue: "twoSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "1", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23020:non_grouping"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 8.0e7 },
          endDate: { value: "05-Apr-22", numValue: 1.6491168e12 },
          tradeDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
          event: {},
          haircutPctRate: { numValue: 100.0 },
          price: { numValue: 1.0 },
          endCash: { numValue: -7.99902222222e7 },
          startCash: { numValue: 8.0e7 },
          rebateRateField: { numValue: -0.05 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: {},
          status: { value: "awaitingIssue", arrayValue: ["awaitingIssue"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23013.0 },
          settlementMarket: { value: "GB" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "02-Dec-21", numValue: 1.6384032e12 },
        },
        context: [
          { fieldValue: "oneSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "2", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23013:non_grouping"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 7.5e7 },
          endDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
          tradeDate: { value: "26-Nov-21", numValue: 1.6378848e12 },
          event: {},
          haircutPctRate: { numValue: 100.0 },
          price: { numValue: 1.0 },
          endCash: { numValue: -7.50001041667e7 },
          startCash: { numValue: 7.5e7 },
          rebateRateField: { numValue: 0.05 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: {},
          status: { value: "awaitingIssue", arrayValue: ["awaitingIssue"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23018.0 },
          settlementMarket: { value: "US" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "29-Nov-21", numValue: 1.638144e12 },
        },
        context: [
          { fieldValue: "oneSided", fieldName: "recOrientation" },
          { fieldValue: "false", fieldName: "hasNewComment" },
          { fieldValue: "5", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23018:non_grouping"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 5.0e7 },
          endDate: { value: "30-Nov-21", numValue: 1.6382304e12 },
          tradeDate: { value: "26-Nov-21", numValue: 1.6378848e12 },
          event: {},
          haircutPctRate: { numValue: 100.0 },
          price: { numValue: 1.0 },
          endCash: { numValue: -5.00000694444e7 },
          startCash: { numValue: 5.0e7 },
          rebateRateField: { numValue: 0.05 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: {},
          status: { value: "awaitingIssue", arrayValue: ["awaitingIssue"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23012.0 },
          settlementMarket: { value: "US" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "29-Nov-21", numValue: 1.638144e12 },
        },
        context: [
          { fieldValue: "oneSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "3", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23012:non_grouping"],
      },
      {
        cells: {
          rateTypeField: {},
          itemRef: { value: faker.finance.accountName() },
          counterparty: {
            value: faker.finance.accountName(),
            tooltip: faker.finance.transactionDescription(),
          },
          conversation: {},
          contractCurrency: { value: faker.finance.currencyCode() },
          nominal: { numValue: 2.5e7 },
          endDate: { value: "OPEN" },
          tradeDate: { value: "23-Nov-21", numValue: 1.6376256e12 },
          event: {},
          haircutPctRate: { numValue: 100.0 },
          price: { numValue: 1.0 },
          endCash: {},
          startCash: { numValue: 2.5e7 },
          rebateRateField: { numValue: -0.55 },
          direction: { value: "Buy" },
          tradeRef: { value: faker.finance.transactionType() },
          contractId: { value: faker.finance.transactionType() },
          rateSpread: { numValue: 0.0 },
          eventDate: {},
          status: { value: "awaitingIssue", arrayValue: ["awaitingIssue"] },
          securityDescription: {
            value: faker.finance.transactionDescription(),
            tooltip: faker.finance.transactionDescription(),
          },
          indexName: {},
          createdBy: {},
          itemId: { numValue: 23017.0 },
          settlementMarket: { value: "AT" },
          securityId: { value: faker.finance.transactionType() },
          startDate: { value: "24-Nov-21", numValue: 1.637712e12 },
        },
        context: [
          { fieldValue: "oneSided", fieldName: "recOrientation" },
          { fieldValue: "true", fieldName: "hasNewComment" },
          { fieldValue: "2", fieldName: "commentCount" },
          { fieldValue: "false", fieldName: "hasNestedComment" },
        ],
        path: ["23017:non_grouping"],
      },
    ],
    summaryRowData: [],
    aggregation: [],
    isTruncated: false,
  },
  pagination: {
    totalValues: 14,
    totalPages: 1,
    currentPage: 1,
    pageRowLimit: 100,
  },
  workflowStatusCounts: [
    {
      status: "awaitingIssue",
      title: "Awaiting Issue",
      count: 6,
      active: false,
    },
    { status: "unmatched", title: "Unmatched", count: 0, active: false },
    { status: "mismatched", title: "Mismatched", count: 2, active: false },
    { status: "alleged", title: "Alleged", count: 0, active: false },
    { status: "matched", title: "Matched", count: 6, active: false },
    { status: "cancelled", title: "Cancelled", count: 0, active: false },
    { status: "rejected", title: "Rejected", count: 0, active: false },
  ],
  totalStatusCount: { count: 14, active: true },
  filterByFields: [
    {
      fieldMetaData: {
        fieldName: "period",
        title: "Period",
        description: "Period",
        type: "date",
      },
      filterOptions: [
        {
          fieldValue: "20211130",
          title: "Tue 30-Nov",
          colorKey: "PERIOD_CURRENT_COLOR",
          children: [],
        },
        {
          fieldValue: "20211129",
          title: "Mon 29-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211126",
          title: "Fri 26-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211124",
          title: "Wed 24-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211123",
          title: "Tue 23-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211122",
          title: "Mon 22-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211119",
          title: "Fri 19-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211118",
          title: "Thu 18-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211117",
          title: "Wed 17-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211116",
          title: "Tue 16-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211115",
          title: "Mon 15-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211111",
          title: "Thu 11-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211109",
          title: "Tue 9-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
        {
          fieldValue: "20211103",
          title: "Wed 3-Nov",
          colorKey: "PERIOD_PREVIOUS_COLOR",
          children: [],
        },
      ],
      isRequired: true,
      isMultiSelect: false,
      isSearchable: false,
      selectedFieldValues: ["20211130"],
      quickFilter: true,
    },
    {
      fieldMetaData: {
        fieldName: "recOrientation",
        title: "Counterparty Type",
        description: "Counterparty Type",
      },
      filterOptions: [
        { fieldValue: "oneSided", title: "One Sided", children: [] },
        { fieldValue: "twoSided", title: "Two Sided", children: [] },
      ],
      isRequired: false,
      isMultiSelect: false,
      isSearchable: true,
    },
    {
      fieldMetaData: {
        fieldName: "relationId",
        title: "Relation",
        description: "Relation",
      },
      filterOptions: [
        { fieldValue: "-1", title: "All Counterparties", children: [] },
        {
          fieldValue: "10888963",
          title: faker.finance.accountName(),
          children: [],
          tooltip: faker.finance.transactionDescription(),
        },
        {
          fieldValue: "10888978",
          title: faker.finance.accountName(),
          children: [],
          tooltip: faker.finance.transactionDescription(),
        },
      ],
      isRequired: true,
      isMultiSelect: false,
      isSearchable: true,
      selectedFieldValues: ["-1"],
      quickFilter: true,
    },
  ],
};

export { userInfo, clientGrouped, menuTree, table };
