import {
  FILTER_TYPE_OF_SFT_KEY,
  FILTER_COLLATERAL_BREAK_KEY,
  FILTER_TRADE_BREAK_KEY,
  FILTER_PARTICIPANT_ID_KEY,
} from "../common/constants/filters";

// Sets View Menu
export const streetAndAllocationView = Object.freeze({
  label: 'Street and Allocation View',
  shortLabel: 'Street View',
});
export const allocationOnlyView = Object.freeze({
  label: 'Allocation Only View',
  shortLabel: 'Allocation View'
});
export const setsViewMenuItems = Object.freeze([streetAndAllocationView, allocationOnlyView]);

// Filters
export const HAS_BROKEN_TRADES = 'has_broken_items';
export const HAS_BROKEN_TRADES_COLLATERAL = 'colHasBrokenItems';
export const PHASE_FILTER_KEY = 'Phases';

// TODO: would be better if api sent a boolean rather than a string: SFTR-1681. 
export const IS_BROKEN_FLAG = 'Y';
export const allOptionLabel = 'All';

export const queryFieldNames = Object.freeze({
  BREAKS_ONLY: 'breaksOnly',
  PERIOD: 'period',
  RELATION: 'relation',
  CURRENCY: 'currency',
  REC_ASSET_ID: 'assetId',
  SET_ID: 'setId',
  COMMENTS_ONLY: 'commentsOnly',
});

const queryFilters = Object.freeze([
  queryFieldNames.PERIOD,
  queryFieldNames.RELATION,
  queryFieldNames.CURRENCY,
]);

const customFilters = Object.freeze([
  FILTER_TYPE_OF_SFT_KEY,
  FILTER_COLLATERAL_BREAK_KEY,
  FILTER_TRADE_BREAK_KEY,
  FILTER_PARTICIPANT_ID_KEY,
]);
export const assetFilterByFieldsStructure = Object.freeze({
  queryFilters,
  breaksOnlyToggle: HAS_BROKEN_TRADES,
  customFilters,
});

// Misc
export const generateOverlayNoRowsTemplate = (text) => `<span style="color: #32374C; font-size: 16px;">${text}</span>`;

export const NO_ROWS_FOR_RECONCILIATION_OVERLAY = 'No reconciliation data could be found';
export const NO_ROWS_FOR_RECONCILIATION_FILTERS_OVERLAY = 'No reconciliation data matches the current filters';

export const SHOW_MORE_TOOLTIP = 'Show more details';
export const COPY_TO_CLIPBOARD = 'Copy to clipboard';

// These are the rec service APIs for each product
export const REC_SERVICES = Object.freeze({
  SFTR: 'recReader',
  COACS_IC: 'coacs',
});

// These are the rec types that are used in create action types for dynamic action creation
export const REC_TYPES = Object.freeze({
  SFTR_TRADE: 'SFTR_TRADE',
  SFTR_COLLATERAL: 'SFTR_COLLATERAL',
  SFTR_MARGIN: 'SFTR_MARGIN',
  COACS_IC: 'COACS_IC',
});

// These are the top level slices of the redux store for products
export const APP_SLICES = Object.freeze({
  SFTR: 'sftr',
  COACS_IC: 'coacs',
});

// These are the sub level slices of the redux store for rec types of products
export const STATE_SLICES = Object.freeze({
  SFTR_TRADE: 'trade',
  SFTR_COLLATERAL: 'collateral',
  SFTR_MARGIN: 'margin',
  COACS_IC: 'reconciliations',
});

// Defines the permitted 'levels' conversations can be saved at
export const CONVERSATION_LEVELS = Object.freeze({
  SECURITY: 'security',
});

export const actionTypes = {
  SELECT_ASSET_ROW: 'SELECT_ASSET_ROW',
  DESELECT_ASSET_ROW: 'DESELECT_ASSET_ROW',
  SELECT_PERIOD: 'SELECT_PERIOD',
  SELECT_RELATION: 'SELECT_RELATION',
  SELECT_CURRENCY: 'SELECT_CURRENCY',
  FETCH_ASSET_ID_LIST_SUCCESS: 'FETCH_ASSET_ID_LIST_SUCCESS',
  FETCH_ASSET_LIST_BY_ID_SUCCESS: 'FETCH_ASSET_LIST_BY_ID_SUCCESS',
  SET_SORT_MODEL: 'SET_SORT_MODEL',
  SET_SET_LIST: 'SET_SET_LIST',
  CLEAR_SET_LIST: 'CLEAR_SET_LIST',
  SET_ALLOCATIONS_LIST: 'SET_ALLOCATIONS_LIST',
  SET_IS_BREAKS_ONLY: 'SET_IS_BREAKS_ONLY',
  SET_SHOW_LEGAL_IDENTIFIERS: 'SET_SHOW_LEGAL_IDENTIFIERS',
  SET_SET_ID: 'SET_SET_ID',
  CLEAR_SET_ID: 'CLEAR_SET_ID',
  SET_DRILLDOWN_DATA: 'SET_DRILLDOWN_DATA',
  PREPARE_DRILLDOWN_MODAL: 'PREPARE_DRILLDOWN_MODAL',
  CLOSE_DRILLDOWN_MODAL: 'CLOSE_DRILLDOWN_MODAL',
  SET_DRILLDOWN_IS_BREAKS_ONLY: 'SET_DRILLDOWN_IS_BREAKS_ONLY',
  SET_DRILLDOWN_IS_POPULATED_ONLY: 'SET_DRILLDOWN_IS_POPULATED_ONLY',
  SET_PHASES: 'SET_PHASES',
  FETCH_REPORT: 'FETCH_REPORT',
  FETCH_REPORT_FAIL: 'FETCH_REPORT_FAIL',
};

export const TOGGLE_VISIBLE_COLS = Object.freeze({
  TRADE_FULL_LEGAL_IDENTIFIERS: 'counterparty_name',
  COL_FULL_LEGAL_IDENTIFIERS: 'colCounterpartyName',
});

export const TOGGLE_OPTIONS = Object.freeze({
  showFullLegalIdentifiers: 'showFullLegalIdentifiers',
  isCommentsOnly: 'isCommentsOnly',
  commentsOnly: 'commentsOnly', // Similar key names are due to the mishandling of bool as a string on the BE
});

export const CONFIG_DEFINITIONS = Object.freeze({
  REC_SERVICE: 'recService',
  REC_TYPE: 'recType',
  API_NAME: 'apiName',
  APP_SLICE: 'appSlice',
  SLICE: 'slice',
  BROKEN_TRADES_KEY: 'brokenTradesKey',
  CUSTOM_RENDERERS: 'customRenderers',
  ASSET_ROW_DETAIL: 'assetRowDetail',
  SET_ROW_DETAIL: 'setRowDetail',
  FILTERS: 'filters',
  SECURITIES_VIEW: 'securitiesView',
  SETS_VIEW: 'setsView',
  SETS_DRILL_DOWN: 'setsDrillDown',
  COMMENTS: 'comments',
  SERVICE_VISIBILITY: 'serviceVisibility',
  ENABLED_TOGGLE_OPTIONS: 'enabledToggleOptions',
  LEVEL: 'level',
  AUDIT_VIEW: 'auditView',
});
