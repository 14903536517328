import { Set } from "immutable";
import isEqual from "lodash.isequal";
import React, { useEffect, useState } from "react";
import { shouldUseNewSSO } from "pirumconnect/auth/AuthManager";
import {
  connectStorageLocal,
  unconnectStorageLocal,
} from "pirumconnect/storage/local/connectToStorage";
import { getPsSEToken } from "pirumconnect/storage/local/oldToken";
import QueryParams from "pirumconnect/storage/local/QueryParams";

const getClientIds = () => {
  const data = shouldUseNewSSO() ? QueryParams.getQueryParams() : getPsSEToken();
  const activeClientIds = Array.isArray(data.clientIds) ? [...data.clientIds] : [data.clientIds];
  return {
    activeClientIds,
  };
};

//Please consider very carefully when you don't want to force refresh
//This is built as a safety catch to refresh components when a different client is selected
//Avoids showing the wrong data to the client
export default function withStorageLocal(
  WrappedComponent,
  forceRefresh = true,
  onClientsChanged = () => {}
) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = getClientIds();
      this.setComponentState = this.setComponentState.bind(this);
    }

    setComponentState() {
      this.setState(getClientIds());
    }

    componentDidMount() {
      connectStorageLocal(this.setComponentState);
    }

    componentWillUnmount() {
      unconnectStorageLocal(this.setComponentState);
    }

    componentDidUpdate(_, prevState) {
      const oldClientIds = Set(prevState.activeClientIds);
      const newClientIds = Set(this.state.activeClientIds);
      const activeClientIdsChanged = !newClientIds.equals(oldClientIds);
      if (activeClientIdsChanged) {
        onClientsChanged();
      }
    }

    shouldComponentUpdate(nextProps, nextState) {
      return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    render() {
      if (!forceRefresh) {
        return <WrappedComponent {...this.props} {...this.state} />;
      }
      let key = "";
      try { key = JSON.stringify(this.state); } catch (e) {}
      return <WrappedComponent {...this.props} {...this.state} key={key} />;
    }
  };
}

export function useStorageLocal() {
  const [localStorage, setLocalStorage] = useState({});

  useEffect(() => {
    const callback = () => setLocalStorage(getClientIds());
    connectStorageLocal(callback);

    return () => unconnectStorageLocal(callback);
  }, [localStorage]);

  const key = JSON.stringify(localStorage);

  return {
    localStorage,
    key,
  };
}
