import services from 'services/services';
import { apiNames, apiOperatonIds } from 'constants/api/collateralApi';
import { prepareFiltersRequest } from 'utils/parseUtils';

export function fetchCollateralSummaries(dispatch, params) {
  const request = {
    filterBy: params ? prepareFiltersRequest(params) : [],
  };
  return services.collateralProfile(apiNames.BROWSER, apiOperatonIds.COLLATERAL_PROFILE_BROWSER_AG)(dispatch, { request });
}

export function deleteSchedule(dispatch, requestParams) {
  const { operationId, ...params } = requestParams;
  return services.collateralProfile(apiNames.BROWSER, operationId, true)(dispatch, { request: params });
}

export function copySchedule(dispatch, requestParams) {
  const { operationId, ...params } = requestParams;
  return services.collateralProfile(apiNames.BROWSER, operationId, true)(dispatch, { request: params });
}

/**
 * Fetches form fields, options, and selected options (if applicable i.e. not a creating a new schedule). 
 * This is used to get the form structure for creating a new schedule, and 
 * also to get form field options that are dependent on the selection of
 * an option in another field
 */

export function fetchScheduleTypes(dispatch) {
  return services.collateralProfile(apiNames.ATTRIBUTES, apiOperatonIds.TYPES)(dispatch);
}

export function fetchScheduleBuildFormWithLimits(dispatch, params) {
  return services.collateralProfile(apiNames.ATTRIBUTES, apiOperatonIds.FORM_WITH_LIMITS, true)(dispatch, { request: params });
}

export function fetchEditScheduleBuilderFormWithLimits(dispatch, params) {
  return services.collateralProfile(apiNames.ATTRIBUTES, apiOperatonIds.EDIT_WITH_LIMITS, true)(dispatch, { request: params });
}

export function saveScheduleBuilderFormWithLimits(dispatch, params) {
  return services.collateralProfile(apiNames.ATTRIBUTES, apiOperatonIds.SAVE_WITH_LIMITS, true)(dispatch, { request: params });
}

export function fetchProfileSummary(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.COLLATERAL_PROFILE_SUMMARY)(dispatch, { request: params });
}

export function fetchRuleTypes(dispatch) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.ELIGIBILITY_RULE_TYPES)(dispatch);
}

export function fetchBaseRuleTypes(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.RULE_TYPES)(dispatch, { request: params });
}

export function fetchRuleValues(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.RULE_OPERATOR_VALUES)(dispatch, { request: params });
}

export function saveRule(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.SAVE_RULE, true)(dispatch, { request: params });
}

export function editRuleName(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.EDIT_RULE_NAME, true)(dispatch, { request: params });
}

export function fetchRuleSummaries(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.RULE_SUMMARIES)(dispatch, { request: params });
}

export function fetchRule(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.FIND_RULE)(dispatch, { request: params });
}

export function deleteRule(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.DELETE_RULE, true)(dispatch, { request: params });
}

export function copyRule(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.COPY_RULE)(dispatch, { request: params });
}

export function fetchLogicalOperators(dispatch) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.LOGICAL_OPERATORS)(dispatch);
}

export function addNewRule(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.ADD_RULE)(dispatch, { request: params });
}

export function publishSchedule(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.PUBLISH, true)(dispatch, { request: params });
}

export function fetchLimitTypes(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.LIMIT_TYPES)(dispatch, { request: params });
}

export function fetchLimitOptions(dispatch, params) {
  return services.collateralProfile(apiNames.RULES_EDITOR, apiOperatonIds.LIMIT_OPTIONS, true)(dispatch, { request: params });
}