import { Map } from 'immutable';
import { streetAndAllocationView, allocationOnlyView } from 'reconciliation/constants';
import { actionTypes } from 'reconciliation/constants';
import { createReducer } from '@reduxjs/toolkit';
import { createNamedAction } from 'utils/helpers';

const initialFilterStateObject = Map({
  setList: null,
  selectedSetId: null,
  currentView: streetAndAllocationView,
  isBreaksOnly: false,
  showFullLegalIdentifiers: false,
});

const initialState = Map(initialFilterStateObject);

export const createSetsViewReducerWithNamedType = (recType) => createReducer(initialState, {
  [createNamedAction(actionTypes.SET_SET_LIST, recType)]: (state, action) => {
    return state
      .set('setList', {
        table: action.payload.table,
        filterByFields: action.payload.filterByFields,
      })
      .set('currentView', streetAndAllocationView);
  },
  [createNamedAction(actionTypes.CLEAR_SET_LIST, recType)]: (state) => {
    return state.set('setList', null);
  },
  [createNamedAction(actionTypes.SET_SET_ID, recType)]: (state, action) => {
    return state.set('selectedSetId', action.payload);
  },
  [createNamedAction(actionTypes.SET_ALLOCATIONS_LIST, recType)]: (state, action) => {
    return state
      .set('setList', {
        table: action.payload.table,
        filterByFields: action.payload.filterByFields,
      })
      .set('currentView', allocationOnlyView);
  },
  [createNamedAction(actionTypes.SET_IS_BREAKS_ONLY, recType)]: (state, action) => {
    return state.set('isBreaksOnly', action.payload);
  },
  [createNamedAction(actionTypes.SET_SHOW_LEGAL_IDENTIFIERS, recType)]: (state, action) => {
    return state.set('showFullLegalIdentifiers', action.payload);
  },
});
