import { combineReducers } from 'redux';
import { createRecReducerWithNamedType } from 'reconciliation/common/containers/SecuritiesView/reducer';
import { createSetsViewReducerWithNamedType } from 'reconciliation/common/containers/SetsView/reducer';
import { createSetsDrillDownReducerWithNamedType } from 'reconciliation/common/containers/SetsDrillDown/reducer';
import { REC_TYPES } from 'reconciliation/constants';

const marginReducer = combineReducers({
  securitiesView: createRecReducerWithNamedType(REC_TYPES.SFTR_MARGIN),
  setsView: createSetsViewReducerWithNamedType(REC_TYPES.SFTR_MARGIN),
  setsDrillDown: createSetsDrillDownReducerWithNamedType(REC_TYPES.SFTR_MARGIN),
});

export default marginReducer;
