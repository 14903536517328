import MarginReconciliationAdapter from './servicesAdapter';
import services from 'services/services';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';


export function fetchMarginReconciliation(dispatch, params) {
  const adapter = new MarginReconciliationAdapter();
  const request = MarginReconciliationAdapter.prepareRequest(params);
  return services.query(apiNames.MARGIN_RECONCILIATION, apiOperatonIds.MARGIN_RECONCILIATION, true)(dispatch, { request })
    .then(result => adapter.parseAPIResponse(result));
}

export function fetchMarginReconciliationFilters(dispatch, params) {
  const adapter = new MarginReconciliationAdapter();
  const request = MarginReconciliationAdapter.prepareRequest(params);

  return services.query(apiNames.MARGIN_RECONCILIATION, apiOperatonIds.MARGIN_RECONCILIATION_FILTERS, true)(dispatch, { request })
    .then(result => {
      return adapter.parseAPIResponse(result);
    });
}

export function fetchMarginReconciliationSpreadsheet(dispatch, params) {
  const request = MarginReconciliationAdapter.prepareRequest(params);
  return services.query(apiNames.MARGIN_RECONCILIATION, apiOperatonIds.MARGIN_RECONCILIATION_SPREADSHEET)(dispatch, { request })
    .then(result => result);
}
