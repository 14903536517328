import BaseBreaksAdapter from './BaseBreaksAdapter';

class BreaksTrendAdapter extends BaseBreaksAdapter {

  parseResponseData(apiResponse) {
    /**
     * Parsing date into more convienient Date object
     */
    Object.keys(apiResponse.data).reduce((acc, key) => {
      acc[key].date =  new Date(key.substr(0,4)+'-'+key.substr(4,2)+'-'+key.substr(6,2));
      return acc;
    }, apiResponse.data);

    return super.parseResponseData(apiResponse);
  }

}

export default BreaksTrendAdapter;
