export const ID_TOKEN_NAME = 'idToken';
export const IMP_TOKEN_NAME = 'impersonationToken';
export const PIRUM_CAPABILITIES = 'pirum-capabilities';
export const CURRENT_URL = 'currentURL';
export const SSO_SESSION = 'ssoSessionStatus';
export const QUERY_PARAMS = 'queryParams';
export const PIRUM_MODE_SELECT_URL = '/mode-select';

// NOTE: this MUST be the same what is in the server-side php,
//       see Pirum\SSO\Site::SESS_TOKEN_NAME
export const SESS_TOKEN_NAME = 'psSE';

export const pirumModes = {
  DEMO: 'demo',
  SUPPORT: 'support',
  MODE_SELECT: 'mode-select',
};
