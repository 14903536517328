import services from '../../services/services';
import {
  apiNames,
  apiOperatonIds,
} from 'constants/api/authApi';

export function getClientsList(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.CLIENTS)(dispatch, req);
}

export function getUsersList(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.USERS)(dispatch, req);
}

export function impersonateUser(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.IMPERSONATE)(dispatch, req);
}

export function stopImpersonation(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.STOP_IMPERSONATION)(dispatch, req);
}

export function selectSupportClient(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.SELECT_BANK)(dispatch, req);
}

export function deselectSupportClient(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.LEAVE_SUPPORT)(dispatch, req);
}

export function fetchToken(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.TOKENS)(dispatch, req);
}

export function fetchRefreshToken(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.REFRESH)(dispatch, req);
}

export function fetchLoginURL(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.AUTHORIZATION_URL)(dispatch, req);
}

export function fetchLogoutURL(dispatch, req) {
  return services.auth(apiNames.AUTH, apiOperatonIds.LOGOUT)(dispatch, req);
}