import appLogger from "pirumconnect/logger";

export default class StorageUtils {
  static parseToken(tokenName) {
    try {
      return JSON.parse(localStorage.getItem(tokenName));
    }
    catch(error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      appLogger.fatalException({
        where: 'parseToken',
        what: 'Token parse exception for: [' + tokenName + ']',
        vars: {
          token: localStorage.getItem(tokenName),
        },
      }, error);
      return null;
    }
  }
  static setToLocalStore(tokenName, token) {
    try {
      localStorage.setItem(tokenName, JSON.stringify(token));
    }
    catch(error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      appLogger.fatalException({
        where: 'setToLocalStore',
        what: 'Token set token exception for: [' + tokenName + ']',
        vars: {
          token: localStorage.getItem(tokenName),
        },
      }, error);
      return {};
    }
  }
  static getStorageArgs(storageObject, keys) {
    return !storageObject
      ? {}
      : (keys || []).reduce((args, key) => {
        args[key] = storageObject[key];
        return args;
      }, {});
  }
}