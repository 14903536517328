import React from 'react';
import styledComponents from './styles';
import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';
import { ReactComponent as Tick } from 'assets/images/tick-alt.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/error-icon.svg';
import { ReactComponent as TimerIcon } from 'assets/images/timer.svg';

const icons = {
  'filter': () => (
    <styledComponents.FilterWrapper>
      <FilterIcon />
    </styledComponents.FilterWrapper>
  ),
  'success': Tick,
  'pending': TimerIcon,
  'error': () => (
    <styledComponents.ErrorWrapper>
      <ErrorIcon />
    </styledComponents.ErrorWrapper>
  ),
};

export default function Icon ({icon, type}) {
  const SvgIcon = icons[icon];
  return (
    <styledComponents.IconWrapper type={type}>
      <SvgIcon />
    </styledComponents.IconWrapper>
  );
}