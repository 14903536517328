
export const API_REQUEST_FAILED_VAR = '{apiId}';
export const API_REQUEST_FAILED_TEMPLATE = 'API_REQUEST_FAILED_' + API_REQUEST_FAILED_VAR;
export const API_REQUEST_ABOUT_TO_SEND = 'API_REQUEST_ABOUT_TO_SEND';
export const API_REQUEST_RESOLVED = 'API_REQUEST_RESOLVED';
export const API_REQUEST_FAILED = 'API_REQUEST_FAILED';
export const CLEAR_API_ERROR = 'CLEAR_API_ERROR';

export const FETCH_APP_INIT_DATA_COMPLETED = 'FETCH_APP_INIT_DATA_COMPLETED';

export const REVOKE_USER_AUTHENTICATION = 'REVOKE_USER_AUTHENTICATION';
export const REVOKE_USER_AUTHORIZATION = 'REVOKE_USER_AUTHORIZATION';
export const AUTHENTICATE_USER_COMPLETED = 'AUTHENTICATE_USER_COMPLETED';
export const AUTHORIZE_USER_COMPLETED = 'AUTHORIZE_USER_COMPLETED';

export const FETCH_VENUE_FULL_SUMMARY_SUCCESS = 'FETCH_VENUE_FULL_SUMMARY_SUCCESS';

export const LOGIN_SWITCH_FORM = 'LOGIN_SWITCH_FORM';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE_SUCCESS = 'LOGIN_RESPONSE_SUCCESS';
export const LOGIN_RESPONSE_ERROR = 'LOGIN_RESPONSE_ERROR';

export const AGREE_TERMS_RESPONSE_SUCCESS = 'AGREE_TERMS_RESPONSE_SUCCESS';
export const AGREE_TERMS_RESPONSE_ERROR = 'AGREE_TERMS_RESPONSE_ERROR';

export const LOGIN_DEVICE_FORM_REQUEST = 'LOGIN_DEVICE_FORM_REQUEST';
export const LOGIN_DEVICE_FORM_RESPONSE_SUCCESS = 'LOGIN_DEVICE_FORM_RESPONSE_SUCCESS';

export const LOGIN_DEVICE_AUTH_REQUEST = 'LOGIN_DEVICE_AUTH_REQUEST';
export const LOGIN_DEVICE_AUTH_RESPONSE_SUCCESS = 'LOGIN_DEVICE_AUTH_RESPONSE_SUCCESS';
export const LOGIN_DEVICE_AUTH_RESPONSE_ERROR = 'LOGIN_DEVICE_AUTH_RESPONSE_ERROR';

export const LOGIN_DATA_REQUEST = 'LOGIN_DATA_REQUEST';
export const LOGIN_DATA_RESPONSE_SUCCESS = 'LOGIN_DATA_RESPONSE_SUCCESS';
export const LOGIN_DATA_RESPONSE_ERROR = 'LOGIN_DATA_RESPONSE_ERROR';

export const LOGIN_PASSWORD_FORM_REQUEST = 'LOGIN_PASSWORD_FORM_REQUEST';
export const LOGIN_PASSWORD_FORM_RESPONSE_SUCCESS = 'LOGIN_PASSWORD_FORM_RESPONSE_SUCCESS';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RESPONSE_SUCCESS = 'RESET_PASSWORD_RESPONSE_SUCCESS';
export const RESET_PASSWORD_RESPONSE_ERROR = 'RESET_PASSWORD_RESPONSE_ERROR';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_RESPONSE_SUCCESS = 'CHANGE_PASSWORD_RESPONSE_SUCCESS';
export const CHANGE_PASSWORD_RESPONSE_ERROR = 'CHANGE_PASSWORD_RESPONSE_ERROR';

export const MODE_CLIENTS_LIST_REQUEST = 'MODE_CLIENTS_LIST_REQUEST';
export const MODE_CLIENTS_LIST_SUCCESS = 'MODE_CLIENTS_LIST_SUCCESS';
export const MODE_CLIENTS_LIST_ERROR = 'MODE_CLIENTS_LIST_ERROR';

export const MODE_USERS_LIST_REQUEST = 'MODE_USERS_LIST_REQUEST';
export const MODE_USERS_LIST_SUCCESS = 'MODE_USERS_LIST_SUCCESS';
export const MODE_USERS_LIST_ERROR = 'MODE_USERS_LIST_ERROR';

export const MODE_SELECT_REQUEST = 'MODE_SELECT_REQUEST';
export const MODE_SELECT_SUCCESS = 'MODE_SELECT_SUCCESS';
export const MODE_SELECT_ERROR = 'MODE_SELECT_ERROR';

export const ACCOUNT_CLIENTS_LIST_REQUEST = 'ACCOUNT_CLIENTS_LIST_REQUEST';
export const ACCOUNT_CLIENTS_LIST_SUCCESS = 'ACCOUNT_CLIENTS_LIST_SUCCESS';

export const SET_ACTIVE_VALID_CLIENT_IDS = "SET_ACTIVE_VALID_CLIENT_IDS";

export const ACCOUNT_UPDATE_CLIENT_IDS = 'ACCOUNT_UPDATE_CLIENT_IDS';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const UPDATE_COUNTER_PARTY_ACTIVE_IDS_SUCESS = 'UPDATE_COUNTER_PARTY_ACTIVE_IDS_SUCESS';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const MODAL_ERROR = 'MODAL_ERROR';

export const FETCH_DASHBOARD_VENUE_SUMMARY_SUCCESS = 'FETCH_DASHBOARD_VENUE_SUMMARY_SUCCESS';
export const FETCH_DASHBOARD_BREAKS_LATEST_SUCCESS = 'FETCH_DASHBOARD_BREAKS_LATEST_SUCCESS';

export const FETCH_SBLREX_BREAKS_TREND_SUCCESS = 'FETCH_SBLREX_BREAKS_TREND_SUCCESS';
export const FETCH_SBLREX_BREAKS_COUNTERPARTY_SUCCESS = 'FETCH_SBLREX_BREAKS_COUNTERPARTY_SUCCESS';

export const SET_FILTER_QUICK_SELECT = 'SET_FILTER_QUICK_SELECT';
export const SET_FILTER_PANE_OPEN = 'SET_FILTER_PANE_OPEN';
export const SET_FILTER_BUTTON_HOVER = 'SET_FILTER_BUTTON_HOVER';
export const SET_FILTERS = 'SET_FILTERS';

export const SET_FILTER_QUICK_SELECT_COMMON = 'SET_FILTER_QUICK_SELECT_COMMON';
export const SET_FILTER_PANE_OPEN_COMMON = 'SET_FILTER_PANE_OPEN_COMMON';
export const SET_FILTER_BUTTON_HOVER_COMMON = 'SET_FILTER_BUTTON_HOVER_COMMON';
export const SET_FILTERS_COMMON = 'SET_FILTERS_COMMON';

export const FETCH_PLATFORM_DETAILS_SUCCESS = 'FETCH_PLATFORM_DETAILS_SUCCESS';

export const FETCH_SCHEDULE_VIEWER_SUCCESS = 'FETCH_SCHEDULE_VIEWER_SUCCESS';
export const SET_SCHEDULE_VIEWER_FOCUSED_SECTION = 'SET_SCHEDULE_VIEWER_FOCUSED_SECTION';
export const TOGGLE_SCHEDULE_VIEWER_DRILLDOWN = 'TOGGLE_SCHEDULE_VIEWER_DRILLDOWN';
