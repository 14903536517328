import React from "react";
import { EventActions } from "repo/models/EventAction";

export const EVENT_ACTIONS_TEXT: Record<EventActions, JSX.Element> = {
  [EventActions.Issue]: (
    <span>
      By Issuing this trade you will be Issuing the trade to your counterpart
      and the trade status will be updated to <strong>Unmatched</strong>.
    </span>
  ),
  [EventActions.Mismatch]: (
    <span>
      By Mismatching this trade, the trade status will be updated to{" "}
      <strong>Mismatched</strong>.
    </span>
  ),
  [EventActions.Reject]: (
    <span>
      By Rejecting this trade, the trade status will be updated to{" "}
      <strong>Rejected</strong>.
    </span>
  ),
  [EventActions.Match]: (
    <span>
      By Matching this trade, the trade status will be updated to{" "}
      <strong>Matched</strong>.
    </span>
  ),
  [EventActions.Unmatch]: (
    <span>
      By Unmatching this trade, the trade status will be updated to{" "}
      <strong>Unmatched</strong>.
    </span>
  ),
  [EventActions.Allege]: (
    <span>
      By Alleging this trade, the trade status will be updated to{" "}
      <strong>Alleged</strong>.
    </span>
  ),
};
