import FileSaver from "file-saver";
import * as svc from "./services";
import { setFilters } from "actions/common/filtersActions";
import { TABLE_TYPE_OPTIONS } from "./Page/config";
import { sessionStorageVariables } from "./constants";

export const actionTypes = {
  FETCH_ELIGIBILITY_RECONCILIATION_SUCCESS:
    "FETCH_ELIGIBILITY_RECONCILIATION_SUCCESS",
  FETCH_BENEFICIAL_OWNER_SUCCESS: "FETCH_BENEFICIAL_OWNER_SUCCESS",
  FETCH_COMBINED_SUCCESS: "FETCH_COMBINED_SUCCESS",
};

const fetchEligiblityCheckerSuccess = (payload) => ({
  type: actionTypes.FETCH_ELIGIBILITY_RECONCILIATION_SUCCESS,
  payload,
});

const fetchBeneficialCheckerSuccess = (payload) => ({
  type: actionTypes.FETCH_BENEFICIAL_OWNER_SUCCESS,
  payload,
});

const fetchCombinedCheckerSuccess = (payload) => ({
  type: actionTypes.FETCH_COMBINED_SUCCESS,
  payload,
});

export function fetchEligibilityReconciliationAg(params, clearPrevious = true) {
  return (dispatch) => {
    sessionStorage.setItem(
      sessionStorageVariables.TABLE_TYPE,
      params.filterBy.tableType
    );
    switch (params.filterBy.tableType) {
      case TABLE_TYPE_OPTIONS.BENEFICIAL_OWNER.value:
        return svc.fetchBeneficialOwnerAg(dispatch, params).then((payload) => {
          dispatch(fetchBeneficialCheckerSuccess(payload));
          dispatch(setFilters(payload, clearPrevious));
        });
      case TABLE_TYPE_OPTIONS.COMBINED.value:
        return svc
          .fetchCombinedAg(dispatch, params)
          .then((payload) => {
            dispatch(fetchCombinedCheckerSuccess(payload));
            dispatch(setFilters(payload, clearPrevious));
          });
      case TABLE_TYPE_OPTIONS.RECONCILIATION.value:
      default:
        return svc
          .fetchEligibilityReconciliationAg(dispatch, params)
          .then((payload) => {
            dispatch(fetchEligiblityCheckerSuccess(payload));
            dispatch(setFilters(payload, clearPrevious));
          });
    }
  };
}

export function fetchEligibilityReconciliationFilters(params) {
  return (dispatch) => {
    return svc.fetchEligibilityReconciliationFilters(dispatch, params);
  };
}

export function fetchEligibilityReconciliationSpreadsheet(params) {
  return (dispatch) => {
    return svc
      .fetchEligibilityReconciliationSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers["content-disposition"]
          .match(/".*"/gi)[0]
          .replace(/"/g, "");
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function fetchBeneficialOwnerSpreadsheet(params) {
  return (dispatch) => {
    return svc
      .fetchBeneficialOwnerSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers["content-disposition"]
          .match(/".*"/gi)[0]
          .replace(/"/g, "");
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function fetchCombinedSpreadsheet(params) {
  return (dispatch) => {
    return svc
      .fetchCombinedSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers["content-disposition"]
          .match(/".*"/gi)[0]
          .replace(/"/g, "");
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}
