import { LimitOffsetPagination } from "common/types/pagination";
import { Map } from "immutable";
import { StatusType } from "repo/models/EventStatus";
import { actionTypes, AllActions } from "./actions";
import {
  DetailsHeader,
  DetailsOneSidedForm,
  DetailsTwoSidedTable,
  MatchingManagerTable,
  TableSortModel,
  WorkflowStatusCount,
} from "./types";
import { ResponseError } from "common/types/APIResponses";
import { RepoPermissions, FilterModel } from "./types";
import { CommentInfo } from "common/Comments/types";

export interface MatchingManagerState extends Map<string, any> {
  table?: MatchingManagerTable;
  pagination: LimitOffsetPagination;
  activeStatusFilters: StatusType[];
  detailsCommentInfo?: CommentInfo;
  detailsOneSided?: DetailsOneSidedForm;
  detailsHeader?: DetailsHeader;
  detailsTwoSidedData: any; // NOT specified yet
  detailsTwoSidedTable?: DetailsTwoSidedTable;
  workflowStatusCounts: WorkflowStatusCount[];
  isSubscribed?: boolean;
  detailsError: ResponseError;
  tableSortModel: TableSortModel;
  repoPermission: RepoPermissions[];
  set(key: string, value: any): this;
  updateWorkflowStatusSuccess: boolean;
  updateWorkflowStatusError: boolean;
  filterModel: FilterModel;
}

export const initialStateObj = {
  table: undefined,
  pagination: {
    totalValues: 0,
    currentPage: 1,
    totalPages: 0,
    pageRowLimit: 50,
  },
  activeStatusFilters: [],
  detailsHeader: undefined,
  detailsCommentInfo: undefined,
  detailsOneSided: undefined,
  detailsTwoSidedData: {},
  detailsTwoSidedTable: undefined,
  workflowStatusCounts: [],
  isSubscribed: undefined,
  repoPermission: undefined,
  detailsError: undefined,
  tableSortModel: {},
  updateWorkflowStatusSuccess: undefined,
  updateWorkflowStatusError: undefined,
  filterModel: {},
};

const initialState = Map(initialStateObj) as MatchingManagerState;

const matchingManager = (
  state = initialState,
  action: AllActions
): MatchingManagerState => {
  switch (action.type) {
    case actionTypes.FETCH_MATCHING_MANAGER_SUCCESS:
      return state
        .set("table", action.payload.table)
        .set("pagination", action.payload.pagination)
        .set("workflowStatusCounts", action.payload.workflowStatusCounts);
    case actionTypes.FETCH_EVENTS_DETAILS_ONE_SIDED_SUCCESS:
      return state
        .set("detailsCommentInfo", action.payload.commentInfo)
        .set("detailsOneSided", action.payload.forms)
        .set("detailsFormHeaders", action.payload.formHeaders)
        .set("detailsOvernightData", action.payload.overnightData);
    case actionTypes.FETCH_EVENTS_DETAILS_TWO_SIDED_DATA_SUCCESS:
      return state.set("detailsTwoSidedData", action.payload);
    case actionTypes.FETCH_EVENTS_DETAILS_TWO_SIDED_TABLE_SUCCESS:
      return state
        .set("detailsCommentInfo", action.payload.commentInfo)
        .set("detailsTwoSidedSummaries", action.payload.summaries)
        .set("detailsHeader", action.payload.header)
        .set("detailsOvernightData", action.payload.overnightData);
    case actionTypes.CLEAR_DETAILS_DATA:
      return state
        .set("detailsCommentInfo", undefined)
        .set("detailsTwoSidedTables", undefined)
        .set("detailsTwoSidedData", {})
        .set("detailsOneSided", undefined)
        .set("detailsHeader", undefined)
        .set("detailsFormHeaders", undefined)
        .set("detailsError", undefined)
        .set("updateWorkflowStatusSuccess", undefined)
        .set("updateWorkflowStatusError", undefined);
    case actionTypes.SET_ACTIVE_STATUS_FILTERS:
      return state.set("activeStatusFilters", action.payload);
    case actionTypes.UPDATE_TABLE_SORT_MODEL:
      return state.set("tableSortModel", action.payload);
    case actionTypes.FETCH_USER_INFORMATION_SUCCESS:
      return state
        .set("isSubscribed", action.payload.userInformation.isSubscribed)
        .set("repoPermission", action.payload.userInformation.repoPermission);
    case actionTypes.FETCH_FEATURES_SUCCESS:
      return state
        .set("features", action.payload);
    case actionTypes.FETCH_EVENTS_DETAILS_FAILED:
      return state.set("detailsError", action.payload);
    case actionTypes.UPDATE_WORKFLOW_STATUS_SUCCESS:
      return state.set("updateWorkflowStatusSuccess", true);
    case actionTypes.UPDATE_WORKFLOW_STATUS_ERROR:
      return state.set("updateWorkflowStatusError", action.payload);
    case actionTypes.CLEAR_WORKFLOW_STATUS_STATE:
      return state
        .set("updateWorkflowStatusSuccess", undefined)
        .set("updateWorkflowStatusError", undefined);
        case actionTypes.SET_FILTER_MODEL:
      return state.set("filterModel", action.payload);
    default:
      return state;
  }
};

export default matchingManager;
