import { parseFilters, prepareFiltersRequest } from 'utils/parseUtils';
import { getColorById } from 'utils/colorUtils';
import { config } from './servicesAdapterConfig';


class SunburstAdapter {
  parseGroupers(result) {
    const { defaultValue } = config.groupDropdown;
    const groupBy = [ result.groupBy1 || defaultValue,
      result.groupBy2 || defaultValue,
      result.groupBy3 || defaultValue,
      result.groupBy4 || defaultValue,
      result.groupBy5 || defaultValue,
    ];

    return { groupBy };
  }

  getSunburst(result){
    const sunburst = {...result.sunburst};
    sunburst.color = 'rgb(49, 54, 74)';//app bg colour
    sunburst.id =  config.rootId;
    sunburst.elId = '_0';
    if(sunburst.children) {
      sunburst.children = this.parseSunburstChildren(sunburst.children, sunburst.elId);
    }

    return sunburst;
  }

  parseSunburstChildren(children, parentId) {
    const ch = children.map((it, index) => {
      const elId = `${parentId}_${index}`;
      return {
        ...it,
        color: getColorById(it.colorKey).bg,
        elId,
        id: JSON.stringify(it.context),
        ...(it.children ? {children: this.parseSunburstChildren(it.children, elId)} : {}),
      };
    });

    return ch;
  }

  parseData(result) {
    const { groupBy } = this.parseGroupers(result);
    const { apiId, requestId, header, groupingFields } = result;

    return {
      ...parseFilters(result.filterByFields),
      apiId,
      requestId,
      groupingFields,
      groupBy,
      header,
      sunburst:this.getSunburst(result),
    };
  }

  static prepareRequest(params) {
    const filterBy = prepareFiltersRequest(params);
    const groupBy = params.groupBy ? params.groupBy.filter(it => it !== config.groupDropdown.defaultValue) : null;

    const request = {
      filterBy,
      zoomContext: params.breadcrumbs
    };

    if (groupBy) {
      request.groupBy = groupBy;
    }

    return request;
  }
}

export default SunburstAdapter;
