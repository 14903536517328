import { actionTypes } from './actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames } from 'constants/api/queryApi';
import { Map } from 'immutable';


const FETCH_SECURITY_ELIGIBILITY_FAIL = getFetchFailActionByApi(apiNames.SECURITY_ELIGIBILITY);

export const initialState = Map({
  maxNumberOfRequestedSearchIds: 0,
});

export const eligibilityChecker = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_SECURITY_ELIGIBILITY_SUCCESS:
      return state.withMutations(st =>
        st.set('maxNumberOfRequestedSearchIds', action.payload.maxNumberOfRequestedSearchIds));

    case FETCH_SECURITY_ELIGIBILITY_FAIL:
      return initialState;

    default:
      return state;
  }
};
