import styled from "styled-components/macro";
import {
  PIRUM_GREEN,
  PIRUM_UI_ALERT,
  PIRUM_UI_BG_1,
  PIRUM_UNDERCOVERED_RED,
} from "styles/colors/brandColors";

const typeColors = {
  primary: PIRUM_GREEN[100],
  warning: PIRUM_UI_ALERT[100],
  error: PIRUM_UNDERCOVERED_RED[100],
};

export const transitionClassName = "toast";

const Container = styled.div`
  position: absolute;
  top: 110px;
  right: 65px;
  z-index: 3;
  overflow: hidden;
  pointer-events: none;
`;

const Message = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 188px;
  height: 57px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.27);
  background-color: ${PIRUM_UI_BG_1[100]};
  border-bottom: 6px solid ${(props) => typeColors[props.type] || "#ffffff"};
  font-size: 14px;
  font-family: "Open Sans Light";
  padding: 0 12px;
  margin: 10px;
  transition: height 0.4s 0.2s ease-out, right 0.2s ease-out,
    filter 0.4s 1s ease-out;
  filter: brightness(1);
  overflow: hidden;
  pointer-events: auto;

  &.${transitionClassName}-enter {
    right: -100%;
    height: 0px;
    filter: brightness(1.1);
  }

  &.${transitionClassName}-enter-active {
    right: 0;
    height: 57px;
    filter: brightness(1);
  }

  &.${transitionClassName}-exit {
    right: 0;
  }

  &.${transitionClassName}-exit-active {
    right: -120%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${(props) => typeColors[props.type] || "#ffffff"};
  border-radius: 50%;
  margin-right: 10px;
  color: ${PIRUM_UI_BG_1[100]};
  flex: 0 0 auto;

  & svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const FilterWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
`;

const ErrorWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: ${typeColors.error};
  background-color: ${PIRUM_UI_BG_1[100]};
`;

const styledComponents = {
  Container,
  Message,
  IconWrapper,
  ErrorWrapper,
  FilterWrapper,
};

export default styledComponents;
