import { actionTypes } from './actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames } from 'constants/api/queryApi';
import { Map } from 'immutable';

const FETCH_MARGIN_CHECKER_FAIL = getFetchFailActionByApi(apiNames.MARGIN_RECONCILIATION);

export const initialState = Map({
  table: {},
});

export const marginReconciliation = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MARGIN_RECONCILIATION_SUCCESS:
      return state.withMutations(st =>
        st.set('table', action.payload.results));
    case FETCH_MARGIN_CHECKER_FAIL:
      return initialState;
    default:
      return state;
  }
};
