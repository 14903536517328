import * as svc from '../services';
import FileSaver from 'file-saver';

export const actionTypes = {
  FETCH_CONFIG_LIST_STARTED: 'configconnect/viewer/FETCH_CONFIG_LIST_STARTED',
  FETCH_CONFIG_LIST_SUCCESS: 'configconnect/viewer/FETCH_CONFIG_LIST_SUCCESS',
  FETCH_OVERVIEW_LIST_SUCCESS: 'configconnect/viewer/FETCH_OVERVIEW_LIST_SUCCESS',
  FETCH_AUDIT_LIST_SUCCESS: 'configconnect/viewer/FETCH_AUDIT_LIST_SUCCESS',
  FETCH_FILTERS_SUCCESS: 'configconnect/viewer/FETCH_FILTERS_SUCCESS',
  FETCH_ADDITIONAL_DETAILS_SUCCESS: 'configconnect/viewer/FETCH_ADDITIONAL_DETAILS_SUCCESS',
  UPDATE_STATUS_MESSAGE: 'configconnect/viewer/UPDATE_STATUS_MESSAGE',
  UPDATE_MODAL_MESSAGE: 'configconnect/viewer/UPDATE_MODAL_MESSAGE',
};

const fetchConfigListDataSuccess = payload => ({
  type: actionTypes.FETCH_CONFIG_LIST_SUCCESS,
  payload
});

const fetchFiltersSuccess = payload => ({
  type: actionTypes.FETCH_FILTERS_SUCCESS,
  payload
});

const fetchConfigListDataStarted = () => ({
  type: actionTypes.FETCH_CONFIG_LIST_STARTED,
});

export function fetchConfigList({configId}) {
  return async (dispatch) =>{
    dispatch(fetchConfigListDataStarted());
    const payload = await svc.fetchConfigList(dispatch, { configId });
    dispatch(fetchConfigListDataSuccess(payload));
  };
}

export function fetchFilters(params) {
  return async (dispatch) =>{
    const payload = await svc.fetchFilters(dispatch, params);
    dispatch(fetchFiltersSuccess(payload));
  };
}

export function fetchAdditionalDetail(params) {
  return async (dispatch) =>{
    const payload = await svc.fetchAdditionalDetail(dispatch, params);
    dispatch(fetchAdditionalDetailSuccess(payload));
  };
}

const fetchAdditionalDetailSuccess = payload => ({
  type: actionTypes.FETCH_ADDITIONAL_DETAILS_SUCCESS,
  payload
});

export const updateStatusMessage = (payload) => ({
  type: actionTypes.UPDATE_STATUS_MESSAGE,
  payload,
});

export const updateModalMessage = (payload) => ({
  type: actionTypes.UPDATE_MODAL_MESSAGE,
  payload,
});

export function confirmApprove(params) {
  return (dispatch) =>{
    return svc.confirmApprove(dispatch, params);
  };
}

export function confirmRelease(params) {
  return (dispatch) =>{
    return svc.confirmRelease(dispatch, params);
  };
}

export function confirmReject(params) {
  return (dispatch) =>{
    return svc.confirmReject(dispatch, params);
  };
}

const fetchOverviewListSuccess = (payload) => ({
  type: actionTypes.FETCH_OVERVIEW_LIST_SUCCESS,
  payload,
});

export function fetchOverviewList({ clientIds }) {
  return async (dispatch) => {
    const payload = await svc.fetchOverviewList(dispatch, { clientIds });
    dispatch(fetchOverviewListSuccess(payload));
  };
}

export function fetchAuditList({ clientIds }) {
  return async (dispatch) => {
    const payload = await svc.fetchAuditList(dispatch, { clientIds });
    dispatch(fetchAuditListSuccess(payload));
  };
}

const fetchAuditListSuccess = (payload) => ({
  type: actionTypes.FETCH_AUDIT_LIST_SUCCESS,
  payload,
});

export function downloadExcel(params) {
  return (dispatch) => {
    return svc.downloadExcel(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}
