import { Map } from "immutable";
import { actionTypes } from "./actions";

export const initialState = Map({
  selectedCounterparty: undefined,
  selectedPeriod: undefined,
  periodFilterOptions: undefined,
  counterpartyFilterOptions: undefined,
});

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_COUNTERPARTY:
      return state.set("selectedCounterparty", action.payload);
    case actionTypes.SET_SELECTED_PERIOD:
      return state.set("selectedPeriod", action.payload);
    case actionTypes.SET_PERIOD_FILTER_OPTIONS:
      return state.set("periodFilterOptions", action.payload);
    case actionTypes.SET_COUNTERPARTY_FILTER_OPTIONS:
      return state.set("counterpartyFilterOptions", action.payload);
    case actionTypes.CLEAR_COMMON_REDUCER:
      return state.clear();
    default:
      return state;
  }
};

export default commonReducer;
