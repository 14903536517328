import { appConfigs } from 'pirumconnect/routing/routesConfig';
import { listToObject } from 'utils/dataUtils';
import { getPsSEToken } from 'pirumconnect/storage/local/oldToken';
import { shouldUseNewSSO } from "pirumconnect/auth/AuthManager";
import QueryParams from 'pirumconnect/storage/local/QueryParams';
import { ALLOWED_CLIENT_IDS } from "../../mocks/allowedClientIDs";

class PlatformDetailsAdapter {

  parsePlatformTree(rootNode, refsByLevel) {
    const {viewId, title, enabled, children} = rootNode;
    const apps = children.map( appData => this.parseApplicationTree(appData, refsByLevel, title));
    refsByLevel.appsList = apps;

    return {
      viewId,
      title,
      enabled,
      apps,
    };
  }

  parseApplicationTree(appNode, refsByLevel, parentTitle) {
    const {viewId, title, allowedClientIds, isMultiClient, children, defaultChildId:defaultSectionViewId} = appNode;

    const isAppViewIdInRoutesConfig = appConfigs[viewId];
    if (!isAppViewIdInRoutesConfig) {
      return {
        allowedClientIds,
        viewId,
        title,
        enabled: false,
        isMultiClient: false,
        path: '',
        sections: [],
      };
    }

    const appRouteConfig = appConfigs[viewId].routes;
    const parentalTitle = this.getParentalTitle(parentTitle, title);
    const path = appRouteConfig.app.path;
    const enabled = appNode.enabled && this.getActiveValidClientIds(allowedClientIds, isMultiClient).length !== 0;

    const sections = (!children) ? []
      : children.map( sectionsData => this.parseSectionTree(sectionsData, refsByLevel, parentalTitle, appRouteConfig, path) );
    refsByLevel.sectionsList = [ ...refsByLevel.sectionsList, ...sections ];

    return {
      allowedClientIds,
      viewId,
      title,
      enabled,
      isMultiClient,
      parentalTitle,
      defaultSectionViewId,
      path,
      ...(sections.length? {sections} : {}),
    };
  }

  parseSectionTree(sectionNode, refsByLevel, parentTitle, appRouteConfig, appPath, ) {
    const {viewId, title, allowedClientIds, isMultiClient, children, defaultChildId} = sectionNode;
    const parentalTitle = this.getParentalTitle(parentTitle, title);

    const sectionConfig = appRouteConfig.sections[viewId];
    if (!sectionConfig) {
      return {
        allowedClientIds,
        viewId,
        title,
        enabled: false,
        isMultiClient,
        parentalTitle,
        path: '',
      };
    }

    const path = appPath + appRouteConfig.sections[viewId].subpath;
    const enabled = sectionNode.enabled && this.getActiveValidClientIds(allowedClientIds, isMultiClient).length !== 0;

    const pages = (!children) ? []
      : children.map( pagesData => this.parsePage(pagesData, parentalTitle, appRouteConfig, path));

    refsByLevel.pagesList = [ ...refsByLevel.pagesList, ...pages ];

    //Use the first enabled page if the defaultChildId page is disabled either by server or also
    //by client, beacuse lack of config, see below in parsePage() logic: "Boolean(pageConfig)").
    const defaultPageViewId = this.getEnabledDefaultPageId(defaultChildId, pages);

    return {
      allowedClientIds,
      viewId,
      title,
      enabled,
      parentalTitle,
      ...defaultPageViewId && {defaultPageViewId},
      path,
      ...(pages.length? {pages} : {}),
      helpPath: sectionConfig.helpPath,
    };
  }

  parsePage(pageNode, parentTitle, appRouteConfig, sectionPath) {
    const {viewId, title, allowedClientIds, isMultiClient, description} = pageNode;
    const enabled = pageNode.enabled && this.getActiveValidClientIds(allowedClientIds, isMultiClient).length !== 0;

    const pageConfig = appRouteConfig.pages[viewId];

    return {
      allowedClientIds,
      viewId,
      title,
      enabled: enabled && Boolean(pageConfig),
      isMultiClient,
      description,
      parentalTitle: this.getParentalTitle(parentTitle, title),
      path: pageConfig ? sectionPath + pageConfig.subpath : '',
      helpPath: pageConfig ? pageConfig.helpPath : '',
    };
  }

  getParentalTitle(parentTitle, title) {
    return title + ' | ' + parentTitle;
  }

  getEnabledDefaultPageId(defaultPageViewId='', pages=[]) {

    const defaultPage = pages.find(it => it.viewId === defaultPageViewId);

    if (defaultPage && defaultPage.enabled) {
      return defaultPageViewId;
    }

    const firstEnabledPage = pages.find(it => it.enabled);
    return firstEnabledPage && firstEnabledPage.viewId;
  }


  parse (menuTree) {
    const refsByLevel = {appsList: [], sectionsList:[], pagesList:[]};
    const platformTree = menuTree ? this.parsePlatformTree(menuTree, refsByLevel) : {};
    const pathsProps = [
      ...refsByLevel.appsList,
      ...refsByLevel.sectionsList,
      ...refsByLevel.pagesList
    ].reduce((acc,it) => ({
      ...acc,
      ...{[it.path]:
          {
            enabled: it.enabled,
            viewId: it.viewId,
            ...(it.helpPath ? {helpPath: it.helpPath} : {}),
          }},
    }));

    return {
      apps: listToObject(refsByLevel.appsList, 'viewId'),
      sections: listToObject(refsByLevel.sectionsList, 'viewId'),
      pages: listToObject(refsByLevel.pagesList, 'viewId'),
      pathsProps,
      platformTree,
    };
  }

  getActiveValidClientIds(allowedClientIds, isMultiClient) {
    // Take session ids and intersect with valid
    const { NODE_ENV, REACT_APP_MOCK_API = "false" } = process.env;
    
    const { clientIds = (NODE_ENV === "development" && REACT_APP_MOCK_API === "true") ? ALLOWED_CLIENT_IDS : [] } = shouldUseNewSSO() ? QueryParams.getQueryParams() : getPsSEToken();
    let allowedIds = clientIds && clientIds.filter(clientId => allowedClientIds.includes(clientId));
    return isMultiClient ? allowedIds : [allowedIds[0]];
  }
}

export default PlatformDetailsAdapter;
