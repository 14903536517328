import React from 'react';
import { connect } from 'react-redux';
import Toast from './Toast';
import styledComponents from './styles';
import { removeToast } from './actions';

function ToastMessages({toasts, removeToast}) {
  return (
    <styledComponents.Container>
      {
        toasts.reverse()
          .map(toast => 
            <Toast {...toast} onAnimationComplete={removeToast.bind(null, toast.key)} />
          )
      }
    </styledComponents.Container>
  );
}

const mapStateToProps = (state) => {
  return {
    toasts: state.app.toast,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeToast: (key) => {
      dispatch(removeToast(key));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessages);