export default class TokenFormatter {

  static format(decodedToken) {
    /**
     * It's convention in JWT to namespace non-standard claims, thus a formatter is required until we fix
     * the way our API handles parameters like clientIds.
     * Ensures clients_ids are moved to the highest level of the token object with
     * the key "clientIds".
     *
     * Under the new sso, the client's client ids come on both the id and impersonation
     * tokens as TOKEN_NAME.pirum.client_ids. This function solves two problems:
     *
     * First, the client ids must be sent in the query parameters of certain api calls as "clientIds"
     * and are picked up from localStorage before the call is made by searching for this key.
     *
     * Second, it allows the client ids to be updated more easily in localStorage by functions
     * that exist for the old auth flow, such as when the user deselects a client in the client
     * list.
     */
    const { pirum } = decodedToken;
    if (!pirum) {
      return decodedToken;
    }
    const newDecodedToken = {...decodedToken};
    const { base_ccy, client_ids } = pirum;
    if (client_ids) {
      newDecodedToken.clientIds = client_ids.map(id => +id);
    }
    if (base_ccy) {
      newDecodedToken.baseCurrency = pirum.base_ccy;
    }
    return newDecodedToken;
  }

}