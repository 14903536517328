import React from "react";
import ClaimsPage from "./pages/Claims";
import DashboardPage from "./pages/Dashboard";
import PaymentsPage from "./pages/Payments";
import ReconciliationsPage from "./pages/Reconciliations";
import withStorageLocal from "hoc/withStorageLocal";
import store from "pirumconnect/store";
import { clearCommonReducer } from "./common/actions";

const appViewId = "coacsConnect";
const sectionsViewIds = {
  DASHBOARD: "coacsDashboard",
  CLAIMS: "coacsClaims",
  PAYMENTS: "coacsPayments",
  RECONCILIATIONS: "coacsReconciliations",
};
const viewIds = {
  app: { CORPORATE_ACTIONS_CONNECT: appViewId },
  sections: sectionsViewIds,
};
const routes = {
  app: {
    viewId: viewIds.app.CORPORATE_ACTIONS_CONNECT,
    path: "/coacsConnect",
  },
  sectionsList: [
    {
      viewId: viewIds.sections.DASHBOARD,
      component: DashboardPage,
      subpath: "/dashboard",
    },
    {
      viewId: viewIds.sections.CLAIMS,
      component: ClaimsPage,
      subpath: "/claims",
      helpPath: "/help/claimsManager.pdf",
    },
    {
      viewId: viewIds.sections.PAYMENTS,
      component: PaymentsPage,
      subpath: "/payments",
      helpPath: "/help/paymentsManager.pdf",
    },
    {
      viewId: viewIds.sections.RECONCILIATIONS,
      component: ReconciliationsPage,
      subpath: "/reconciliations",
    },
  ].map((section) => ({
    ...section,
    component: withStorageLocal(
      ({ storageProps }) => <section.component {...storageProps} />,
      true,
      () => store.dispatch(clearCommonReducer())
    ),
  })),
  pagesList: [],
};
const coacsConnectConfig = {
  appViewId,
  viewIds,
  routes,
};
export default coacsConnectConfig;
