import { actionTypes } from './actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';
import { Map } from 'immutable';


const FETCH_SCHEDULE_VIEWER_FAIL = getFetchFailActionByApi(apiNames.COLLATERAL_PROFILE_VIEWER, apiOperatonIds.COLLATERAL_PROFILE_VIEWER);
const FETCH_SCHEDULE_IMPLIED_VIEWER_FAIL = getFetchFailActionByApi(apiNames.COLLATERAL_PROFILE_VIEWER, apiOperatonIds.COLLATERAL_PROFILE_IMPLIED_VIEWER);

export const initialState = Map({
  header: {},
  sections: [],
  exclusionSections: [],
  commonRules: [],
  focusedSection: 0,
  drilldownMode: false,
  limitSections: [],
  error:null,
  reason:"",
  isEligible: null,
  securityDetails: [],
  margins: [],
});

export const scheduleViewer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.FETCH_SCHEDULE_VIEWER_SUCCESS:
      return state.withMutations(s =>
        s.set('header', action.payload.header)
          .set('sections', action.payload.sections)
          .set('exclusionSections', action.payload.exclusionSections)
          .set('commonRules', action.payload.commonRules)
          .set('focusedSection', 0)
          .set('drilldownMode', false)
          .set('limitSections', action.payload.limitSections)
          .set('reason', action.payload.reason)
          .set('isEligible', action.payload.isEligible)
          .set('securityDetails', action.payload.securityDetails)
          .set('margins', action.payload.margins)
          .set('error', null));

    case actionTypes.SET_SCHEDULE_VIEWER_FOCUSED_SECTION:
      return state.withMutations(s =>
        s.set('focusedSection', action.payload));

    case actionTypes.TOGGLE_SCHEDULE_VIEWER_DRILLDOWN:
      return state.withMutations(s =>
        s.set('focusedSection', action.payload)
          .set('drilldownMode', !state.get('drilldownMode')));

    case FETCH_SCHEDULE_VIEWER_FAIL:
    case FETCH_SCHEDULE_IMPLIED_VIEWER_FAIL:
      return initialState.withMutations(s =>
        s.set('error', action.payload));

    default:
      return state;
  }
};
