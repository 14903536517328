import { types } from './actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames, apiOperatonIds } from 'constants/api/misApi';
import { Map } from 'immutable';


const FETCH_DASHBOARD_BREAKS_LATEST_FAIL = getFetchFailActionByApi(apiNames.DASHBOARD, apiOperatonIds.BREAKS_LATEST);

export const initialState = Map({
  dataSet: {},
});

export const breaks = (state = initialState, action) => {

  switch (action.type) {
    case types.FETCH_DASHBOARD_BREAKS_LATEST_SUCCESS:
      return state.withMutations(s =>
        s.set('dataSet', action.payload));

    case FETCH_DASHBOARD_BREAKS_LATEST_FAIL:
      return initialState;

    default:
      return state;
  }
};
