import * as svc from './services';
import { setFilters } from 'actions/common/filtersActions';

export const actionTypes = {
  FETCH_TIME_SERIES_SUCCESS: 'ccs/timeSeries/FETCH_TIME_SERIES_SUCCESS',
};

const fetchTimeSeriesSuccess = payload => ({
  type: actionTypes.FETCH_TIME_SERIES_SUCCESS,
  payload,
});


/**
 *
 * Async actions
 *
 */
export function fetchTimeSeriesData(params, saveSelection=true) {
  return dispatch => {

    return svc.fetchTimeSeries(dispatch, params)
      .then(payload => {
        dispatch(fetchTimeSeriesSuccess(payload));
        dispatch(setFilters(payload, saveSelection));
      });
  };
}

export function fetchTimeSeriesFilters(params) {
  return (dispatch) => {
    return svc.fetchTimeSeriesFilters(dispatch, params);
  };
}
