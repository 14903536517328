const apiNames = {
  ALLOCATION_TREE: "allocationTree",
  INVENTORY_TREE: "inventoryTree",
  ALLOCATION_SUNBURST: "allocationSunburst",
  INVENTORY_SUNBURST: "inventorySunburst",
  COLLATERAL_GRADE: "collateralGrade",
  COLLATERAL_GRADE_COMPARISON: "collateralGradeComparison",
  VENUE_DASHBOARD: "venueDashboard",
  COLLATERAL_PROFILE_VIEWER: "CollateralProfileViewer",
  COLLATERAL_REQUIREMENT_GROUPED: "collateralRequirementGrouped",
  COLLATERAL_REQUIREMENT_GROUPED_TIME_SERIES:
    "collateralRequirementGroupedTimeSeries",
  ELIGIBILITY_ANALYTICS: "eligibilityAnalytics",
  CUSTOM_PORTFOLIO: "customPortfolio",
  ELIGIBILITY_CHECKER: "EligibilityChecker",
  ELIGIBILITY_RECONCILIATION: "EligibilityReconciliation",
  ELIGIBILITY_SECURITIES: "EligibilitySecurities",
  COLLATERAL_PROJECTIONS: "collateralProjections",
  LIMIT_RECONCILIATION: "LimitReconciliation",
  RQV_ADJUSTMENT: "rqvAdjustments",
  MARGIN_RECONCILIATION: "MarginReconciliation",
  EXPOSURE_ANALYTICS: "exposureAnalytics",
};

const apiOperatonIds = {
  ALLOCATION_SECURITY_DETAIL: "allocationSecurityDetail2",
  ALLOCATION_SUNBURST_FILTERS: "allocationSunburstFilters",
  ALLOCATION_SECURITY_SPREADSHEET: "allocationSecurityDetailSpreadsheet",
  INVENTORY_SECURITY_DETAIL: "inventorySecurityDetail2",
  INVENTORY_SECURITY_SPREADSHEET: "inventorySecurityDetailSpreadsheet",
  COLLATERAL_GRADE_FILTERS: "collateralGradeFilters",
  COLLATERAL_GRADE_COMPARISON: "collateralGradeComparison",
  COLLATERAL_GRADE_COMPARISON_FILTERS: "collateralGradeComparisonFilters",
  VENUE_DASHBOARD_ROUTE: "venueDashboardRoute",
  COLLATERAL_REQUIREMENT_GROUPED_FILTERS: "collateralRequirementGroupedFilters",
  COLLATERAL_PROFILE_VIEWER: "collateralProfileViewer",
  COLLATERAL_PROFILE_IMPLIED_VIEWER: "collateralProfileImpliedViewer",
  VENUE_TIME_SERIES: "collateralRequirementGroupedTimeSeries",
  COLLATERAL_REQUIREMENT_GROUPED_TIME_SERIES_FILTERS:
    "collateralRequirementGroupedTimeSeriesFilters",
  ELIGIBILITY_ANALYTICS_RESULTS: "eligibilityAnalyticsResults",
  ELIGIBILITY_ANALYTICS_RESULTS_FAILURE: "allocationFailuresSpreadsheet",
  ELIGIBILITY_ANALYTICS_SHORTS: "eligibilityAnalyticsShorts",
  ELIGIBILITY_ANALYTICS_SHORTS_FILTERS: "eligibilityAnalyticsShortsFilters",
  ELIGIBILITY_ANALYTICS_INVENTORY: "eligibilityAnalyticsInventory",
  ELIGIBILITY_ANALYTICS_INVENTORY_FILTERS:
    "eligibilityAnalyticsInventoryFilters",
  ELIGIBILITY_ANALYTICS_SETTINGS: "eligibilityAnalyticsSettings",
  ELIGIBILITY_ANALYTICS_FAILURE_REPORT: "eligibilityAnalyticsFailureReport",
  CUSTOM_PORTFOLIO_SPREADSHEET_DOWNLOAD: "customPortfolioSpreadsheetDownload",
  CUSTOM_PORTFOLIO_SPREADSHEET_UPLOAD: "customPortfolioSpreadsheetUpload",
  APPLY_CUSTOM_PORTFOLIO: "applyCustomPortfolioInventory",
  RQV_ADJUSTMENT_FILTERS: "rqvAdjustmentsFilters",
  APPLY_ADJUSTMENT_ACTION: "applyAdjustmentAction",
  RQV_WIDGETS: "widgets",
  ELIGIBILITY_CHECKER: "eligibilityChecker",
  ELIGIBILITY_CHECKER_FILTERS: "EligibilityCheckerFilters",
  ELIGIBILITY_RECONCILIATION: "eligibilityReconciliation",
  ELIGIBILITY_RECONCILIATION_AG: "eligibilityReconciliationAg",
  BENEFICIAL_OWNER_AG: "beneficialOwnerEligibilityReconciliationAg",
  COMBINED_ELIGIBILITY_RECONCILIATION_AG: "combinedReconciliationAg",
  ELIGIBILITY_RECONCILIATION_FILTERS: "EligibilityReconciliationFilters",
  ELIGIBILITY_RECONCILIATION_SPREADSHEET:
    "eligibilityReconciliationSpreadsheet",
  ELIGIBILITY_RECONCILIATION_BENEFICIAL_OWNER_SPREADSHEET:
    "beneficialOwnerEligibilityReconciliationSpreadsheet",
    COMBINED_ELIGIBILITY_RECONCILIATION_SPREADSHEET:
    "combinedReconciliationSpreadsheet",
  ELIGIBILITY_SECURITIES: "eligibilitySecurities",
  ELIGIBILITY_CHECKER_SPREADSHEET: "eligibilityCheckerSpreadsheet",
  COLLATERAL_PROJECTIONS: "collateralProjections",
  COLLATERAL_PROJECTIONS_SPREADSHEET: "collateralProjectionsSpreadsheet",
  COLLATERAL_PROJECTIONS_FILTER: "collateralProjectionsFilters",
  COLLATERAL_GRADE_FX_REPORT_SPREADSHEET: "collateralGradeFxReportSpreadsheet",
  COLLATERAL_PROJECTIONS_INTRADAY_FILTER: "intradayProjectionsFilters",
  COLLATERAL_PROJECTIONS_INTRADAY_PERIODS: "intradayPeriods",
  COLLATERAL_PROJECTIONS_INTRADAY: "intradayProjections2",
  COLLATERAL_PROJECTIONS_INTRADAY_SPREADSHEET: "intradayProjectionsSpreadsheet",
  TRADE_ITEMS_SPREADSHEET: "tradeItemsSpreadsheet",
  LIMIT_RECONCILIATION_FILTER: "LimitReconciliationFilters",
  LIMIT_RECONCILIATION: "limitReconciliation",
  LIMIT_RECONCILIATION_SPREADSHEET: "limitReconciliationSpreadsheet",
  MARGIN_RECONCILIATION: "marginReconciliation",
  MARGIN_RECONCILIATION_FILTERS: "MarginReconciliationFilters",
  MARGIN_RECONCILIATION_SPREADSHEET: "marginReconciliationSpreadsheet",
  EXPOSURE_ANALYTICS_COVERAGE: "coverage",
  EXPOSURE_ANALYTICS_COVERAGE_SPREADSHEET: "coverageSpreadsheet",
  EXPOSURE_ANALYTICS_FX_RECONCILIATION: "fxReconciliation",
  EXPOSURE_ANALYTICS_FX_RECONCILIATION_SPREADSHEET:
    "fxReconciliationSpreadsheet",
  EXPOSURE_ANALYTICS_VALUATION_RISK: "valuationRisk",
  EXPOSURE_ANALYTICS_VALUATION_RISK_SPREADSHEET: "valuationRiskSpreadsheet",
  EXPOSURE_ANALYTICS_FX_MISMATCH: "fxMismatch",
  EXPOSURE_ANALYTICS_FX_MISMATCH_SPREADSHEET: "fxMismatchSpreadsheet",
  EXPOSURE_ANALYTICS_FILTERS: "exposureAnalyticsFilters",
  EXPOSURE_ANALYTICS_SETTINGS: "settings",
};

export { apiNames, apiOperatonIds };
