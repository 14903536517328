import * as svc from './services';
import FileSaver from 'file-saver';
import { setFilters } from 'actions/common/filtersActions';

export const actionTypes = {
  FETCH_COLLATERAL_GRADE_SUCCESS: 'ccs/collateralGrade/FETCH_COLLATERAL_GRADE_SUCCESS',
  TOGGLE_COLLATERAL_GRADE_ACTIVE_KEYS: 'ccs/collateralGrade/TOGGLE_COLLATERAL_GRADE_ACTIVE_KEYS',
  SET_COLLATERAL_GRADE_SORTBY: 'ccs/collateralGrade/SET_COLLATERAL_GRADE_SORTBY',
  RESET_COLLATERAL_GRADE_ACTIVE_KEYS: 'ccs/collateralGrade/RESET_COLLATERAL_GRADE_ACTIVE_KEYS',
  TOGGLE_COLLATERAL_GRADE_SHOW_TRADE: 'ccs/collateralGrade/TOGGLE_COLLATERAL_GRADE_SHOW_TRADE',
  TOGGLE_COLLATERAL_GRADE_SHOW_COLLATERAL: 'ccs/collateralGrade/TOGGLE_COLLATERAL_GRADE_SHOW_COLLATERAL',
};

const fetchCollateralGradeSuccess = payload => ({
  type: actionTypes.FETCH_COLLATERAL_GRADE_SUCCESS,
  payload
});

export function fetchCollateralGrade(params = {}, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchCollateralGrade(dispatch, {...params})
      .then((payload) => {
        dispatch(fetchCollateralGradeSuccess(payload));
        dispatch(setFilters(payload, clearPrevious));
      });

  };
}

export function fetchCollateralGradeFilters(params, apiName) {
  return (dispatch) => {
    return svc.fetchCollateralGradeFilters(dispatch, params, apiName);
  };
}

export function fetchCollateralGradeComparison(params = {}, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchCollateralGradeComparison(dispatch, {...params})
      .then((payload) => {
        dispatch(fetchCollateralGradeSuccess(payload));
        dispatch(setFilters(payload, clearPrevious));
      });

  };
}

export function fetchCollateralGradeComparisonFilters(params, apiName) {
  return (dispatch) => {
    return svc.fetchCollateralGradeComparisonFilters(dispatch, params, apiName);
  };
}

export const toggleActiveKeys = items => ({
  type: actionTypes.TOGGLE_COLLATERAL_GRADE_ACTIVE_KEYS,
  payload: items
});

export const resetActiveKeys = () => ({
  type: actionTypes.RESET_COLLATERAL_GRADE_ACTIVE_KEYS,
});

export const setSortBy = option => ({
  type: actionTypes.SET_COLLATERAL_GRADE_SORTBY,
  payload: option
});

export const toggleShowTrade = showTrade => ({
  type: actionTypes.TOGGLE_COLLATERAL_GRADE_SHOW_TRADE,
  payload: showTrade
});

export const toggleShowCollateral = showCollateral => ({
  type: actionTypes.TOGGLE_COLLATERAL_GRADE_SHOW_COLLATERAL,
  payload: showCollateral
});

export function fetchCollateralGradeSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchCollateralGradeSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}