import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "common/Checkbox";
import { ReactComponent as CaretLeftSVG } from "assets/images/caret-left.svg";
import { ReactComponent as TickSVG } from "assets/images/tick.svg";
import { useSelectedEvents } from "../hooks/useSelectedEvents";
import { eventActionSelector, selectedEventsSelector } from "../selectors";
import * as styledComponents from "./styles";
import { StatusType } from "repo/models/EventStatus";
import { EVENT_ACTIONS_TEXT } from "../constants";
import { ActionTypes } from "../types";
import useToast from "common/notifications/toast/useToast";
import {
  updateWorkflowStatusErrorSelector,
  updateWorkflowStatusLoadingSelector,
  updateWorkflowStatusSuccessSelector,
} from "repo/matchingManager/selectors";
import {
  clearWorkflowStatusState,
  fetchOneSidedEventDetails,
  updateWorkflowStatus,
} from "repo/matchingManager/actions";
import {
  GroupedStatus,
  MatchingManagerUrlParams,
} from "repo/matchingManager/types";
import { useParams } from "react-router-dom";
import { EventActions } from "repo/models/EventAction";

const capitalizeStatus = (currentStatus: StatusType) => {
  return currentStatus
    .split(/(?=[A-Z])/)
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");
};

const getEventActionText = (eventAction: EventActions) => {
  return EVENT_ACTIONS_TEXT[eventAction];
};

interface ToasterTextProps {
  isOnlyOneTradeReference: boolean;
  tradesCount: number;
  newWorkflowStatus: StatusType;
}

const getToasterSuccessText = ({
  isOnlyOneTradeReference,
  tradesCount,
  newWorkflowStatus,
}: ToasterTextProps) => {
  return `Status changed on ${
    isOnlyOneTradeReference ? `trade` : `${tradesCount} trades`
  } to ${capitalizeStatus(newWorkflowStatus)}`;
};

const getToasterFailureText = ({
  isOnlyOneTradeReference,
  tradesCount,
  newWorkflowStatus,
}: ToasterTextProps) => {
  return `Unable to change status to ${capitalizeStatus(
    newWorkflowStatus
  )} on ${
    isOnlyOneTradeReference
      ? `trade.`
      : `${tradesCount} trades.`
  }.`;
};

export const NonGrouped = () => {
  const dispatch = useDispatch();
  const selectedEvents = useSelector(selectedEventsSelector);
  const eventAction = useSelector(eventActionSelector);
  const changeStatusLoading = useSelector(updateWorkflowStatusLoadingSelector);
  const changeStatusSuccess = useSelector(updateWorkflowStatusSuccessSelector);
  const changeStatusError = useSelector(updateWorkflowStatusErrorSelector);
  const { displayErrorToast, displaySuccessToast } = useToast();
  const tradeWord = (length: number) => `trade${length > 1 ? "s" : ""}`;
  const uniqueEvents = Array.from(
    new Map(selectedEvents.map((v) => [v.tradeRef, v])).values()
  );
  const {
    groupedStatus,
    itemRef,
    period,
    recOrientation,
  } = useParams<MatchingManagerUrlParams>();
  const isEventDetailsPage =
    groupedStatus || itemRef || period || recOrientation;

  const {
    allEventsUnselected,
    apiPayload,
    onCheckboxesChange,
    selectedItems,
  } = useSelectedEvents(uniqueEvents);

  useEffect(() => {
    if (changeStatusSuccess) {
      handleSuccess();
    }

    if (changeStatusError) {
      handleError();
    }
  }, [changeStatusSuccess, changeStatusError]);

  const ACTION_TEXT =
    eventAction.charAt(0).toUpperCase() + eventAction.slice(1);

  const exitModal = () => {
    dispatch({ type: ActionTypes.CLOSE_MATCHING_MODAL });
    dispatch(clearWorkflowStatusState());
  };

  const updateStatus = () => {
    dispatch(updateWorkflowStatus(apiPayload!));
  };

  const isOnlyOneTradeReference = apiPayload?.items?.length === 1;
  const tradesCount = apiPayload?.items?.length ?? 0;
  const newWorkflowStatus =
    apiPayload?.items?.[0]?.newWorkflowStatus ?? "unmatched";

  function handleSuccess() {
    displaySuccessToast(
      getToasterSuccessText({
        isOnlyOneTradeReference,
        tradesCount,
        newWorkflowStatus,
      })
    );

    const isGrouped = groupedStatus === GroupedStatus.Grouped;

    if (isEventDetailsPage) {
      /** Using a delay to wait until kafka applies changes in the db */
      setTimeout(() => {
        dispatch(
          fetchOneSidedEventDetails({
            isGrouped, // TODO: remove this property as part of RPO-572
            itemRef,
            period, // TODO: remove this property as part of RPO-572
            periods: { realTime: period },
          })
        );
      }, 1000);
    }
    exitModal();
  }

  function handleError() {
    displayErrorToast(
      getToasterFailureText({
        isOnlyOneTradeReference,
        tradesCount,
        newWorkflowStatus,
      })
    );
    exitModal();
  }

  return (
    <>
      <styledComponents.Header>
        You are about to change the below {tradeWord(selectedEvents.length)}{" "}
        status:
      </styledComponents.Header>
      <styledComponents.WarningContainer
        $allEventsUnselected={allEventsUnselected}
      >
        <styledComponents.InfoIcon />
        <styledComponents.WarningText>
          Please select at least one trade that you want to change status on.
        </styledComponents.WarningText>
      </styledComponents.WarningContainer>
      <styledComponents.CheckboxesContainer>
        {selectedItems.map((item) => (
          <styledComponents.LabelContainer key={item.tradeRef}>
            <div
              onMouseDown={() => onCheckboxesChange(item.tradeRef)}
              data-testid={`tradeRef-${item.tradeRef}`}
            >
              <Checkbox
                width={30}
                height={30}
                checked={item.checked}
                active
                glowOnChecked={false}
              />
            </div>

            <styledComponents.LabelText data-testid="labelText">
              Your current status for trade {item.tradeRef} is{" "}
              <strong>{capitalizeStatus(item.currentStatus)}</strong>.{" "}
              {getEventActionText(eventAction)}
            </styledComponents.LabelText>
          </styledComponents.LabelContainer>
        ))}
      </styledComponents.CheckboxesContainer>
      <styledComponents.ButtonsContainer>
        <styledComponents.ActionButton onClick={exitModal}>
          <CaretLeftSVG /> Cancel &amp; return
        </styledComponents.ActionButton>
        <styledComponents.PrimaryActionButton
          onClick={updateStatus}
          disabled={allEventsUnselected || changeStatusLoading}
          data-testid="actionButton"
        >
          <TickSVG /> {ACTION_TEXT}
        </styledComponents.PrimaryActionButton>
      </styledComponents.ButtonsContainer>
    </>
  );
};
