import { combineReducers } from "redux";
import paymentsView from "./pages/Payments/reducer";
import reconciliations from "./pages/Reconciliations/reducer";
import common from "./common/reducer";

const coacs = combineReducers({
  common,
  paymentsView,
  reconciliations,
});

export default coacs;
