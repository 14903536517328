import { ValueGetterParams } from "ag-grid-community";
import { PirumColDef } from "common/tables/Grid/types";
import moment from "moment";
import { RepoTimelineRow } from "repo/common/Grid/types";
import {
  TimelineMessage,
  StatusMessages,
  TimelineConversationMessages,
} from "repo/matchingManager/types";
import { toTitleCase } from "utils/stringUtils";

type TimelineValueGetter = (params: ValueGetterParams, field: string) => string;

export interface TimelineActivities {
  columnDefs: PirumColDef[];
  rows: RepoTimelineRow[];
}

const columnDefConfig = {
  trade: {
    suppressSizeToFit: false,
    width: 125,
  },
  eventType: {
    suppressSizeToFit: false,
    width: 110,
  },
  eventSubType: {
    minWidth: 125,
    suppressSizeToFit: false,
  },
  eventDate: {
    suppressSizeToFit: false,
    width: 115,
  },
  created: {
    minWidth: 175,
    suppressSizeToFit: true,
  },
  source: {
    suppressSizeToFit: false,
    width: 125,
  },
  type: {
    suppressSizeToFit: true,
    width: 125,
  },
  status: {
    minWidth: 150,
    suppressSizeToFit: false,
  },
  action: {
    suppressSizeToFit: false,
    width: 250,
  },
  createdBy: {
    suppressSizeToFit: false,
    width: 150,
  },
};

const valueGetter: TimelineValueGetter = (params, field) => {
  const { data } = params;

  if (!data?.cells?.[field]) return null;

  return data.cells[field]?.value;
};

const valueField = (value: string, args = {}) => ({ value, ...args });

// we don't need a value formatter
const columnDef = ([field, values]: [string, PirumColDef]) => ({
  field,
  headerName: values.headerName || toTitleCase(field),
  maxWidth: 500,
  resizable: true,
  valueGetter: (params: ValueGetterParams) => valueGetter(params, field),
  ...values,
});

const checkMessageMetadata = (
  activity: TimelineMessage
): activity is TimelineConversationMessages => "context" in activity;

const isStatusContext = (
  activity: TimelineMessage
): activity is StatusMessages => activity.context === "status";

const statusText = (status: string) => `This event was ${toTitleCase(status)}`;

const prepareCellValues = (activity: TimelineMessage) => {
  const isMessage = checkMessageMetadata(activity);

  const source = isMessage ? activity.metadata.clientName : "";
  const content = isMessage ? activity.metadata.content : "";

  switch (activity.context) {
    case "delete":
      return {
        action: content,
        source,
        type: "Delete Comment",
      };
    case "message":
      const visibility = isMessage ? activity.metadata.visibility : "";
      return {
        action: content,
        source,
        type: `${visibility} Comment`,
      };
    case "status":
      const {
        repoEventDate = "",
        repoEventSubType = "",
        repoEventType = "",
        status = "",
        tradeRef = "",
      } = isStatusContext(activity) ? activity.metadata : {};

      return {
        action: statusText(status),
        repoEventDate: !!repoEventDate // don't format if empty string
          ? moment(repoEventDate).format("DD-MMM-YYYY")
          : "",
        repoEventSubType,
        repoEventType,
        source,
        status,
        trade: tradeRef,
        type: "Status Update",
      };
    default:
      return null;
  }
};

const prepareTimelineData = (
  messages: TimelineMessage[] | undefined
): TimelineActivities | null => {
  if (!messages) return null;

  const activities: TimelineActivities = {
    columnDefs: Object.entries(columnDefConfig).map(columnDef),
    rows: [],
  };

  // sort comparison function to reverse timeline activities by timestamp
  const reverseByTimestamp = (a: TimelineMessage, b: TimelineMessage) =>
    // need to tell moment the format specification
    b.metadata.epochTime - a.metadata.epochTime;

  const sortedMessages = messages.sort(reverseByTimestamp);

  sortedMessages.forEach((activity) => {
    const cellValues = prepareCellValues(activity);
    if (cellValues === null) return; // guard clause if invalid;

    const status = cellValues.status ? { status: cellValues.status } : {};

    activities.rows.push({
      cells: {
        action: valueField(cellValues.action || "", status),
        created: valueField(activity.metadata.timestamp || ""),
        createdBy: valueField(activity.metadata.fullName || ""),
        eventDate: valueField(cellValues.repoEventDate || ""),
        eventSubType: valueField(cellValues.repoEventSubType || ""),
        eventType: valueField(cellValues.repoEventType || ""),
        source: valueField(cellValues.source || ""),
        status: valueField(cellValues.status || ""),
        trade: valueField(cellValues.trade || ""),
        type: valueField(cellValues.type || ""),
      },
    });
  });

  return activities;
};

export default prepareTimelineData;
