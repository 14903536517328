import { createAction } from "@reduxjs/toolkit";

let lastId = 0;

// Can be used for pretty much in any context
// but i created it for providing unique ids to form fields
// `<label for="id">
// <input id="id"/>`
function getUniqueId(prefix = "uniqid") {
  lastId++;

  return `${prefix}-${lastId}`;
}

function isActiveItem(item, activeList, index = 0) {
  return !!(
    activeList &&
    activeList.length &&
    item.fieldName === activeList[index]
  );
}

function getAPIDateFormat(date = null) {
  const d = date || new Date();
  return d.toISOString().slice(0, 10).replace(/-/g, "");
}

function isAPIDateToday(dateString) {
  return !!dateString && dateString.toString() === getAPIDateFormat();
}

const createNamedAction = (action, name) => {
  return createAction(`${action}_${name}`);
};

const getBodyHeight = (modifer = 0.5) => {
  return Math.floor(document.querySelector("body").clientHeight * modifer);
};

const parseBool = (x) => (x === "true" ? true : false);

export {
  getUniqueId,
  isActiveItem,
  getAPIDateFormat,
  isAPIDateToday,
  createNamedAction,
  getBodyHeight,
  parseBool,
};
