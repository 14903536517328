import styled from 'styled-components';
import { ReactComponent as MatchSVG } from 'assets/images/match-cpty.svg';
import { ReactComponent as CircleBackslashSVG } from 'assets/images/circle-backslash.svg';
import { ReactComponent as IconQuerySVG } from 'assets/images/icon-query.svg';
import { ReactComponent as IssueSVG } from 'assets/images/issue.svg';
import { ReactComponent as RejectSVG } from 'assets/images/reject.svg';

export const MatchIcon = styled(MatchSVG)`
  path {
    stroke: white;
  }
`;

export const CircleBackslashIcon = styled(CircleBackslashSVG)`
  max-width: 17px;
  max-height: 17px;
  transform: scale(-1,1);
  path {
    stroke: white;
    stroke-width: 2;
  }
`;
export const RejectIcon = styled(RejectSVG)`
  path {
    fill: white;
    stroke: white;
  }
`;
export const IconQueryIcon = styled(IconQuerySVG)`
  path:first-child {
    stroke: white;
  }
  path:last-child {
    fill: white;
  }
`;
export const IssueIcon = styled(IssueSVG)`
  path {
    fill: white;
  }
`;