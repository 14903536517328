import { actionTypes } from './actions';
import { getFetchFailActionByApi } from '../../utils/apiUtils';
import { apiNames } from '../../constants/api/queryApi';
import { Map } from 'immutable';


const FETCH_TIME_SERIES_FAIL = getFetchFailActionByApi(apiNames.COLLATERAL_REQUIREMENT_GROUPED_TIME_SERIES, apiNames.COLLATERAL_REQUIREMENT_GROUPED_TIME_SERIES);

export const initialState = Map({
  groupingFields: [],
  groupBy: null,
  sparklines: [],
  sparklineSum: [],
  dateRange: [],
  maxValue: 0,
  labels: {},
});

export const timeSeries = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TIME_SERIES_SUCCESS:
      return state.withMutations(s =>
        s.set('groupingFields', action.payload.groupingFields)
          .set('groupBy', action.payload.groupBy)
          .set('sparklines', action.payload.sparklines)
          .set('sparklineSum', action.payload.sparklineSum)
          .set('dateRange', action.payload.dateRange)
          .set('maxValue', action.payload.maxValue)
          .set('labels', action.payload.labels));

    case FETCH_TIME_SERIES_FAIL:
      return initialState;

    default:
      return state;
  }
};
