import services from 'services/services';
import {apiNames, apiOperatonIds} from 'constants/api/queryApi';
import collateralGradeAdapter from './servicesAdapter';
import storage from 'pirumconnect/storage/session';

const config = {
  namespace: 'CollateralGrade'
};

export function fetchCollateralGrade(dispatch, params) {
  const adapter = new collateralGradeAdapter();
  const request = collateralGradeAdapter.prepareRequest(params);

  return services.query(apiNames.COLLATERAL_GRADE)(dispatch, {request})
    .then(result => {
      return adapter.parseAPIResponse(result);
    });
}

export function fetchCollateralGradeSpreadsheet(dispatch, params) {
  const request = collateralGradeAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_GRADE, apiOperatonIds.COLLATERAL_GRADE_FX_REPORT_SPREADSHEET)(dispatch, {request});
}

export function fetchCollateralGradeFilters(dispatch, params) {
  const adapter = new collateralGradeAdapter();
  const request = collateralGradeAdapter.prepareRequest(params);

  return services.query(apiNames.COLLATERAL_GRADE, apiOperatonIds.COLLATERAL_GRADE_FILTERS)(dispatch, {request})
    .then(result => {
      return adapter.parseAPIResponse(result);
    });
}

export function fetchCollateralGradeComparisonFilters(dispatch, params) {
  const adapter = new collateralGradeAdapter();
  const request = collateralGradeAdapter.prepareRequest(params);

  return services.query(apiNames.COLLATERAL_GRADE_COMPARISON, apiOperatonIds.COLLATERAL_GRADE_COMPARISON_FILTERS)(dispatch, {request})
    .then(result => {
      return adapter.parseAPIResponse(result);
    });
}

export function fetchCollateralGradeComparison(dispatch, params) {
  const adapter = new collateralGradeAdapter();
  const request = collateralGradeAdapter.prepareRequest(params);

  return services.query(apiNames.COLLATERAL_GRADE_COMPARISON, apiOperatonIds.COLLATERAL_GRADE_COMPARISON)(dispatch, {request})
    .then(result => {
      return adapter.parseAPIResponse(result);
    });
}

export function storeCollateralGradeState(state) {
  clearCollateralGradeState();
  Object.keys(state).forEach(key => storage.setItem(config.namespace, key, state[key]));
}

export function getCollateralGradeState(keys = []) {
  const storedValues = keys.reduce((acc, key) => {
  	acc[key] = storage.getItem(config.namespace, key);
  	return acc;
  }, {});
  return storedValues;
}

export function clearCollateralGradeState() {
  storage.clear(config.namespace);
}