import { useEffect, useState } from "react";
import TableState from "pirumconnect/storage/session/TableState";
import { getGridState, setGridState } from "./GridUtils";
import {
  AgGridEvent,
  GridReadyEvent,
  ModelUpdatedEvent,
} from "ag-grid-community";
import { PirumColumnApi } from "./types";

export const STATE_CHANGE_EVENTS = [
  "onColumnVisible",
  "onColumnMoved",
  "onColumnResized",
  "onColumnPinned",
  "onFilterChanged",
  "onSortChanged",
] as const;

const useGridStatePreserver = (gridId: string, preserveState: boolean) => {
  const [gridRestored, setGridRestored] = useState(false);

  function handleStateChangedEvent(event: AgGridEvent) {
    const { api, columnApi } = event;

    const shouldWeSetColumnState = validateEvent(event);

    if (gridRestored && shouldWeSetColumnState) {
      const currentInMemoryGridState = getGridState(
        api,
        columnApi as PirumColumnApi
      );
      const columnState = currentInMemoryGridState.columnState || [];
      if (columnState.length > 0) {
        TableState.setColumnState(gridId, currentInMemoryGridState);
      }
    }
  }

  interface ValidateEvent {
    type: string;
    finished?: boolean;
    source?: string;
  }

  function validateEvent(props: ValidateEvent) {
    const { type, finished = true, source } = props;
    if (!finished) return false;

    if (type === "columnResized" && source !== "uiColumnDragged") return false;
    if (
      source &&
      type === "columnVisible" &&
      ["api", "gridOptionsChanged"].includes(source)
    )
      return false;

    return true;
  }

  useEffect(() => {
    if (gridRestored) {
      const customEvent = new CustomEvent("onGridRestored", {
        detail: {
          gridId,
          source: "gridStatePreserver",
          type: "gridRestored",
        },
      });
      dispatchEvent(customEvent);
    }
  }, [gridId, gridRestored]);

  function handleGridReset(): void {
    // the gridId is checked in useCustomAgGridEvents
    TableState.clearColumnState(gridId);
  }

  function handleGridReady({ api, columnApi }: GridReadyEvent) {
    if (gridRestored) {
      return;
    }
    setGridRestored(true);
    setGridState(
      api,
      columnApi as PirumColumnApi,
      TableState.getColumnState(gridId)
    );
  }

  function handleModelUpdated({
    api,
    columnApi,
    newData,
    newPage,
  }: ModelUpdatedEvent) {
    if (newData || newPage) {
      const columnState = TableState.getColumnState(gridId);
      setGridState(api, columnApi as PirumColumnApi, columnState);
    }
  }

  function getStatePreserverEvents() {
    const statePreserverEvents = STATE_CHANGE_EVENTS.reduce<
      Partial<
        Record<typeof STATE_CHANGE_EVENTS[number], (e: AgGridEvent) => void>
      >
    >((acc, eventName) => {
      acc[eventName] = handleStateChangedEvent;
      return acc;
    }, {});

    return {
      ...statePreserverEvents,
      onGridReady: handleGridReady,
      onGridReset: handleGridReset,
      onModelUpdated: handleModelUpdated,
    };
  }

  return preserveState
    ? {
        gridStateInitialised: gridRestored,
        gridStatePreserverEvents: getStatePreserverEvents(),
      }
    : {};
};

export default useGridStatePreserver;
