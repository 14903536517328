import services from 'services/services';
import {apiNames, apiOperatonIds} from 'constants/api/misApi';
import BreaksTrendAdapter from './BreaksTrendAdapter';
import BreaksCounterpartyAdapter from './BreaksCounterpartyAdapter';
import { getBreakTypeColorById } from 'utils/colorUtils';

export function fetchBreaksLatest(dispatch, fetchCount, islaGroup) {
  const request = { fetchCount, islaGroup };
  //TODO: services.sblrex should be changed to services.mis
  return services.sblrex(apiNames.DASHBOARD, apiOperatonIds.BREAKS_LATEST, true)(dispatch, {request})
    .then(result => {
      if (!!result.datakeys.reason_band) {
        result.datakeys.reason_band = result.datakeys.reason_band.map(it => ({
          ...it,
          color: getBreakTypeColorById(it.colorKey).bg
        }));
      }
      return result;
    });
}

export function fetchBreaksTrend(dispatch, fetchCount, islaGroup, partyIds, monthsCount=0) {
  const request = { fetchCount, islaGroup, partyIds };
  const adapter = new BreaksTrendAdapter();
  if (monthsCount > 0) {
    request['interval'] = monthsCount;
  }

  return services.sblrex(apiNames.DASHBOARD, apiOperatonIds.BREAKS_DAILY)(dispatch, {request})
    .then(result => adapter.parseResponseData(result));
}

export function fetchBreaksCounterparty(dispatch, fetchCount, islaGroup, partyIds) {
  const request = { fetchCount, islaGroup, partyIds };
  const adapter = new BreaksCounterpartyAdapter();

  return services.sblrex(apiNames.DASHBOARD, apiOperatonIds.BREAKS_COUNTERPARTY)(dispatch, {request})
    .then(result => adapter.parseResponseData(result));
}
