import EligibilityReconciliationAdapter from "./servicesAdapter";
import services from "services/services";
import { apiNames, apiOperatonIds } from "constants/api/queryApi";

export function fetchEligibilityReconciliation(dispatch, params) {
  const adapter = new EligibilityReconciliationAdapter();
  const request = EligibilityReconciliationAdapter.prepareRequest(params);
  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.ELIGIBILITY_RECONCILIATION
    )(dispatch, { request })
    .then((result) => adapter.parseAPIResponse(result));
}

export function fetchEligibilityReconciliationAg(dispatch, params) {
  const adapter = new EligibilityReconciliationAdapter();
  const request = EligibilityReconciliationAdapter.prepareRequest(params);
  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.ELIGIBILITY_RECONCILIATION_AG
    )(dispatch, {
      request,
    })
    .then((result) => adapter.parseAPIResponse(result));
}

export function fetchCombinedAg(dispatch, params) {
  const adapter = new EligibilityReconciliationAdapter();
  const request = EligibilityReconciliationAdapter.prepareRequest(params);
  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.COMBINED_ELIGIBILITY_RECONCILIATION_AG 
    )(dispatch, {
      request,
    })
    .then((result) => adapter.parseAPIResponse(result));
}

export function fetchBeneficialOwnerAg(dispatch, params) {
  const adapter = new EligibilityReconciliationAdapter();
  const request = EligibilityReconciliationAdapter.prepareRequest(params);
  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.BENEFICIAL_OWNER_AG
    )(dispatch, {
      request,
    })
    .then((result) => adapter.parseAPIResponse(result));
}

export function fetchEligibilityReconciliationSpreadsheet(dispatch, params) {
  const request = EligibilityReconciliationAdapter.prepareRequest(params);

  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.ELIGIBILITY_RECONCILIATION_SPREADSHEET
    )(dispatch, { request })
    .then((result) => result);
}

export function fetchBeneficialOwnerSpreadsheet(dispatch, params) {
  const request = EligibilityReconciliationAdapter.prepareRequest(params);

  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.ELIGIBILITY_RECONCILIATION_BENEFICIAL_OWNER_SPREADSHEET
    )(dispatch, { request })
    .then((result) => result);
}

export function fetchCombinedSpreadsheet(dispatch, params) {
  const request = EligibilityReconciliationAdapter.prepareRequest(params);

  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.COMBINED_ELIGIBILITY_RECONCILIATION_SPREADSHEET,
    )(dispatch, { request })
    .then((result) => result);
}

export function fetchEligibilityReconciliationFilters(dispatch, params) {
  const adapter = new EligibilityReconciliationAdapter();
  const request = EligibilityReconciliationAdapter.prepareRequest(params);

  return services
    .query(
      apiNames.ELIGIBILITY_RECONCILIATION,
      apiOperatonIds.ELIGIBILITY_RECONCILIATION_FILTERS
    )(dispatch, { request })
    .then((result) => {
      return adapter.parseAPIResponse(result);
    });
}
