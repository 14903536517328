import BreaksPage from 'mis/breaks/containers/Page/Loadable';


const appViewId = 'mis';

const viewIds = {
  app: {
    MIS: appViewId,
  },
  sections: {
    TRANSACTION_BREAKS: 'transactionBreaks',
  },
  pages: {
    BREAKS_BY_COUNTERPARTY: 'breaksByCounterparty',
    BREAKS_TREND: 'breaksTrend',
  }
};

const routes = {
  app: {
    viewId: viewIds.app.MIS,
    path: '/mis',
  },
  sectionsList: [
    {
      viewId: viewIds.sections.TRANSACTION_BREAKS,            
      subpath: '/breaks',
    },
  ],
  pagesList: [
    {
      viewId: viewIds.pages.BREAKS_BY_COUNTERPARTY,
      component: BreaksPage,
      subpath: '/counterparty',
    },
    {
      viewId: viewIds.pages.BREAKS_TREND,
      component: BreaksPage,
      subpath: '/trend',
    },
  ],
};


const misRoutesConfig = { 
  appViewId,
  viewIds,
  routes,
};

export default misRoutesConfig;
