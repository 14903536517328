import { Map } from "immutable";
import { EventActions } from "repo/models/EventAction";
import { StatusType } from "repo/models/EventStatus";

export enum ModalStatus {
  Closed = "Closed", // Modal Closed
  Grouped = "Grouped", // Modal Open, display Grouped modal view
  NonGrouped = "NonGrouped", // Modal Open, display NonGrouped modal view
}

export interface SelectedEvents {
  currentStatus: StatusType;
  itemRef: string;
  tradeRef: string;
}

export interface CheckboxesSelectedEvents extends SelectedEvents {
  checked: boolean;
}

export interface ChangeStatusModalState extends Map<string, any> {
  status: ModalStatus;
  modalOpen: boolean;
  selectedEvents: SelectedEvents[];
  eventAction: EventActions;
}

export enum ActionTypes {
  SET_MATCHING_MODAL_GROUPED = "repo/changeStatusModal/SET_MATCHING_MODAL_GROUPED",
  SET_MATCHING_MODAL_NONGROUPED = "repo/changeStatusModal/SET_MATCHING_MODAL_NONGROUPED",
  CLOSE_MATCHING_MODAL = "repo/changeStatusModal/CLOSE_MATCHING_MODAL",
  CLEAR_MATCHING_MODAL_STATE = "repo/changeStatusModal/CLEAR_MATCHING_MODAL_STATE",
  SET_MATCHING_MODAL_SELECTED_EVENTS = "repo/changeStatusModal/SET_MATCHING_MODAL_SELECTED_EVENTS",
  OPEN_MATCHING_MODAL = "repo/changeStatusModal/OPEN_MATCHING_MODAL",
  SET_EVENT_ACTION = "repo/changeStatusModal/SET_EVENT_ACTION",
}

export interface SetMatchingModalGrouped {
  type: ActionTypes.SET_MATCHING_MODAL_GROUPED;
}

export interface SetMatchingModalNonGrouped {
  type: ActionTypes.SET_MATCHING_MODAL_NONGROUPED;
}

export interface CloseMatchingModal {
  type: ActionTypes.CLOSE_MATCHING_MODAL;
}

export interface ClearMatchingModalState {
  type: ActionTypes.CLEAR_MATCHING_MODAL_STATE;
}

export interface SetMatchingModalSelectedEvents {
  type: ActionTypes.SET_MATCHING_MODAL_SELECTED_EVENTS;
  payload: SelectedEvents[];
}

export interface OpenMatchingModal {
  type: ActionTypes.OPEN_MATCHING_MODAL;
}

export interface SetEventAction {
  type: ActionTypes.SET_EVENT_ACTION;
  payload: EventActions;
}

export type ChangeStatusModalActions =
  | SetMatchingModalGrouped
  | SetMatchingModalNonGrouped
  | CloseMatchingModal
  | ClearMatchingModalState
  | SetMatchingModalSelectedEvents
  | OpenMatchingModal
  | SetEventAction;
