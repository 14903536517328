import "core-js";
import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";

import { GlobalStyleContainer } from "./styles/globalContainer";
import store from "./pirumconnect/store";

import appLogger from "./pirumconnect/logger";
import { InitErrorBoundary } from "common/error";
import { AppInitialiser } from "pirumconnect/AppInitialiser";
import { Terms } from "components/common/Terms";
import ToastMessages from "common/notifications/toast";
import { TOGGLE_NEW_SSO } from "utils/ssoUtils";

appLogger.init();

const { NODE_ENV, REACT_APP_MOCK_API = "false" } = process.env;

function prepareMSW() {
  if (NODE_ENV === "development" && REACT_APP_MOCK_API === "true") {
    const { worker } = require("./mocks/browser");
    return worker.start();
  }
  return Promise.resolve();
}

function updateReactSettings() {
  const REACT_SETTINGS_ELEMENT = document.getElementById("react-settings");
  if (REACT_SETTINGS_ELEMENT) {
    REACT_SETTINGS_ELEMENT.setAttribute("data-toggle-new-sso", TOGGLE_NEW_SSO);
  }
  return Promise.resolve();
}

function mountApplication() {
  return ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <InitErrorBoundary>
          <ToastMessages />
          <BrowserRouter>
            <Switch>
              {/* Routes that don't require user authentication to view */}
              <Route path="/terms-and-conditions" exact component={Terms} />
              {/* Routes that require user authentication to view */}
              <Route path="*" component={AppInitialiser} />
            </Switch>
          </BrowserRouter>
          <GlobalStyleContainer />
        </InitErrorBoundary>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

prepareMSW().then(updateReactSettings).then(mountApplication);
