export default class ScheduleViewerAdapter {
  parseData(result) {
    return result;
  }
  static prepareRequest(params) {
    const request = {
      venueId: parseInt(params.venueId, 10),
      profileId: params.profileId,
      filterBy: params.filterBy,
    };

    return request;
  }
}
