import TradePage from 'sftr/trade/Loadable';
import CollateralPage from 'sftr/collateral/Loadable';
import MarginPage from 'sftr/margin/Loadable';
import AuditPage from 'sftr/audit/Loadable';

const appViewId = 'regulatory';

const viewIds = {
  app: {
    REGULATORY: appViewId,
  },
  sections: {
    SFTR: 'sftr',
  },    
  pages: {
    SFTR_TRADE: 'sftrTrade',
    SFTR_COLLATERAL: 'sftrNetExpCollateral',
    SFTR_MARGIN: 'sftrMarginLending',
    SFTR_AUDIT: 'sftrAudit',
  },
};

const routes = {
  app: {
    viewId: viewIds.app.REGULATORY,
    path: '/regulatory',
  },
  sectionsList: [
    {
      viewId: viewIds.sections.SFTR,            
      subpath: '/sftr',
    },
  ],
  pagesList: [
    {
      viewId: viewIds.pages.SFTR_TRADE,
      component: TradePage,
      subpath: '/trade',
      helpPath: '/help/sftrreconciliation.pdf',
    },
    {
      viewId: viewIds.pages.SFTR_COLLATERAL,
      component: CollateralPage,
      subpath: '/collateral',
      helpPath: '/help/sftrreconciliation.pdf',
    },
    {
      viewId: viewIds.pages.SFTR_MARGIN,
      component: MarginPage,
      subpath: '/margin',
      helpPath: '/help/sftrreconciliation.pdf',
    },
    {
      viewId: viewIds.pages.SFTR_AUDIT,
      component: AuditPage,
      subpath: '/audit',
      helpPath: '/help/sftrreconciliation.pdf',
    },
  ],
};


const sftrRoutesConfig = { 
  appViewId,
  viewIds,
  routes,
};

export default sftrRoutesConfig;
