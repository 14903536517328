import { actionTypes } from './actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames } from 'constants/api/queryApi';
import { Map } from 'immutable';

const FETCH_EXPOSURE_COVERAGE_CHECKER_FAIL = getFetchFailActionByApi(apiNames.EXPOSURE_ANALYTICS);

export const initialState = Map({
  enabledTabs: null,
  exposureCoverageTable: {},
  fxReconciliationTable: {},
  valuationRiskTable: {},
  fxMismatchTable: {},
});

export const exposureAnalytics = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EXPOSURE_ANALYTICS_SETTINGS_SUCCESS:
      return state.withMutations(s =>
        s.set('enabledTabs', action.payload.enabledTabs));
    case actionTypes.FETCH_EXPOSURE_COVERAGE_SUCCESS:
      return state.withMutations(s =>
        s.set('exposureCoverageTable', action.payload.table));
    case actionTypes.FETCH_EXPOSURE_FX_RECONCILIATION_SUCCESS:
      return state.withMutations(s =>
        s.set('fxReconciliationTable', action.payload.table));
    case actionTypes.FETCH_EXPOSURE_VALUATION_RISK_SUCCESS:
      return state.withMutations(s =>
        s.set('valuationRiskTable', action.payload.table));
    case actionTypes.FETCH_EXPOSURE_FX_MISMATCH_SUCCESS:
      return state.withMutations(s =>
        s.set('fxMismatchTable', action.payload.table));
    case FETCH_EXPOSURE_COVERAGE_CHECKER_FAIL:
      return initialState;
    default:
      return state;
  }
};
