// import { customIds } from "./customIds";
import faker from "@faker-js/faker";

// const { REACT_APP_USE_FAKER = "true" } = process.env;

// export const ALLOWED_CLIENT_IDS =
//   REACT_APP_USE_FAKER === "true"
//     ? Array(3).fill(faker.datatype.number())
//     : customIds;
export const ALLOWED_CLIENT_IDS = Array(3).fill(faker.datatype.number());
