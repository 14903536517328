import { combineReducers } from "redux";

//TODO: Separate PirumConnect Reducers from CCS reducers

import { dashboardVenueSummary } from 'reducers/venueSummary/dashboardVenueSummaryReducer';

import { allocationsSunburst } from './allocationsSunburst/reducer';

import { venueDetail } from './venueDetail/reducer';
import { timeSeries } from './timeSeries/reducer';
import { collateralGrade } from './collateralGrade/reducer';
import { scheduleViewer } from './scheduleViewer/reducer';
import scheduleManager from './scheduleManager/reducer';
import { linkingTool } from './linkingTool/reducer';
import { projections } from './projections/reducer';
import { eligibilityAnalytics } from './eligibilityAnalytics/reducer';
import { exposureAnalytics } from './exposureAnalytics/reducer';
import { eligibilityChecker } from './eligibilityChecker/reducer';
import { eligibilityReconciliation } from './eligibilityReconciliation/reducer';
import { marginReconciliation } from './marginReconciliation/reducer';
import { eligibilityLimit } from './eligibilityLimit/reducer';


export default combineReducers({
  dashboardVenueSummary,
  allocationsSunburst,
  eligibilityReconciliation,
  marginReconciliation,
  eligibilityAnalytics,
  eligibilityChecker,
  eligibilityLimit,
  venueDetail,
  timeSeries,
  collateralGrade,
  scheduleViewer,
  scheduleManager,
  linkingTool,
  projections,
  exposureAnalytics,
});