import { EventActions } from "repo/models/EventAction";
import { AppState } from "repo/types";
import { ModalStatus, SelectedEvents } from "./types";

export const statusSelector = (state: AppState): ModalStatus =>
  state.repo.changeStatusModal.get("status");

export const modalOpenSelector = (state: AppState): boolean =>
  state.repo.changeStatusModal.get("modalOpen");

export const selectedEventsSelector = (state: AppState): SelectedEvents[] =>
  state.repo.changeStatusModal.get("selectedEvents");

export const eventActionSelector = (state: AppState): EventActions =>
  state.repo.changeStatusModal.get("eventAction");
