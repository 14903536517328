import services from 'services/services';
import ProjectionsAdapter from './servicesAdapter';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';


export function fetchProjections(dispatch, params) {
  const adapter = new ProjectionsAdapter();
  const request = ProjectionsAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS )(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}

export function fetchIntraday(dispatch, params) {
  const adapter = new ProjectionsAdapter();
  const request = ProjectionsAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS_INTRADAY)(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}

export function fetchIntradayTimeline(dispatch, params) {
  const request = ProjectionsAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS_INTRADAY_PERIODS )(dispatch, {request})
    .then(result => {
      return result;
    });
}

export function fetchIntradaySpreadsheet(dispatch, params) {
  const request = ProjectionsAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS_INTRADAY_SPREADSHEET)(dispatch, { request })
    .then(result => result)
    // eslint-disable-next-line no-console 
    .catch(e=>console.error(e));
}

export function fetchProjectionsSpreadsheet(dispatch, params) {
  const request = ProjectionsAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS_SPREADSHEET)(dispatch, { request })
    .then(result => result)
    // eslint-disable-next-line no-console 
    .catch(e=>console.error(e));
}

export function fetchProjectionsFilters(dispatch, params) {
  const adapter = new ProjectionsAdapter();
  const request = ProjectionsAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS_FILTER )(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}

export function fetchIntradayFilters(dispatch, params) {
  const adapter = new ProjectionsAdapter();
  const request = ProjectionsAdapter.prepareRequest(params);
  return services.query(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS_INTRADAY_FILTER )(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}

export function fetchViewLink(dispatch, params, apiName, operationId) {
  return services.query(apiName, operationId)(dispatch, {request: params});
}