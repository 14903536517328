import { actionTypes } from './actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames } from 'constants/api/queryApi';
import { Map } from 'immutable';

const FETCH_ALLOCATION_SUNBURST_FAIL = getFetchFailActionByApi(apiNames.ALLOCATIONS_SUNBURST);

export const initialState = Map({
  groups: [],
  groupBy: null,
  groupingFields: [],
  sunburst: {},
  securitiesPane: false,
  securitiesHover: false,
  breadcrumbs: [],
  table: null,
  isInventoryTable: false,
  awaitingNewFilterParams: false,//new filter params comes with FETCH_SUNBURST_SUCCESS
});

export const allocationsSunburst = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SUNBURST_STARTED:
      return state.withMutations(s => s.set('awaitingNewFilterParams', true));
    case actionTypes.FETCH_SUNBURST_SUCCESS:
      return state.withMutations(s =>
        s.set('groups', action.payload.groups)
          .set('groupBy', action.payload.groupBy)
          .set('groupingFields', action.payload.groupingFields)
          .set('sunburst', action.payload.sunburst)
          .set('breadcrumbs', initialState.get('breadcrumbs'))
          .set('awaitingNewFilterParams', false)
      );

    case actionTypes.SET_SUNBURST_BREADCRUMBS:
      return state.withMutations(s =>
        s.set('breadcrumbs', action.payload));
      
    case actionTypes.CLEAR_SUNBURST:
      return state.withMutations(s =>
        s.set('sunburst', initialState.get('sunburst')));

    case FETCH_ALLOCATION_SUNBURST_FAIL:
    case actionTypes.CLEAR_STATE:
      return initialState;

    case actionTypes.FETCH_SUNBURST_SECURITIES_DETAILS_SUCCESS:
      return state.withMutations(s => 
        s.set('table', action.payload.table)
          .set('isInventoryTable', action.payload.isInventoryTable));
  
    case actionTypes.CLEAR_SUNBURST_SECURITIES_DETAILS:
      return state.withMutations(s => s.set('table', null));

    default:
      return state;
  }
};
