import store from "pirumconnect/store";
import { shouldUseNewSSO } from "pirumconnect/auth/AuthManager";
import TokenStorage from "pirumconnect/storage/local/tokens";

export const gainSightEvent = ([ event_name, data ]: AptrinsicCallParams["track"]):void => {
  if (typeof window.aptrinsic !== "undefined") {
    window.aptrinsic('track', event_name, data);
  } else {
    console.warn("aptrinsic call was not made");
  }
};

export const gainSightIdentify = () => {
  if (typeof window.aptrinsic !== "undefined") {
    const userId = shouldUseNewSSO()
      ? TokenStorage.getIdToken().pirum.pirum_user_id
      : store && store.getState().app.userAccount.userInfo.user_id;
    const clientId = shouldUseNewSSO()
      ? TokenStorage.getIdToken().pirum.real_client_id
      : store && store.getState().app.userAccount.userInfo.home_entity;
    const clientCode = shouldUseNewSSO()
      ? TokenStorage.getIdToken().pirum.real_client_cd
      : store && store.getState().app.userAccount.userInfo.home_entity_cd;
    const shortname = shouldUseNewSSO()
      ? TokenStorage.getIdToken().pirum.shortname
      : store && store.getState().app.userAccount.userInfo.shortname;
    window.aptrinsic(
      "identify",
      {
        id: userId,
        clientId: clientId,
        shortname: shortname,
        clientCode: clientCode,
        screenX: window.screen.width,
        screenY: window.screen.height,
        windowX: window.innerWidth,
        windowY: window.innerHeight,
        lastLogin: new Date().toUTCString(),
      },
      {
        id: clientId,
        clientCode: clientCode
      }
    );
  } else {
    console.warn("aptrinsic call was not made");
  }
};
