import { AllocationsSunburstPage, InventorySunburstPage } from './allocationsSunburst/Page/Loadable';
import { CollateralGradeDayOnDayPage, CollateralGradePage } from './collateralGrade/Pages/Loadable';
import EligibilityAnalytics from './eligibilityAnalytics/Page/Loadable';
import EligibilityCheckerPage from './eligibilityChecker/Page/Loadable';
import EligibilityLimitPage from './eligibilityLimit/Page/Loadable';
import EligibilityReconciliationPage from './eligibilityReconciliation/Page/Loadable';
import ExposureAnalytics from './exposureAnalytics/Page/Loadable';
import LinkingTool from './linkingTool/Page/Loadable';
import MarginReconciliationPage from './marginReconciliation/Page/Loadable';
import ProjectionsPage from './projections/Page/Loadable';
import ScheduleManagerPage from './scheduleManager/';
import TimeSeriesPage from './timeSeries/Page/Loadable';
import VenueDetailPage from './venueDetail/Page/Loadable';
import OptimisationInitiation from './optimisationInitiation/Page/Loadable';
import EfficiencyAnalytics from './efficiencyAnalytics/Page/Loadable';

const appViewId = 'collateralConnect';
const sectionsViewIds = {
  VISIBILITY: 'visibility',
  SCHEDULES_ELIGIBILITY: 'schedulesAndEligibility',
  ANALYTICS: 'analytics',
  OPTIMISATION: 'optimisation',
};

const pagesViewIds = {
  REQUIRED_COLLATERAL: 'requiredCollateral',
  ALLOCATION_SUNBURST: 'allocationSunburst',
  INVENTORY_SUNBURST: 'inventorySunburst',
  COLLATERAL_GRADE: 'collateralGrade',
  COLLATERAL_GRADE_COMPARISON: 'collateralGradeComparison',
  REQUIRED_COLLATERAL_TIME_SERIES: 'requiredCollateralTimeSeries',
  ELIGIBILITY_ANALYTICS: 'eligibilityAnalytics',
  ELIGIBILITY_CHECKER: 'eligibilityChecker',
  ELIGIBILITY_RECONCILIATION: 'eligibilityReconciliation',
  MARGIN_RECONCILIATION: 'marginReconciliation',
  COLLATERAL_PROJECTION: 'collateralProjection',
  LIMIT_RECONCILIATION: 'limitReconciliation',
  SCHEDULE_MANAGER: 'scheduleManager',
  LINKING_TOOL: 'linkingTool',
  EXPOSURE_ANALYTICS: 'exposureAnalytics',
  OPTIMISATION_INITIATION: 'optimisationInitiation',
  EFFICIENCYANALYTICS: 'efficiencyAnalytics',
};

const viewIds = {
  app: { COLLATERAL_CONNECT: appViewId },
  sections: sectionsViewIds,
  pages: pagesViewIds,
};

const routes = {
  app: {
    viewId: viewIds.app.COLLATERAL_CONNECT,
    path: '/collateral-connect',
  },

  sectionsList: [
    {
      viewId: viewIds.sections.VISIBILITY,
      subpath: '/visibility',
    },
    {
      viewId: viewIds.sections.SCHEDULES_ELIGIBILITY,
      subpath: '/schedules-eligibility',
    },
    {
      viewId: viewIds.sections.ANALYTICS,
      subpath: '/analytics',
    },
    {
      viewId: viewIds.sections.OPTIMISATION,
      subpath: '/optimisation',
    },
  ],

  pagesList: [
    {
      viewId: viewIds.pages.REQUIRED_COLLATERAL,
      component: VenueDetailPage,
      subpath: '/summary',
      helpPath: '/help/venuedetail.pdf',
    },
    {
      viewId: viewIds.pages.COLLATERAL_GRADE,
      component: CollateralGradePage,
      subpath: '/grade',
      helpPath: '/help/collateralgrade.pdf',
    },
    {
      viewId: viewIds.pages.COLLATERAL_GRADE_COMPARISON,
      component: CollateralGradeDayOnDayPage,
      subpath: '/day-on-day-grade',
      helpPath: '/help/collateralgrade.pdf',
    },
    {
      viewId: viewIds.pages.ALLOCATION_SUNBURST,
      component: AllocationsSunburstPage,
      subpath: '/allocations',
    },
    {
      viewId: viewIds.pages.INVENTORY_SUNBURST,
      component: InventorySunburstPage,
      subpath: '/inventory',
    },
    {
      viewId: viewIds.pages.COLLATERAL_PROJECTION,
      component: ProjectionsPage,
      subpath: '/projections',
    },
    {
      viewId: viewIds.pages.REQUIRED_COLLATERAL_TIME_SERIES,
      component: TimeSeriesPage,
      subpath: '/time-series',
      helpPath: '/help/time-series.pdf',
    },
    {
      viewId: viewIds.pages.ELIGIBILITY_ANALYTICS,
      // TODO CWS-7881: Needs here and over the whole module folder
      component: EfficiencyAnalytics,
      subpath: '/analytics',
    },
    {
      viewId: viewIds.pages.EXPOSURE_ANALYTICS,
      component: ExposureAnalytics,
      subpath: '/exposure',
    },
    {
      viewId: viewIds.pages.ELIGIBILITY_RECONCILIATION,
      component: EligibilityReconciliationPage,
      subpath: '/reconciliation',
    },
    {
      viewId: viewIds.pages.MARGIN_RECONCILIATION,
      component: MarginReconciliationPage,
      subpath: '/marginreconciliation',
    },
    {
      viewId: viewIds.pages.ELIGIBILITY_CHECKER,
      component: EligibilityCheckerPage,
      subpath: '/checker',
    },
    {
      viewId: viewIds.pages.LIMIT_RECONCILIATION,
      component: EligibilityLimitPage,
      subpath: '/limit',
    },
    {
      viewId: viewIds.pages.SCHEDULE_MANAGER,
      component: ScheduleManagerPage,
      subpath: '/schedule-manager',
    },
    {
      viewId: viewIds.pages.LINKING_TOOL,
      component: LinkingTool,
      subpath: '/linking-tool',
    },
    {
      viewId: viewIds.pages.OPTIMISATION_INITIATION,
      component: OptimisationInitiation,
      subpath: '/initiation',
    },
    {
      viewId: viewIds.pages.EFFICIENCYANALYTICS,
      // TODO CWS-7881: Needs here and over the whole module folder
      component: EligibilityAnalytics,
      subpath: '/efficiency-analytics',
    },
  ],
};


const ccsRoutesConfig = {
  appViewId,
  viewIds,
  routes,
};

export default ccsRoutesConfig;
export { sectionsViewIds, pagesViewIds, };

