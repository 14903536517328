import services from '../services';
import {apiNames as uiConfigApiNames} from 'constants/api/uiConfigApi';
import {apiNames as uiApiNames, apiOperatonIds as uiApiOperatonIds} from 'constants/api/uiApi';

export function getClientGroups(dispatch) {
  return services
    .uiConfig(uiConfigApiNames.CLIENT_GROUPED)(dispatch, { request: {} })
    .then(clientGroupedResponse => {
      return {
        ...clientGroupedResponse
      };
    });
}

export function getUserInfo(dispatch) {
  return services
    .ui(uiApiNames.USER, uiApiOperatonIds.USER_INFO)(dispatch, { request: {} })
    .then(userInfo => {
      return {
        ...userInfo,
      };
    });
}
