import DashboardPage from "./trm/Loadable";

const viewIds = {
  app: {
    FOS: "fos",
  },
  sections: {
    RISK_MANAGER: "fosRiskManager",
  },
};

const routes = {
  app: {
    viewId: viewIds.app.FOS,
    path: "/fos",
  },
  sectionsList: [
    {
      viewId: viewIds.sections.RISK_MANAGER,
      component: DashboardPage,
      subpath: "/risk_manager/:selectedRisk?/:period?",
      helpPath: "/help/riskManager.pdf",
    },
  ],
  pagesList: [],
};

const routesConfig = {
  appViewId: viewIds.app.FOS,
  viewIds,
  routes,
};

export default routesConfig;
