import LoadingSuspense from "common/LoadingSuspense";
import React from "react";

const Component = React.lazy(() => import("./PaymentsPage"));

const LoadablePaymentsPage = (props) => (
  <LoadingSuspense>
    <Component {...props} />
  </LoadingSuspense>
);

export default LoadablePaymentsPage;
