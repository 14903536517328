import * as actionTypes from 'constants/actionTypes';

export const initialState = {
  appDataInitialised: false,
  initalisationRuntimeError: null,
};

export const init = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.FETCH_APP_INIT_DATA_COMPLETED: {
      const initalisationRuntimeError = action.payload;
      return {
        ...state,
        appDataInitialised: !initalisationRuntimeError,
        initalisationRuntimeError,
      };
    }
    default:
      return state;
  }
};
