import { Map } from 'immutable';
import { actionTypes } from 'reconciliation/constants';
import { createReducer } from '@reduxjs/toolkit';
import { createNamedAction } from 'utils/helpers';

const initialFilterStateObject = Map({
  data: null,
  selectedSetID: null,
  currentView: null,
  isDrillDownOpen: false,
  isDrillDownLoading: false,
  selectedFilters: {
    isBreaksOnly: false,
    isPopulatedOnly: false,
    phaseLabels: [],
  },
});

const initialState = Map(initialFilterStateObject);

export const createSetsDrillDownReducerWithNamedType = (recType) => createReducer(initialState, {
  [createNamedAction(actionTypes.PREPARE_DRILLDOWN_MODAL, recType)]: (state, action) => {
    return state.withMutations(st => {
      st.set('isDrillDownOpen', true);
      st.set('isDrillDownLoading', true);
      st.set('currentView', action.payload);
    });
  },
  [createNamedAction(actionTypes.SET_DRILLDOWN_DATA, recType)]: (state, action) => {
    return state.withMutations(st => {
      st.set('selectedSetID', action.payload.setId);
      st.set('data', {
        table: action.payload.data.table,
        filterByFields: action.payload.data.filterByFields,
        breaks: action.payload.data.breaks,
        columnToggles: action.payload.data.columnToggles,
      });
      st.set('isDrillDownLoading', false);
      st.set('currentView', action.payload.view);
    });
  },
  [createNamedAction(actionTypes.CLOSE_DRILLDOWN_MODAL, recType)]: (state) => {
    return state.withMutations(st => {
      st.set('isDrillDownOpen', false);
      st.set('selectedSetID', null);
      st.set('isDrillDownLoading', false);
      st.set('currentView', null);
      st.set('selectedFilters', {
        isBreaksOnly: false,
        phaseLabels: [],
      });
    });
  },
  [createNamedAction(actionTypes.SET_DRILLDOWN_IS_BREAKS_ONLY, recType)]: (state, action) => {
    return state.update(
      'selectedFilters',
      selectedFilters => ({
        ...selectedFilters,
        isBreaksOnly: action.payload,
      }),
    );
  },
  [createNamedAction(actionTypes.SET_DRILLDOWN_IS_POPULATED_ONLY, recType)]: (state, action) => {
    return state.update(
      'selectedFilters',
      selectedFilters => ({
        ...selectedFilters,
        isPopulatedOnly: action.payload,
      }),
    );
  },
  [createNamedAction(actionTypes.SET_PHASES, recType)]: (state, action) => {
    return state.update(
      'selectedFilters',
      selectedFilters => ({
        ...selectedFilters,
        phaseLabels: action.payload,
      }),
    );
  },
});
