import services from 'services/services';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';
import VenueDetailAdapter from './servicesAdapter';

import storage from 'pirumconnect/storage/session';

const config = {
  namespace: 'VenueDetail'
};

export function fetchVenueDetail(dispatch, params) {
  const adapter = new VenueDetailAdapter();
  const request = VenueDetailAdapter.prepareRequest(params);

  return services.query(apiNames.COLLATERAL_REQUIREMENT_GROUPED)(dispatch, { request })
    .then(result => adapter.parseData(result));
}

export function storeVenueDetailState(state) {
  storage.clear(config.namespace);
  Object.keys(state).forEach(key => storage.setItem(config.namespace, key, state[key]));
}

export function getVenueDetailState(keys = []) {
  const storedValues = keys.reduce((acc, key) => {
  	acc[key] = storage.getItem(config.namespace, key);
  	return acc;
  }, {});
  return storedValues;
}

export function fetchVenueDetailFilters(dispatch, params) {
  const adapter = new VenueDetailAdapter();
  const request = VenueDetailAdapter.prepareRequest(params);

  return services.query(apiNames.COLLATERAL_REQUIREMENT_GROUPED, apiOperatonIds.COLLATERAL_REQUIREMENT_GROUPED_FILTERS)(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}


export function clearVenueDetailState() {
  storage.clear(config.namespace);
}
