import store from 'pirumconnect/store';
import { getPsSEToken } from 'pirumconnect/storage/local/oldToken';
import { sendStats } from 'pirumconnect/services';
import { browserInfo } from 'utils/browser';
import { APP_VERSION } from 'constants/app';
import { shouldUseNewSSO } from "./auth/AuthManager";
import QueryParams from 'pirumconnect/storage/local/QueryParams';

const UPON_LIMIT = 200; //Time in miliseconds

class PirumAnalytics {
  constructor(appVersion='no_ver_set') {
    
    const session = shouldUseNewSSO() ? QueryParams.getQueryParams() : getPsSEToken();

    this.appVersion = APP_VERSION || appVersion;
    this.trackingId = shouldUseNewSSO() ? session.jti : session.aToken;//'unique-app-tracking-id-per-session';//from psSE
    this.userId = store?.getState().app.userAccount.userInfo.user_id;
    this.clientsIds = session.clientIds && [...session.clientIds];
    this.prevTimestamp = 0;
    this.prevSentTime = 0;
    this.requestData = {
      entries: [],
      browserInfo,
      appVersion: this.appVersion,      
    };
    this.scheduledToSend = false;
    
    this.send = this.send.bind(this);
    this.attemptToSend = this.attemptToSend.bind(this);
  }
  

  send(data={}) {
    const timestamp = Date.now();
    const upon = this.prevTimestamp - timestamp < UPON_LIMIT;
    this.prevTimestamp = timestamp;

    const trackingData = {  
      trackingId: this.trackingId,
      clientsIds: this.clientsIds,
      location: window.location.pathname,
      component: data.gridId || data.componentId,
      action: data.actionName,
      payload: JSON.stringify({...data, upon}),
      timestamp,      
    };

    this.requestData.entries.push(trackingData);
    
    if (!this.scheduledToSend) {
      this.attemptToSend(upon);
    }
  }

  
  attemptToSend(shouldTimeout=false) {
    const justRequestedAttempt = this.prevTimestamp - Date.now() < UPON_LIMIT;

    if (shouldTimeout || !justRequestedAttempt) {
      this.scheduledToSend = true;
      setTimeout(this.attemptToSend, 1000);
    } else {      
      sendStats({...this.requestData});
      this.requestData.entries = [];
      this.scheduledToSend = false;
    }
  }

  sendToMockServer__DEBUG(trackingData={}) {
    require('whatwg-fetch');
    fetch('/useraction/statistic/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(trackingData),
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        // eslint-disable-next-line no-console
        console.log( 'response with status >= 200 && status < 300: ' + response, 'request trackingData',trackingData );
      } else {
        // eslint-disable-next-line no-console
        console.log('error > response.statusText', response.statusText, ' > response',response, 'request trackingData',trackingData);         
      }
    }
    ).catch(error => {
      // eslint-disable-next-line no-console
      console.log('request failed', error, 'request trackingData',trackingData);
    });
  }
}


const APP_VER = 'no_ver_set';//TODO: app version support would be required
const analytics = new PirumAnalytics(APP_VER);
export default analytics; //Utilising ES6 modules for creating a Singleton
