import services from 'services/services';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';

export function downloadExcelTemplate(dispatch, params) {
  return services.query(apiNames.CUSTOM_PORTFOLIO, apiOperatonIds.CUSTOM_PORTFOLIO_SPREADSHEET_DOWNLOAD, true)(
    dispatch, {}
  );
}

export function uploadExcelToFetchTable(dispatch, base64excelString) {
  return services.query(apiNames.CUSTOM_PORTFOLIO, apiOperatonIds.CUSTOM_PORTFOLIO_SPREADSHEET_UPLOAD, true)(
    dispatch,
    { request: { spreadsheet: base64excelString } }
  );
}

export function rqvFilters(dispatch, params) {
  return services.query(apiNames.RQV_ADJUSTMENT, apiOperatonIds.RQV_ADJUSTMENT_FILTERS, true)(dispatch, { request: params });
}

export function rqvWidgets(dispatch) {
  return services.query(apiNames.RQV_ADJUSTMENT, apiOperatonIds.RQV_WIDGETS, true)(
    dispatch, {}
  );
}

export function applyAdjustmentAction(dispatch, params) {
  const filterBy = Object.entries(params.filterBy).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(value => {
        acc.push({
          fieldName: key,
          fieldValue: value.value
        });
      });
    }
    else {
      acc.push({
        fieldName: key,
        fieldValue: value.value
      });
    }
    return acc;
  }, []);
  const context = {
    ...params,
    filterBy: filterBy,
  };
  return services.query(apiNames.RQV_ADJUSTMENT, apiOperatonIds.APPLY_ADJUSTMENT_ACTION)(dispatch, { request: { context } });
}