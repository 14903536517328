import styled from 'styled-components/macro';
import { 
  PIRUM_MAIN_GREY,
  PIRUM_UI_BG_3,
  PIRUM_UI_BG_2,
  PIRUM_UI_BG_1,
} from 'styles/colors/brandColors';
import { ReactComponent as CloseSVG } from 'assets/images/close.svg';
import ReactModalAdapter from './reactModalAdapter';

export const ModalContainer = styled.div`
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const StyledCloseSVG = styled(CloseSVG)`
  width: 16px;
  height: 16px;
  color: ${PIRUM_MAIN_GREY[100]};
  &:hover{
    color: ${PIRUM_UI_BG_3[100]};
  }
  &:focus{
    color: ${PIRUM_UI_BG_3[100]};
  }
`;

export const StyledErrorModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  /* Portal styles here (though usually you will have none) */

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${PIRUM_UI_BG_2[100]};
    background-image: linear-gradient(-1deg, transparent 58%, ${PIRUM_UI_BG_1[100]} 100%);
    will-change: background-color;
    transition: background-color 0.25s ease-out;
  }

  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: auto;
    min-width: 600px;
    min-height: 400px;
    outline: none;
    border: none;
    background-color: ${PIRUM_UI_BG_1[100]};
    box-shadow: 0 2px 67px 0 rgba(0, 0, 0, 0.18);
  }
`;