import React from 'react';
import LoadingSuspense from 'common/LoadingSuspense';

const AllocationsComponent = React.lazy(() => import('./allocationsSunburstPage' /* webpackChunkName: "AllocationsSunburstPage" */));

export const AllocationsSunburstPage = (props) => {
  return (
    <LoadingSuspense>
      <AllocationsComponent {...props} />
    </LoadingSuspense>
  );
};

const InventoryComponent = React.lazy(() => import('./inventorySunburstPage' /* webpackChunkName: "AllocationsSunburstPage" */));

export const InventorySunburstPage = (props) => {
  return (
    <LoadingSuspense>
      <InventoryComponent {...props} />
    </LoadingSuspense>
  );
};