import * as actionTypes from "constants/actionTypes";
import * as svc from "services/common/userAccountService";

import { updateSession } from "pirumconnect/storage/local/oldToken";
import { ActiveUserBank } from "pirumconnect/storage/UserBankId";
import {
  shouldUseNewSSO,
  updateQueryParams,
} from "pirumconnect/auth/AuthManager";
import QueryParams from "pirumconnect/storage/local/QueryParams";

// Client Ids
export function getClientList() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ACCOUNT_CLIENTS_LIST_REQUEST,
    });
    return svc.getClientGroups(dispatch).then((response) => {
      ActiveUserBank.setIdFromClientList(response?.groups?.[0]?.clients ?? []);
      dispatch({
        type: actionTypes.ACCOUNT_CLIENTS_LIST_SUCCESS,
        payload: response,
      });
    });
  };
}

export function updateClientIds(clientIds) {
  return (dispatch) => {
    return Promise.resolve(clientIds).then((_) => {
      if (shouldUseNewSSO()) {
        const jti = QueryParams.getQueryParams().jti;
        updateQueryParams({ jti, clientIds });
      } else {
        updateSession({ clientIds });
      }
      dispatch({
        type: actionTypes.ACCOUNT_UPDATE_CLIENT_IDS,
      });
    });
  };
}

export function updateCounterPartySelectedIds(cpIds) {
  //TODO:This is now specifically used only by breaks, should it be part of breaks(MIS) actions???
  return (dispatch) => {
    return Promise.resolve(cpIds).then((payload) => {
      dispatch({
        type: actionTypes.UPDATE_COUNTER_PARTY_ACTIVE_IDS_SUCESS,
        payload,
      });
    });
  };
}

// User info
export function getUserInfo() {
  return (dispatch) => {
    return svc.getUserInfo(dispatch).then((payload) => {
      dispatch({
        type: actionTypes.GET_USER_INFO_SUCCESS,
        payload,
      });
    });
  };
}
