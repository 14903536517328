import React from "react";
import { MemoryRouter, Route, Switch, useHistory } from "react-router-dom";
import ScheduleManagerPage from "./ScheduleManagerPage/Loadable";
import ScheduleBuilderPage from "./ScheduleBuilderPage/Loadable";
import RuleBuilderPage from "./RuleBuilderPage/Loadable";
import { PATH } from "./constants";

//Internal small router for the smaller pages in Schedule Manager flow
//Using memory router to avoid changing the URL

export default () => {
  const history = useHistory();
  return (
    <MemoryRouter>
      <Switch>
        <Route exact path={PATH.builder}>
          <RuleBuilderPage history={history} />
        </Route>
        <Route exact path={PATH.schedule}>
          <ScheduleBuilderPage history={history} />
        </Route>
        <Route exact path={PATH.manager}>
          <ScheduleManagerPage history={history} />
        </Route>
      </Switch>
    </MemoryRouter>
  );
};
