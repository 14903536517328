import styled, { css } from "styled-components/macro";
import { PIRUM_BLUEGREY_1 } from "styles/colors/brandColors";

export enum ButtonVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}

export enum ButtonSizes {
  LARGE = "large",
  NORMAL = "normal",
  SMALL = "small",
  X_SMALL = "x_small",
}

type ConfigFormat = {
  [K: string]: {
    [J in ButtonSizes]: string;
  };
};

const config: ConfigFormat = {
  fontSizeByBtnSize: {
    large: "9px",
    normal: "13px",
    small: "11px",
    x_small: "11px",
  },
  heightByBtnSize: {
    large: "47px",
    normal: "33px",
    small: "21px",
    x_small: "auto",
  },
  paddingBySize: {
    large: "10px 8px",
    normal: "10px 8px",
    small: "5px 10px",
    x_small: "3px",
  },
};

const buttonTypeStyles = ({
  type,
  theme,
}: {
  type: ButtonVariants;
  theme: { mode: "light" | "dark" };
}) => {
  switch (type) {
    case ButtonVariants.PRIMARY:
      switch (theme.mode) {
        case "dark":
          return css<ButtonProps>`
            background: ${(props) => (props.selected ? "#8bc482" : "#72b767")};
            ${(props) =>
              props.disabled
                ? css<ButtonProps>`
                    background: rgb(79, 84, 102);
                  `
                : css<ButtonProps>`
                    &:hover {
                      background: #8bc482;
                    }
                  `}
          `;
        case "light":
          return css<ButtonProps>`
            background: #72b767;
            ${(props) =>
              props.disabled
                ? css<ButtonProps>`
                    background: #ababab;
                  `
                : css<ButtonProps>`
                    &:hover {
                      background: #46b535;
                    }
                  `}
          `;
      }
      break;
    case ButtonVariants.SECONDARY:
      switch (theme.mode) {
        case "dark":
          return css<ButtonProps>`
            background: ${(props) =>
              props.selected ? "rgba(89, 96, 121, 1)" : "rgba(89,96,121, 0.5)"};
            ${(props) =>
              props.disabled
                ? css<ButtonProps>`
                    background: rgba(89, 96, 121, 0.15);
                  `
                : css<ButtonProps>`
                    &:hover {
                      background: rgba(89, 96, 121, 1);
                    }
                  `}
          `;
        case "light":
          return css<ButtonProps>`
            background: ${PIRUM_BLUEGREY_1[100]};
            ${(props) =>
              props.disabled
                ? css<ButtonProps>`
                    background: #ababab;
                  `
                : css<ButtonProps>`
                    &:hover {
                      background: ${PIRUM_BLUEGREY_1[70]};
                    }
                  `}
          `;
      }
      break;
    case ButtonVariants.TERTIARY:
      switch (theme.mode) {
        case "dark":
          return css<ButtonProps>`
            border: 1px solid #596079;

            &:hover {
              background: #596079;
            }
          `;
      }
  }
};

export interface ButtonProps {
  width?: string;
  disabled?: boolean;
  selected?: boolean;
  size?: ButtonSizes;
  type?: ButtonVariants;
  theme?: "light" | "dark";
}

const Button = styled.div<ButtonProps>`
  width: ${({ width = "auto" }) => width};
  font-size: ${({ size = ButtonSizes.NORMAL }) =>
    config.fontSizeByBtnSize[size]};
  display: flex;
  align-items: center;
  padding: ${({ size = ButtonSizes.NORMAL }) => config.paddingBySize[size]};
  height: ${({ size = ButtonSizes.NORMAL }) => config.heightByBtnSize[size]};
  transition: background 0.4s;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  color: ${({ disabled = false }) =>
    disabled ? "rgba(255,255,255,0.4);" : "rgba(255,255,255,1);"};

  ${({ disabled = false }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${({ type = ButtonVariants.PRIMARY, theme = { mode: "light" } }) =>
    buttonTypeStyles({ type, theme })}
`;

export default Button;
