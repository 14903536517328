import * as actionTypes from '../constants/actionTypes';
import * as specs from 'services/specs';

const getApiId = (apiName, operationId) => {
  return (!operationId)? apiName : `${apiName}-${operationId}`;
};

const getFetchFailActionByApiId = (apiId) => {
  return actionTypes.API_REQUEST_FAILED_TEMPLATE.replace( actionTypes.API_REQUEST_FAILED_VAR, apiId );
};

const getFetchFailActionByApi = (apiName, operationId) => {
  return actionTypes.API_REQUEST_FAILED_TEMPLATE.replace(
    actionTypes.API_REQUEST_FAILED_VAR,
    getApiId(apiName, operationId)
  );
};

const getApiParams = (specName, apiName, operationId) => {
  const spec = specs[specName];

  if (!spec) {
    return {};
  }

  const entry = Object.values(spec.paths).find(entry => entry.post.operationId === operationId && entry.post.tags.includes(apiName));

  if (!entry) {
    return {};
  }

  const schema = entry.post.parameters[0].schema['$ref'].split('/')[2];
  const params = Object.keys(spec.definitions[schema].properties)
    .map(paramName => {
      return {name: paramName, type: spec.definitions[schema].properties[paramName].type};
    });
  
  return params;
};

const generateUniqueId = () => new Date().getTime() + '_' + Math.floor(Math.random() * 10000);


export {
  getApiId, 
  getFetchFailActionByApi, 
  getFetchFailActionByApiId, 
  getApiParams, 
  generateUniqueId,
};

