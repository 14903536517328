import React from 'react';
import { redirectToLogout } from 'pirumconnect/storage/local/oldToken';
import authManager, { shouldUseNewSSO } from 'pirumconnect/auth/AuthManager';
import { ErrorModal, errorMessage, errorButtons, errorTypes } from 'common/error';
import ErrorMessage from '../components/ErrorMessage/index';
import appLogger from 'pirumconnect/logger';


class ErrorManager {

  static sendErrorReport(error, info='') {
    // eslint-disable-next-line no-console
    process.env.NODE_ENV === 'development' && console.error(error);
    appLogger.error({error, info});
  }

  static getDerivedStateFromError(error) {
    return { componentError: error };
  }
  
  static shouldIgnoreApiError(error) {
    return !error.getApiId
           || error.suppressError
           || error.getStatus() === 403;//ignore Unauthorised 403, as in-page error will be shown instead
  }

  static createError(errorType, error, buttons=[{label:errorButtons.CLOSE, onClick:_=>_}], asModal=true, shouldSendReport=true, messageVariables = {}) {
    const errorMessageComponent = <ErrorMessage errorType={errorType} buttons={buttons} messageVariables={messageVariables} />;
    
    if (shouldSendReport) {
      ErrorManager.sendErrorReport(error, errorType);
    }

    if (asModal) {
      return this.createModalError(errorType, errorMessageComponent, buttons[0] ? buttons[0].onClick : _=>_);
    }

    return errorMessageComponent;
  }

  static createModalError (errorType, errorMessageComponent, handleClick) {
    const msg = errorMessage.content[errorType];
    let onClick = handleClick;

    if (errorType === errorTypes.UNAUTHORIZED_USER) {
      onClick = !shouldUseNewSSO() ? redirectToLogout : authManager.clearTokensAndRedirectToExternalLogout;
    }

    return (
      <ErrorModal
        isOpen={true}
        title={msg.title}
        onClick={onClick}
      >
        { errorMessageComponent }      
      </ErrorModal>
    );
  }
    
}

export { ErrorManager };