const apiNames = {
  CLIENT_GROUPED: 'clientGrouped',
  MENU: 'menu',
  USERACTION: 'useraction',  
};

const apiOperatonIds = {
  MENU: 'menuTree',
  STATISTICS: 'statisticsRoute',
};

export {apiNames, apiOperatonIds};
