import React from "react";

import { GlobalResetStyle } from "./reset";
import { GlobalStyle } from "@pirum-systems/common-components";

export const GlobalStyleContainer = () => (
  <>
    <GlobalResetStyle />
    <GlobalStyle />
  </>
);
