import { Map } from "immutable";
import {
  ActionTypes,
  ChangeStatusModalActions,
  ChangeStatusModalState,
  ModalStatus,
} from "./types";

export const INITIAL_STATE = Map({
  status: ModalStatus.Closed,
  modalOpen: false,
  selectedEvents: [],
  eventAction: "",
}) as ChangeStatusModalState;

export default function changeStatusModalReducer(
  state = INITIAL_STATE,
  action: ChangeStatusModalActions
) {
  switch (action.type) {
    case ActionTypes.CLEAR_MATCHING_MODAL_STATE:
      return state
        .set("status", INITIAL_STATE.get("status"))
        .set("modalOpen", INITIAL_STATE.get("modalOpen"))
        .set("selectedEvents", INITIAL_STATE.get("selectedEvents"))
        .set("eventAction", "");
    case ActionTypes.CLOSE_MATCHING_MODAL:
      return state.set("modalOpen", false).set("status", ModalStatus.Closed);
    case ActionTypes.SET_MATCHING_MODAL_GROUPED:
      return state.set("status", ModalStatus.Grouped);
    case ActionTypes.SET_MATCHING_MODAL_NONGROUPED:
      return state.set("status", ModalStatus.NonGrouped);
    case ActionTypes.SET_MATCHING_MODAL_SELECTED_EVENTS:
      return state.set("selectedEvents", action.payload);
    case ActionTypes.SET_EVENT_ACTION:
      return state.set("eventAction", action.payload);
    case ActionTypes.OPEN_MATCHING_MODAL:
      return state.set("modalOpen", true);
    default:
      return state;
  }
}
