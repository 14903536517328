import * as svc from './services';

export const actionTypes = {
  FETCH_SCHEDULES_SUCCESS: 'ccs/linkingTool/FETCH_ANALYTICS_RESULTS_SUCCESS',
  FETCH_TRADES_SUCCESS: 'ccs/linkingTool/FETCH_ANALYTICS_SHORTS_SUCCESS',
  FETCH_LINKS_SUCCESS: 'ccs/linkingTool/FETCH_ANALYTICS_LINKS_SUCCESS',
  CREATE_LINKS_SUCCESS: 'ccs/linkingTool/CREATE_LINKS_SUCCESS',
  DELETE_LINKS_SUCCESS: 'ccs/linkingTool/DELETE_LINKS_SUCCESS',
};

/**
 *
 * Sync actions
 *
 */
const fetchSchedulesSuccess = payload => ({
  type: actionTypes.FETCH_SCHEDULES_SUCCESS,
  payload,
});

const fetchTradesSuccess = payload => ({
  type: actionTypes.FETCH_TRADES_SUCCESS,
  payload,
});

const fetchLinksSuccess = payload => ({
  type: actionTypes.FETCH_LINKS_SUCCESS,
  payload,
});

const deleteLinksSuccess = payload => ({
  type: actionTypes.DELETE_LINKS_SUCCESS,
  payload,
});

export const createLinksSuccess = payload => ({
  type: actionTypes.CREATE_LINKS_SUCCESS,
  payload,
});

/**
 *
 * Async actions
 *
 */

export function fetchSchedules() {
  return dispatch => {
    return svc.fetchSchedules(dispatch)
      .then(payload => {
        dispatch(fetchSchedulesSuccess(payload));
      });
  };
}

export function fetchTrades() {
  return dispatch => {
    return svc.fetchTrades(dispatch)
      .then(payload => {
        dispatch(fetchTradesSuccess(payload));
      });
  };
}

export function fetchLinks() {
  return dispatch => {
    return svc.fetchLinks(dispatch)
      .then(payload => {
        dispatch(fetchLinksSuccess(payload));
      });
  };
}

export function createLinks(params) {
  return dispatch => {
    return svc.createLinks(dispatch, params);
  };
}

export function deleteLinks(params) {
  return dispatch => {
    return svc.deleteLinks(dispatch, params)
      .then(payload => {
        dispatch(deleteLinksSuccess(payload));
      });
  };
}