import * as actionTypes from '../../constants/actionTypes';
import * as svc from '../../services/common/platformDetailsService';

const fetchPlatformDetailsSuccess = payload => ({
  type: actionTypes.FETCH_PLATFORM_DETAILS_SUCCESS,
  payload,
});

export function getPlatformDetails() {
  
  return dispatch => {
    return svc.getPlatformDetails(dispatch)
      .then((response) => {        
        dispatch(fetchPlatformDetailsSuccess(response));
      });
  };
}
