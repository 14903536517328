import * as svc from './services';
import { addToast } from 'common/notifications/toast/actions';
import TOASTS from './toasts.js';
import FileSaver from 'file-saver';

export const actionTypes = {
  FETCH_ANALYTICS_RESULTS_SUCCESS: 'ccs/eligibilityAnalytics/FETCH_ANALYTICS_RESULTS_SUCCESS',
  FETCH_ANALYTICS_SHORTS_SSUCCESS: 'ccs/eligibilityAnalytics/FETCH_ANALYTICS_SHORTS_SSUCCESS',
  FETCH_ANALYTICS_SETTINGS_SUCCESS: 'ccs/eligibilityAnalytics/FETCH_ANALYTICS_SETTINGS_SUCCESS',
  FETCH_ANALYTICS_INVENTORY_SUCCESS: 'ccs/eligibilityAnalytics/FETCH_ANALYTICS_INVENTORY_SUCCESS',
  FETCH_ANALYTICS_CUSTOM_INVENTORY_SUCCESS: 'ccs/eligibilityAnalytics/FETCH_ANALYTICS_CUSTOM_INVENTORY_SUCCESS',
  CLEAR_ANALYTICS_DATA: 'ccs/eligibilityAnalytics/CLEAR_ANALYTICS_DATA',
  CLEAR_INVENTORY_DATA: 'ccs/eligibilityAnalytics/modal/CLEAR_INVENTORY_DATA',
  CLEAR_RESULTS: 'ccs/eligibilityAnalytics/CLEAR_RESULTS',
  SET_RQV_ADJUSTMENT: 'ccs/eligibilityAnalytics/modal/SET_RQV_ADJUSTMENT',
};

/**
 *
 * Sync actions
 *
 */
const fetchAnalyticsResultsSuccess = payload => ({
  type: actionTypes.FETCH_ANALYTICS_RESULTS_SUCCESS,
  payload,
});
const fetchAnalyticsShortsSuccess = payload => ({
  type: actionTypes.FETCH_ANALYTICS_SHORTS_SSUCCESS,
  payload,
});
const fetchAnalyticsInventorySuccess = payload => ({
  type: actionTypes.FETCH_ANALYTICS_INVENTORY_SUCCESS,
  payload,
});
const fetchAnalyticsCustomInventorySuccess = payload => ({
  type: actionTypes.FETCH_ANALYTICS_CUSTOM_INVENTORY_SUCCESS,
  payload,
});
const fetchAnalyticsSettingsSuccess = payload => ({
  type: actionTypes.FETCH_ANALYTICS_SETTINGS_SUCCESS,
  payload,
});

export const clearInventoryData = () => ({
  type: actionTypes.CLEAR_INVENTORY_DATA,
});

export const clearResultsData = () => ({
  type: actionTypes.CLEAR_RESULTS,
});

/**
 *
 * Async actions
 *
 */
export function fetchInputData({ adjustments, shorts, inventory, hypotheticalInventory }) {
  return dispatch => {
    dispatch(fetchAnalyticsShorts({ ...shorts, adjustments }));
    hypotheticalInventory
      ? dispatch(fetchAnalyticsCustomInventory({ inventorySelection: hypotheticalInventory }))
      : dispatch(fetchAnalyticsInventory({ ...inventory, adjustments }));
  };
}

export function fetchCustomInputData(params) {
  return dispatch => {
    dispatch(fetchAnalyticsCustomInventory(params));
  };
}

export function fetchAnalyticsSettings() {
  return dispatch => {
    return svc.fetchAnalyticsSettings(dispatch)
      .then(payload => {
        dispatch(fetchAnalyticsSettingsSuccess(payload));
      });
  };
}

export function fetchAnalyticsResults(params) {
  return dispatch => {
    return svc.fetchAnalyticsResults(dispatch, params)
      .then(payload => {
        dispatch(fetchAnalyticsResultsSuccess(payload));
      });
  };
}

export function fetchAnalyticsShorts(params) {
  return dispatch => {
    return svc.fetchAnalyticsShorts(dispatch, params)
      .then(payload => {
        payload.warnings && payload.warnings.forEach((warning) => {
          dispatch(addToast(TOASTS.ADJUSTMENT_WARNING(warning)));
        });
        dispatch(fetchAnalyticsShortsSuccess(payload));
      });
  };
}

export function fetchAnalyticsInventory(params) {
  return dispatch => {
    dispatch(clearInventoryData());
    return svc.fetchAnalyticsInventory(dispatch, params)
      .then(payload => {
        dispatch(fetchAnalyticsInventorySuccess(payload));
      });
  };
}

export function fetchAnalyticsCustomInventory(params) {
  return dispatch => {
    dispatch(clearInventoryData());
    return svc.fetchAnalyticsCustomInventory(dispatch, params)
      .then(payload => {
        dispatch(fetchAnalyticsCustomInventorySuccess(payload));
      });
  };
}

export function fetchAnalyticsShortsFilters(params) {
  return (dispatch) => {
    return svc.fetchAnalyticsShortsFilters(dispatch, params);
  };
}

export function fetchAnalyticsInventoryFilters(params) {
  return (dispatch) => {
    return svc.fetchAnalyticsInventoryFilters(dispatch, params);
  };
}

export function downloadAllocationReport(params) {
  return dispatch => {
    return svc.downloadAllocationReport(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export const setRqvAdjustment = (payload) => ({
  type: actionTypes.SET_RQV_ADJUSTMENT,
  payload: payload,
});