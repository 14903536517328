// The value that the API expects as part of a request for filter types
export const FILTER_BREAK_TERM = 'break';
export const FILTER_SEARCH_TERM = 'search';
export const FILTER_TYPE_OF_SFT_TERM = 'typeOfSft';
export const FILTER_PARTICIPANT_ID_TERM = 'participantId';

// The values that the API provides as a key to access filter values
export const FILTER_TRADE_BREAK_KEY = 'breaksDescription';
export const FILTER_COLLATERAL_BREAK_KEY = 'colBreaksDescription';
export const FILTER_TYPE_OF_SFT_KEY = 'typesOfSft';
export const FILTER_PARTICIPANT_ID_KEY = 'participantIds';

// Named filters that share reducer / action functionality
export const COMMON = 'COMMON';
export const REC = 'REC';