import React from "react";
import LoadingSuspense from "common/LoadingSuspense";

const Component = React.lazy(() => import("./"));

const LoadableExposureAnalytics = (props) => {
  return (
    <LoadingSuspense>
      <Component {...props} />
    </LoadingSuspense>
  );
};

export default LoadableExposureAnalytics;
