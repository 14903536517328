import React from 'react';
import PropTypes from 'prop-types';
import { errorMessage as config, errorTypes } from 'common/error';

import * as styledComponents from './styles';

function ErrorMessage ({ errorType = errorTypes.DISPLAY_ERROR, buttons = [], messageVariables = {}}) {
  const {title, desc} = config.content[errorType];


  const variablesRegex = /\{\S*\}/g; //All non-space characters wrapped in curly braces
  const variables = desc.match(variablesRegex) || [];

  const message = variables.reduce((acc, variable) => {
    const variableName = variable.replace(/(\{|\})/g, ''); //Remove curly braces
    if (!messageVariables[variableName]) {
      return acc;
    }

    return acc.replace(variable, messageVariables[variableName]);
  }, desc);
  
  return (
    <styledComponents.ErrorMessage>
      <styledComponents.Icon />
      <styledComponents.Title>{ title }</styledComponents.Title>
      <styledComponents.Desc>{ message }</styledComponents.Desc>
      <styledComponents.MessageFooter>{ config.messageFooter }</styledComponents.MessageFooter>
      {
        buttons && buttons.map((button, i) => 
          <styledComponents.Button key={i} onClick={button.onClick} >
            { button.label }
          </styledComponents.Button>
        )
      }
    </styledComponents.ErrorMessage>
  );
}

ErrorMessage.propTypes = {
  errorType: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })),
  messageVariables: PropTypes.object,
};

export default ErrorMessage;
