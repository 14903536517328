import React from "react";
import { useDispatch } from "react-redux";

import * as styledComponents from "./styles";
import { ReactComponent as CaretLeftSVG } from "assets/images/caret-left.svg";
import { ReactComponent as TickSVG } from "assets/images/tick.svg";
import { ActionTypes } from "../types";

export const Grouped = () => {
  const dispatch = useDispatch();

  const exitModal = () => {
    dispatch({ type: ActionTypes.CLOSE_MATCHING_MODAL });
  };

  return (
    <>
      <styledComponents.Header>
        Which trades do you wish to change status of?
      </styledComponents.Header>
      <styledComponents.ButtonsContainer>
        <styledComponents.ActionButton onClick={exitModal}>
          <CaretLeftSVG /> Cancel &amp; return
        </styledComponents.ActionButton>
        <styledComponents.PrimaryActionButton
          onClick={() => {}}
          data-testid="actionButton"
        >
          <TickSVG /> Issue
        </styledComponents.PrimaryActionButton>
      </styledComponents.ButtonsContainer>
    </>
  );
};
