import EligibilityCheckerAdapter from './servicesAdapter';
import services from 'services/services';
import {apiNames, apiOperatonIds} from 'constants/api/queryApi';


export function fetchEligibilityChecker(dispatch, params) {
  const adapter = new EligibilityCheckerAdapter();
  const request = EligibilityCheckerAdapter.prepareRequest(params);
  return services.query(apiNames.ELIGIBILITY_CHECKER, apiOperatonIds.ELIGIBILITY_CHECKER_FILTERS)(dispatch, { request })
    .then(result => adapter.parseAPIResponse(result));
}

export function fetchEligibilityCheckerSpreadsheet(dispatch, params) {
  const request = EligibilityCheckerAdapter.prepareRequest(params);

  return services.query(apiNames.ELIGIBILITY_CHECKER, apiOperatonIds.ELIGIBILITY_CHECKER_SPREADSHEET)(dispatch, { request })
    .then(result => result);
}
