import { parseFilters, prepareFiltersRequest, config as parserConfig } from 'utils/parseUtils';
import { PIRUM_UNDERCOVERED_RED } from "styles/colors/brandColors";
import moment from 'moment';

const config = {
  colors: {
    'COLOR_0': '#488DCA',
    'COLOR_1': '#D0AB1B',
    'COLOR_2': PIRUM_UNDERCOVERED_RED[100],
    'COLOR_3': '#45C583',
    'COLOR_4': '#A0A5AE',
    'COLOR_5': '#3C66AC',
    'COLOR_6': '#E9B04C',
  },
  dropdown: {
    defaultLabel: parserConfig.filterDropdown.defaultLabel,
    defaultValue: parserConfig.filterDropdown.defaultValue,
  },
  groupByDropdown: {
    defaultValue: 'none'
  },
};


class TimeSeriesAdapter {
  parseSparklines(response) {
    let maxValue = 0;
    const sparklineSumByDate = {};//make it Object for now as the sparklines might have data(values) for different dates (X points)
    const sparklines = response.map((sparkline, index) => {
      sparkline.data.forEach(it => {
        const date = moment(it.x, 'YYYYMMDD').toDate();
        const dateInt = date.getTime();

        it.date = date;
        it.dateInt = dateInt;
        //Stores sparkline sum per date (it.x)
        if (sparklineSumByDate[it.x] === undefined) {
          sparklineSumByDate[it.x] = {date, dateInt, yValue:it.y};
        } else {
          sparklineSumByDate[it.x].yValue += it.y;
        }
        //Determines max it.y value
        if (it.y > maxValue ) {
          maxValue = it.y;
        }
      });

      return {
        data: sparkline.data,
        labels: Array.isArray(sparkline.descriptions) ? sparkline.descriptions : [''],
        color: config.colors['COLOR_' +  index ]
      };
    });

    const sparklineSum = Object.keys(sparklineSumByDate)
      .map((key) => sparklineSumByDate[key])
      .sort((a,b) => a.dateInt-b.dateInt);// make sure the date is growing
    const dateRange = (sparklineSum.length > 0)
      ? [sparklineSum[0].dateInt, sparklineSum[sparklineSum.length-1].dateInt]
      : [];

    return {
      sparklines,
      sparklineSum,
      dateRange,
      maxValue,
    };
  }

  parseData(result) {
    //Notice, thet `splice(2)` is used in order to exlude the `PeriodFrom` and `PeriodTo` options
    //from the filter option list that is currently comming from the server.
    //Remove the `splice(2)` bit once the change is made on server side.
    const groupBy = [
      result.groupBy1 || config.groupByDropdown.defaultValue,
      result.groupBy2 || config.groupByDropdown.defaultValue,
    ];

    return {
      ...this.parseSparklines(result.sparkLines || []),
      ...parseFilters(result.filterByFields),
      labels: {
        yAxis: result.yAxisTitle,
      },
      groupingFields: result.groupingFields,
      groupBy,
    };
  }

  static prepareRequest(params) {
    const request = {
      filterBy: prepareFiltersRequest(params),
    };

    if(params.groupBy) {
      request.groupBy = params.groupBy.filter(it => it !== config.groupByDropdown.defaultValue);
    }

    return request;
  }
}

export default TimeSeriesAdapter;
