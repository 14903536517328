import React from 'react';
import styledComponents from './styles';
import text from './text';
import { ReactComponent as PirumLogo } from 'assets/images/pirum-logo.svg';
import { ReactComponent as PirumText } from 'assets/images/pirum-text.svg';

class Terms extends React.PureComponent {

  render() {
    return (
      <styledComponents.Wrapper>
        <styledComponents.TopBar>
          <PirumLogo />
          <PirumText />
        </styledComponents.TopBar>
        <styledComponents.Terms>
          <styledComponents.Heading>Disclaimer & Conditions of Use</styledComponents.Heading>
          {text.disclaimer}
          <styledComponents.Heading>Privacy Policy</styledComponents.Heading>
          {text.privacy}
        </styledComponents.Terms>
      </styledComponents.Wrapper>
    );
  }
}

export { Terms };