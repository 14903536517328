import { actionTypes } from './actions';
import { apiNames } from 'constants/api/queryApi';
import { getFetchFailActionByApi} from 'utils/apiUtils';
import { Map } from 'immutable';


const FETCH_VENUE_DETAIL_FAIL = getFetchFailActionByApi(apiNames.COLLATERAL_REQUIREMENT_GROUPED);

export const initialState = Map({
  groupBy: [],
  groupingFields: [],
  metadata: {},
  totals: [],
  colors: {},
  data: [],
  keys: [],
  hasDrilldown: false,
  barLinks: {},
});

export const venueDetail = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VENUE_DETAIL_SUCCESS:
      const { payload } = action;
      return state.withMutations(s =>
        s.set('groupBy', payload.groupBy)
          .set('groupingFields', payload.groupingFields)
          .set('barLinks', payload.barLinks)
          .set('metadata', payload.metadata)
          .set('totals', payload.totals)
          .set('colors', payload.colors)
          .set('data', payload.data)
          .set('keys', payload.keys)
          .set('hasDrilldown', payload.hasDrilldown));

    case FETCH_VENUE_DETAIL_FAIL:
      return initialState;

    default:
      return state;
  }
};
