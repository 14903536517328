import FileSaver from 'file-saver';

import * as svc from './services';
import { apiNames } from 'constants/api/queryApi';
import { setFilters } from 'actions/common/filtersActions';


export const actionTypes = {
  FETCH_SUNBURST_STARTED: 'ccs/allocationsSunburst/FETCH_SUNBURST_STARTED',
  FETCH_SUNBURST_SUCCESS: 'ccs/allocationsSunburst/FETCH_SUNBURST_SUCCESS',
  STORED_SUNBURST_STATE: 'ccs/allocationsSunburst/STORED_SUNBURST_STATE',
  CLEAR_SUNBURST_STATE: 'ccs/allocationsSunburst/CLEAR_SUNBURST_STATE',
  SET_SUNBURST_BREADCRUMBS: 'ccs/allocationsSunburst/SET_SUNBURST_BREADCRUMBS',
  CLEAR_SUNBURST: 'ccs/allocationsSunburst/CLEAR_SUNBURST',

  FETCH_SUNBURST_SECURITIES_DETAILS_SUCCESS: 'ccs/allocationsSunburst/FETCH_SUNBURST_SECURITIES_DETAILS_SUCCESS',
  CLEAR_SUNBURST_SECURITIES_DETAILS: 'ccs/allocationsSunburst/CLEAR_SUNBURST_SECURITIES_DETAILS',
};
/**
 *
 * Sync actions
 *
 */
const fetchSunburstDataSuccess = payload => ({
  type: actionTypes.FETCH_SUNBURST_SUCCESS,
  payload
});

const fetchSunburstDataStarted = () => ({
  type: actionTypes.FETCH_SUNBURST_STARTED,
});

export const clearSunburst = () => ({
  type: actionTypes.CLEAR_SUNBURST,
});

export const setBreadcrumbs = breadcrumbs => ({
  type: actionTypes.SET_SUNBURST_BREADCRUMBS,
  payload: breadcrumbs
});

const fetchSunburstSecuritiesDetailsSuccess = payload => ({
  type: actionTypes.FETCH_SUNBURST_SECURITIES_DETAILS_SUCCESS,
  payload
});

export const clearSunburstSecuritiesDetails = _ => ({
  type: actionTypes.CLEAR_SUNBURST_SECURITIES_DETAILS,
});

/**
 *
 * Async actions
 *
 */
export function fetchSunburst(params, apiName) {
  return dispatch => {
    dispatch(fetchSunburstDataStarted());
    return svc.fetchSunburst(dispatch, params, apiName)
      .then(payload => {
        dispatch(setFilters(payload, true));
        dispatch(fetchSunburstDataSuccess(payload));
      });
  };
}

export function fetchSunburstFilters(params, apiName) {
  return (dispatch) => {
    return svc.fetchSunburstFilters(dispatch, params, apiName);
  };
}

export function fetchSunburstSecuritiesDetails(params, apiName) { 
  return (dispatch) => {
    dispatch(clearSunburstSecuritiesDetails());
    return svc.fetchSunburstSecuritiesDetail(dispatch, params, apiName)
      .then(payload => {
        const isInventoryTable = apiName === apiNames.INVENTORY_SUNBURST;
        dispatch(fetchSunburstSecuritiesDetailsSuccess({...payload, isInventoryTable}));
      }); 
  };
}

export function fetchSecuritiesSpreadsheet(params, apiName) {
  return (dispatch) => {
    return svc.fetchSecuritiesSpreadsheet(dispatch, params, apiName)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}
