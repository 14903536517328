import * as svc from './services';
import { setFilters } from 'actions/common/filtersActions';
import FileSaver from 'file-saver';

export const actionTypes = {
  FETCH_MARGIN_RECONCILIATION_SUCCESS: 'FETCH_MARGIN_RECONCILIATION_SUCCESS',
};

const fetchMarginCheckerSuccess = payload => ({
  type: actionTypes.FETCH_MARGIN_RECONCILIATION_SUCCESS,
  payload
});

export function fetchMarginReconciliation(params, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchMarginReconciliation(dispatch, params)
      .then((payload) => {
        dispatch(fetchMarginCheckerSuccess(payload));
        dispatch(setFilters(payload, clearPrevious));
      });
  };
}

export function fetchMarginReconciliationFilters(params) {
  return (dispatch) => {
    return svc.fetchMarginReconciliationFilters(dispatch, params);
  };
}

export function fetchMarginReconciliationSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchMarginReconciliationSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}
