import * as actionTypes from '../../constants/actionTypes';
import {
  getUserInfo,
  getClientList,
  updateClientIds
} from '../../actions/common/userAccountActions';
import {
  getPlatformDetails
} from './platformDetailsActions';
import { getPsSEToken } from 'pirumconnect/storage/local/oldToken';
import {
  unconnectStorageLocal,
  connectStorageLocal,
} from 'pirumconnect/storage/local/connectToStorage';
import authManager from "pirumconnect/auth/AuthManager";
import { gainSightIdentify } from "../../utils/gainsight";

/**
 *
 * Sync actions
 *
 */
const initialiseMainPageCompleted = payload => ({
  type: actionTypes.FETCH_APP_INIT_DATA_COMPLETED,
  payload,
});


let localStorageUpdateConnector;

const handleLocalStorageUpdate = (dispatch, newValue) => {
  getUserInfo()(dispatch);
  getClientList()(dispatch).then(() => {
    getPlatformDetails()(dispatch);
  });
};

/**
 *
 * Async actions
 *
 */
export function initialiseMainPageOld() {
  authManager.checkOldLogin();
  return (dispatch, getState) => {
    getUserInfo()(dispatch);
    return getClientList()(dispatch)
      .then(async () => {
        const se = getPsSEToken();
        //On initial login, selectAllClientIdsOnInit can be undefined or true
        if(se.selectAllClientIdsOnInit !== false) {
          const allClientIDs = getState().app.userAccount.groups.reduce((acc, groups) => {
            const groupClientsIds = groups.clients ? groups.clients.map(it => it.clientId) : [];
            acc.push(...groupClientsIds);
            return acc;
          }, []);
          await updateClientIds(allClientIDs)(dispatch);
        }
        //Wait for clientIds to update before getting platform details
        getPlatformDetails()(dispatch)
          .then(() => {
            localStorageUpdateConnector = handleLocalStorageUpdate.bind(this, dispatch);
            connectStorageLocal(localStorageUpdateConnector);
            dispatch(initialiseMainPageCompleted());
          }).catch(error=>{
            dispatch(initialiseMainPageCompleted(error));
          });
          gainSightIdentify();
      });
  };
}

export function destroyMainPage() {
  if (localStorageUpdateConnector) {
    unconnectStorageLocal(localStorageUpdateConnector);
  }
}