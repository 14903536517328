import React from 'react';
import LoadingSuspense from 'common/LoadingSuspense';

const Component = React.lazy(() => import('./VenueDetailPage'));

export default (props) => {
  return (
    <LoadingSuspense>
      <Component {...props} />
    </LoadingSuspense>
  );
};
