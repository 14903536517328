/*
 * Some notes on combineReducers performace.
 *
 * While combineReducers split up the store into smaller scopes by each
 * reducer so then the reducer can operate over the local potion of the
 * scope, it eventually will call all reducer to find out which one
 * handle the action.
 *
 * Consequences of the above:
 *  A) the store performace is O(n*k) where
 *       "n" is the number of possible actions in the system
 *       "k" is the average number of dispatch we do in a given time.
 *     the more action we get and the more times we dispatch,
 *     the store will be more and more slow
 *  B) while the action.type is unique within an individual reducer,
 *     the same action.type one can be caught by different reducers
 *     simultaneously (ie: nothing prevents anyone from having the
 *     same "case x:" in multiple reducers simultaneously, and since
 *     all reducer will be called, all will match and execute it)
 *
 * As it is still just linear coplexity, which we can live with it,
 * especially since the javascript engine in the compile phase
 * optimize the switch-case into a jump/hash table behind the sceens.
 *
 * We can consider redux-ignore (https://github.com/omnidan/redux-ignore)
 * to do a prefix matching using the prediate calling format of filterActions,
 * which will not evaluate the irrelevant reducer sub-trees.
 * This will also fix problem B) as it will enforce that the same action
 * cannot be added into multiple reducers.
 * NOTE: but this will require being consitent on the action naming
 *       and use common prefixes for the action.type within reducers
 *       (ie: all app reducer action.type have to be APP_*, and so on)
 *
 * See also:
 * http://redux.js.org/docs/faq/Performance.html#wont-calling-all-my-reducers-for-each-action-be-slow
 * https://github.com/reactjs/redux/issues/1303
 */

import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose
} from 'redux';

import thunk from 'redux-thunk';

import appReducers from './reducers';
import ccsReducers from 'ccs/reducers';
import misReducers from  'mis/reducers';
import sftrReducers from 'sftr/reducers';
import coacsReducers from 'coacsconnect/reducers';
import configReducers from 'configconnect/reducers';
import repoReducers from 'repo/reducers';
import { authentication as auth } from '../reducers/auth';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = applyMiddleware(thunk);

const reducers = combineReducers({
  app: appReducers,
  ccs: ccsReducers,
  sblrex: misReducers,
  sftr: sftrReducers,
  coacs: coacsReducers,
  config: configReducers,
  repo: repoReducers,
  auth,
});

const store = createStore(reducers, composeEnhancers(middlewares));

export default store;