import { RequestHandler } from "msw";

import { PirumProjects } from "./pirumProjects";
import { handlers as repoHandlers } from "./repo";

const HANDLERS: Record<PirumProjects, (useFaker: boolean) => RequestHandler[]> =
  {
    [PirumProjects.REPO]: repoHandlers,
    [PirumProjects.CCS]: () => [],
    [PirumProjects.COACS]: () => [],
    [PirumProjects.FOS]: () => [],
    [PirumProjects.SFTR]: () => [],
  };

export const getHandlers = () => {
  const {
    REACT_APP_PIRUM_PROJECT = PirumProjects.REPO,
    REACT_APP_USE_FAKER = "true",
  } = process.env;
  return HANDLERS[(REACT_APP_PIRUM_PROJECT as PirumProjects) ?? HANDLERS.repo](
    REACT_APP_USE_FAKER === "true"
  );
};
