import { useDispatch } from "react-redux";
import { addToast } from "./actions";

export default function useToast() {
  const dispatch = useDispatch();

  /** @param {{type: string, icon: string, text: string}} config */
  function displayToast(config) {
    dispatch(addToast(config));
  }

  function displayErrorToast(text) {
    dispatch(
      addToast({
        type: "error",
        icon: "error",
        text,
      })
    );
  }

  function displaySuccessToast(text) {
    dispatch(
      addToast({
        type: "primary",
        icon: "success",
        text,
      })
    );
  }

  return {
    displayToast,
    displayErrorToast,
    displaySuccessToast,
  };
}
