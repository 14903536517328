import { StyleKey } from "common/tables/Grid/types";

export type StyleKeyConfig = Record<string, StyleKey>;

const styleKeyConfig: StyleKeyConfig = {
  endCash: StyleKey.VALUE,
  price: StyleKey.VALUE_FOUR_DECIMALS,
  rateSpread: StyleKey.PERCENTAGE_FOUR_DECIMALS,
  rebateRateField: StyleKey.PERCENTAGE_FOUR_DECIMALS,
  repoInterest: StyleKey.VALUE,
  repoRate: StyleKey.PERCENTAGE_FOUR_DECIMALS,
  secSettlementDate: StyleKey.FULL_DATE,
  spread: StyleKey.PERCENTAGE_FOUR_DECIMALS,
  startCash: StyleKey.VALUE,
};

export default styleKeyConfig;
