import * as actionTypes from '../../constants/actionTypes';
import { ServiceError } from '../../services/common/error';
/**
 *
 * Sync actions
 *
 */
export function requestSent(apiId, requestId) {
  return {
    type: actionTypes.API_REQUEST_ABOUT_TO_SEND,
    payload: {apiId, requestId},
  };
}

export function requestResolved(apiId, requestId) {
  return {
    type: actionTypes.API_REQUEST_RESOLVED,
    payload: {apiId, requestId},
  };
}

export function requestFailed(apiId, requestId, error, suppressError) {
  const serviceError = new ServiceError(error, apiId, requestId, suppressError);

  return {
    type: actionTypes.API_REQUEST_FAILED,
    payload: {error: {...serviceError}, apiId, requestId}
  };
}

export function requestFailedForSpecificApi(error) {
  return {
    type: actionTypes.API_REQUEST_FAILED_TEMPLATE.replace(actionTypes.API_REQUEST_FAILED_VAR, error.getApiId()),
    payload: error,
  };
}

export function clearApiError() {
  return {
    type: actionTypes.CLEAR_API_ERROR,
    payload: null,
  };
}
