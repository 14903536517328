import * as actionTypes from '../../constants/actionTypes';
import { getFetchFailActionByApi } from '../../utils/apiUtils';
import { apiNames, apiOperatonIds } from '../../constants/api/queryApi';
import { Map } from 'immutable';


const FETCH_DASHBOARD_VENUE_SUMMARY_FAIL = getFetchFailActionByApi(apiNames.VENUE_DASHBOARD, apiOperatonIds.VENUE_DASHBOARD_ROUTE);

export const initialState = Map({
  dataSet: {},
});

export const dashboardVenueSummary = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.FETCH_DASHBOARD_VENUE_SUMMARY_SUCCESS:
      return state.withMutations(s =>
        s.set('dataSet', action.payload));

    case FETCH_DASHBOARD_VENUE_SUMMARY_FAIL:
      return initialState;

    default:
      return state;
  }
};
