export const config = {
  namespace: 'Allocations',
  filterDropdown: {
    defaultLabel: 'ALL',
    defaultValue: 'all'
  },
  groupDropdown: {
    defaultLabel: 'None',
    defaultValue: 'none'
  },
  rootId: 'root',
};
