export const truncate = (label: string, maxChars = 28): string => {
  return label.length > maxChars ? label.substr(0, maxChars) + "..." : label;
};

export const capitalize = (string: string): string => {
  const [first, ...rest] = string;
  return first.toUpperCase() + rest.join("").toLowerCase();
};

export const toTitleCase = (string: string): string => {
  if (!string || typeof string !== "string") return string;
  const result = string
    .replace(/(_)+/g, " ")
    .replace(/([a-z])([A-Z][a-z])/g, "$1 $2")
    .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2")
    .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2")
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2")
    .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2")

    // exclude plurals of acronyms, e.g. "ABCs"
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2")
    .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2")

    // add space on phrases correctly
    .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2")
    .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2")
    .trim();

  // Capitalise the first letter
  return result.charAt(0).toUpperCase() + result.slice(1);
};
