export const actionColumn = {
  field: "actions",
  headerName: {
    title:  " ",
    subtitle: null,
  },
  maxWidth: 170,
  width: 170,
  minWidth: 170,
  sortable: false,
  suppressMenu: true,
};

export const SCHEDULE_ACTION_TYPES = {
  EDIT: "EDIT",
  VIEW: "VIEW",
  COPY: "COPY",
  DELETE: "DELETE",
};

export const PATH = {
  builder: "/builder",
  schedule: "/schedule",
  manager: "/",
};

export const LOGICAL_GROUP_KEYS = {
  AND: 'and',
  OR: 'or',
  NAND: 'nand',
  NOR: 'nor',
};

export const CONNECTION_TYPES = {
  MIDDLE: 'middle',
  STRAIGHT: 'straight',
  END: 'end',
};

export const INPUT_TYPE_KEY = {
  DECIMAL_INPUT: 'decimalInput',
  DECIMAL_RANGE: 'decimalRangeInput',
  HIERARCHY_LIST_INPUT: 'hierarchyListInput',
  INTEGER_RANGE_AND_LIST: 'integerRangeAndList',
  LIST_AND_DECIMAL_INPUT: 'singleSelectListAndDecimalInput',
  LIST_AND_INTEGER: 'listAndInteger',
  LIST_TEXT_INPUT: 'listTextInput',
  VALIDATED_LIST_TEXT_INPUT: 'validatedListTextInput',
  MULTI_SELECT_LIST_INPUT: 'multiSelectListInput',
  SELECT_DAYS_AROUND: 'singleSelectDaysAroundInput',
  SINGLE_SELECT_LIST_INPUT: 'singleSelectListInput',
};

export const shoudUseKeysForDisplay = (ruleType) => {
  switch (ruleType) {
    case 'assetClass':
    case 'assetCurrency':
    case 'incorporationCountry':
    case 'issuerCountry':
    case 'marginBaseCurrency':
    case 'taxCountry':
    case 'tradeCurrency':
      return true;
    default:
      return false;
  }
};

export const RULE_TYPE = {
  ELEGIBILITY: 'Elegibility',
  MARGIN: 'Margin',
  LIMIT: 'Limit',
};

export const alphaNumericRegex = "^[a-zA-Z0-9]*$";
export const decimalRegex = "^\\d+(\\.\\d{1,2})?$";
export const fullRangeDecimalRegex = "^-?\\d+(\\.\\d{1,2})?$";
export const fullRangeDecimal5Regex = "^-?\\d+(\\.\\d{1,5})?$";
export const positiveNoDecimalRegex = "^\\d+$";
export const numberNoDecimalRegex = "^-?\\d+$";
