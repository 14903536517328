import * as svc from './services';
import FileSaver from 'file-saver';


export const actionTypes = {
  SET_HYPOTHETICAL_TABLE: 'ccs/eligibilityAnalytics/modal/SET_HYPOTHETICAL_TABLE',
  CLEAR_HYPOTHETICAL_TABLE: 'ccs/eligibilityAnalytics/modal/CLEAR_HYPOTHETICAL_TABLE',
  SET_ALGORITHM: 'ccs/eligibilityAnalytics/modal/SET_ALGORITHM',
  SET_MINVALUE: 'ccs/eligibilityAnalytics/modal/SET_MINVALUE',
  SET_RQV_FILTERS: 'ccs/eligibilityAnalytics/modal/SET_RQV_FILTERS',
  SET_RQV_WIDGETS: 'ccs/eligibilityAnalytics/modal/SET_RQV_WIDGETS',
};

/**
 *
 * Sync actions
 *
 */
export const setHpTable = (payload) => ({
  type: actionTypes.SET_HYPOTHETICAL_TABLE,
  payload,
});

export const clearHypotheticalTable = (payload) => ({
  type: actionTypes.CLEAR_HYPOTHETICAL_TABLE,
});

export const setAlgorithm = (algorithm) => ({
  type: actionTypes.SET_ALGORITHM,
  payload: algorithm,
});

export const setMinValue = (minValue) => ({
  type: actionTypes.SET_MINVALUE,
  payload: minValue,
});

const setRqvFilters = (payload) => ({
  type: actionTypes.SET_RQV_FILTERS,
  payload: payload,
});

const setRqvWidgets = (payload) => ({
  type: actionTypes.SET_RQV_WIDGETS,
  payload: payload,
});

/**
 *
 * Async actions
 *
 */
export function downloadExcelTemplate(params) {
  return dispatch => {
    return svc.downloadExcelTemplate(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function uploadExcelToFetchTable(base64excelString) {
  return dispatch => {
    return svc.uploadExcelToFetchTable(dispatch, base64excelString);
  };
}

export function rqvFilters(params) {
  return dispatch => {
    return svc.rqvFilters(dispatch, params)
      .then((payload) => {
        dispatch(setRqvFilters(payload));
      });
  };
}

export function rqvWidgets(params) {
  return dispatch => {
    return svc.rqvWidgets(dispatch, params)
      .then((payload) => {
        dispatch(setRqvWidgets(payload));
      });
  };
}

export function applyAdjustmentAction(params) {
  return (dispatch) => {
    return svc.applyAdjustmentAction(dispatch, params);
  };
}