interface ClientItem {
  clientId: number;
  bankId: number;
}

export class UserBankID {
  private currentBank = 0;
  get ID() {
    return this.currentBank;
  }

  _reset() {
    this.currentBank = 0;
  }

  setIdFromClientList(clients: ClientItem[]) {
    if (!clients.length) {
      // eslint-disable-next-line no-console
      console.error(
        "Attempt to identify the users BankId requires at least one entry"
      );
      return;
    }

    const iDList = clients.reduce(
      (acc, cur) => {
        acc.bankId.add(cur.bankId);
        acc.clientId.add(cur.clientId);
        return acc;
      },
      { bankId: new Set<number>(), clientId: new Set<number>() }
    );

    iDList.bankId.forEach((value, key, set) => {
      if (iDList.clientId.has(value)) {
        set.delete(value);
      }
    });

    if (iDList.bankId.size === 0) {
      // eslint-disable-next-line no-console
      console.error(
        "Attempt to identify the users BankId returned a circular reference"
      );
      return;
    }
    if (iDList.bankId.size > 1) {
      // eslint-disable-next-line no-console
      console.error(
        "Attempt to identify the users BankId returned forked client parent banks"
      );
      return;
    }
    if (iDList.bankId.values().next().value === 0) {
      // List contains a global client
      this.currentBank =
        clients.find((client) => client.bankId === 0)?.clientId ?? 0;
      return;
    }
    this.currentBank = iDList.bankId.values().next().value;
  }
}

const ActiveUserBank = new UserBankID();

export { ActiveUserBank };
