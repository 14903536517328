const errorTypes = {
  UNAUTHORIZED_USER: 'unauthorized_user',//not logged in
  IDP_SESSION_ENDED: 'idp_session_ended', //idp session has ended
  PAGE_NO_ACCESS: 'page_no_access', //logged in but trying to access a page that is not permitted. 
  PAGE_NOT_FOUND: 'page_not_found', //for bad url
  EXPORT_ERROR: 'export_error', //for any other error 
  DISPLAY_ERROR: 'display_error', //for any other error 
};

const errorButtons = {
  CLOSE: 'Close',
  GO_TO_DASHBOARD: 'Go to dashboard',
  RELOAD: 'Reload',
  GO_TO_MODE_SELECT: 'Go to mode select',
  GO_BACK_TO_IDP_LOGIN: 'Return to Identity Provider',
};

const errorMessage = {
  messageFooter: 'If you require assistance please contact Pirum Support: +44 (0)20 7220 0960.',
  content: {
    [errorTypes.UNAUTHORIZED_USER]: {
      title: 'Unauthorized',
      desc: 'Please log in again',
    },
    [errorTypes.IDP_SESSION_ENDED]: {
      title: 'Your session has timed out.',
      desc: 'If you wish to continue using the application, please re-authenticate by clicking the button below',
    },
    [errorTypes.PAGE_NO_ACCESS]: {
      title: 'Selected client does not have access to current screen',
      desc: 'Please change selection',
    },
    [errorTypes.PAGE_NOT_FOUND]: {
      title: 'Page not found',
      desc: 'Page {pathname} could not be found',//const { pathname } = this.props.location;
    },
    [errorTypes.DISPLAY_ERROR]: {
      title: 'Display error',
      desc: 'The system has encountered an issue and cannot display this page. This has been logged and a member of our technical team will look to fix the issue. Please accept our apologies for any inconvenience caused.'
    },
    [errorTypes.EXPORT_ERROR]: {
      title: 'Export error',
      desc: 'The system has encountered an issue and cannot export the data. This has been logged and a member of our technical team will look to fix the issue. Please accept our apologies for any inconvenience caused.'
    },

  },
};

export {
  errorTypes,
  errorButtons,
  errorMessage,
};
