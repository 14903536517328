const apiNames = {
  BROWSER: "browser",
  ATTRIBUTES: "attributes",
  RULES_EDITOR: "rulesEditor",
  LINKING_TOOL: "linkingTool",
};

const apiOperatonIds = {
  COLLATERAL_PROFILE_BROWSER_AG: "collateralProfileBrowser",
  TYPES: "attributes_profile_types",
  DELETE_SCHEDULE: "delete_profile",
  EDIT: "attributes_edit",
  EDIT_WITH_LIMITS: "attributes_edit_limits",
  FORM: "attributes_form",
  FORM_WITH_LIMITS: "attributes_form_limits",
  SAVE: "attributes_save",
  SAVE_WITH_LIMITS: "attributes_save_limits",
  COLLATERAL_PROFILE_SUMMARY: "collateralProfileSummary",
  ELIGIBILITY_RULE_TYPES: "eligibilityRuleTypes",
  RULE_TYPES: "ruleTypes",
  RULE_OPERATOR_VALUES: "eligibilityRuleOperatorsValues",
  SAVE_RULE: "saveRule",
  ADD_RULE: "addRule",
  RULE_SUMMARIES: "ruleSummaries",
  FIND_RULE: "findRule",
  LOGICAL_OPERATORS: "logicalOperators",
  COPY_RULE: "copyRule",
  DELETE_RULE: "deleteRule",
  EDIT_RULE_NAME: "editRuleName",
  PUBLISH: "publishDraft",
  LIMIT_TYPES: "ruleLimitTypes",
  LIMIT_OPTIONS: "ruleLimitOptions",
  COLLATERAL_PROFILE_LINKS_PROFILE_SUMMARIES: "collateralProfileLinksProfileSummaries",
  COLLATERAL_PROFILE_LINKS_TRADE_GROUPS: "collateralProfileLinksTradeGroups",
  COLLATERAL_PROFILE_LINKS_AT_VENUE_FOR_TABLES: "collateralProfileLinksAtVenueForTables",
  COLLATERAL_PROFILE_CREATE_LINKS: "collateralProfileCreateLinks",
  COLLATERAL_PROFILE_DELETE_LINKS: "collateralProfileDeleteLinks",
};

export { apiNames, apiOperatonIds };
