import * as actionTypes from '../../constants/actionTypes';

export const initialState = {
  username: '',
  token: '',
  ok: null,
  error: null,
  isLoading: false,
  tooManyFailure: false,
  redirectPage: '',
  successUrl: '',
  idpSessionHasEnded: false,
  userAuthenticated: false,
  userAuthorized: false,
  mode: {
    clientsList: [],
    usersList: [],
    error: null,
    isLoading: false,
    redirectUrl: '',
  },
  privacy: {
    redirectPage: '',
  }
};

const request = (state) => ({
  ...state,
  isLoading: true,
  tooManyFailure: false,
  redirectPage: '',
  fetch: '',
  token: '',
  ok: null,
  error: null
});

const success = (state, action) => ({
  ...state,
  ...action.payload,
  isLoading: false
});

const error = (state, action) => ({
  ...state,
  isLoading: false,
  error: action.payload
});

export function authentication(state = initialState, action) {

  switch (action.type) {
    // LOGIN_SWITCH_FORM: switch between login forms
    case actionTypes.LOGIN_SWITCH_FORM:
      return success(request(state), action);

    // LOGIN_DATA_*: used to show the login form, contains user from cert/cookie, news
    case actionTypes.LOGIN_DATA_REQUEST:
      return request(state);

    case actionTypes.LOGIN_DATA_RESPONSE_SUCCESS:
      return success(state, action);

    case actionTypes.LOGIN_DATA_RESPONSE_ERROR:
      return error(state, action);

    // LOGIN_*: the login itself (via username + password)
    case actionTypes.LOGIN_REQUEST:
      return request(state);

    case actionTypes.LOGIN_RESPONSE_SUCCESS:
      return success(state, action);

    case actionTypes.LOGIN_RESPONSE_ERROR:
      return error(state, action);

    // LOGIN_DEVICE_FORM_*: getting the form data for the cookie auth form
    case actionTypes.LOGIN_DEVICE_FORM_REQUEST:
      return request(state);

    case actionTypes.LOGIN_DEVICE_FORM_RESPONSE_SUCCESS:
      return success(state, action);

    // LOGIN_DEVICE_AUTH_*: sending the cookie auth form
    case actionTypes.LOGIN_DEVICE_AUTH_REQUEST:
      return request(state);

    case actionTypes.LOGIN_DEVICE_AUTH_RESPONSE_SUCCESS:
      return success(state, action);

    case actionTypes.LOGIN_DEVICE_AUTH_RESPONSE_ERROR:
      return error(state, action);

    // RESET_PASSWORD_*: forgot password, reset password
    case actionTypes.RESET_PASSWORD_REQUEST:
      return request(state);

    case actionTypes.RESET_PASSWORD_RESPONSE_SUCCESS:
      return success(state, action);

    case actionTypes.RESET_PASSWORD_RESPONSE_ERROR:
      return error(state, action);

    // CHANGE_PASSWORD_*: password change
    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return request(state);

    case actionTypes.CHANGE_PASSWORD_RESPONSE_SUCCESS:
      return success(state, action);

    case actionTypes.CHANGE_PASSWORD_RESPONSE_ERROR:
      return error(state, action);

    // MODE_*: pirum user demo / support mode screens
    case actionTypes.MODE_CLIENTS_LIST_REQUEST:
      return {
        ...state,
        mode: {
          ...state.mode,
          clientsList: [],
          isLoading: true,
          error: null
        }
      };

    case actionTypes.MODE_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        mode: {
          ...state.mode,
          clientsList: action.payload,
          isLoading: false
        }
      };

    case actionTypes.MODE_CLIENTS_LIST_ERROR:
      return {
        ...state,
        mode: {
          ...state.mode,
          clientsList: [],
          isLoading: false,
          error: action.payload
        }
      };

    case actionTypes.MODE_USERS_LIST_REQUEST:
      return {
        ...state,
        mode: {
          ...state.mode,
          usersList: [],
          isLoading: true,
          error: null
        }
      };

    case actionTypes.MODE_USERS_LIST_SUCCESS:
      return {
        ...state,
        mode: {
          ...state.mode,
          usersList: action.payload,
          isLoading: false
        }
      };

    case actionTypes.MODE_USERS_LIST_ERROR:
      return {
        ...state,
        mode: {
          ...state.mode,
          usersList: [],
          isLoading: false,
          error: action.payload
        }
      };

    case actionTypes.MODE_SELECT_REQUEST:
      return {
        ...state,
        mode: {
          ...state.mode,
          isLoading: true,
          error: null
        }
      };

    case actionTypes.MODE_SELECT_SUCCESS:
      return {
        ...state,
        mode: {
          ...state.mode,
          ...action.payload,
          isLoading: false
        }
      };

    case actionTypes.MODE_SELECT_ERROR:
      return {
        ...state,
        mode: {
          ...state.mode,
          error: action.payload,
          isLoading: false,
        }
      };

    case actionTypes.AGREE_TERMS_RESPONSE_SUCCESS:
      return {
        ...state,
        privacy: {
          ...action.payload,
        },
      };
    case actionTypes.AUTHENTICATE_USER_COMPLETED:
      return {
        ...state,
        userAuthenticated: true,
      };
    case actionTypes.AUTHORIZE_USER_COMPLETED:
      return {
        ...state,
        userAuthorized: true,
      };
    case actionTypes.REVOKE_USER_AUTHORIZATION:
      return {
        ...state,
        userAuthorized: false,
      };
    case actionTypes.REVOKE_USER_AUTHENTICATION:
      return {
        ...state,
        userAuthenticated: false,
      };
    default:
      return state;
  }


}
