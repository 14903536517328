import FileSaver from 'file-saver';
import * as svc from './services';
import { setFilters } from 'actions/common/filtersActions';

export const actionTypes = {
  FETCH_LIMIT_RECONCILIATION_SUCCESS: 'ccs/eligiblityLimit/FETCH_LIMIT_RECONCILIATION_SUCCESS',
  SET_TABLE_CONTEXT: 'ccs/eligibilityLimit/SET_TABLE_CONTEXT',
};

const fetchEligiblityCheckerSuccess = (payload) => ({
  type: actionTypes.FETCH_LIMIT_RECONCILIATION_SUCCESS,
  payload: payload,
});

export const fetchLimitReconciliation = (params) => {
  return (dispatch) => {
    return svc.fetchLimitReconciliation(dispatch, params)
      .then((payload) => {
        dispatch(setFilters(payload));
        dispatch(fetchEligiblityCheckerSuccess(payload));
      });
  };
};

export const fetchLimitReconciliationFilter = (params) => {
  return (dispatch) => {
    return svc.fetchLimitReconciliationFilter(dispatch, params);
  };
};

export function fetchLimitReconciliationSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchLimitReconciliationSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export const setTableContext = context => ({
  type: actionTypes.SET_TABLE_CONTEXT,
  payload: context,
});