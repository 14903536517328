import {
  ColumnApi,
  GridApi,
  RefreshCellsParams,
  ValueGetterParams,
} from "ag-grid-community";
import { getConfig } from "common/tables/Grid/styleKeyDefs";
import { TRACKING_EVENTS } from "./GridTracking";
import {
  GridState,
  PirumColDef,
  PirumColSpan,
  PirumColumnApi,
  PirumGridOptions,
} from "./types";
import { STATE_CHANGE_EVENTS } from "./useGridStatePreserver";

export const GRID_EVENTS = [
  "onGridReady",
  "onModelUpdated",
  "onRowClicked",
] as const;

export const getAllColumnsIds = (columnApi: ColumnApi) => {
  const allColumns = columnApi.getAllColumns() || [];
  return allColumns.map((column) => column.getColId());
};

export const autoSizeAllColumns = (columnApi: ColumnApi) => {
  try {
    columnApi.autoSizeColumns(getAllColumnsIds(columnApi));
  } catch (e) {
    /**
     * There is a known issue with ag-grid trowing an error:
     * "TypeError: Cannot read property 'addEventListenerOutsideAngular' of null"
     * This error doesn't break anything it just displays webpack error and in the JS console. this try..catch is to prevent that.
     * @see https://github.com/ag-grid/ag-grid/issues/3292
     */
  }
};

export const sizeColumnsToFitInGrid = (api: GridApi, columnApi: ColumnApi) => {
  api.sizeColumnsToFit();
};

export const resetAllColumnsSize = (
  columnApi: ColumnApi,
  ignoreGroups = false
) => {
  const allColumns = columnApi.getAllColumns() || [];
  allColumns.forEach((column) => {
    const colDef = column.getColDef();
    if (!ignoreGroups || !colDef.showRowGroup) {
      colDef.width && columnApi.setColumnWidth(column.getColId(), colDef.width);
    }
  });
};

export const refreshCells = (api: GridApi, columnApi: ColumnApi) => {
  const params: RefreshCellsParams = {
    force: true,
    columns: getAllColumnsIds(columnApi),
  };

  api.refreshCells(params);
};

/***  MINIMUM GRID STATE DATA REQUIRED TO RESTORE THE GRID
 * gridOptions.columnApi:
 *   getColumnState() - returns objects with less detail than the above funciton - only has: aggFunc, colId, hide, pinned, pivotIndex, rowGroupIndex and width
 *   setColumnState(columnState) - this allows you to set columns to hidden, visible or pinned, columnState should be what is returned from getColumnState()
 * Available from gridOptions.api:
 *  getFilterModel() - gets the current filter model
 *  setFilterModel(model) - set the filter model of the grid, model should be the same format as is returned from getFilterModel()
 */
export const getGridState = (gridApi: GridApi, columnApi: PirumColumnApi) => {
  const gridState: GridState = {};

  if (columnApi) {
    gridState.columnState = columnApi.getColumnState();
  }

  if (gridApi) {
    gridState.sortModel =
      columnApi.getColumnState().filter((colState) => colState.sort) || [];
    gridState.filterModel = gridApi.getFilterModel();
  }

  return gridState;
};

export const setGridState = (
  gridApi: GridApi,
  columnApi: PirumColumnApi,
  gridState: GridState
) => {
  if (!gridState) {
    return;
  }

  try {
    if (gridApi) {
      gridApi.setSortModel(gridState.sortModel);
      gridApi.setFilterModel(gridState.filterModel);
    }

    if (columnApi) {
      columnApi.setColumnState(gridState.columnState || []);
    }
  } catch (e) {
    /**
     * There is a known issue with ag-grid trowing an error:
     * "TypeError: Cannot read property 'addEventListenerOutsideAngular' of null"
     * This error doesn't break anything it just displays webpack error and in the JS console. this try..catch is to prevent that.
     * @see https://github.com/ag-grid/ag-grid/issues/3292
     */
  }
};

const valueGetter = (params: ValueGetterParams) => {
  const { colDef, data } = params;
  const colDefField = colDef.field || "";

  if (!data?.cells[colDefField]) {
    return null;
  }
  const { arrayValue, value, numValue } = data.cells[colDefField];
  return arrayValue || value || numValue;
};

// TODO: https://jira.pirum.com/browse/PC-862 >>> remove need for duplicate valuegetters
export function addValueGetterToColumnDefs<T extends PirumColDef>(
  columnDefs: T[] = [],
  valueGetterCustom?: (params: ValueGetterParams) => any
): T[] {
  return columnDefs.map((it) => {
    const columnDef = {
      ...it,
      valueGetter: valueGetterCustom || valueGetter,
      valueFormatter: getConfig(it.styleKey).valueFormatter,
    };

    if (it.children) {
      columnDef.children = it.children.map((ch) => ({
        ...ch,
        valueGetter: valueGetterCustom || valueGetter,
        valueFormatter: getConfig(it.styleKey).valueFormatter,
      }));
    }

    return columnDef;
  });
}

const colSpan: PirumColSpan = (params) => {
  const { data, column, columnApi } = params;
  const allColumns = columnApi?.getAllGridColumns();
  const columnField = column.getColDef().field || "";
  const mergeId = data.cells[columnField].mergeId;
  let counting = false;
  const colSpan = allColumns?.reduce((acc, currentColumn) => {
    const currentField = currentColumn.getColDef().field || "";
    const currentMergeId = data.cells[currentField]?.mergeId;
    if (currentColumn.getColDef().merge && !isNaN(currentMergeId)) {
      if (!counting && currentField === columnField) {
        counting = true;
        return acc;
      }
      if (counting && currentMergeId === mergeId) {
        return acc + 1;
      }
    }
    counting = false;
    return acc;
  }, 1);
  return colSpan || 1;
};

export const addColSpanToColumnDefs = (columnDefs: PirumColDef[]) =>
  columnDefs.map((it) => {
    const columnDef = { ...it };
    if (it.merge) {
      columnDef.colSpan = colSpan;
    }
    if (it.children) {
      columnDef.children = it.children.map((ch) => {
        return ch.merge ? { ...ch, colSpan: colSpan } : ch;
      });
    }
    return columnDef;
  });

/**
 * All the grid events that are in the interest of the external actors
 *  -  GridStatePreserver
 *  -  GridTracking
 * to be listen to.
 */
function getEventsList() {
  const allEvents = [
    ...TRACKING_EVENTS,
    ...STATE_CHANGE_EVENTS,
    ...GRID_EVENTS,
  ];
  const uniqueEventsList = new Set(allEvents);
  return [...uniqueEventsList];
}

export const combineGridEvents = (agGridPropsArray: PirumGridOptions[]) => {
  const eventsList = getEventsList();
  const gridEventHandlers = eventsList.reduce<Partial<PirumGridOptions>>(
    (acc, eventName) => {
      acc[eventName] = (...eventArgs) => {
        agGridPropsArray.forEach((gridEventHandlers) => {
          const handler = gridEventHandlers && gridEventHandlers[eventName];
          handler && handler(eventArgs[0]);
        });
      };
      return acc;
    },
    {}
  );

  return {
    ...agGridPropsArray.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    ...gridEventHandlers,
  };
};
