const encode = (val: string | number | boolean) => {
  try {
    return encodeURIComponent(val);
  } catch (e) {
    console.error("error encode %o");
  }
  return null;
};

const decode = (val: string) => {
  try {
    return decodeURIComponent(val);
  } catch (err) {
    console.error("error decode %o");
  }
  return null;
};

const handleSkey = (sKey: string | number | boolean) =>
  encode(sKey)?.replace(/[\-\.\+\*]/g, "\\$&");

const Cookies = {
  getItem(sKey: string | number | boolean) {
    if (!sKey) {
      return null;
    }
    return (
      decode(
        document.cookie.replace(
          new RegExp(`(?:(?:^|.*;)\\s*${handleSkey(sKey)}\\s*\\=\\s*([^;]*).*$)|^.*$`),
          "$1"
        )
      ) || null
    );
  },

  setItem(
    sKey: string,
    sValue: string | number | boolean,
    vEnd: number | string | Date,
    sPath: string,
    sDomain: string,
    bSecure: boolean
  ) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    let sExpires = "";
    if (vEnd) {
      if (vEnd instanceof Number || typeof vEnd === "number") {
        if (vEnd === Infinity) {
          sExpires = "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        } else {
          sExpires = `; max-age=${vEnd}`;
        }
      }
      if (vEnd instanceof String || typeof vEnd === "string") {
        sExpires = `; expires=${vEnd}`;
      }
      if (vEnd instanceof Date) {
        sExpires = `; expires=${vEnd.toUTCString()}`;
      }
    }
    document.cookie = [
      encode(sKey),
      "=",
      encode(sValue),
      sExpires,
      sDomain ? `; domain=${sDomain}` : "",
      sPath ? `; path=${sPath}` : "",
      bSecure ? "; secure" : "",
    ].join("");
    return true;
  },

  removeItem(sKey: string, sPath: string, sDomain: string) {
    if (!this.hasItem(sKey)) {
      return false;
    }
    document.cookie = [
      encode(sKey),
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT",
      sDomain ? `; domain=${sDomain}` : "",
      sPath ? `; path=${sPath}` : "",
    ].join("");
    return true;
  },

  hasItem(sKey: string) {
    if (!sKey) {
      return false;
    }
    return new RegExp(`(?:^|;\\s*)${encode(sKey)?.replace(/[\-\.\+\*]/g, "\\$&")}\\s*\\=`).test(
      document.cookie
    );
  },

  keys() {
    const aKeys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
      .split(/\s*(?:\=[^;]*)?;\s*/);
    return aKeys.map((key) => decode(key));
  },
};

export default Cookies;
