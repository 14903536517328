import { combineReducers } from "redux";
import matchingManager, {
  MatchingManagerState,
} from "./matchingManager/reducer";
import { changeStatusModalReducer as changeStatusModal } from "./ChangeStatusModal";
import type { ChangeStatusModalState } from "./ChangeStatusModal";

export interface RepoState {
  matchingManager: MatchingManagerState;
  changeStatusModal: ChangeStatusModalState;
}

export default combineReducers({
  matchingManager,
  changeStatusModal,
});
