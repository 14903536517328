import { Map } from 'immutable';
import { actionTypes } from 'reconciliation/constants';
import { createReducer } from '@reduxjs/toolkit';
import { createNamedAction } from 'utils/helpers';

const initialFilterStateObject = {
  allAssetIdsList: { assetIds: [], filterByFields: [] },
  assetByIdList: { table: {} },
  selectedAssetId: null,
  selectedPeriod: null,
  selectedRelation: null,
  selectedCurrency: null,
  sortModel: [],
  reportError: false,
};

const initialState = Map(initialFilterStateObject);

export const createRecReducerWithNamedType = (recType) => createReducer(initialState, {
  [createNamedAction(actionTypes.SELECT_ASSET_ROW, recType)]: (state, action) => {
    return state.set('selectedAssetId', action.payload);
  },
  [createNamedAction(actionTypes.DESELECT_ASSET_ROW, recType)]: (state) => {
    return state.set('selectedAssetId', null);
  },
  [createNamedAction(actionTypes.SELECT_PERIOD, recType)]: (state, action) => {
    return state.set('selectedPeriod',  action.payload);
  },
  [createNamedAction(actionTypes.SELECT_RELATION, recType)]: (state, action) => {
    return state.set('selectedRelation',  action.payload);
  },
  [createNamedAction(actionTypes.SELECT_CURRENCY, recType)]: (state, action) => {
    return state.set('selectedCurrency',  action.payload);
  },
  [createNamedAction(actionTypes.SET_SORT_MODEL, recType)]: (state, action) => {
    return state.set('sortModel',  action.payload);
  },
  [createNamedAction(actionTypes.FETCH_ASSET_ID_LIST_SUCCESS, recType)]: (state, action) => {
    return state.set('allAssetIdsList',  action.payload);
  },
  [createNamedAction(actionTypes.FETCH_ASSET_LIST_BY_ID_SUCCESS, recType)]: (state, action) => {
    return state.set('assetByIdList',  action.payload);
  },
  [createNamedAction(actionTypes.FETCH_REPORT_FAIL, recType)]: (state, action) => {
    return state.set('reportError',  action.payload);
  },
  [createNamedAction(actionTypes.SET_SHOW_LEGAL_IDENTIFIERS, recType)]: (state, action) => {
    return state.set('showFullLegalIdentifiers', action.payload);
  },
});