import numeral from 'numeral';

export const nNumWithCommas = (naturalNumberStr) => naturalNumberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const fNumWithCommas = (floatingPointNumberStr) => { //use this one if there are more than 3 digits after the decimal point
  const [natural, fraction] = floatingPointNumberStr.split('.');
  return `${nNumWithCommas(natural)}.${fraction}`;
};

export const VALUE_PREFIX_OPTIONS = {
  noPrefix: 0,
  thousand: 1,
  million: 2,
  billion: 3,
};

const dividers = {
  [VALUE_PREFIX_OPTIONS.billion]: 1000000000,
  [VALUE_PREFIX_OPTIONS.million]: 1000000,
  [VALUE_PREFIX_OPTIONS.thousand]:1000,
  [VALUE_PREFIX_OPTIONS.noPrefix]: 1,
};
export const unitPrefix = {
  [VALUE_PREFIX_OPTIONS.billion]: 'B',
  [VALUE_PREFIX_OPTIONS.million]: 'M',
  [VALUE_PREFIX_OPTIONS.thousand]: 'k',
  [VALUE_PREFIX_OPTIONS.noPrefix]: '',
};

export const formatNumber = (value, valuePrefixOption=VALUE_PREFIX_OPTIONS.noPrefix, useSign=false) => {
  const inputValue = Number(value);
  if (isNaN(inputValue)){    
    // eslint-disable-next-line no-console
    console.warn('Warning: The formatted value of ',value,' is NaN.');
    return value;
  }  
  if (inputValue === 0) {
    return '0';
  }
  
  const roundValue = Math.round(inputValue/dividers[valuePrefixOption]);
  let sign = '';
  if (useSign) {
    if (inputValue > 0) {
      sign = '+';
    } else if (roundValue===0) {
      sign = '-';
    }
  } 
  
  const formatedValue = nNumWithCommas(sign + roundValue + unitPrefix[valuePrefixOption]);  
  return roundValue===0 ? formatedValue + '*' : formatedValue;
};

export const formatNumberWithoutRounding = (value, valuePrefixOption=VALUE_PREFIX_OPTIONS.noPrefix, useSign=false) => {
  const inputValue = Number(value);
  if (isNaN(inputValue)){    
    // eslint-disable-next-line no-console
    console.warn('Warning: The formatted value of ',value,' is NaN.');
    return value;
  }  
  if (inputValue === 0) {
    return '0';
  }
  
  const newValue = inputValue/dividers[valuePrefixOption];
  let sign = '';
  if (useSign) {
    if (inputValue > 0) {
      sign = '+';
    } else if (newValue===0) {
      sign = '-';
    }
  } 
  const formatedValue = fNumWithCommas(sign + newValue + unitPrefix[valuePrefixOption]);  
  return newValue===0 ? formatedValue + '*' : formatedValue;
};

export const formatFloat = (value, decimals) => {
  let numValue = Number(value);
  if (isNaN(numValue)){
    // eslint-disable-next-line no-console
    console.warn('Warning: The formatted value of ',value,' is NaN.');
    return value;
  }
  const magnitude = Math.pow(10, Math.abs(decimals));
  numValue = Math.round(numValue * magnitude) / magnitude;//to calculate the rounding
  const strFormattedInt = formatNumber(numValue > 0 ? Math.floor(numValue) : Math.ceil(numValue)); //ex. '12,347'
  const strFraction = !decimals ? '' : numValue.toFixed(decimals).substr(-(decimals+1));//+1 to include the dot (ex. '.54')

  return strFormattedInt + strFraction;//ex. '12,347' + '.54' =  '12,347.54'
};

export const createDataFilter = (filter) => (entry) => {
  return Object.keys(filter).every(key => filter[key] === entry[key]);
};

export const contextToFilter = (context) => {
  return Object.keys(context)
    .map(key => ({
      fieldValue: context[key].toString(),
      fieldName: key.toString()
    }));
};
/**
 * Formats a numerical value by shortenning it and adding postfix.
 * For example, by default if the forrmated value is a floating point one, it
 * makes sure that the number of digits is < 3, like 88.8k, 88.0k, 8.00k, etc.
 * @param  {*}      value     The numerical value that is going to be formatted.
 * @param  {Number} minDigitCount Minimal digits count in the formatted number
 *                                 exept the number is 0.
 * @return {String}           The formatted value.
 */
export const shortNumberUnit = (value, minDigitCount = 3) => {
  value = parseFloat(value);
  const isNegative = value < 0;

  if (isNaN(value)) {
    return '';
  }
  if(isNegative) {
    value *= -1;
  }

  const digitCount = Math.max(Math.min(3, minDigitCount), 1);
  const postfixes = ['', 'k', 'M', 'B', 'T', 'Q'];
  let i, v, decimals;

  for(i = 0; value >= 1000; value /= 1000, i++);
  for(v = value, decimals=digitCount-1; v >= 10 && decimals > 0; v /= 10, decimals--);

  const stringValue = value.toFixed(decimals);
  let displayValue;
  switch (stringValue) {
    case (0).toFixed(Math.max(digitCount-1, 0)):
      displayValue = '0';
      break;
    case (10).toFixed(Math.max(digitCount-1, 0)):
      displayValue = (10).toFixed(Math.max(digitCount-2, 0)) + postfixes[i];
      break;
    case (100).toFixed(Math.max(digitCount-2, 0)):
      displayValue = '100' + postfixes[i];
      break;
    case '1000':
      displayValue = (1).toFixed(Math.max(digitCount-1, 0)) + postfixes[i+1];
      break;
    default:
      displayValue = stringValue + postfixes[i];
  }

  return isNegative ? `-${displayValue}` : displayValue;
};

export const titleCase = (str) => {
  return str.toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
};

export const round = (value, decimals = 2, withCommas = true) => {
  let formatString = '0';
  if (withCommas) {
    formatString += ',0';
  }

  if (decimals > 0) {
    formatString += '.[';
    for (let i = 0; i < decimals; i++) {
      formatString += '0';
    }
    formatString += ']';
  }
  else {
    const magnitude = Math.pow(10, Math.abs(decimals));
    value = Math.round(+(value) / magnitude) * magnitude;
  }

  return numeral(value).format(formatString);
};

export const getLatestSpanBySizeInDays = (days) => {
  const now = new Date().getTime();
  return [now-days*24*3600000, now];
};

export const validateCellValue = (val) => {
  if(val || val === 0) {
    return val;
  } else if(typeof val === 'boolean') {
    return String(val);
  }

  return '';
};

export const arraysToCvsString = (csvDataAs2dArray) => {
  if (!csvDataAs2dArray || !csvDataAs2dArray.length) return '';
  return csvDataAs2dArray.map((row, index) => {
    return row.map((it) => '"' + validateCellValue(it) + '"').join(',');
  }).join(`\n`);
};



/**
 * Helper turning array list to an object by the specified key
 * @param {Array} list List of items that will become VALUES for the returning object.
 * @param {String} key property name of the list item. The value of the property will become a KEY for the returning object.
 * 
 * @return {Object} The list data turned into object .
 */
export const listToObject = (list, key) => {
  return list?.reduce((acc, it) => {
    acc[it[key]] = it;
    return acc;
  }, {});
};

/**
 * Provides a way of mapping comma separated values in a string to a format that the API can use for a filter
 * @param {String} csvString the terms being passed in to use as a filter.
 * @param {String} fieldName determines the type of filter being used.
 * @return {Object} The data structure expected by the API for a filter.
 */
export const createFilterFromCSV = (csvString, fieldName) => {
  return csvString !== "" ? csvString?.split(',').map((param, i) => ({
    fieldName: fieldName + i,
    fieldValue: param.trim(),
  })) : {};
};

/**
 * Provides a way of mapping values sent from an object to a format the API can reuse as a filter
 * @param {Object} filter the object being used to construct the filter.
 * @param {String} key determines the access key for values of the filter being used.
 * @param {String} fieldName determines the type of filter being used.
 * @return {Object} The data structure expected by the API for a filter.
 */
export const createFilterFromObject = (filter, key, fieldName) => {
  const filterByFieldConversion = {
    typeOfSft: "typesOfSft",
    participantId: "participantIds",
    breakDescription: "breaksDescription",
  };
  return filter[key] ? filter[key].map((filter, i) => ({
    fieldKey: filterByFieldConversion[fieldName] || fieldName,
    fieldName: fieldName + i,
    fieldValue: filter.fieldValue,
  })) : [];
};

/**
 * Provides a way of reconstructing a set of search terms back into a single string
 * @param {String} searchTerms the terms being passed in to use as a filter.
 * @return {String} The data structure expected by the UI for displaying search text.
 */
export const convertSearchTermsToCSV = (searchTerms) => {
  const terms = searchTerms.map(({fieldValue}) => fieldValue);
  return terms.join(",");
};