import React, {Component} from 'react';
import { connect } from 'react-redux';
import { requestFailedForSpecificApi, clearApiError } from 'actions/common/apiActions';
import { redirectToLogout } from 'pirumconnect/storage/local/oldToken';
import authManager, { shouldUseNewSSO } from 'pirumconnect/auth/AuthManager';
import { ErrorManager, errorTypes, errorButtons } from 'common/error';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';
import { getApiId } from 'utils/apiUtils';


class PageErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      componentError: null,
    };

    this.props.clearApiError();
  }

  static getDerivedStateFromError(error) {
    return { componentError: error };
  }


  componentDidCatch(error, info) {
    ErrorManager.sendErrorReport(error, info);
  }

  goToDashboardBtnHandler() {
    this.props.history.push('/dashboard');
  }

  refreshBtnHandler() {
    this.props.history.refresh();
  }

  render() {
    const apiError = this.props.error;
    
    const { componentError } = this.state;
    let errorType = componentError ? errorTypes.DISPLAY_ERROR : null;
    let buttons = [];
    let asModal = false;
    let sendReport = false;

    
    if(!componentError && apiError && !ErrorManager.shouldIgnoreApiError(apiError)) {
      sendReport = true;
      asModal = false;

      switch (apiError.getApiId()) {
        case getApiId(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.COLLATERAL_PROJECTIONS_SPREADSHEET):
        case getApiId(apiNames.COLLATERAL_PROJECTIONS, apiOperatonIds.TRADE_ITEMS_SPREADSHEET): 
          errorType = errorTypes.EXPORT_ERROR;
          asModal = true;
          break;
        default:
          if(apiError.getStatus() === 401){
            errorType = errorTypes.UNAUTHORIZED_USER;
            buttons.push( {label:errorButtons.CLOSE, onClick: !shouldUseNewSSO() ? redirectToLogout : authManager.clearTokensAndRedirectToExternalLogout } );
          } else {
            errorType = errorTypes.DISPLAY_ERROR;
            // buttons.push( //TODO: Decide whether we need these buttons??
            //   { label:errorButtons.RELOAD, onClick:this.refreshBtnHandler },
            //   { label:errorButtons.GO_TO_DASHBOARD, onClick:this.goToDashboardBtnHandler },
            //  );
          }
      }
    }
    
    // If it is a modal error, don't return the component
    // Instead, the error will be mounted next to children
    if (!asModal && errorType) {
      return ErrorManager.createError( errorType, apiError || componentError, buttons, asModal, sendReport );
    }

    return (
      <React.Fragment>
        { this.props.children }
        {asModal && ErrorManager.createError( errorType, apiError || componentError, buttons, asModal, sendReport )}
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    error: state.app.api.error,
  };
};

const wrapper = connect(mapStateToProps, {
  requestFailedForSpecificApi,
  clearApiError,
})(PageErrorBoundary);

export {
  wrapper as PageErrorBoundary
};