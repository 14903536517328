import ConfigurationPage from './viewer/Loadable';
import AuditPage from 'configconnect/viewer/AuditPage/Loadable';

const appViewId = 'configConnect';

const viewIds = {
  app: {
    CONFIG_CONNECT: appViewId,
  },
  sections: {
    CONFIGURATION: 'configViewer',
    AUDIT: 'configAudit',
  },
  pages: {
  },
};

const routes = {
  app: {
    viewId: viewIds.app.CONFIG_CONNECT,
    path: '/config-connect',
  },
  sectionsList: [
    {
      viewId: viewIds.sections.CONFIGURATION,
      subpath: '/viewer',
      component: ConfigurationPage,
    },
    {
      viewId: viewIds.sections.AUDIT,
      subpath: '/audit',
      component: AuditPage,
    },
  ],
  pagesList: [],
};


const configurationRoutesConfig = {
  appViewId,
  viewIds,
  routes,
  theme: {
    mode: 'light',
  },
};

export default configurationRoutesConfig;
