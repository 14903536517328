import * as svc from './services';
import { setFilters } from 'actions/common/filtersActions';
import FileSaver from 'file-saver';

export const actionTypes = {
  FETCH_EXPOSURE_COVERAGE_SUCCESS: 'FETCH_EXPOSURE_COVERAGE_SUCCESS',
  FETCH_EXPOSURE_ANALYTICS_SETTINGS_SUCCESS: 'FETCH_EXPOSURE_ANALYTICS_SETTINGS_SUCCESS',
  FETCH_EXPOSURE_FX_RECONCILIATION_SUCCESS: 'FETCH_EXPOSURE_FX_RECONCILIATION_SUCCESS',
  FETCH_EXPOSURE_VALUATION_RISK_SUCCESS: 'FETCH_EXPOSURE_VALUATION_RISK_SUCCESS',
  FETCH_EXPOSURE_FX_MISMATCH_SUCCESS: 'FETCH_EXPOSURE_FX_MISMATCH_SUCCESS',
};

const fetchExposureCoverageCheckerSuccess = payload => ({
  type: actionTypes.FETCH_EXPOSURE_COVERAGE_SUCCESS,
  payload
});

const fetchFxReconciliationSuccess = payload => ({
  type: actionTypes.FETCH_EXPOSURE_FX_RECONCILIATION_SUCCESS,
  payload
});

const fetchValuationRiskSuccess = payload => ({
  type: actionTypes.FETCH_EXPOSURE_VALUATION_RISK_SUCCESS,
  payload
});

const fetchFxMismatchSuccess = payload => ({
  type: actionTypes.FETCH_EXPOSURE_FX_MISMATCH_SUCCESS,
  payload
});

const fetchExposureAnalyticsSettingsSuccess = payload => ({
  type: actionTypes.FETCH_EXPOSURE_ANALYTICS_SETTINGS_SUCCESS,
  payload
});

export function fetchExposureCoverage(params, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchExposureCoverage(dispatch, params)
      .then((payload) => {
        dispatch(fetchExposureCoverageCheckerSuccess(payload));
        dispatch(setFilters(payload));
      });
  };
}

export function fetchFxReconciliation(params, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchFxReconciliation(dispatch, params)
      .then((payload) => {
        dispatch(fetchFxReconciliationSuccess(payload));
        dispatch(setFilters(payload));
      });
  };
}

export function fetchFxMismatch(params, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchFxMismatch(dispatch, params)
      .then((payload) => {
        dispatch(fetchFxMismatchSuccess(payload));
        dispatch(setFilters(payload));
      });
  };
}

export function fetchValuationRisk(params, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchValuationRisk(dispatch, params)
      .then((payload) => {
        dispatch(fetchValuationRiskSuccess(payload));
        dispatch(setFilters(payload));
      });
  };
}

export function updateFilters(params, clearPrevious = true) {
  return (dispatch) => dispatch(setFilters(params, clearPrevious));
}

export function fetchExposureAnalyticsFilters(params, clearPrevious = true) {
  return (dispatch) => {
    return svc.fetchExposureAnalyticsFilters(dispatch, params);
  };
}

export function fetchExposureAnalyticsSettings() {
  return dispatch => {
    return svc.fetchExposureAnalyticsSettings(dispatch)
      .then(payload => {
        dispatch(fetchExposureAnalyticsSettingsSuccess(payload));
      });
  };
}

export function fetchFxReconciliationSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchFxReconciliationSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function fetchExposureCoverageSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchExposureCoverageSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function fetchValuationRiskSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchValuationRiskSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function fetchFxMismatchSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchFxMismatchSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}


