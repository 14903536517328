import { parseFilters, prepareFiltersRequest } from "utils/parseUtils";

export default class EligibilityReconciliationAdapter {
  parseAPIResponse(result) {
    return {
      ...result,
      ...parseFilters(result.filterByFields),
    };
  }
  static prepareRequest(params) {
    const filterBy = prepareFiltersRequest(params);
    const request = {
      filterBy,
      run: !!params.run,
    };

    return request;
  }
}
