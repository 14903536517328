import LoadingSuspense from "common/LoadingSuspense";
import React from "react";
import { ClaimsPageProps } from "./ClaimsPage";

const Component = React.lazy(() => import("./ClaimsPage"));

const LoadableClaimsPage = (props: ClaimsPageProps) => (
  <LoadingSuspense>
    <Component {...props} />
  </LoadingSuspense>
);

export default LoadableClaimsPage;
