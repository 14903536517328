import { parseFilters, prepareFiltersRequest } from 'utils/parseUtils';

export const config = {
  filterDropdown: {
    defaultLabel: 'ALL',
    defaultValue: 'all'
  },
};

export default class ProjectionsAdapter {
  parseData(result) {
    const { apiId, requestId, headerInfo, table, isGrossValues, groupings } = result;

    return {
      ...parseFilters(result.filterByFields),
      apiId,
      requestId,
      groupings,
      headerInfo,
      table,
      isGrossValues,
    };
  }

  static prepareRequest(params) {
    if (!params.filterBy) {
      params.filterBy = {};
    }
    if (!params.groupBy) {
      params.groupBy = [];
    }

    const filterBy = prepareFiltersRequest(params);

    const groupBy = params.groupBy.filter(it => it !== config.filterDropdown.defaultValue);

    const request = {
      minTime: params.minTime,
      maxTime: params.maxTime,
      groupBy,
      filterBy,
      showGrossValues: params.showGrossValues
    };

    return request;
  }
}
