export class ServiceError extends Error {
  constructor(error, apiId, requestId, suppressError) {
    const errorMessage = error.obj ? error.obj.error : error.statusText;
    super(errorMessage || "An error occured, please try again later.");

    this.props = error;
    this.apiId = apiId;
    this.requestId = requestId;
    this.suppressError = suppressError;

    this.getStatus = () => {
      return this.props.status;
    };

    this.getApiId = () => {
      return this.apiId;
    };

    this.getRequestId = () => {
      return this.requestId;
    };
  }


}
