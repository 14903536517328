export const APP_ALLOW_SSO =
  "development" === process.env.NODE_ENV ||
  "sso" === process.env.REACT_APP_SITE;
export const APP_ALLOW_CCS =
  "development" === process.env.NODE_ENV ||
  "sso" !== process.env.REACT_APP_SITE;
export const APP_ALLOW_MIS =
  "development" === process.env.NODE_ENV ||
  "sso" !== process.env.REACT_APP_SITE;
export const APP_ALLOW_SFTR =
  "development" === process.env.NODE_ENV ||
  "sso" !== process.env.REACT_APP_SITE;
export const APP_ALLOW_STYLEGUIDE = "development" === process.env.NODE_ENV;
export const APP_VERSION = process.env.APP_VERSION;

export const LOGOUT_URL = APP_ALLOW_SSO ? "/sso/login?act=logout" : "/logout";
export const LOGOUT_TIMEOUT = 7200; // in seconds
export const LOGOUT_CHECK_INTERVAL = 30; // in minutes

export const IE11Message =
  "We have detected you are using Internet Explorer, from 15 June 2022 Microsoft will no longer support this browser. Please convert to using MS Edge Chromium, Firefox or Google Chrome browsers and advise support@pirum.com of any issues.";
export const IELessThan11Message =
  "The version of Internet Explorer you are currently using is not supported by the PirumConnect application. Some features will not perform well and some may not function at all. We strongly advise you to use an alternative browser such as Chrome or Firefox.";
