import { TwoSidedTablePayload } from "repo/matchingManager/actions";
import { TableDetailColDef } from "repo/matchingManager/EventDetails/TwoSided/TableView/Table/types";
import { RepoCell } from "repo/common/Grid/types";
import { UnmatchedTradesDictionary } from "repo/matchingManager/EventDetails/TwoSided/DataView";
import { getActiveValidClientIds } from "pirumconnect/storage/activeValidClientIds";

type TradeType = "trade" | "pairOff";

export type CellData =
  | string
  | boolean
  | undefined
  | {
      clientId: string;
      index: number;
      isBreak: boolean;
      isUnmatched?: boolean;
      setId: string;
      tradeType: TradeType;
      value: string | number;
    };

export interface RowData {
  [k: string]: CellData;
  break?: boolean;
  field: string;
  label: string;
  pinned?: string;
}

type ColumnsDictionary = Record<string, RowData>;

type SetData = {
  index: number;
  setId: string;
};

interface MappedCells {
  cells: Record<string, RepoCell>;
  clientId: string;
  id: string;
  setData: SetData;
  status: string;
  tradeType: TradeType;
}

interface PopulatedTradeType {
  clientId: string;
  setId: string;
  tradeType: TradeType;
}

interface DataViewData {
  breaks: string[];
  clients: Client[];
  rows: RowData[];
  unmatchedTrades: UnmatchedTradesDictionary;
}

export interface Client {
  id: string;
  index: number;
  isActiveClient: boolean;
  isEndOfSet?: boolean;
  isStartOfSet?: boolean;
  label: string;
  setId: string;
  tradeType: TradeType;
}

interface MapClientData {
  isActiveClient: boolean;
  setData: SetData;
  source: string;
  tradeType: TradeType;
}

// This service formats the TableView data to work with the DataView
// The TableView is a series of cols whereas the DataView is a series of rows
// The data is transposes the columns to rows
// It also adds additional data to aid in the styling
const prepareTwoSidedEventDetailsData = (
  tableData: TwoSidedTablePayload
): DataViewData => {
  const activeClientIds = getActiveValidClientIds().map((id) => id.toString());

  const breaks: { data: Record<number, string[]> } = { data: {} };
  const columnsDictionary: ColumnsDictionary = {};
  const clients: Client[] = [];

  const EXCLUDED_ROWS = ["source"];

  const UNMATCHED_STATUSES = ["alleged", "unmatched"];

  const preparedData: DataViewData = {
    breaks: [],
    clients: [],
    rows: [],
    unmatchedTrades: {},
  };

  type Set = {
    [k in TradeType]: Record<string, { clients: string[] }>;
  };

  const sets: Set = {
    pairOff: {},
    trade: {},
  };

  const mapClient = ({
    isActiveClient,
    setData,
    source,
    tradeType,
  }: MapClientData): Client => {
    const id = `${source.toLowerCase()}-${setData.setId}-${setData.index}`;

    return { label: source, id, isActiveClient, ...setData, tradeType };
  };

  const checkIfBreak = (key: string, setId: number): boolean =>
    breaks.data[setId]?.length ? breaks.data[setId]?.includes(key) : false;

  const checkIfUnmatched = (status: string): boolean =>
    UNMATCHED_STATUSES.includes(status);

  const mapCellsToRow = ({
    cells,
    clientId,
    id,
    setData,
    status,
    tradeType,
  }: MappedCells): void => {
    Object.entries(cells).forEach(([key, cell]) => {
      if (!columnsDictionary[key]) return; // guard clause if column has no def
      const value = cell.value || cell.numValue || null;
      if (!value) return; // only store when there is a value

      const isBreak = checkIfBreak(key, parseInt(setData.setId, 10));
      if (isBreak) columnsDictionary[key].break = isBreak;

      const isUnmatched = checkIfUnmatched(status);
      if (isUnmatched && !preparedData.unmatchedTrades[id])
        preparedData.unmatchedTrades[id] = isUnmatched;

      columnsDictionary[key][id] = {
        ...setData,
        clientId,
        isBreak,
        tradeType,
        value,
      };
    });
  };

  const populateTradeType = ({
    clientId,
    setId,
    tradeType,
  }: PopulatedTradeType): void => {
    if (!sets[tradeType]) return;

    if (!sets[tradeType][setId]) {
      sets[tradeType][setId] = { clients: [clientId] };
    } else {
      sets[tradeType][setId].clients.push(clientId);
    }
  };

  if (!tableData?.summaries) return preparedData; // guard clause if missing data

  Object.entries(tableData.summaries).forEach(([key, summary]) => {
    const tradeType = key as TradeType;
    summary.table.columnDefs.forEach((columnDef) => {
      if (
        columnsDictionary[columnDef.field] ||
        EXCLUDED_ROWS.includes(columnDef.field)
      ) {
        return;
      }
      const {
        field = "",
        headerName,
        pinned,
        styleKey,
      } = columnDef as unknown as TableDetailColDef;
      const title =
        typeof headerName === "object" ? headerName.title : headerName;
      columnsDictionary[columnDef.field] = {
        field,
        label: title || field?.split(/(?=[A-Z])/).join(" "),
        styleKey,
      };
      if (pinned)
        columnsDictionary[columnDef.field].pinned = pinned as
          | string
          | undefined;
    });

    const activeSet = { id: "", index: 0 };

    summary.table.rows.forEach((row) => {
      breaks.data = { ...breaks.data, ...summary.breaks }; // merge breaks data
      const setId: string = row?.cells?.setId?.value as string;
      const status: string = row?.cells?.status?.value as string;
      const clientId: string = row?.cells?.clientId?.value as string;
      const isActiveClient = activeClientIds.includes(clientId);

      if (activeSet.id !== setId) {
        activeSet.id = setId;
        activeSet.index = 0;
      } else activeSet.index += 1;

      const setData = { index: activeSet.index, setId };

      const value: string = row?.cells?.source?.value as string;
      const client = mapClient({
        isActiveClient,
        setData,
        source: value,
        tradeType,
      });
      if (!clients.includes(client)) clients.push(client);

      populateTradeType({ clientId: client.id, setId, tradeType });

      mapCellsToRow({
        cells: row?.cells,
        clientId,
        id: client.id,
        setData,
        status,
        tradeType,
      });
    });
  });

  Object.values(breaks.data)
    .flat()
    .forEach((key) => {
      const breakValue: string =
        columnsDictionary[key]?.label ||
        key?.toString()?.replace(/[_\- ]/g, " ");

      if (breakValue && !preparedData.breaks.includes(breakValue)) {
        preparedData.breaks.push(breakValue);
      }
    });

  preparedData.clients = clients.map((client) => {
    const setsForClient =
      sets?.[client.tradeType]?.[client.setId]?.clients || [];
    return {
      isStartOfSet: client.index == 0,
      isEndOfSet: setsForClient.length - 1 === client.index,
      ...client,
    };
  });

  preparedData.rows = Object.values(columnsDictionary);
  return preparedData;
};

export default prepareTwoSidedEventDetailsData;
