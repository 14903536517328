import * as actionTypes from '../../constants/actionTypes';
import * as svc from '../../services/common/filters';
import { COMMON } from 'common/constants/filters';

export const setFilterPane = (filterOpen, filterType = COMMON) => ({
  type: `${actionTypes.SET_FILTER_PANE_OPEN}_${filterType}`,
  payload: filterOpen
});

export const setFilterHover = (filterHover, filterType = COMMON) => ({
  type: `${actionTypes.SET_FILTER_BUTTON_HOVER}_${filterType}`,
  payload: filterHover
});

export const setFilters = (payload, clearPrevious = true, filterType = COMMON) => {
  const {filterBy, filtersList, filtersSummary, filterByFields} = payload;
  if (clearPrevious) {
    svc.storeSelectedCommonFilterState({
      filterBy,
      filtersList,
      filterByFields,
      filtersSummary
    });
  }
  return {
    type: `${actionTypes.SET_FILTERS}_${filterType}`,
    payload: {
      filterBy,
      filtersList,
      filtersSummary,
      filterByFields,
      clearPrevious
    }
  };
};

let deselectFilterTimeoutId = null;

export function quickSelectFilter(filterName, invokedByTimeout=false, filterType = COMMON) {
  return (dispatch) => {
    if (invokedByTimeout) {
      deselectFilterTimeoutId = null;
    } else {
      if (deselectFilterTimeoutId !== null) {
        clearTimeout(deselectFilterTimeoutId);
      }
      //Deselect filter after timeout
      deselectFilterTimeoutId = setTimeout(() => {dispatch(quickSelectFilter('', true));}, 1500);
    }
    return dispatch({
      type: `${actionTypes.SET_FILTER_QUICK_SELECT}_${filterType}`,
      payload: filterName
    });
  };
}
