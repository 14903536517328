import {
  ID_TOKEN_NAME,
  IMP_TOKEN_NAME,
  SESS_TOKEN_NAME,
} from "pirumconnect/storage/constants";

let connected = [];
let lastTokenString = "";

export function sendToConnected(token) {
  connected.forEach(callback => callback(token));
  lastTokenString = JSON.stringify(token);
}

export function connectStorageLocal( callback ) {
  const found = connected.filter(it => it === callback);
  if ( !found.length ) {
    connected.push(callback);
  }
}

export function unconnectStorageLocal( callback ) {
  connected = connected.filter(it => it !== callback);
}

if (window.addEventListener) {
  window.addEventListener('storage', handleStorageEvent, false);
} else {
  window.attachEvent('onstorage', handleStorageEvent);
}

function handleStorageEvent(event) {
  if (!event) {
    event = window.event;
  }
  // Note: IE has a bug in storage events that it fires up the event on the source window as well
  // see https://connect.microsoft.com/IE/feedback/details/774798/localstorage-event-fired-in-source-window
  // so we have to check if this was the instance doing the set operation
  const isTokenEvent = event
    && (event.key === SESS_TOKEN_NAME || event.key === ID_TOKEN_NAME || event.key === IMP_TOKEN_NAME);
  if (isTokenEvent && lastTokenString !== event.newValue ) {
    try {
      sendToConnected(JSON.parse(event.newValue));
      lastTokenString = "";
    }
    catch (e) {}
  }
}