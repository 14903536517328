import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import { ReactComponent as TickAlt } from 'assets/images/tick-alt.svg';

export const CheckboxContainer = styled.div<{ $indeterminate: boolean, checked: boolean, glowOnChecked: boolean }>`
  margin-right: 10px;
  border-radius: 1px;
  position: relative;
  ${({ glowOnChecked, checked, $indeterminate }) => glowOnChecked && checked && css`
    box-shadow: ${$indeterminate ? 'rgba(221, 203, 125, 0.5)' : 'rgba(114, 183, 103, 0.5)'} 0px 0px 0px 4px;
  `}
`;

export const Tick = styled(TickAlt) <{ checked: boolean, disabled: boolean, $indeterminate: boolean }>`
  position: absolute;
  height: ${({ height = 14 }) => height}px;
  width: ${({ width = 14 }) => width}px;
  color: #ffffff;
  display: none;
  ${props => props.checked && css`
    display: block;
    background-color: #72b767;
  `}
  ${props => props.checked && props.disabled && css`
    background-color: rgba(232,232,232,0.7);
  `}
`;

const checkBoxBorders = theme('mode', {
  light: (props: any) => css`
    border: ${!props.checked ? '1px solid #23283A' : 'none'};
    border-color: ${props.checked && !props.active ? 'rgba(232,232,232,0.7)' : null};
  `,
  dark: (props: any) => css`
    border: ${!props.checked ? '1px solid white' : '1px solid #72b767'};
    border-color: ${props.checked && !props.active ? 'rgba(232,232,232,0.7)' : null};
  `,
});

export const Checkbox = styled.div<{ height: number, width: number, $indeterminate: boolean, checked: boolean, active: boolean }>`
  height: ${({ height = 14 }) => height}px;
  width: ${({ width = 14 }) => width}px;
  position: relative;
  ${checkBoxBorders}
  ${props => props.$indeterminate && css`
    border: none;
    background-color: #F4E78F;
  `}
`;
