import { actionTypes } from "./actions";
import { getFetchFailActionByApi } from "utils/apiUtils";
import { apiNames } from "constants/api/queryApi";
import { Map } from "immutable";

const FETCH_ELIGIBILITY_CHECKER_FAIL = getFetchFailActionByApi(
  apiNames.ELIGIBILITY_RECONCILIATION
);

export const initialState = Map({
  summaryByCount: {},
  summaryByValue: {},
  details: [],
});

export const eligibilityReconciliation = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ELIGIBILITY_RECONCILIATION_SUCCESS:
      return state.withMutations((st) =>
        st
          .set("summaryByCount", action.payload.summaryByCount)
          .set("summaryByValue", action.payload.summaryByValue)
          .set("summaryByVenue", action.payload.summaryByVenue)
          .set("details", action.payload.details)
          .set("recTable", action.payload.table)
          .set(
            "allowBeneficialOwnerRec",
            action.payload.allowBeneficialOwnerRec
          )
      );
    case actionTypes.FETCH_BENEFICIAL_OWNER_SUCCESS:
      return state.withMutations((st) =>
        st
          .set("summaryByCount", action.payload.summaryByCount)
          .set("summaryByValue", action.payload.summaryByValue)
          .set("summaryByVenue", action.payload.summaryByVenue)
          .set("details", action.payload.details)
          .set("benTable", action.payload.table)
          .set(
            "allowBeneficialOwnerRec",
            action.payload.allowBeneficialOwnerRec
          )
      );
    case actionTypes.FETCH_COMBINED_SUCCESS:
      return state.withMutations((st) =>
        st
          .set("summaryByCount", action.payload.summaryByCount)
          .set("summaryByValue", action.payload.summaryByValue)
          .set("summaryByVenue", action.payload.summaryByVenue)
          .set("details", action.payload.details)
          .set("combinedTable", action.payload.table)
          .set(
            "allowBeneficialOwnerRec",
            action.payload.allowBeneficialOwnerRec
          )
      );
    case FETCH_ELIGIBILITY_CHECKER_FAIL:
      return initialState;

    default:
      return state;
  }
};
