import { combineReducers } from "redux";

import { api } from './apiReducer';
import { init } from './initReducer';
import { userAccount } from './userAccountReducer';
import { createFilterReducerWithNamedType } from './filterReducer';
import { platformDetails } from './platformDetailsReducer';
import toastReducer from 'common/notifications/toast/reducer';
import {
  COMMON,
  REC,
} from 'common/constants/filters';

export default combineReducers({
  api,
  init,
  userAccount,
  commonFilters: createFilterReducerWithNamedType(COMMON),
  recFilters: createFilterReducerWithNamedType(REC),
  platformDetails,
  toast: toastReducer,
});