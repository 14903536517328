const apiNames = {
  AUTH: 'auth',
};

const apiOperatonIds = {
  TOKENS: 'tokens',
  AUTHORIZATION_URL: 'authorizationUrl',
  REFRESH: 'refresh',
  CLIENTS: 'clients',
  USERS: 'users',
  LOGOUT: 'logout',
  IMPERSONATE: 'impersonate',
  STOP_IMPERSONATION: 'stopImpersonation',
  SELECT_BANK: 'selectBank',
  LEAVE_SUPPORT: 'leaveSupport',
};

export {apiNames, apiOperatonIds};
