import styled from "styled-components";
import { ReactComponent as CrossSVG } from "assets/images/close.svg";
import { PIRUM_UI_BG_1, PIRUM_UI_GB_4 } from "styles/colors/brandColors";

export const transition = {
  className: "WorkflowModal",
  duration: 400,
};

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  top: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme?.mode === "dark" ? PIRUM_UI_BG_1[100] : PIRUM_UI_GB_4[100]};
  z-index: 10;
  transition: opacity ease-in-out ${transition.duration}ms;
  &.${transition.className}-enter {
    opacity: 0;
  }

  &.${transition.className}-enter-active {
    opacity: 1;
  }

  &.${transition.className}-exit {
    opacity: 1;
  }

  &.${transition.className}-exit-active {
    opacity: 0;
  }
`;

export const CloseIcon = styled(CrossSVG)`
  position: absolute;
  top: 30px;
  right: 40px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const slideInTransition = {
  className: "WorkflowModal__contents",
  duration: 400,
};

export const SlideInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: opacity ease-in-out ${slideInTransition.duration}ms,
    transform ${slideInTransition.duration}ms;

  &.${slideInTransition.className}-enter {
    /* opacity: 0; */
    transform: translateX(100%);
  }
  &.${slideInTransition.className}-enter-active {
    /* opacity: 1; */
    transform: translateX(0%);
  }
  &.${slideInTransition.className}-exit {
    /* opacity: 1; */
    transform: translateX(0%);
  }
  &.${slideInTransition.className}-exit-active {
    /* opacity: 0; */
    transform: translateX(-100%);
  }
`;
