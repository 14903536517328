import styled from 'styled-components/macro';
import { ReactComponent as ErrorIcon } from 'assets/images/error-icon.svg';

export const ErrorMessage = styled.div`
  color: #FDD400;
  text-align: center;
  padding: 65px;
`;

export const Title = styled.div`
  color: inherit;
  font-family: "Open Sans Light";
  font-size: 2.9rem;
  letter-spacing: -0.5px;
  margin: 20px 0;
`;

export const Desc = styled.div`
  color: inherit;
  font-size: 1.4rem;
  line-height: 1.5em;
`;

export const MessageFooter = styled.div`
  font-family: "Open Sans Light";
  color: #E8E8E8;
  font-size: 1.4rem;
  margin-top: 25px;
`;

export const Button = styled.button`
  font-family: "Open Sans Semi Bold";
  color: #E8E8E8;
  background: #596079;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  padding: 0 20px;
  min-height: 45px;
  line-height: 45px;
  margin-top: 50px;
  margin-left: 4px;
  margin-right: 4px;

  &:hover,
  &:focus {
    background: #72778B;
    transition: background 0.25s ease-out;
  }
`;

export const Icon = styled(ErrorIcon)`
  margin: auto;
  width: 77px;
  height: 77px;
  color: #FDD400;
`;
