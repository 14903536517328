import { GridApi } from "ag-grid-community";
import pirumAnalytics from "pirumconnect/analytics";
import { getGridState } from "./GridUtils";
import { PirumColumn, PirumColumnApi, PirumGridOptions } from "./types";

/**
 * COLUMN Props
 */
const getColumnsTrackingProps = (columnList: PirumColumn[]) => {
  return columnList.map((column) => ({
    editable: column.getColDef().editable,
    filter: column.getColDef().filter,
    field: column.getColDef().field,
    headerName: column.getColDef().headerName,
    hide: column.getColDef().hide,
    menuTabs: column.getColDef().menuTabs,
    pinnedRowCellRenderer: column.getColDef().pinnedRowCellRenderer,
    resizable: column.getColDef().resizable,
    rowGroup: column.getColDef().rowGroup,
    sortable: column.getColDef().sortable,
    suppressMovable: column.getColDef().suppressMovable,
    suppressToolPanel: column.getColDef().suppressToolPanel,
    colId: column.getColId(),
    actualWidth: column.getActualWidth(),
    fieldContainsDots: column.isFieldContainsDots(),
    filterActive: column.isFilterActive(),
    firstRightPinned: column.isFirstRightPinned(),
    lastLeftPinned: column.isLastLeftPinned(),
    left: column.getLeft(),
    lockPinned: column.getColDef().lockPinned,
    lockPosition: column.getColDef().lockPosition,
    lockVisible: column.getColDef().lockVisible,
    menuVisible: column.isMenuVisible(),
    minWidth: column.getMinWidth(),
    moving: column.isMoving(),
    oldLeft: column.getOldLeft(),
    pinned: column.isPinned(),
    pivotActive: column.isPivotActive(),
    primary: column.isPrimary(),
    rowGroupActive: column.isRowGroupActive(),
    sort: column.getSort(),
    // @ts-expect-error sortedAt prop doesn't exist neither on agGrid types nor any pirum types
    sortedAt: column.sortedAt,
    tooltipFieldContainsDots: column.isTooltipFieldContainsDots(),
    visible: column.isVisible(),
  }));
};

const getGridTrackingState = (gridApi: GridApi, columnApi: PirumColumnApi) => {
  return {
    ...getGridState(gridApi, columnApi),
    allDisplayedColumns: getColumnsTrackingProps(
      columnApi.getAllDisplayedColumns()
    ),
    allDisplayedVirtualColumns: getColumnsTrackingProps(
      columnApi.getAllDisplayedVirtualColumns()
    ),
  };
};

/**
 * All ag-grid events that are going to be sent via /useraction/statistics endpoint.
 * It is convenient to see the entire list of events and
 * comment out on those that we don't want to listen to.
 * So adding and removing events in the future would be trivial.
 */
export const TRACKING_EVENTS = [
  "onGridReady",
  "onSelectionChanged",
  "onFilterChanged",
  "onFilterModified",
  "onSortChanged",
  "onPinnedRowDataChanged",
  "onNewColumnsLoaded",
  "onGridColumnsChanged",
  // "onVirtualColumnsChanged",
  "onColumnPivotModeChanged",
  "onColumnEverythingChanged",
  // "onDisplayedColumnsChanged",
  "onToolPanelVisibleChanged",
  "onCellFocused",
  // "onViewportChanged",
  "onGridSizeChanged",
  "onColumnGroupOpened",
  // "onBodyScroll",
  // "onPaginationChanged", SFTR-4502 - Turning this off as its adding more requests on polling
  "onExpandOrCollapseAll",
  "onDragStarted",
  "onDragStopped",
  "onRowDragEnter",
  "onRowDragEnd",
  "onRowDragLeave",
  "onColumnPivotChanged",
  "onColumnRowGroupChanged",
  "onColumnValueChanged",
  "onColumnMoved",
  // "onColumnResized",
  "onColumnVisible",
  "onColumnPinned",
] as const;

export const agGridEventHandlers = (gridId: string) => {
  return TRACKING_EVENTS.reduce<
    Pick<PirumGridOptions, typeof TRACKING_EVENTS[number]>
  >((acc, eventAgPropName) => {
    acc[eventAgPropName] = (event) => {
      const { api, columnApi, type } = event;
      pirumAnalytics.send({
        gridId,
        actionName: "gridEvent_" + type,
        gridState: getGridTrackingState(api, columnApi as PirumColumnApi),
      });
    };
    return acc;
  }, {});
};
