import React, {useState, useEffect} from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from './Icon';
import styledComponents, {transitionClassName} from './styles';

const messageDuration = 4000;
const animationDuration = 1200;

export default function Toast ({icon, type, text, onAnimationComplete}) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    setTimeout(_ => setMounted(false), messageDuration);
  }, []);
  return (
    <CSSTransition in={mounted} timeout={animationDuration} classNames={transitionClassName} unmountOnExit onExited={onAnimationComplete}>
      <styledComponents.Message type={type}>
        <Icon icon={icon} type={type} />{text}
      </styledComponents.Message>
    </CSSTransition>
  );
}