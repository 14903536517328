export const actionTypes = {
  ADD_TOAST: 'toasts/ADD_TOAST',
  REMOVE_TOAST: 'toasts/REMOVE_TOAST',
};

export const addToast = payload => ({
  type: actionTypes.ADD_TOAST,
  payload
});

export const removeToast = payload => ({
  type: actionTypes.REMOVE_TOAST,
  payload
});