import routesConfig from "./routesConfig";

const appConfig = {
  ...routesConfig,
  theme: {
    mode: "light",
  },
};

export default appConfig;
