import React from 'react';
import { styledComponents } from './styles';

interface LoadingProps {
  description?: string;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ description, className }) => (
  <styledComponents.loadingPosition
    className={className}
    role="status"
    aria-label="Loading"
  >
    <styledComponents.loadingContainer>
      <styledComponents.loader> {description} </styledComponents.loader>
    </styledComponents.loadingContainer>
  </styledComponents.loadingPosition>
);

export { Loading };
