import services from 'services/services';
import { apiNames, apiOperatonIds } from '../../constants/api/queryApi';
import TimeSeriesAdapter from './servicesAdapter';


/**
 * Obtaining data from remote SERVER
 */
export function fetchTimeSeries(dispatch, params) {
  const request = TimeSeriesAdapter.prepareRequest(params);
  const adapter = new TimeSeriesAdapter();
  return services.query(apiNames.COLLATERAL_REQUIREMENT_GROUPED_TIME_SERIES, apiOperatonIds.VENUE_TIME_SERIES)(dispatch, { request })
    .then(result => adapter.parseData(result));
}

export function fetchTimeSeriesFilters(dispatch, params) {
  const adapter = new TimeSeriesAdapter();
  const request = TimeSeriesAdapter.prepareRequest(params);

  return services.query(apiNames.COLLATERAL_REQUIREMENT_GROUPED_TIME_SERIES, apiOperatonIds.COLLATERAL_REQUIREMENT_GROUPED_TIME_SERIES_FILTERS)(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}