import React from "react";
import { WorkflowModal } from "common/WorkflowModal";
import { ActionTypes, ModalStatus } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { modalOpenSelector, statusSelector } from "../selectors";
import { Grouped } from "./Grouped";
import { NonGrouped } from "./NonGrouped";
import { clearWorkflowStatusState } from "repo/matchingManager/actions";

const workflowStgages: Record<ModalStatus, JSX.Element | null> = {
  [ModalStatus.Grouped]: <Grouped />,
  [ModalStatus.NonGrouped]: <NonGrouped />,
  [ModalStatus.Closed]: null,
};

function shouldShowCross(status: ModalStatus) {
  return (
    status === ModalStatus.Grouped ||
    status === ModalStatus.NonGrouped ||
    status === ModalStatus.Closed
  );
}

const ChangeStatusModal = () => {
  const dispatch = useDispatch();
  const modalOpen = useSelector(modalOpenSelector);
  const status = useSelector(statusSelector);

  const exitModal = () => {
    dispatch({ type: ActionTypes.CLOSE_MATCHING_MODAL });
    dispatch(clearWorkflowStatusState());
  };

  return (
    <WorkflowModal<ModalStatus>
      modalOpen={modalOpen}
      status={status}
      exitModal={exitModal}
      workflowStages={workflowStgages}
      shouldShowCross={shouldShowCross}
    />
  );
};

export default ChangeStatusModal;
