import React from "react";
import LoadingSuspense from "common/LoadingSuspense";

const Component = React.lazy(() => import("./"));

export default (): JSX.Element => {
  return (
    <LoadingSuspense>
      <Component />
    </LoadingSuspense>
  );
};
