import LoadingSuspense from "common/LoadingSuspense";
import React from "react";

const Component = React.lazy(() => import("./ReconciliationsPage"));

const LoadableRec = () => (
  <LoadingSuspense>
    <Component />
  </LoadingSuspense>
);

export default LoadableRec;
