import Bowser from 'bowser';


const browser = Bowser.getParser(window.navigator.userAgent);

const isIE = Boolean(browser.satisfies({'internet explorer': '>=11' }));
const browserInfo = browser.getResult();


export {
  isIE,
  browserInfo,
};
