// TODO: FE-305 Refactor and move to local
import isEqual from 'lodash.isequal';
import logger from '../logger';

const config = {
  prefix: 'CCS',
  separator: '|',
  messages: {
    noStorageAvailable: 'This storage is not available'
  }
};

// In the future this function will define the kind of storage we use
const _getStorage = () => typeof localStorage !== 'undefined' ? localStorage : null;

const _defineKey = (namespace = '', key = '') =>
  `${config.prefix}${config.separator}${namespace}${config.separator}${key}`;

const _warn = (msg) => {
  logger.warn(msg);
  // eslint-disable-next-line no-console
  console.warn(msg);
};

function createStorage() {
  return {
    isEnabled,
    setItem,
    getItem,
    updateWithDefaults,
    removeItem,
    clear,
    keys,
    getValues
  };
}

function isEnabled() {
  if(!_getStorage()) _warn(config.messages.noStorageAvailable);
  return !!_getStorage();
}

function setItem(namespace, key, value) {
  if(!isEnabled()) return null;
  try {
    return value ? _getStorage().setItem(_defineKey(namespace, key), JSON.stringify(value)) : removeItem(namespace, key);
  }
  catch (e) {
    _warn(e);
  }
}

function getItem(namespace, key) {
  if(!isEnabled()) return null;
  try {
    return JSON.parse(_getStorage().getItem(_defineKey(namespace, key)));
  }
  catch (e) {
    _warn(e);
    removeItem(namespace, key);
    return null;
  }
}

function removeItem(namespace, key) {
  if(!isEnabled()) return null;
  try {
    _getStorage().removeItem(_defineKey(namespace, key));
  }
  catch (e) {
    _warn(e);
  }
}

function clear(namespace) {
  if(!isEnabled()) return null;
  keys(namespace).forEach(key => _getStorage().removeItem(key));
}

function keys(namespace) {
  return Object.keys(_getStorage())
    .filter(key => {
      const parts = key.split(config.separator);
      return parts[0] === config.prefix && (!namespace || parts[1] === namespace);
    });
}

//Replaces all the variables in a given namespace
function updateWithDefaults(namespace, values, keys, defaults = {}) {
  const keyFilter = (keys || Object.keys(values)).reduce((acc, key) => ({...acc, [key]: true}), {});
  Object.keys(values)
    .filter(key => keyFilter[key])
    .forEach(key => {
      if ( isEqual(values[key], defaults[key]) ) {
        removeItem(namespace, key);
      }
      else {
        setItem(namespace, key, values[key]);
      }
    });
}

function getValues(namespace, keys) {
  return keys
    .reduce((acc, key) => {
      const value = getItem(namespace, key);
      return typeof value !== "undefined" && value !== null ? {...acc, [key]: value} : acc;
    }, {});
}

export default createStorage();
