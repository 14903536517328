import { apiNames, apiOperatonIds } from 'constants/api/collateralApi';
import services from 'services/services';

export function fetchTrades(dispatch) {
  return services.collateralProfile(apiNames.LINKING_TOOL, apiOperatonIds.COLLATERAL_PROFILE_LINKS_TRADE_GROUPS)(dispatch, {});
}

export function fetchSchedules(dispatch) {
  return services.collateralProfile(apiNames.LINKING_TOOL, apiOperatonIds.COLLATERAL_PROFILE_LINKS_PROFILE_SUMMARIES)(dispatch, {});
}

export function fetchLinks(dispatch) {
  return services.collateralProfile(apiNames.LINKING_TOOL, apiOperatonIds.COLLATERAL_PROFILE_LINKS_AT_VENUE_FOR_TABLES)(dispatch, {});
}

export function createLinks(dispatch, params) {
  const request = { ...params };
  return services.collateralProfile(apiNames.LINKING_TOOL, apiOperatonIds.COLLATERAL_PROFILE_CREATE_LINKS, true)(dispatch, { request });
}

export function deleteLinks(dispatch, params) {
  const request = { links: params };
  return services.collateralProfile(apiNames.LINKING_TOOL, apiOperatonIds.COLLATERAL_PROFILE_DELETE_LINKS)(dispatch, { request });
}