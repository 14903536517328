import React from 'react';
import ReactModal from 'react-modal';

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <ReactModal
    className={modalClassName}
    portalClassName={className}
    {...props}
  />
);

export default ReactModalAdapter;