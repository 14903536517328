import ccsConfig from 'ccs/appConfig';
import misRoutesConfig from  'mis/routesConfig';
import sftrConfig from 'sftr/appConfig';
import configurationRoutesConfig from 'configconnect/routesConfig';
import fosRoutesConfig from 'fos/appConfig';
import { listToObject } from 'utils/dataUtils';
import coAcsConnectConfig from 'coacsconnect/appConfig';
import repoRoutesConfig from 'repo/appConfig';

const routesConfigList = [
  ccsConfig,
  misRoutesConfig,
  sftrConfig,
  configurationRoutesConfig,
  coAcsConnectConfig,
  repoRoutesConfig,
  fosRoutesConfig
];

const appsViewIdsList = routesConfigList.map(it => it.appViewId);
const appsViewIds = routesConfigList.reduce((acc, it) => ({...acc, ...it.viewIds.app}), {});


routesConfigList.forEach(routeConfig => {
  routeConfig.routes.sections = listToObject(routeConfig.routes.sectionsList, 'viewId');// for convienient access to a section config by viewId
  routeConfig.routes.pages = listToObject(routeConfig.routes.pagesList, 'viewId');// for convienient  access to a page config by viewId
});

const appConfigs = listToObject(routesConfigList, 'appViewId');// for convienient access to a route config by the application's viewId

export {
  appsViewIdsList,
  routesConfigList,
  appsViewIds,
  appConfigs,
};
