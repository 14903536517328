/***
 * This file represents the psSE object (token / SESS_TOKEN_NAME) in local storage.
 * Example:
 *   aToken: "12345abcdef67890fedcba"
 *   baseCurrency: "USD"
 *   baseEntity: "26000"
 *   businessDate: "2019-06-19"
 *   clientIds: [26000]
 *   exp: 1560962196
 *   iat: 1560954996
 *   pirumMode: "support"
 *   selectAllClientIdsOnInit: false
 */

import jwtDecode from 'jwt-decode';
import Cookies from './cookies';
import isEqual from 'lodash.isequal';
import { sendToConnected } from './connectToStorage';

import { LOGOUT_URL } from '../../../constants/app';

import appLogger from '../../logger';

import {
  SESS_TOKEN_NAME,
  CURRENT_URL,
} from 'pirumconnect/storage/constants';

export const currentURLStorage = {
  set() {
    const { pathname } = window.location;
    localStorage.setItem(CURRENT_URL, pathname);
  },
  get() {
    return localStorage.getItem(CURRENT_URL);
  },
  remove() {
    return localStorage.removeItem(CURRENT_URL);
  },
};

export function getPsSEToken() {
  try {
    const session = JSON.parse(localStorage.getItem(SESS_TOKEN_NAME));
    return (session && session.exp && (session.exp >= (new Date()).getTime()/1000) ? session : {});
  }
  catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    appLogger.fatalException({ where: 'getSession', what: 'Session parse throws an error', vars: {psSE: localStorage.getItem(SESS_TOKEN_NAME)} }, error);
    return {};
  }
}

if ( Cookies.hasItem(SESS_TOKEN_NAME) ) {
  updateSessionFromHeaders({["X-"+SESS_TOKEN_NAME]: Cookies.getItem(SESS_TOKEN_NAME)});
  Cookies.removeItem(SESS_TOKEN_NAME, '/');
}

function hasSwitchedEntity(update, session) {
  return (update.baseEntity !== undefined) && (update.baseEntity !== session.baseEntity);
}

function hasRefreshed(update, session) {
  return session.aToken !== update.aToken;
}

// Note: custom http header names meant to be prefixed with "X-"
export function updateSessionFromHeaders(headers) {
  // Note: headers may be case insensitive (yes express, Im talking about you...)
  const jwt = headers["X-"+SESS_TOKEN_NAME] || headers[("X-"+SESS_TOKEN_NAME).toLowerCase()] || headers[("X-"+SESS_TOKEN_NAME).toUpperCase()];

  if ( jwt ) {
    try {
      const update = jwtDecode(jwt);
      const session = getPsSEToken();
      const enrichedUpdate = {...session, ...update};

      enrichedUpdate.selectAllClientIdsOnInit = hasSwitchedEntity(update, session);

      if(hasRefreshed(update, session) && (!update.clientIds || !session.clientIds)) {
        enrichedUpdate.clientIds = [parseInt(update.baseEntity || session.baseEntity, 10)];
      }

      updateSession(enrichedUpdate);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
}

export function updateSession(update) {
  try {
    const session = getPsSEToken();
    update.selectAllClientIdsOnInit = hasSwitchedEntity(update, session);

    if(hasRefreshed(update, session) && (!update.clientIds || !session.clientIds)) {
      update.clientIds = [parseInt(update.baseEntity || session.baseEntity, 10)];
    }

    const newToken = {...session, ...update};

    // if selected client list has changed clear out the session store
    const doSessionStoreClear = !isEqual(session.clientIds, update.clientIds);

    localStorage.setItem(SESS_TOKEN_NAME, JSON.stringify(newToken));

    if ( doSessionStoreClear ) {
      // Note: this sessionStore clear MUST be next to the setItem call,
      // otherwise we will end up with concurrent execution issues with fetch
      sessionStorage.clear();
    }

    sendToConnected(newToken);
  }
  catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export function getSessionArgs(keys) {
  const session = getPsSEToken();
  return (keys || []).reduce((args, key) => { args[key] = session[key]; return args; }, {});
}

export function isLoggedIn() {
  // In order to fake the session set this {"clientIds":[-1], "exp": 90000000000000000}
  // to the psSE key in the localStorage
  return (Object.keys(getPsSEToken()).length > 0);
}

/**
 * @param interval in seconds
 */
export function createAutoLogoutChecker(interval) {
  let timer;
  (function checkAutoLogout() {

    if ( !isLoggedIn() ) {
      appLogger.debug({ where: 'createAutoLogoutChecker', what: 'User session expired, redirect to logout', vars: {psSE: localStorage.getItem('psSE')} });
      // session login expired, cannot do nothing, have to redirect to login
      redirectToLogout();
      return;
    }

    // re-set timer
    if ( interval ) {
      // make sure that we have only one setTimeout active at any given time,
      // even if we manage to call createAutoLogoutChecker() multiple times
      if ( timer ) {
        clearTimeout(timer);
      }
      // interval is in seconds, setTimeout expect millisec
      timer = setTimeout(checkAutoLogout, interval * 1000);
    }
  })();
}

export function isLoggedOut() {
  try {
    const ls = JSON.parse(localStorage.getItem(SESS_TOKEN_NAME));
    const {pathname, search} = window.location;
    return ls && ls.exp === 1 && pathname+search === LOGOUT_URL;
  } catch(error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  return false;
}

export function redirectToLogout() {
  localStorage.removeItem(SESS_TOKEN_NAME);
  if(window.aptrinsic) {
    window.aptrinsic('reset');
  }
  window.location = LOGOUT_URL;
}

