import * as actionTypes from 'constants/actionTypes';
import { Map } from 'immutable';

export const initialState = Map({
  platformTree: {},
  apps: {}, 
  sections:{}, 
  pages:{},
  pathsProps:{},
});

export const platformDetails = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PLATFORM_DETAILS_SUCCESS:
      const { payload } = action;
      return state.withMutations( st => {
        st.set('platformTree', payload.platformTree)
          .set('apps', payload.apps)
          .set('sections', payload.sections)
          .set('pages', payload.pages)
          .set('pathsProps', payload.pathsProps);
      });
    default:
      return state;
  }
};
