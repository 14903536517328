import { getTableResponse } from "./getTableResponse";
import { rest } from "msw";
import moment from "moment";

import {
  userInfo as fakerUserInfo,
  clientGrouped as fakerClientGrouped,
  menuTree as fakerMenuTree,
} from "./faker.mocks";
// import {
//   userInfo as customUserInfo,
//   clientGrouped as customClientGrouped,
//   menuTree as customMenuTree,
// } from "./custom-data.mocks";

interface TableRequestBody {
  workflowStatuses: string[];
}

export const handlers = (useFaker: boolean) => [
  rest.post("/uidata/user_info", (req, res, ctx) => {
    return res(
      ctx.json({
        ...fakerUserInfo,
      })
    );
  }),
  rest.post("/pirumconnect_uiconfig/1_3/clientGrouped", (req, res, ctx) => {
    return res(
      ctx.json({
        ...fakerClientGrouped,
      })
    );
  }),
  rest.post("/jsnlog.logger", (req, res, ctx) => {
    return res(ctx.json([]));
  }),
  rest.post("/query/1_3/dashboard/venue", (req, res, ctx) => {
    return res(
      ctx.json({
        metrics: [],
        period: moment().format("YYYYMMDD"),
      })
    );
  }),
  rest.post("/pirumconnect_uiconfig/1_3/menuTree", (req, res, ctx) => {
    return res(
      ctx.json({
        ...fakerMenuTree,
      })
    );
  }),
  rest.post(
    "/pirumconnect_uiconfig/1_3/useraction/statistics",
    (req, res, ctx) => {
      return res(ctx.set("Content-Type", "text/plain"), ctx.text("OK"));
    }
  ),
  rest.post(
    "/affirmation-workflow/1_1/userInformation/status",
    (req, res, ctx) => {
      return res(ctx.json({ userInformation: { isSubscribed: false } }));
    }
  ),
  rest.post<TableRequestBody>(
    "/affirmation-workflow/1_1/matchingManager/table",
    (req, res, ctx) => {
      const statuses = req.body.workflowStatuses;
      return res(ctx.json(getTableResponse(statuses, useFaker)));
    }
  ),
];
