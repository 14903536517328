import TokenStorage from 'pirumconnect/storage/local/tokens';

export default class UserActivityManager {

  constructor(halfTimeActivityCallback) {
    this.events = ['mousemove', 'mousedown', 'touchstart', 'click', 'keypress', 'scroll'];
    this.halfTimeActivityCallback = halfTimeActivityCallback;

    this.setNotIdleDetection = this.setNotIdleDetection.bind(this);
    this.removeNotIdleDetection = this.removeNotIdleDetection.bind(this);
    this.handleNotIdle = this.handleNotIdle.bind(this);
    this.notIdleDetectionActive = false;
  }

  scheduleActivityDetection() {
    // This sets a timer for now till the halfway expiry for the token
    setTimeout(this.setNotIdleDetection, TokenStorage.getHalfwayExpiry() - Date.now());
  }

  setNotIdleDetection() {
    if(!this.notIdleDetectionActive){
      this.events.forEach(event => {
        window.addEventListener(event, this.handleNotIdle, event === 'scroll');
      });
      this.notIdleDetectionActive = true;
    }
  }

  removeNotIdleDetection() {
    if(this.notIdleDetectionActive) {
      this.events.forEach(event => {
        window.removeEventListener(event, this.handleNotIdle, event === 'scroll');
      });
      this.notIdleDetectionActive = false;
    }
  }

  handleNotIdle() {
    this.removeNotIdleDetection();
    this.halfTimeActivityCallback();
  }
}
