import { fromJS, Map } from 'immutable';
import { createReducer } from '@reduxjs/toolkit';
import {
  SET_FILTERS,
  SET_FILTER_BUTTON_HOVER,
  SET_FILTER_PANE_OPEN,
  SET_FILTER_QUICK_SELECT
} from 'constants/actionTypes';
import { COMMON } from 'common/constants/filters';
import { createNamedAction } from 'utils/helpers';

export const initialFilterStateObject = {
  filterBy: {},
  filtersList: [],
  filtersSummary: [],
  filterPane: false,
  filterHover: false,
  filterHighlight: '',
  filterByFields: [],
  selected: {
    filterBy: {},
    filtersSummary: [],
    filtersList: [],
  },
};

const initialState = Map(initialFilterStateObject);

export const createFilterReducerWithNamedType = (filterType = COMMON) => createReducer(initialState, {
  [createNamedAction(SET_FILTER_PANE_OPEN, filterType)]: (state, action) => {
    return state.set('filterPane', fromJS(action.payload));
  },
  [createNamedAction(SET_FILTER_BUTTON_HOVER, filterType)]: (state, action) => {
    return state.set('filterHover', action.payload);
  },
  [createNamedAction(SET_FILTERS, filterType)]: (state, action) => {
    return state.withMutations(s => {
      s.set('filterBy', action.payload.filterBy)
        .set('filtersList', action.payload.filtersList)
        .set('filtersSummary', action.payload.filtersSummary)
        .set('filterByFields', action.payload.filterByFields);

      if (action.payload.clearPrevious) {
        s.set('selected', {
          filterBy: action.payload.filterBy,
          filtersSummary: action.payload.filtersSummary,
          filtersList: action.payload.filtersList,
          filterByFields: action.payload.filterByFields,
        });
      }

      return s;
    });
  },
  [createNamedAction(SET_FILTER_QUICK_SELECT, filterType)]: (state, action) => {
    return state.withMutations(s =>
      s.set('filterPane', action.payload ? true : s.get('filterPane'))
        .set('filterHighlight', action.payload));
  },
});