import { List } from "immutable";
import { actionTypes } from "./actions";

const initialState = List([]);

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TOAST:
      return state.push({
        ...action.payload,
        key: Date.now(),
      });
    case actionTypes.REMOVE_TOAST:
      return state.filter((toast) => toast.key !== action.payload);
    default:
      return state;
  }
};

export default toastReducer;
