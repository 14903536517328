import {
  ColDef,
  ColGroupDef,
  ColSpanParams,
  Column,
  ColumnApi,
  ColumnState,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IHeaderParams,
} from "ag-grid-community";
import { SortModel } from "common/types/grid";
import { WidthKey } from "./constants";
import { CustomEventParams } from "./useCustomAgGridEvents";

export enum StyleKey {
  DEFAULT = "DEFAULT",
  TEXT = "TEXT",
  SET_TEXT = "SET_TEXT",
  SET_ARRAY = "SET_ARRAY",
  QUANTITY = "QUANTITY",
  QUANTITY_NO_DECIMAL = "QUANTITY_NO_DECIMAL",
  VALUE = "VALUE",
  VALUE_NO_DECIMAL = "VALUE_NO_DECIMAL",
  VALUE_FOUR_DECIMALS = "VALUE_FOUR_DECIMALS",
  REGULATORY_VALUE = "REGULATORY_VALUE",
  PRICE = "PRICE",
  PRICE_SHORT = "PRICE_SHORT",
  PERCENTAGE = "PERCENTAGE",
  PERCENTAGE_FOUR_DECIMALS = "PERCENTAGE_FOUR_DECIMALS",
  FEE = "FEE",
  FX_RATE = "FX_RATE",
  PERIOD_DATE = "PERIOD_DATE",
  FULL_DATE = "FULL_DATE",
  FULL_DATE_TIME = "FULL_DATE_TIME",
  DATE = "DATE",
  DATETIME = "DATETIME",
  CURRENCY = "CURRENCY",
  BREAK = "BREAK",
  BOLD = "BOLD",
  BOLD_BREAK = "BOLD_BREAK",
  STYLE_BREAK = "STYLE_BREAK",
  STYLE_BOLD = "STYLE_BOLD",
  STATUS_FILTER = "STATUS_FILTER",
}

export enum FLEX_ALIGNMENT {
  Left = "flex-start",
  Right = "flex-end",
  Center = "center",
}

type CellStyleKey = string;

export interface PirumCellData {
  value?: string;
  numValue?: number;
  tooltip?: string;
  styleKey?: CellStyleKey;
}

type PirumSummaryRowData = Record<string, PirumCellData>;

export interface PirumGridOptions extends Omit<GridOptions, "columnDefs"> {
  columnDefs?: PirumColDef[];
  summaryRowData?: PirumSummaryRowData[];
  sortModel?: SortModel[]; // used for fake serverside sort model
  onServerSideSortChanged?: (sortModel: SortModel) => void; // used for fake serverside sorting
  onServerSideFilterChanged?: (filterModel: PirumFilterModel) => void; // used for fake serverside filtering // TODO restrict filterModel type
  onGridRestored?: (params: CustomEventParams) => void; // custom Pirum event
}

export interface PirumColumnApi
  extends Omit<
    ColumnApi,
    | "getAllGridColumns"
    | "getAllDisplayedColumns"
    | "getAllDisplayedVirtualColumns"
  > {
  getAllGridColumns: () => PirumColumn[];
  getAllDisplayedColumns: () => PirumColumn[];
  getAllDisplayedVirtualColumns: () => PirumColumn[];
}

interface AgGridReactProps {
  api: GridApi;
  columnApi: ColumnApi;
  gridOptions: PirumGridOptions;
  props: PirumAgGridProps;
}

export interface PirumColumn extends Omit<Column, "getColDef"> {
  getColDef(): PirumColDef;
}

export interface HeaderData {
  title?: string;
  subtitle?: string;
}

export interface PirumColDef
  extends Omit<ColDef, "headerName" | "colSpan">,
    Omit<ColGroupDef, "headerName" | "children"> {
  styleKey?: StyleKey; // key defining set of colDef props (mostly used by BE)
  widthKey?: WidthKey; // key defining width (mostly used by BE)
  tooltip?: string;
  headerTooltipMessage?: string;
  headerName?: HeaderData | string;
  headerData?: HeaderData;
  children?: PirumColDef[]; // replace for agGrid columnDef type
  alignFlex?: FLEX_ALIGNMENT; // alignment for cell content in whole column
  suppressToolPanel?: boolean;
  merge?: boolean; // used by agGrid colSpan for merging cells between columns
  colSpan?: PirumColSpan;
  filterable?: boolean;
  sortable?: boolean;
}

export interface PirumICellRendererParams
  extends Omit<ICellRendererParams, "colDef"> {
  colDef: PirumColDef;
  agGridReact?: AgGridReactProps;
}

interface PirumAgGridProps extends PirumGridOptions {
  enableMenu?: boolean;
  enableSorting?: boolean;
}

export interface PirumIHeaderParams extends Omit<IHeaderParams, "column"> {
  // colDef: PirumColDef;
  column: PirumColumn;
  agGridReact?: AgGridReactProps;
}

export type ColDefFeatureFn = (
  colDef: PirumColDef,
  columnIndex?: number
) => PirumColDef;

type PirumFilterModel = any; // TODO FE-736

export interface GridState {
  columnState?: ColumnState[];
  sortModel?: ColumnState[];
  filterModel?: PirumFilterModel;
}

interface PirumColSpanParams extends Omit<ColSpanParams, "columnApi"> {
  columnApi: PirumColumnApi;
}

export type PirumColSpan = (params: PirumColSpanParams) => number;

// INoRowsOverlayParams interface should be provided by agGrid but for some reason this type does not include
// api prop but it is listed in doc: https://www.ag-grid.com/react-data-grid/component-overlay/#reference-INoRowsOverlayParams
export interface INoRowsOverlayParams {
  api: GridApi;
}
