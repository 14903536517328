import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getActiveValidClientIds } from "pirumconnect/storage/activeValidClientIds";

import store from "pirumconnect/store";
import {
  CheckboxesSelectedEvents,
  SelectedEvents,
} from "../types";
import { eventActionSelector } from "../selectors";
import { UpdateWorkflowStatusParams } from "repo/matchingManager/types";
import { EventActions } from "repo/models/EventAction";
import { StatusType } from "repo/models/EventStatus";

const newWorkflowStatusMap: Record<EventActions, StatusType> = {
  [EventActions.Issue]: "unmatched",
  [EventActions.Match]: "matched",
  [EventActions.Mismatch]: "mismatched",
  [EventActions.Reject]: "rejected",
  [EventActions.Unmatch]: "unmatched",
  [EventActions.Allege]: "alleged"
};

export const useSelectedEvents = (selectedEvents: SelectedEvents[]) => {
  const [selectedItems, setSelecteditems] = useState<
    CheckboxesSelectedEvents[]
  >(() => selectedEvents.map((event) => ({ ...event, checked: true })));
  const [apiPayload, setApiPayload] = useState<UpdateWorkflowStatusParams>();

  const clientIds = getActiveValidClientIds().map((id) => id.toString());
  const createdBy = store.getState().app.userAccount.userInfo.name || "";
  const eventAction = useSelector(eventActionSelector);

  const onCheckboxesChange = (tradeRef: string) => {
    setSelecteditems(
      selectedItems.map((item) => ({
        ...item,
        checked: item.tradeRef === tradeRef ? !item.checked : item.checked,
      }))
    );
  };

  useEffect(() => {
    setApiPayload({
      queryParams: {
        clientIds,
      },
      createdBy,
      items: selectedItems
        .filter((item) => item.checked)
        .map((item) => ({
          itemRef: item.itemRef,
          currentWorkflowStatus: item.currentStatus,
          newWorkflowStatus: newWorkflowStatusMap[eventAction],
        })),
    });
  }, [selectedItems]);

  const allEventsUnselected = selectedItems.every((item) => !item.checked);

  return {
    allEventsUnselected,
    selectedItems,
    onCheckboxesChange,
    apiPayload,
  };
};
