import ExposureAnalyticsAdapter from './servicesAdapter';
import services from 'services/services';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';

export function fetchExposureCoverage(dispatch, params) {
  const adapter = new ExposureAnalyticsAdapter();
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_COVERAGE, true)(dispatch, { request })
    .then(res => adapter.parseAPIResponse(res));
}

export function fetchExposureCoverageSpreadsheet(dispatch, params) {
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_COVERAGE_SPREADSHEET)(dispatch, { request });
}

export function fetchFxReconciliation(dispatch, params) {
  const adapter = new ExposureAnalyticsAdapter();
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_FX_RECONCILIATION, true)(dispatch, { request })
    .then(res => adapter.parseAPIResponse(res));
}

export function fetchFxReconciliationSpreadsheet(dispatch, params) {
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_FX_RECONCILIATION_SPREADSHEET)(dispatch, { request });
}

export function fetchValuationRisk(dispatch, params) {
  const adapter = new ExposureAnalyticsAdapter();
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_VALUATION_RISK, true)(dispatch, { request })
    .then(res => adapter.parseAPIResponse(res));
}

export function fetchValuationRiskSpreadsheet(dispatch, params) {
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_VALUATION_RISK_SPREADSHEET)(dispatch, { request });
}

export function fetchFxMismatch(dispatch, params) {
  const adapter = new ExposureAnalyticsAdapter();
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_FX_MISMATCH, true)(dispatch, { request })
    .then(res => adapter.parseAPIResponse(res));
}

export function fetchFxMismatchSpreadsheet(dispatch, params) {
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_FX_MISMATCH_SPREADSHEET)(dispatch, { request });
}

export function fetchExposureAnalyticsFilters(dispatch, params) {
  const adapter = new ExposureAnalyticsAdapter();
  const request = ExposureAnalyticsAdapter.prepareRequest(params);
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_FILTERS, true)(dispatch, { request })
    .then(result => adapter.parseAPIResponse(result));
}

export function fetchExposureAnalyticsSettings(dispatch) {
  return services.query(apiNames.EXPOSURE_ANALYTICS, apiOperatonIds.EXPOSURE_ANALYTICS_SETTINGS)(dispatch, {});
}