import styled from 'styled-components/macro';
import { fontSize } from 'styles/vars';
import { PIRUM_GREEN } from 'styles/colors/brandColors';

const Wrapper = styled.div`
  padding: 10px;
  height: 100%;
  position: relative;
  overflow-y: auto;
`;

const TopBar = styled.div`
  font-size: 25px;
  display: flex;
  align-items: center;

  & > svg:first-child {
    margin-right: 10px;
  }
`;

const LogoSVG = styled.svg`
  width: 45px;
  height: 45px;
  margin-right: 10px;
`;

const Terms = styled.div`
  padding: 15px;
  line-height: 1.3;

  & p {
    margin-bottom: 15px;
  }

  & a {
    color: inherit;
  }

  & ul {
    margin-bottom: 15px;
  }

  & li {
    list-style: disc;
    margin: 10px 0px 10px 50px;
  }

  & h3 {
    margin-bottom: 16px;
    color: ${PIRUM_GREEN[100]};
  }
`;

const Heading = styled.h2`
  padding: 10px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${PIRUM_GREEN[100]};
  color: ${PIRUM_GREEN[100]};
  font-size: ${fontSize.L};
  display: block;
`;

const styledComponents = {
  Wrapper,
  TopBar,
  LogoSVG,
  Terms,
  Heading,
};

export default styledComponents;