import { getColorById } from './colorUtils';

const config = {
  periodFieldName: 'period',
  filterDropdown: {
    defaultLabel: 'ALL',
    defaultValue: 'all',
  },
};

//TODO: New Filters only require filterByFields, so this parse filter is redundant after we implement new filters on all pages
function parseFilters(filterByFields) {
  let filterBy = {};
  let filtersSummary = [];

  const filtersList = filterByFields.map(it => {
    const { fieldMetaData:metadata, filterOptions } = it;
    let isAnyOptionSelected = false;
    let selectedLabels = [];
    let selectedValues = [];
    let selectedColor = '';

    const options = filterOptions.map(op => {
      let isSelected = false;

      if(it.selectedFieldValues) {
        isSelected = it.selectedFieldValues.indexOf(op.fieldValue) !== -1;
        filterBy[metadata.fieldName] = it.selectedFieldValues;
      }

      if(isSelected) {
        isAnyOptionSelected = true;
        selectedLabels.push(op.title);
        selectedValues.push(op.fieldValue);
        const cl = getColorById(op.colorKey);
        selectedColor = cl.default ? '' : cl.bg;
      }

      return {
        active: isSelected,
        label: op.title,
        value: op.fieldValue,
        color: getColorById(op.colorKey).bg,
        parent: metadata.fieldName,
      };
    });

    let defaultFilter = [];
    if(!it.isRequired) {
      defaultFilter.push({
        label: config.filterDropdown.defaultLabel,
        value: config.filterDropdown.defaultValue,
        active: !isAnyOptionSelected,
        parent: metadata.fieldName
      });
    }

    const filter = {
      label: metadata.title,
      value: metadata.fieldName,
      description: metadata.description,
      options: [ ...defaultFilter, ...options],
      selected: {
        labels: selectedLabels,
        values: selectedValues,
        color: selectedColor,
      },
      isRequired: it.isRequired,
      isPeriod: metadata.fieldName === config.periodFieldName,
      isMultiSelect: it.isMultiSelect,
    };

    filtersSummary.push({
      color: selectedColor,
      label: metadata.title,
      isRequired: it.isRequired,
      shownValues: selectedLabels,
      value: metadata.fieldName,
    });

    return filter;
  });

  return {
    filtersList,
    filterBy,
    filtersSummary,
    filterByFields,
  };
}

function prepareFiltersRequest (params) {
  if (!params.filterBy) {
    params.filterBy = {};
  }

  const filterBy = Object.keys(params.filterBy).reduce((acc, it) => {
    const val = params.filterBy[it];

    if(val === config.filterDropdown.defaultValue || val === null || typeof val === 'undefined') {
      return acc;
    }

    if(Array.isArray(val)) {
      const filters = val.map(filter => ({
        fieldName: it,
        fieldValue: filter === Object(filter) ? filter.fieldValue : filter, //New style filters is an object. Old style filters expect just value. 
      }));

      return [...acc, ...filters];
    }
    else {
      const current = {
        fieldName: it,
        fieldValue: val
      };

      return [...acc, current];
    }
  }, []);

  return filterBy;

}

export { parseFilters, prepareFiltersRequest, config };
