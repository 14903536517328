import { actionTypes } from './actions';
import { actionTypes as modalActionTypes } from './Modal/actions';
import { Map } from 'immutable';


export const initialState = Map({
  results: {},
  shorts: {},
  inventory: {},
  minValue: 0,
  algorithms: [],
  algorithm: '',
  hypotheticalTable: null,
  enabledTabs: [],
  maxUploadFileSize: 0,
  uploadOverRowLimit: false,
  rqvFilters: [],
  rqvWidgets: [],
  adjustments: [],
});

export const eligibilityAnalytics = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.FETCH_ANALYTICS_SETTINGS_SUCCESS:
      return state.withMutations(s =>
        s.set('minValue', action.payload.minValue)
          .set('algorithms', action.payload.algorithms)
          .set('algorithm', action.payload.algorithms[0].fieldValue)
          .set('enabledTabs', action.payload.enabledTabs)
          .set('maxUploadFileSize', action.payload.maxUploadFileSize)
          .set('maxSelectionsClientVenueCombinations', action.payload.maxSelectionsClientVenueCombinations)
          .set('maxSelectionsAllocations', action.payload.maxSelectionsAllocations)

      );
    case actionTypes.FETCH_ANALYTICS_RESULTS_SUCCESS:
      return state.withMutations(s => s.set('results', action.payload));
    case actionTypes.FETCH_ANALYTICS_SHORTS_SSUCCESS:
      return state.withMutations(s => s.set('shorts', action.payload));
    case actionTypes.FETCH_ANALYTICS_INVENTORY_SUCCESS:
      return state.withMutations(s => s.set('inventory', action.payload));
    case actionTypes.FETCH_ANALYTICS_CUSTOM_INVENTORY_SUCCESS:
      return state.withMutations(s => s.set('inventory', action.payload));
    case actionTypes.CLEAR_INVENTORY_DATA:
      return state.withMutations(s => s.set('inventory', {}));
    case actionTypes.CLEAR_RESULTS:
      return state.withMutations(s => s.set('results', {}));
    case actionTypes.SET_RQV_ADJUSTMENT:
      return state.withMutations(s => s.set('adjustments', action.payload));
    case modalActionTypes.SET_ALGORITHM:
      return state.withMutations(s => s.set('algorithm', action.payload));
    case modalActionTypes.SET_MINVALUE:
      return state.withMutations(s => s.set('minValue', action.payload));
    case modalActionTypes.SET_HYPOTHETICAL_TABLE:
      return state.withMutations(s =>
        s.set('hypotheticalTable', action.payload.table)
          .set('uploadOverRowLimit', action.payload.overRowLimit));
    case modalActionTypes.CLEAR_HYPOTHETICAL_TABLE:
      return state.withMutations(s => s.set('hypotheticalTable', null));
    case modalActionTypes.SET_RQV_FILTERS:
      return state.withMutations(s => s.set('rqvFilters', action.payload.filterByFields));
    case modalActionTypes.SET_RQV_WIDGETS:
      return state.withMutations(s => s.set('rqvWidgets', action.payload));
    default:
      return state;
  }
};
