import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initialiseMainPageOld, destroyMainPage } from 'actions/common/mainPageActions';
import { shouldUseNewSSO } from 'pirumconnect/auth/AuthManager';
import { initialiseMainPageNewSSO } from './actions';
import AppRouting from 'pirumconnect/routing/Loadable';
import ModeSelectPage from 'pirumconnect/auth/selector/Loadable';
import { PIRUM_MODE_SELECT_URL } from 'pirumconnect/storage/constants';
import * as newSSOActions from "pirumconnect/actions";
import TokenStorage from 'pirumconnect/storage/local/tokens';
import StatsSessionStorage from 'pirumconnect/storage/session/stats';
import pirumAnalytics from 'pirumconnect/analytics';
import authManager from 'pirumconnect/auth/AuthManager';

class AppInitialiser extends Component {
  constructor(props) {
    super(props);

    this.shouldInitializeMainPage = this.shouldInitializeMainPage.bind(this);
    this.handleInitialiseMainPageNewSSOAndRedirect = this.handleInitialiseMainPageNewSSOAndRedirect.bind(this);
    this.userStatusHasJustChanged = this.userStatusHasJustChanged.bind(this);
  }
  /**
   * Obtains the initial app data on componentDidMount()
   * in order to handle potential errors here to be displayed to the user.
   */
  componentDidMount() {
    if(shouldUseNewSSO()) {
      this.props.startAuthProcess();
    } else {
      this.props.initialiseMainPageOld();
      this.sendLoginStats(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.shouldInitializeMainPage(prevProps)) {
      this.handleInitialiseMainPageNewSSOAndRedirect();
    }
  }

  /**
   * Check the state the user is in, to decide if we need to initialise the main page.
   *
   * Authentication - (Who you are) The user is still not authenticated via Keycloak, they shouldn't be able to load
   * anything from the main application in this state.
   *
   * Authorization - (What you can do / see) The user will by default be authorized when they are authenticated.
   * This is because Keycloak will provide a JWT with all the required information we need to interact with the AuthService
   * as a normal everyday client.
   *
   * However if the user is an internal Pirum user, e.g. @pirum.com, further authorization steps are included for
   * selecting a user or client via the Demo and Support modes. The authorization step is merely to keep track of the
   * question (do we have enough information to know what to show and allow access to?). In theory Pirum users have
   * access to everything, but in the context of the application, they're provided a scoped access to clients and users.
   *
   * Visually this is discerned into two modes:
   *    Demo mode    - A mode strictly for internal Pirum users where they can impersonate a user on a client
   *    Support mode - A mode strictly for internal Pirum users where they can select a client
   *
   * If the user is not in authorisation mode, its safe to initialise the main page.
   * @returns {boolean}
   */
  shouldInitializeMainPage(prevProps) {
    if(!this.userStatusHasJustChanged(prevProps)) {
      return false;
    }
    const {
      userAuthenticated,
      userAuthorized,
    } = this.props;
    return userAuthenticated && userAuthorized;
  }

  // We want to determine which page to show based on our status
  userStatusHasJustChanged(prevProps) {
    const {
      userAuthorized,
      userAuthenticated,
    } = this.props;
    const userHasJustAuthenticated = userAuthenticated && !prevProps.userAuthenticated;
    const userHasJustAuthorized = userAuthorized && !prevProps.userAuthorized;
    return userHasJustAuthorized || userHasJustAuthenticated;
  }

  async handleInitialiseMainPageNewSSOAndRedirect() {
    await this.props.initialiseMainPageNewSSO();
    if (this.props.location.pathname === PIRUM_MODE_SELECT_URL || authManager.getKeycloakIdCodeFromQuery()) {
      this.props.history.push('/dashboard');
    }
    this.sendLoginStats(true);
  }

  sendLoginStats(newSSO) {
    if (!StatsSessionStorage.isLoginStatSent()) {
      StatsSessionStorage.setLoginStatSendFlag();
      pirumAnalytics.send({componentId:'AppInitialiser', actionName: 'login_completed', newSSO, userImpersonation:TokenStorage.isImpersonationMode()});
    }
  }

  componentWillUnmount() {
    destroyMainPage();
  }

  render() {
    const { appDataInitialised } = this.props;
    // Are we a Pirum user? To complete the full authorization, we need to pick a mode / client / user
    if (TokenStorage.isPirumUser() && TokenStorage.isPirumUserAuthorizationPending()) {
      window.history.pushState({}, 'Mode Select', PIRUM_MODE_SELECT_URL);
      return <ModeSelectPage />;
    }
    if (!appDataInitialised) {
      return null;
    }
    return <AppRouting {...this.props} />;
  }
}

AppInitialiser.propTypes = {
  children: PropTypes.element
};

const mapStateToProps = (state) => {
  const { appDataInitialised } = state.app.init;
  const {
    userAuthenticated,
    userAuthorized,
  } = state.auth;

  return {
    appDataInitialised,
    userAuthenticated,
    userAuthorized,
  };
};
const mapDispatchToProps = {
  initialiseMainPageOld: initialiseMainPageOld,
  initialiseMainPageNewSSO: initialiseMainPageNewSSO,
  startAuthProcess: newSSOActions.startAuthProcess,
};

const wrapper = connect(mapStateToProps, mapDispatchToProps)(AppInitialiser);

export {
  AppInitialiser as UnconnectedAppInitialiser,
  wrapper as AppInitialiser,
};