const fontFamily = {
  REGULAR: 'Open Sans',
  LIGHT: 'Open Sans Light',
  BOLD: 'Open Sans Semi Bold',
  EXTRA_BOLD: 'Open Sans Extra Bold',
};

const fontSize = {
  XXXXS:   '9px',
  XXXS:   '10px',
  XXS:    '11px', 
  XS:     '12px', 
  S:      '13px', 
  M:      '14px', 
  L:      '16px', 
  XL:     '18px', 
  XXL:    '22px', 
  XXXL:   '28px', 
  XXXXL:  '32px', 
  XXXXXL: '40px', 
};

const windowWidthBreakpoints = {
  1280: '1280px',
}

export {
  windowWidthBreakpoints,
  fontFamily,
  fontSize
};
