/***
 * This file represents the idToken object (ID_TOKEN_NAME / IMP_TOKEN_NAME) in local storage.
 * Both are idTokens that are provided by Keycloak. It is assumed that you either
 * have a personal idToken or an impersonation idToken
 * Example (some fields omitted):
    aToken: "12345abcdef67890fedcba"
    businessDate: "2019-06-19"
    clientIds: [1000000]
    0: 1000000
    email: "bob@bobbank.com"
    email_verified: true
    exp: 1560948876
    iat: 1560941677
    iss: "https://api.qaccs03.dc.pirum.com/auth/realms/pirumconnect"
    name: "Bob Bobbank"
    bank_id: "1000000"
    client_ids: ["1000000"]
    mode: "mode-select"
    real_user_id: "12345-abcdef-67890-fedcba"
    pirumMode: "support"
 */

import {
  ID_TOKEN_NAME,
  IMP_TOKEN_NAME,
  PIRUM_CAPABILITIES,
  SSO_SESSION,
  pirumModes,
} from "pirumconnect/storage/constants";

import StorageUtils from 'pirumconnect/storage/local/StorageUtils';

export default class TokenStorage {
  static getIdToken() {
    return StorageUtils.parseToken(ID_TOKEN_NAME);
  }
  static getImpToken() {
    return StorageUtils.parseToken(IMP_TOKEN_NAME);
  }
  static getToken() {
    return TokenStorage.getImpToken() || TokenStorage.getIdToken() || {};
  }
  static setToken(newToken, tokenType) {
    tokenType === IMP_TOKEN_NAME
      ? TokenStorage.setImpToken(newToken)
      : TokenStorage.setIdToken(newToken);
    TokenStorage.setSSOSession();
  }
  static setIdToken(idToken) {
    StorageUtils.setToLocalStore(ID_TOKEN_NAME, idToken);
  }
  static setImpToken(impToken) {
    StorageUtils.setToLocalStore(IMP_TOKEN_NAME, impToken);
  }
  static removeSSOSessionStatus() {
    localStorage.removeItem(SSO_SESSION);
  }
  static removeIdToken() {
    localStorage.removeItem(ID_TOKEN_NAME);
  }
  static removeImpToken() {
    localStorage.removeItem(IMP_TOKEN_NAME);
  }
  static removeAllTokens() {
    TokenStorage.removeIdToken();
    TokenStorage.removeImpToken();
  }
  static setSSOSession() {
    // This persists beyond the token that is provided for login. It allows us to check if the user was previously logged in via an IdP
    StorageUtils.setToLocalStore(SSO_SESSION, {
      idpSession: TokenStorage.getIdpLoginLink() !== '',
      idpLoginLink: TokenStorage.getIdpLoginLink(),
    });
  }
  static getSSOSessionStatus() {
    return StorageUtils.parseToken(SSO_SESSION);
  }
  static isIdpSession() {
    return TokenStorage.getSSOSessionStatus() && TokenStorage.getSSOSessionStatus().idpSession;
  }
  static getIdpLoginLink() {
    const idToken = TokenStorage.getIdToken();
    return (idToken && idToken.identity_provider && idToken.identity_provider.login_link) || '';
  }
  static getUserDetails() {
    return TokenStorage.isImpersonationMode()
      ? { username: TokenStorage.getIdToken().name, impersonatedUsername: TokenStorage.getImpToken().name }
      : { username: TokenStorage.getIdToken().name };
  }
  static isImpersonationMode() {
    return Boolean(TokenStorage.getImpToken());
  }
  static getHalfwayExpiry() {
    // Takes the issued at time and expiry and will calculate the halfway point
    const {iat, exp} = TokenStorage.getToken();
    if (!iat || !exp){
      return -1;
    }
    return (((+exp - +iat) / 2) + +iat) * 1000;
  }
  static hasPassedHalfwayToExpiry() {
    const halfwayExpiry = TokenStorage.getHalfwayExpiry();
    return halfwayExpiry >= 0 && Date.now() > halfwayExpiry;
  }
  static hasTokenExpired() {
    const exp = TokenStorage.getToken();
    return exp && (exp * 1000) < Date.now();
  }
  static isTokenEmpty() {
    return Object.keys(TokenStorage.getToken()).length === 0;
  }
  static isPirumUser() {
    const token = TokenStorage.getIdToken();
    return token !== null
      && token.roles
      && token.roles.includes(PIRUM_CAPABILITIES)
      && token.email.split('@')[1] === 'pirum.com';
  }
  static isPirumUserAuthorized() {
    return TokenStorage.getTokenMode() === pirumModes.SUPPORT || TokenStorage.getTokenMode() === pirumModes.DEMO;
  }
  static isPirumUserAuthorizationPending() {
    return TokenStorage.getTokenMode() === pirumModes.MODE_SELECT;
  }
  static getTokenMode() {
    // Note that the mode is provided by Keycloak by the script mapper 'Pirum Mode'
    // found under 'Client Scopes' in the Keycloak dashboard
    return TokenStorage.getToken().pirum.mode || '';
  }
}