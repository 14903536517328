import * as svc from './services';

export const actionTypes = {
  FETCH_SCHEDULE_VIEWER_SUCCESS: 'ccs/scheduleViewer/FETCH_SCHEDULE_VIEWER_SUCCESS',
  SET_SCHEDULE_VIEWER_FOCUSED_SECTION: 'ccs/scheduleViewer/SET_SCHEDULE_VIEWER_FOCUSED_SECTION',
  TOGGLE_SCHEDULE_VIEWER_DRILLDOWN: 'ccs/scheduleViewer/TOGGLE_SCHEDULE_VIEWER_DRILLDOWN',
};

const fetchScheduleViewerSuccess = payload => ({
  type: actionTypes.FETCH_SCHEDULE_VIEWER_SUCCESS,
  payload,
});

export function fetchScheduleViewer(params) {
  return (dispatch) => {
    return svc.fetchScheduleViewer(dispatch, params)
      .then((payload) => {
        dispatch(fetchScheduleViewerSuccess(payload));
      });
  };
}

export const setFocusedSection = index => ({
  type: actionTypes.SET_SCHEDULE_VIEWER_FOCUSED_SECTION,
  payload: index,
});

export const toggleDrilldown = index => ({
  type: actionTypes.TOGGLE_SCHEDULE_VIEWER_DRILLDOWN,
  payload: index,
});
