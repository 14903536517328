import moment from "moment";
import {
  fNumWithCommas,
  formatFloat,
  formatNumber,
  VALUE_PREFIX_OPTIONS,
} from "utils/dataUtils";
import { FLEX_ALIGNMENT, PirumColDef, StyleKey } from "./types";

/**
 * There are four provided filters that come with the grid. The provided filters are as follows:
 *  - agNumberColumnFilter	A Number Filter for number comparisons.
 *  - agTextColumnFilter	A Text Filter for string comparisons.
 *  - agDateColumnFilter	A Date Filter for date comparisons.
 *  - agSetColumnFilter	A Set Filter, influenced by how filters work in Microsoft Excel. This is an ag-Grid-Enterprise feature.
 */
export enum COLUMN_FILTER {
  agNumberColumnFilter = "agNumberColumnFilter",
  agTextColumnFilter = "agTextColumnFilter",
  agDateColumnFilter = "agDateColumnFilter",
  agSetColumnFilter = "agSetColumnFilter",
  excelSetFilter = "excelSetFilter",
  statusFilter = "statusFilter",
  dateRangeFilter = "dateRangeFilter",
  simpleFilter = "simpleFilter",
  numberRangeFilter = "numberRangeFilter",
}

/***
 * @see Docs: https://pirum.atlassian.net/wiki/spaces/FEC/pages/4486693669/Table+Data+Alignment+Formatting
 */
const styleKeyDefs: Record<StyleKey, Partial<PirumColDef>> = {
  DEFAULT: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: undefined,
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  TEXT: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: undefined,
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  SET_TEXT: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: undefined,
    filter: COLUMN_FILTER.excelSetFilter,
  },
  SET_ARRAY: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: (n) => n.data.cells[n.column.getColId()].value,
    filter: COLUMN_FILTER.excelSetFilter,
  },
  QUANTITY: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: undefined, //up to 5 decimal places; Examples 0.001%, 0.01234%
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  QUANTITY_NO_DECIMAL: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) =>
      formatNumber(n.value, VALUE_PREFIX_OPTIONS.noPrefix, false), //000,000,000
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  VALUE: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) => formatFloat(n.value, 2), //000,000,000.00
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  VALUE_FOUR_DECIMALS: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) => formatFloat(n.value, 4), //000,000,000.0000
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  VALUE_NO_DECIMAL: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) => {
      //000,000,000
      //Usually it is going to be a number as Number or String type.
      if (n.value === "" || n.value === null || isNaN(n.value)) {
        //It also could be a text string, for example: 'N/A'.
        return typeof n.value === "string" ? n.value : ""; //Sets empty string for null or undefined.
      }
      return formatNumber(n.value, VALUE_PREFIX_OPTIONS.noPrefix, false);
    },
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  REGULATORY_VALUE: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: undefined, //000,000,000.01234
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  PRICE: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: undefined, //000,000,000.01234
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  PRICE_SHORT: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) => {
      const numValue = Number(n.value);
      const numValue4dp = numValue.toFixed(4);
      return numValue.toString().length > numValue4dp.length
        ? numValue4dp
        : numValue.toString();
    },
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  PERCENTAGE: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) => {
      const numValue = Number(n.value); //Usually it is going to be a number as Number or String type.
      if (!n.value || isNaN(numValue)) {
        //It also could be a text string, for example: 'N/A'.
        return typeof n.value === "string" ? n.value : ""; //Sets empty string for null or undefined.
      }
      return `${fNumWithCommas(numValue.toFixed(2))}%`; //100,000,000.00%
    },
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  PERCENTAGE_FOUR_DECIMALS: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) => {
      const numValue = Number(n.value); //Usually it is going to be a number as Number or String type.
      if ((n.value !== 0 && !n.value) || isNaN(numValue)) {
        //It also could be a text string, for example: 'N/A'.
        return typeof n.value === "string" ? n.value : ""; //Sets empty string for null or undefined.
      }
      return `${fNumWithCommas(numValue.toFixed(4))}%`; //100,000,000.00%
    },
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  FEE: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: undefined, //up to 10 decimal places; Examples 0.001%, 0.0123456789%
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  FX_RATE: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: (n) => {
      //FX_RATE with 6 decimals
      //Usually it is going to be a number as Number or String type.
      if (n.value === "" || n.value === null || isNaN(n.value)) {
        //It also could be a text string, for example: 'N/A'.
        return typeof n.value === "string" ? n.value : ""; //Sets empty string for null or undefined.
      }
      return Number(n.value).toFixed(6);
    },
    filter: COLUMN_FILTER.agNumberColumnFilter,
  },
  PERIOD_DATE: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: undefined, //Dates (period)	Left	Example: Wed 8-May; Plus year if calendar year Example: Wed 8-May-19
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  FULL_DATE: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: (d) => {
      const isNotValidValueWithHypens = d.value
        ? isNaN(+String(d.value).replace(/-/g, ""))
        : true;
      return (isNotValidValueWithHypens && isNaN(d.value)) || d.value === null
        ? ""
        : moment(d.value).format("DD-MMM-YY");
    }, // Example: 27-Oct-20
    filter: COLUMN_FILTER.agDateColumnFilter,
    comparator: (filterValue, numValue) => {
      // Hours must be reset, epochs will report as being from the day before due to timezone checks, this puts dates on a baseline
      const epochFilter = moment(filterValue).hour(0);
      const epochNumValue = moment(numValue).hour(0);
      // We compare in units of days, no interest in time
      return epochNumValue.diff(epochFilter, "days");
    },
  },
  FULL_DATE_TIME: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: (d) => d?.data?.created?.value || "",
    filter: COLUMN_FILTER.agDateColumnFilter,
    comparator: (filterValue, numValue) => {
      const epochFilter = moment(filterValue);
      const epochNumValue = moment(numValue);
      // We compare in units of days since we need the filter to act at that level (date === 5/11/2020 would only return matches if the action took place at 00:00 time)
      return epochNumValue.diff(epochFilter, "days");
    },
  },
  DATE: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: (d) =>
      isNaN(d.value) ? "" : moment(d.value).format("YYYY-MM-DD"), //Since determining locale on the client-side is unreliable the alternative common format for UK and US is going to be used.
    filter: COLUMN_FILTER.agDateColumnFilter,
    comparator: (filterValue, numValue) => {
      // Hours must be reset, epochs will report as being from the day before due to timezone checks, this puts dates on a baseline
      const epochFilter = moment(filterValue).hour(0);
      const epochNumValue = moment(numValue).hour(0);
      // We compare in units of days, no interest in time
      return epochNumValue.diff(epochFilter, "days");
    },
  },
  DATETIME: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: undefined, //Example: Wed 8-May 07:44 BST
    filter: COLUMN_FILTER.agDateColumnFilter,
  },
  CURRENCY: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: undefined, //Text CAPS the server should always send ISO3 Currency (and ISO2 country) - server alredy returns it formated. So no formatter is needed.
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  BREAK: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: undefined,
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  BOLD: {
    alignFlex: FLEX_ALIGNMENT.Left,
    valueFormatter: undefined,
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  BOLD_BREAK: {
    alignFlex: FLEX_ALIGNMENT.Right,
    valueFormatter: undefined,
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  //TODO: remove when backend has changed STYLE_BREAK to BREAK
  STYLE_BREAK: {
    valueFormatter: undefined,
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  STYLE_BOLD: {
    valueFormatter: undefined,
    filter: COLUMN_FILTER.agTextColumnFilter,
  },
  STATUS_FILTER: {
    filter: COLUMN_FILTER.statusFilter,
  },
};

export function getConfig(styleKey?: StyleKey) {
  if (styleKey) {
    return styleKeyDefs[styleKey] || styleKeyDefs.DEFAULT;
  }
  return styleKeyDefs.DEFAULT;
}

export default styleKeyDefs;
