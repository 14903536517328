import React, { useEffect, useRef } from "react";
import * as styledComponents from "./styles";
import { useHistory } from "react-router";
import { UnregisterCallback } from "history";
import { CSSTransition, SwitchTransition } from "react-transition-group";

interface WorkflowModalProps<StatusEnum extends string> {
  workflowStages: Record<StatusEnum, JSX.Element | null>;
  status: StatusEnum;
  modalOpen: boolean;
  shouldShowCross: boolean | ((status: StatusEnum) => boolean);
  exitModal(): void;
  animationOptions?: {
    mainDuration?: number;
    stageDuration?: number;
  };
}

/**
 * Fullscreen Workflow Modal
 *
 * This workflow modal controls animation and style fo the full component
 * This component is a generic and as such requires type specification when used.
 *
 * <WorkflowModal<Enum> />
 *
 */
export const WorkflowModal = <StatusEnum extends string>({
  workflowStages,
  status,
  modalOpen,
  shouldShowCross,
  exitModal,
  animationOptions,
}: WorkflowModalProps<StatusEnum>): JSX.Element => {
  // Block fwd/back navigation while modal is open.
  const unblockHandle = useRef<UnregisterCallback>();
  const history = useHistory();

  useEffect(() => {
    unblockHandle.current = modalOpen ? history.block() : undefined;
    return unblockHandle.current;
  }, [modalOpen]);

  const showCross =
    typeof shouldShowCross === "function"
      ? shouldShowCross(status)
      : shouldShowCross;

  return (
    <CSSTransition
      in={modalOpen}
      timeout={
        animationOptions?.mainDuration ?? styledComponents.transition.duration
      }
      unmountOnExit
      classNames={styledComponents.transition.className}
    >
      <styledComponents.Container>
        {showCross ? (
          <styledComponents.CloseIcon onClick={exitModal} role="Close" />
        ) : null}
        <SwitchTransition mode={"out-in"}>
          <CSSTransition
            key={status}
            timeout={
              animationOptions?.stageDuration ??
              styledComponents.slideInTransition.duration
            }
            unmountOnExit
            mountOnEnter
            classNames={styledComponents.slideInTransition.className}
          >
            <styledComponents.SlideInContainer>
              {workflowStages[status]}
            </styledComponents.SlideInContainer>
          </CSSTransition>
        </SwitchTransition>
      </styledComponents.Container>
    </CSSTransition>
  );
};
