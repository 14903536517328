import { parseFilters, prepareFiltersRequest, config as parserConfig } from 'utils/parseUtils';
import { getColorById } from 'utils/colorUtils';


const config = {
  dropdown: {
    defaultLabel: parserConfig.filterDropdown.defaultLabel,
    defaultValue: parserConfig.filterDropdown.defaultValue,
  },
  groupByDropdown: {
    defaultValue: 'none',
  },
};


class CollateralGrade {
  parseColors(groups) {
    return groups.reduce((acc, datum, index) => {
      datum.bars.forEach((bar) => {
        bar.elements.forEach((group) => {
          acc[group.name] = getColorById(group.colorKey).bg;
        });
      });
      return acc;
    }, {});
  }

  parseContext(context) {
    return context.reduce((result, field) => {result[field.fieldName] = field.fieldValue; return result;}, {});
  }

  parseAPIResponse(response) {
    const {valueType, valueTypeFields = [], buttonFilters} = response;
    const groupBy = [ response.groupBy1, response.groupBy2 || config.groupByDropdown.defaultValue ];


    const groups = !response.groups ? [] : response.groups.map(it => {
      const context = this.parseContext(it.context);
      const bars = it.bars.map(bar => {
        const elements = bar.elements.map(element => {
          return {
            ...element,
            context: this.parseContext(element.context)
          };
        });

        return {
          ...bar,
          elements
        };
      });

      return {
        ...it,
        bars,
        context,
        uniqueId: JSON.stringify(context),
      };
    });

    const colors = this.parseColors(groups);

    const valueTypeFieldsList = valueTypeFields.map((option) => {
      return {
        label: option.title,
        value: option.fieldName,
      };
    });

    return {
      ...parseFilters(response.filterByFields),
      groups,
      title: response.title,
      groupBy,
      colors,
      valueType,
      valueTypeFields: valueTypeFieldsList,
      buttonFilters,
      hasDrilldown: response.hasDrilldown,
      groupingFields: response.groupingFields,
      excelFxReportDownloadEnabled: response.excelFxReportDownloadEnabled,
    };
  }

  static prepareRequest(params) {
    if (!params.filterBy) {
      params.filterBy = {};
    }
    if (!params.groupBy) {
      params.groupBy = [];
    }

    const filterBy = prepareFiltersRequest(params);

    const groupBy = params.groupBy.filter(it => it !== config.groupByDropdown.defaultValue);

    const request = {
      groupBy,
      filterBy,
      valueType: params.valueType,
    };

    return request;
  }
}

export default CollateralGrade;
