import {  QUERY_PARAMS  } from "pirumconnect/storage/constants";

import StorageUtils from "pirumconnect/storage/local/StorageUtils";

export default class QueryParams {
  static setQueryParams(params) {
    StorageUtils.setToLocalStore(QUERY_PARAMS, params);
  }
  static getQueryParams() {
    return StorageUtils.parseToken(QUERY_PARAMS) || {clientIds:[], selectAllClientIdsOnInit: true};
  }
  static removeQueryParams() {
    try {
      localStorage.removeItem(QUERY_PARAMS);
    } catch(error) {
    }
  }
  static getRequiredQueryParams(paramsList) {
    return StorageUtils.getStorageArgs(QueryParams.getQueryParams(), paramsList);
  }
}