import {
  COMMENT_PERMISSION,
  ACTION_PERMISSION,
  PIRUM_USER_PERMISSION,
} from "./constants";
import { addValueGetterToColumnDefs } from "common/tables/Grid/GridUtils";
import { LimitOffsetPagination } from "common/types/pagination";
import { Filter, FilterBy } from "repo/common/types";
import { StatusType } from "repo/models/EventStatus";
import { createSelector } from "reselect";
import { AppState } from "../types";
import { FilterModel, RepoFeatures, RepoPermissions } from "./types";
import {
  matchingManagerTableApiId,
  updateWorkflowStatusApiId,
} from "./MatchingManagerPage/apiIds";
import { ResponseError } from "common/types/APIResponses";
import {
  DetailsTableData,
  MatchingManagerTable,
  TableSortModel,
  WorkflowStatusCount,
} from "./types";
import moment from "moment";
import styleKeyConfig from "repo/matchingManager/styleKeyConfig";
import { addStyleKeysToColumnDefs } from 'repo/matchingManager/helpers';

const matchingManagerDataSelector = (state: AppState): MatchingManagerTable =>
  state.repo.matchingManager.get("table");
export const paginationSelector = (state: AppState): LimitOffsetPagination =>
  state.repo.matchingManager.get("pagination");
export const matchingManagerTableLoadingSelector = (state: AppState): boolean =>
  Boolean(state.app.api.pending[matchingManagerTableApiId]);
export const workflowStatusCountsSelector = (
  state: AppState
): WorkflowStatusCount[] =>
  state.repo.matchingManager.get("workflowStatusCounts");
export const activeStatusFiltersSelector = (state: AppState): StatusType[] =>
  state.repo.matchingManager.get("activeStatusFilters");
export const filterByFieldsSelector = (state: AppState): Filter[] =>
  state.app.commonFilters.get("filterByFields");
export const filterBySelector = (state: AppState): FilterBy =>
  state.app.commonFilters.get("filterBy");
export const isSubscribedSelector = (state: AppState): boolean =>
  state.repo.matchingManager.get("isSubscribed");
export const repoPermissionsSelector = (state: AppState): RepoPermissions[] =>
  state.repo.matchingManager.get("repoPermission");
export const detailsErrorSelector = (state: AppState): ResponseError =>
  state.repo.matchingManager.get("detailsError");
export const tableSortModelSelector = (state: AppState): TableSortModel =>
  state.repo.matchingManager.get("tableSortModel");
export const featuresSelector = (state: AppState): RepoFeatures =>
  state.repo.matchingManager.get("features");
export const updateWorkflowStatusLoadingSelector = (state: AppState): boolean =>
  Boolean(state.app.api.pending[updateWorkflowStatusApiId]);
export const updateWorkflowStatusSuccessSelector = (state: AppState): boolean =>
  state.repo.matchingManager.get("updateWorkflowStatusSuccess");
export const updateWorkflowStatusErrorSelector = (state: AppState): boolean =>
  state.repo.matchingManager.get("updateWorkflowStatusError");
  export const filterModelSelector = (state: AppState): FilterModel => state.repo.matchingManager.get("filterModel");

export const tableDataSelector = createSelector(
  matchingManagerDataSelector,
  formatTableData
);

export const userCanCommentSelector = createSelector(
  repoPermissionsSelector,
  (repoPermissions: RepoPermissions[]) => {
    if (!Array.isArray(repoPermissions)) {
      return false;
    }

    // if is a Pirum user, don't bother checking for the comment permission
    if (repoPermissions.includes(PIRUM_USER_PERMISSION)) {
      return true;
    }

    return repoPermissions.includes(COMMENT_PERMISSION);
  }
);

export const userCanActionSelector = createSelector(
  repoPermissionsSelector,
  (repoPermissions: RepoPermissions[]) => {
    if (!Array.isArray(repoPermissions)) {
      return false;
    }

    // if is a Pirum user, don't bother checking for the action permission
    if (repoPermissions.includes(PIRUM_USER_PERMISSION)) {
      return true;
    }

    return repoPermissions.includes(ACTION_PERMISSION);
  }
);

export const isCurrentPeriodSelector = createSelector(
  filterBySelector,
  (filterByData: FilterBy) => {
    if (!Array.isArray(filterByData?.period)) {
      return false;
    }

    const [currentPeriod = moment()] = filterByData.period;

    return moment(currentPeriod).isSame(moment(), "day");
  }
);

function formatTableData(data?: DetailsTableData) {
  if (!data) {
    return data;
  }

  const { rows, columnDefs, ...rest } = data;

  const columnDefsWithStyleKeys = addStyleKeysToColumnDefs(columnDefs, styleKeyConfig);
  const columnDefsWithValueGetter = addValueGetterToColumnDefs(columnDefsWithStyleKeys);

  return {
    ...rest,
    rowData: rows,
    columnDefs: columnDefsWithValueGetter,
  };
}
