import { Map } from 'immutable';
import { actionTypes } from './actions';

export const initialState = Map({
  tradesTable: {},
  schedulesTable: {},
  links: [],
});

export const linkingTool = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SCHEDULES_SUCCESS:
      return state.withMutations(s => s.set('schedulesTable', action.payload.table));
    case actionTypes.FETCH_TRADES_SUCCESS:
      return state.withMutations(s => s.set('tradesTable', action.payload.table));
    case actionTypes.FETCH_LINKS_SUCCESS:
      return state.withMutations(s => s.set('links', action.payload.links));
    case actionTypes.CREATE_LINKS_SUCCESS:
      return state.withMutations(s => s.set('links', action.payload.links));
    case actionTypes.DELETE_LINKS_SUCCESS:
      return state.withMutations(s => s.set('links', action.payload.links));
    default:
      return state;
  }
};
