import { types } from '../actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames, apiOperatonIds } from 'constants/api/misApi';
import { Map } from 'immutable';


const FETCH_SBLREX_BREAKS_COUNTERPARTY_FAIL = getFetchFailActionByApi(apiNames.DASHBOARD, apiOperatonIds.BREAKS_COUNTERPARTY);

export const initialState = Map({
  dataSet: {},
  reasonBand: [],
  emptyData: true,
});

export const breaksCounterparty = (state = initialState, action) => {

  switch (action.type) {
    case types.FETCH_SBLREX_BREAKS_COUNTERPARTY_SUCCESS:
      const { payload } = action;
      return state.withMutations(s =>
        s.set('dataSet', payload.dataSet)
          .set('reasonBand', payload.reasonBand)
          .set('emptyData', payload.emptyData));

    case FETCH_SBLREX_BREAKS_COUNTERPARTY_FAIL:
      return initialState;

    default:
      return state;
  }
};
