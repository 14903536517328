import services from 'services/services';
import {apiNames, apiOperatonIds} from 'constants/api/queryApi';
import { prepareFiltersRequest } from 'utils/parseUtils';
import SunburstAdapter from './servicesAdapter';


export function fetchSunburst(dispatch, params, apiName) {
  const request = SunburstAdapter.prepareRequest(params);
  return services.query(apiName)(dispatch, { request })
    .then(result => {
      const adapter = new SunburstAdapter();
      return adapter.parseData({ ...result });
    });
}

export function fetchSunburstSecuritiesDetail(dispatch, params, apiName) {
  const request = { filterBy: prepareFiltersRequest(params) };  
  const apiOperatonId = apiName===apiNames.ALLOCATION_SUNBURST 
    ? apiOperatonIds.ALLOCATION_SECURITY_DETAIL
    : apiOperatonIds.INVENTORY_SECURITY_DETAIL;
  return services.query(apiName, apiOperatonId)(dispatch, { request })
    .then(result => {
      return result;
    });
}

export function fetchSunburstFilters(dispatch, params, apiName) {
  const adapter = new SunburstAdapter();
  const request = SunburstAdapter.prepareRequest(params);
  const apiOperatonId = apiName===apiNames.ALLOCATION_SUNBURST 
    ? apiOperatonIds.ALLOCATION_SECURITY_FILTERS
    : apiOperatonIds.INVENTORY_SECURITY_FILTERS;
  return services.query(apiName, apiOperatonId)(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}

export function fetchSecuritiesSpreadsheet(dispatch, params, apiName) {
  const request = SunburstAdapter.prepareRequest(params);
  const operationId = apiName===apiNames.ALLOCATION_SUNBURST 
    ? apiOperatonIds.ALLOCATION_SECURITY_SPREADSHEET
    : apiOperatonIds.INVENTORY_SECURITY_SPREADSHEET;

  return services.query(apiName, operationId)(dispatch, { request })
    .then(result => result);
}
