import * as svc from './services';
import { isAPIDateToday } from 'utils/helpers';
import { setFilters } from 'actions/common/filtersActions';


export const actionTypes = {
  FETCH_VENUE_DETAIL_SUCCESS: 'ccs/venueDetail/FETCH_VENUE_DETAIL_SUCCESS',
  SET_VENUE_DETAIL_FILTER_PANE: 'ccs/venueDetail/SET_VENUE_DETAIL_FILTER_PANE',
  SET_VENUE_DETAIL_FILTER_HOVER: 'ccs/venueDetail/SET_VENUE_DETAIL_FILTER_HOVER',
};

/**
 *
 * Sync actions
 *
 */
const fetchVenueDetailSuccess = payload => ({
  type: actionTypes.FETCH_VENUE_DETAIL_SUCCESS,
  payload
});

export const setFilterPane = bool => ({
  type: actionTypes.SET_VENUE_DETAIL_FILTER_PANE,
  payload: bool
});

export const storeVenueDetailState = (params) => {
  if (params.filterBy && params.filterBy.period && isAPIDateToday(params.filterBy.period)) {
    delete params.filterBy.period;
  }
  svc.storeVenueDetailState(params);
};

export const setFilterHover = bool => ({
  type: actionTypes.SET_VENUE_DETAIL_FILTER_HOVER,
  payload: bool
});



/**
 *
 * Async actions
 *
 */

// type values are 'borrower' or 'lender'
export function fetchVenueDetail(params, clearPrevious = true) {
  return (dispatch, getState) => {
    const partyIds = getState().app.userAccount.selectedCounterPartyIds;

    return svc.fetchVenueDetail(dispatch, {...params, partyIds}) // partyIds
      .then((payload) => {
        dispatch(fetchVenueDetailSuccess(payload));
        dispatch(setFilters(payload, clearPrevious));
        if (params){
          storeVenueDetailState(params);
        }
      });
  };
}

export function fetchVenueDetailFilters(params) {
  return (dispatch) => {
    return svc.fetchVenueDetailFilters(dispatch, params);
  };
}

export const fetchVenueDetailFromStorage = () => {
  return (dispatch) => {
    const params = svc.getVenueDetailState(['filterBy', 'groupBy']);
    return dispatch(fetchVenueDetail(params));
  };
};
