import { parseFilters, prepareFiltersRequest } from 'utils/parseUtils';


export default class EligibilityCheckerAdapter {
  parseAPIResponse(result) {
    return {
      ...result,
      ...parseFilters(result.filterByFields),
  	};
  }
  static prepareRequest(params) {

    const filterBy = prepareFiltersRequest(params);

    const searchIds = typeof params.searchIds !== 'string' ? params.searchIds :
      params.searchIds.split(',').filter((id) => id !== '').map((id) => {
        return id.trim();
      });

    const request = {
      searchIds,
      filterBy,
    };

    return request;
  }
}
