export const actionTypes = {
  SET_SELECTED_COUNTERPARTY: "SET_SELECTED_COUNTERPARTY",
  SET_SELECTED_PERIOD: "SET_SELECTED_PERIOD",
  SET_PERIOD_FILTER_OPTIONS: "SET_PERIOD_FILTER_OPTIONS",
  SET_COUNTERPARTY_FILTER_OPTIONS: "SET_COUNTERPARTY_FILTER_OPTIONS",
  CLEAR_COMMON_REDUCER: "CLEAR_COMMON_REDUCER",
};

/** @param {string} counterparty */
export const setSelectedCounterparty = (counterparty) => ({
  type: actionTypes.SET_SELECTED_COUNTERPARTY,
  payload: counterparty,
});

/** @param {string} period */
export const setSelectedPeriod = (period) => ({
  type: actionTypes.SET_SELECTED_PERIOD,
  payload: period,
});

export const setPeriodFilterOptions = (options) => ({
  type: actionTypes.SET_PERIOD_FILTER_OPTIONS,
  payload: options,
});

export const setCounterpartyFilterOptions = (options) => ({
  type: actionTypes.SET_COUNTERPARTY_FILTER_OPTIONS,
  payload: options,
});

export const clearCommonReducer = (options) => ({
  type: actionTypes.CLEAR_COMMON_REDUCER,
  payload: options,
});
