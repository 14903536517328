const apiNames = {
  DASHBOARD: 'dashboard',
};

const apiOperatonIds = {
  BREAKS_LATEST: 'breaksLatest',
  BREAKS_DAILY: 'breaksDaily',
  BREAKS_COUNTERPARTY: 'breaksCounterparty',
};

export {apiNames, apiOperatonIds};
