import { Map } from 'immutable';
import { actionTypes } from './actions';

export const initialState = Map({
  table1: {},
  table2: {},
  table3: {},
  context: [],
});

export const eligibilityLimit = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.FETCH_LIMIT_RECONCILIATION_SUCCESS:
      return state.withMutations(s => {
        s.set('table1', action.payload.table1)
          .set('table2', action.payload.table2)
          .set('table3', action.payload.table3);
      });

    case actionTypes.SET_TABLE_CONTEXT:
      return state.withMutations(s => {
        s.set('context', action.payload);
      });

    default:
      return state;
  }
};
