const LOGIN_STATS_SENT_FLAG = 'loginStatsSentFlag';

export default class Stats {
  static isLoginStatSent() {  
    return sessionStorage.getItem(LOGIN_STATS_SENT_FLAG) === 'true';
  }

  static setLoginStatSendFlag() {
    sessionStorage.setItem(LOGIN_STATS_SENT_FLAG, true);
  }
}