const apiNames = {
  RULE_EXPR_CONFIG : 'RuleExprConfig',
  CONFIG_FILTER : 'ConfigFilter',
  CONFIG_MODAL_VIEW: 'ConfigModalView',
  CONFIG_AUDIT: 'ConfigAuditView',
  CONFIG_ACTION_VIEW: 'configAction',
  RELEASE_VIEW: 'releaseView',
  RULE_EXPR_STATIC_CONFIG: 'RuleExprStaticConfig',
};

const apiOperationIds = {
  CONFIG_DETAILS: 'configDetails',
  CONFIG_OVERVIEW: 'configOverview',
  CONFIG_FILTERS: 'configFilters',
  CONFIG_MODAL: 'configModal',
  CONFIG_AUDIT: 'configAudit',
  CONFIG_APPROVE: 'configApprove',
  CONFIG_REJECT: 'configReject',
  CONFIG_RELEASE: 'configRelease',
  STATIC_CONFIG_SPREADSHEET: 'staticConfigSpreadsheet',
};

export {
  apiNames,
  apiOperationIds,
};