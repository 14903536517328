import storage from '../../pirumconnect/storage/session';

const config = {
  namespace: 'CommonFilters'
};

export function storeSelectedCommonFilterState(state) {
  clearSelectedCommonFilterState();
  Object.keys(state).forEach(key => storage.setItem(config.namespace, key, state[key]));
}

export function getSelectedCommonFilterState(keys = []) {
  const storedValues = keys.reduce((acc, key) => {
  	acc[key] = storage.getItem(config.namespace, key);
  	return acc;
  }, {});
  return storedValues;
}

export function clearSelectedCommonFilterState() {
  storage.clear(config.namespace);
}
