import { Map } from 'immutable';
import { actionTypes } from "./actions";

const initialState = Map({
  table: null,
  filterByFields: [],
  canCreateProfile: false,
  form: null,
  profileSummary: [],
  ruleSummaries: [],
  margins: {},
  rule: null,
  clientId: null,
  error: null,
  ruleTypes: [],
  profileTypes: [],
});

const scheduleManager = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.FETCH_SCHEDULE_MANAGER_SUCCESS:
      return state
        .set("table", payload.table)
        .set("filterByFields", payload.filterByFields)
        .set("canCreateProfile", payload.canCreateProfile)
        .set("error", null);
    case actionTypes.FETCH_SCHEDULE_TYPES_SUCCESS:
      return state
        .set("profileTypes", payload.profileTypes);
    case actionTypes.FETCH_SCHEDULE_BUILDER_FORM_SUCCESS:
      return state
        .set("form", payload.form)
        .set("error", null);
    case actionTypes.CLEAR_SCHEDULE_BUILDER_FORM:
      return state
        .set("form", null);
    case actionTypes.SAVE_SCHEDULE_BUILDER_FORM_ERROR:
      return state
        .set("error", payload);
    case actionTypes.FETCH_PROFILE_SUMMARY_SUCCESS:
      return state
        .set("profileSummary", payload.profileSummary)
        .set("clientId", payload.clientId)
        .set("margins", payload.margins);
    case actionTypes.FETCH_RULE_SUMMARIES_SUCCESS:
      return state
        .set("ruleSummaries", payload.ruleSummaries);
    case actionTypes.CLEAR_RULE_SUMMARIES:
      return state
        .set("ruleSummaries", []);
    case actionTypes.FETCH_RULE_SUCCESS:
      return state
        .set("rule", {
          id: payload.ruleId,
          data: payload.rule,
        });
    case actionTypes.FETCH_BASE_RULE_TYPES_SUCCESS:
      return state
        .set("ruleTypes", payload.ruleTypes);
    case actionTypes.FETCH_LIMIT_TYPES_SUCCESS:
      return state
        .set("limitTypes", payload.types);
    default:
      return state;
  }
};

export default scheduleManager;