import { actionTypes } from './actions';
import { getFetchFailActionByApi } from 'utils/apiUtils';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';
import { Map, Set, fromJS } from 'immutable';


const FETCH_COLLATERAL_GRADE_FAIL = getFetchFailActionByApi(apiNames.COLLATERAL_GRADE);
const FETCH_COLLATERAL_GRADE_COMPARISON_FAIL = getFetchFailActionByApi(apiNames.COLLATERAL_GRADE, apiOperatonIds.COLLATERAL_GRADE_COMPARISON);



export const initialState = Map({
  groups: [],
  groupBy: [],
  groupingFields: [],
  colors:[],
  activeKeys: Set(),
  sortBy: 'total_desc',
  showTrade: true,
  showCollateral: true,
  valueType: undefined,
  valueTypeFields: [],
  buttonFilters: [],
  hasDrilldown: false,
  excelFxReportDownloadEnabled: false,
});

export const collateralGrade = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COLLATERAL_GRADE_SUCCESS:
      const { buttonFilters } = action.payload;
      // Active keys should only contain current keys,
      // i.e. Active keys need to be a subset of current keys
      const activeKeys = state.get('activeKeys').filter(activeKey => {
        return buttonFilters.filter(buttonFilter => buttonFilter.name === activeKey).length > 0;
      });
      return state.withMutations(s =>
        s.set('groups', action.payload.groups)
          .set('groupBy', action.payload.groupBy)
          .set('groupingFields', action.payload.groupingFields)
          .set('colors', action.payload.colors)
          .set('activeKeys', activeKeys)
          .set('valueType', action.payload.valueType)
          .set('valueTypeFields', action.payload.valueTypeFields)
          .set('hasDrilldown', action.payload.hasDrilldown)
          .set('buttonFilters', buttonFilters)
          .set('excelFxReportDownloadEnabled', action.payload.excelFxReportDownloadEnabled));

    case actionTypes.TOGGLE_COLLATERAL_GRADE_ACTIVE_KEYS:
      return state.withMutations(s =>
        s.update('activeKeys', value => {
          let union = value.union(action.payload);
          if (union.size > value.size) {
            return union;
          } else {
            return value.subtract(action.payload);
          }
        })
      );

    case actionTypes.RESET_COLLATERAL_GRADE_ACTIVE_KEYS:
      return state.withMutations(s =>
        s.set('activeKeys', initialState.get('activeKeys')));

    case actionTypes.SET_COLLATERAL_GRADE_SORTBY:
      return state.withMutations(s =>
        s.set('sortBy', fromJS(action.payload)));

    case actionTypes.TOGGLE_COLLATERAL_GRADE_SHOW_TRADE:
      return state.withMutations(s => {
        //If both are false after toggling
        if (s.get('showTrade') && !s.get('showCollateral'))
        {
          s.set('showCollateral', true);
        }
        else {
          s.set('showTrade', fromJS(action.payload));
        }
      });

    case actionTypes.TOGGLE_COLLATERAL_GRADE_SHOW_COLLATERAL:
      return state.withMutations(s => {
        //If both are false after toggling
        if (s.get('showCollateral') && !s.get('showTrade'))
        {
          s.set('showTrade', true);
        }
        else {
          s.set('showCollateral', fromJS(action.payload));
        }
      });

    case FETCH_COLLATERAL_GRADE_FAIL:
    case FETCH_COLLATERAL_GRADE_COMPARISON_FAIL:
      return initialState;

    default:
      return state;
  }
};
