import { combineReducers } from "redux";

import { breaks } from './dashboard/reducers';
import { breaksTrend } from './breaks/reducers/breaksTrendReducer';
import { breaksCounterparty } from './breaks/reducers/breaksCounterpartyReducer';


export default combineReducers({
  breaks,
  breaksTrend,
  breaksCounterparty,
});
