import services from 'services/services';
import { apiNames, apiOperationIds } from 'constants/api/ruleExprApi';

export function fetchConfigList(dispatch, params) {
  return services.ruleExpr(apiNames.RULE_EXPR_CONFIG, apiOperationIds.CONFIG_DETAILS)(dispatch, { request: params });
}

export function fetchFilters(dispatch, params) {
  return services.ruleExpr(apiNames.CONFIG_FILTER, apiOperationIds.CONFIG_FILTERS)(dispatch, { request: params });
}

export function confirmApprove(dispatch, params) {
  return services.ruleExpr(apiNames.CONFIG_ACTION_VIEW, apiOperationIds.CONFIG_APPROVE)(dispatch, { request: params });
}

export function confirmRelease(dispatch, params) {
  return services.ruleExpr(apiNames.RELEASE_VIEW, apiOperationIds.CONFIG_RELEASE)(dispatch, { request: params });
}

export function confirmReject(dispatch, params) {
  return services.ruleExpr(apiNames.CONFIG_ACTION_VIEW, apiOperationIds.CONFIG_REJECT)(dispatch, { request: params });
}


export function fetchAdditionalDetail(dispatch, params) {
  return services.ruleExpr(apiNames.CONFIG_MODAL_VIEW, apiOperationIds.CONFIG_MODAL)(dispatch, { request: params });
}

export function fetchAuditList(dispatch, params) {
  return services.ruleExpr(apiNames.CONFIG_AUDIT, apiOperationIds.CONFIG_AUDIT)(dispatch, { request: params });
}

export function fetchOverviewList(dispatch, params) {
  return services.ruleExpr(apiNames.RULE_EXPR_CONFIG, apiOperationIds.CONFIG_OVERVIEW)(dispatch, { request: params });
}

export function downloadExcel(dispatch, params) {
  return services.ruleExpr(apiNames.RULE_EXPR_STATIC_CONFIG, apiOperationIds.STATIC_CONFIG_SPREADSHEET, true)(dispatch, { request: params });
}