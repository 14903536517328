import { getVenueSummaryColorById } from 'utils/colorUtils';
import { parseFilters, prepareFiltersRequest, config as parserConfig } from 'utils/parseUtils';

const config = {
  keys: ['covered', 'uncovered', 'overCovered', 'longBox', 'lockUpBox', 'rehypeBox'],
  dropdown: {
    defaultLabel: parserConfig.filterDropdown.defaultLabel,
    defaultValue: parserConfig.filterDropdown.defaultValue,
  },
  groupByDropdown: {
    defaultValue: 'none'
  },
};

class VenueDetailAdapter {

  definePerspectives(apiResponse) {
    const active = apiResponse.perspective;

    const perspectives = apiResponse.perspectiveFields.map(it => ({
      label: it.title,
      value: it.fieldName,
      active: it.fieldName === active
    }));

    return perspectives;
  }

  definePeriods(apiResponse) {
    const active = apiResponse.period.toString();
    const periods = apiResponse.periodFields.map(it => ({
      label: it.title,
      value: parseInt(it.fieldName, 10),
      active: it.fieldName === active,
      color: it.colorKey,
    }));

    return periods;
  }

  defineMetadata(apiResponse) {
    const metadata = apiResponse.fieldMeta || [];
    const totals = !apiResponse.totalRequirement ? {} : apiResponse.totalRequirement.bars.reduce((acc, bar) => {
      acc[bar.name] = bar.value;
      return acc;
    }, {});

    return metadata.reduce((acc, it) => {
      acc[it.fieldName] = {
        id: it.fieldName,
        label: it.title,
        description: it.description,
        color: getVenueSummaryColorById(it.colorKey).bg,
        total: totals[it.fieldName]
      };
      return acc;
    }, {});
  }

  defineKeys(metadata) {
    return Object.keys(metadata)
      .filter(it => config.keys.indexOf(it) !== -1 && metadata[it].total > 0);
  }

  /**
   * Curently the StackedChart expects the following data structure:
   * data[n].descriptions[m] => {"str1","str2"}
   *        .context[m] => { fileldValue, fieldName }
   *        .bars[m].name => "collateral"
   *                .title => "Collateral"
   *                .period => "Mon 18-Dec"
   *                .lastUpdated => {displayText, toolTip}
   *                .groups[k].name ="FUND"
   *                          .title => "Fund"
   *                          .value => 4644195.32
   *                          .colorKey => "ASSET_CATEGORY_COLOR_FUND"
   *                          .context[j] => { fileldValue, fieldName }
   *                          .children[i].name: "F1"
   *                                      .context[l] => { fileldValue, fieldName }
   *                                      .colorKey => "COLLATERAL_GRADE_COLOR_F1"
   *                                      .title => "F1"
   *                                      .value => 4644195.32
   */
  parseBarsData(aggregationInfo) {
    if (!aggregationInfo) { 
      return [];
    }
    return aggregationInfo.map(it => {
      const context = it.context.reduce((result, field) => {result[field.fieldName] = field.fieldValue; return result;}, {});
      const elements = it.bars.map(bar => {
        return {
          ...bar,
          colorKey: getVenueSummaryColorById(it.colorKey).bg,
          context,
        };
      });

      return {
        ...it,
        bars:[{
          name: "",
          title: "",
          period: "",
          lastUpdated: it.lastUpdated,
          elements,
        }],
        context,
        uniqueId: it.id,
      };
    });
  }

  parseData(apiResponse) {
    const filters = parseFilters(apiResponse.filterByFields);
    const groupBy = [ apiResponse.groupBy1, apiResponse.groupBy2 || config.groupByDropdown.defaultValue ];
    const metadata = this.defineMetadata(apiResponse);
    const keys = this.defineKeys(metadata);
    const totals = !apiResponse.totalRequirement ? {} : apiResponse.totalRequirement.bars.map(bar => ({
      ...bar,
      color: getVenueSummaryColorById(bar.colorKey).bg
    }));
    const colors = Object.keys(metadata).reduce((acc, it) => {
      acc[it] = metadata[it].color;
      return acc;
    }, {});

    const data = this.parseBarsData(apiResponse.aggregationInfo);

    return {
      ...filters,
      groupBy,
      totals,
      colors,
      data,
      keys,
      title: apiResponse.title,
      hasDrilldown: apiResponse.hasDrilldown,
      groupingFields: apiResponse.groupingFields,
      barLinks: apiResponse.barLinks,
    };
  }

  static prepareRequest(params) {
    if (!params.filterBy) {
      params.filterBy = {};
    }

    const filterBy = prepareFiltersRequest(params);
    
    const groupBy = !params.groupBy ? []
      : params.groupBy.filter(it => it !== config.groupByDropdown.defaultValue);


    const request = {
      filterBy,
      groupBy,
      ...((!groupBy[0])? {} : {groupBy1: groupBy[0]}),//TODO: we need to refactor server API to get rid of groupBy1 and ...
      ...((!groupBy[1])? {} : {groupBy2: groupBy[1]}),//...groupBy2, because the groupBy array has already sufficient data.
    };

    return request;
  }
}

export default VenueDetailAdapter;
