import matchingManager from './matchingManager';
import pairOffs from './pairOffs';

const appViewId = "repo";
const sectionsViewIds = {
  MATCHING: "repoMatching",
  DASHBOARD: "repoDashboard",
  PAIROFFS: "repoPairOffs",
};

const viewIds = {
  app: {
    REPO: appViewId,
  },
  sections: sectionsViewIds,
};

const routes = {
  app: {
    viewId: viewIds.app.REPO,
    path: '/repoConnect',
  },
  sectionsList: [
    {
      viewId: viewIds.sections.DASHBOARD,
      component: (): null => null,
      subpath: '/dashboard',
    },
    {
      viewId: viewIds.sections.MATCHING,
      component: matchingManager,
      subpath: '/matching/:recOrientation?/:realTimeRecOrientation?/:groupedStatus?/:period?/:realTimePeriod?/:itemRef?',
      helpPath: "/help/RepoConnect_User_Guide_1.0.pdf",
    },
    {
      viewId: viewIds.sections.PAIROFFS,
      component: pairOffs,
      subpath: '/pairoffs',
    },
  ],
  pagesList: [],
};

const routesConfig = {
  appViewId: viewIds.app.REPO,
  viewIds,
  routes,
};

export default routesConfig;