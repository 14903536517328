import { createRecReducerWithNamedType } from "reconciliation/common/containers/SecuritiesView/reducer";
import { createSetsDrillDownReducerWithNamedType } from "reconciliation/common/containers/SetsDrillDown/reducer";
import { createSetsViewReducerWithNamedType } from "reconciliation/common/containers/SetsView/reducer";
import { REC_TYPES } from "reconciliation/constants";
import { combineReducers } from "redux";

const incomeClaimRecReducer = combineReducers({
  securitiesView: createRecReducerWithNamedType(REC_TYPES.COACS_IC),
  setsView: createSetsViewReducerWithNamedType(REC_TYPES.COACS_IC),
  setsDrillDown: createSetsDrillDownReducerWithNamedType(REC_TYPES.COACS_IC),
});

export default incomeClaimRecReducer;
