import { combineReducers } from 'redux';
import trade from './trade/reducer';
import collateral from './collateral/reducer';
import margin from './margin/reducer';

export default combineReducers({
  trade,
  collateral,
  margin,
});
