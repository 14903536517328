import { actionTypes } from './actions';
import { Map } from 'immutable';
import { VALUE_PREFIX_OPTIONS } from 'utils/dataUtils';
import { sessionStorageVariables } from './constants';

export const initialState = Map({
  table: {},
  timeline: {},
  groupings: {},
  headerInfo: {},
  isGrossValues: null,
  valueFormat: VALUE_PREFIX_OPTIONS.million,
});

export const projections = (state = initialState, action) => {
  const storedValueFormat = parseInt(sessionStorage.getItem(sessionStorageVariables.VALUE_FORMAT));
  const valueFormat = [0, 1, 2, 3].includes(storedValueFormat) ? storedValueFormat : VALUE_PREFIX_OPTIONS.million;
  switch (action.type) {
    case actionTypes.FETCH_PROJECTIONS_SUCCESS:
      return state.withMutations(s =>
        s.set('table', action.payload.table)
          .set('groupings', action.payload.groupings)
          .set('headerInfo', action.payload.headerInfo)
          .set('isGrossValues', action.payload.isGrossValues)
          .set('valueFormat', valueFormat)
      );
    case actionTypes.UPDATE_VALUE_FORMAT:
      sessionStorage.setItem(sessionStorageVariables.VALUE_FORMAT, action.payload);
      return state.withMutations(s => s.set('valueFormat', action.payload));
    case actionTypes.FETCH_INTRADAY_SUCCESS:
      return state.withMutations(s =>
        s.set('table', action.payload.table)
          .set('groupings', action.payload.groupings)
          .set('headerInfo', action.payload.headerInfo)
          .set('isGrossValues', action.payload.isGrossValues)
          .set('valueFormat', valueFormat)
      );
    case actionTypes.FETCH_INTRADAY_TIMELINE_SUCCESS:
      return state.withMutations(s =>
        s.set('timeline', action.payload));
    default:
      return state;
  }
};
