import { setFilters } from "actions/common/filtersActions";
import { LimitOffsetPagination } from "common/types/pagination";
import { Dispatch } from "react";
import { RepoColDef, RepoRow } from "repo/common/Grid/types";
import { Filter } from "repo/common/types";
import { StatusType } from "repo/models/EventStatus";
import * as svc from "./services";
import {
  DetailsHeader,
  DetailsOneSidedForm,
  DetailsTwoSidedTables,
  FeaturesResponse,
  FetchEventDetailsParams,
  FilterModel,
  TableSortModel,
  UpdateWorkflowStatusParams,
  UserInformationResponse,
  WorkflowStatusCount,
} from "./types";
import { ResponseError } from "common/types/APIResponses";
import { OvernightData } from './types';
import { CommentInfo } from "common/Comments/types";

export interface IAction<T> {
  type: string;
  payload?: T;
}

export enum actionTypes {
  FETCH_MATCHING_MANAGER_SUCCESS = "repo/matchingManager/FETCH_MATCHING_MANAGER_SUCCESS",
  FETCH_EVENTS_DETAILS_ONE_SIDED_SUCCESS = "repo/matchingManager/FETCH_EVENTS_DETAILS_ONE_SIDED_SUCCESS",
  FETCH_EVENTS_DETAILS_TWO_SIDED_DATA_SUCCESS = "repo/matchingManager/FETCH_EVENTS_DETAILS_TWO_SIDED_DATA_SUCCESS",
  FETCH_EVENTS_DETAILS_TWO_SIDED_TABLE_SUCCESS = "repo/matchingManager/FETCH_EVENTS_DETAILS_TWO_SIDED_TABLE_SUCCESS",
  FETCH_EVENTS_DETAILS_FAILED = "repo/matchingManager/FETCH_EVENTS_DETAILS_FAILED",
  CLEAR_DETAILS_DATA = "repo/matchingManager/CLEAR_DETAILS_DATA",
  SET_ACTIVE_STATUS_FILTERS = "repo/matchingManager/SET_ACTIVE_STATUS_FILTERS",
  FETCH_FEATURES_SUCCESS = "repo/matchingManager/FETCH_FEATURES_SUCCESS",
  FETCH_USER_INFORMATION_SUCCESS = "repo/matchingManager/FETCH_USER_INFORMATION_SUCCESS",
  UPDATE_TABLE_SORT_MODEL = "repo/matchingManager/UPDATE_TABLE_SORT_MODEL",
  UPDATE_WORKFLOW_STATUS_SUCCESS = "repo/matchingManager/UPDATE_WORKFLOW_STATUS_SUCCESS",
  UPDATE_WORKFLOW_STATUS_ERROR = "repo/matchingManager/UPDATE_WORKFLOW_STATUS_ERROR",
  CLEAR_WORKFLOW_STATUS_STATE = "repo/matchingManager/CLEAR_WORKFLOW_STATUS_STATE",
  SET_FILTER_MODEL = "repo/matchingManager/SET_FILTER_MODEL",
}

interface FetchMatchingManagerSuccessPayload {
  table: {
    columnDefs: RepoColDef[];
    rows: RepoRow[];
  };
  pagination: LimitOffsetPagination;
  workflowStatusCounts: WorkflowStatusCount[];
  filterByFields: Filter[];
}

interface FetchMatchingManagerSuccess {
  type: typeof actionTypes.FETCH_MATCHING_MANAGER_SUCCESS;
  payload: FetchMatchingManagerSuccessPayload;
}

export interface FetchOneSidedEventDetailsSucessPayload {
  commentInfo: CommentInfo;
  forms: DetailsOneSidedForm[];
  formHeaders: DetailsHeader[];
  overnightData: OvernightData;
}

interface FetchOneSidedEventDetailsSucess {
  type: typeof actionTypes.FETCH_EVENTS_DETAILS_ONE_SIDED_SUCCESS;
  payload: FetchOneSidedEventDetailsSucessPayload;
}

interface FetchTwoSidedEventDetailsDataSucess {
  type: typeof actionTypes.FETCH_EVENTS_DETAILS_TWO_SIDED_DATA_SUCCESS;
  payload: any; // NOT specified yet
}

export interface TwoSidedTablePayload {
  commentInfo: CommentInfo;
  header: DetailsHeader;
  summaries: DetailsTwoSidedTables;
  overnightData: OvernightData;
}

interface FetchTwoSidedEventDetailsTableSucess {
  type: typeof actionTypes.FETCH_EVENTS_DETAILS_TWO_SIDED_TABLE_SUCCESS;
  payload: TwoSidedTablePayload;
}

interface ClearDetailsData {
  type: typeof actionTypes.CLEAR_DETAILS_DATA;
}

interface SetFilterModel {
  type: typeof actionTypes.SET_FILTER_MODEL;
  payload: FilterModel;
}

interface SetActiveStatusFilters {
  type: typeof actionTypes.SET_ACTIVE_STATUS_FILTERS;
  payload: StatusType[];
}

interface UpdateTableSortModel {
  type: typeof actionTypes.UPDATE_TABLE_SORT_MODEL;
  payload: TableSortModel;
}

interface fetchUserInformationSucess {
  type: typeof actionTypes.FETCH_USER_INFORMATION_SUCCESS;
  payload: UserInformationResponse;
}

interface FetchFeaturesSuccess {
  type: typeof actionTypes.FETCH_FEATURES_SUCCESS;
  payload: FeaturesResponse;
}

export interface UpdateWorkflowStatusSuccess {
  type: typeof actionTypes.UPDATE_WORKFLOW_STATUS_SUCCESS;
}

interface UpdateWorkflowStatusError {
  type: typeof actionTypes.UPDATE_WORKFLOW_STATUS_ERROR;
  payload: ResponseError;
}

interface fetchEventsDetailsFailed {
  type: typeof actionTypes.FETCH_EVENTS_DETAILS_FAILED;
  payload: ResponseError;
}

interface ClearWorkflowStatusState {
  type: typeof actionTypes.CLEAR_WORKFLOW_STATUS_STATE;
}

export type AllActions =
  | ClearDetailsData
  | FetchMatchingManagerSuccess
  | FetchOneSidedEventDetailsSucess
  | FetchTwoSidedEventDetailsDataSucess
  | FetchTwoSidedEventDetailsTableSucess
  | SetActiveStatusFilters
  | UpdateTableSortModel
  | fetchUserInformationSucess
  | FetchFeaturesSuccess
  | fetchEventsDetailsFailed
  | UpdateWorkflowStatusSuccess
  | UpdateWorkflowStatusError
  | ClearWorkflowStatusState
  | SetFilterModel;

function fetchMatchingManagerSuccess(
  payload: FetchMatchingManagerSuccessPayload
): FetchMatchingManagerSuccess {
  return {
    type: actionTypes.FETCH_MATCHING_MANAGER_SUCCESS,
    payload,
  };
}

function fetchOneSidedEventDetailsSucess(
  payload: FetchOneSidedEventDetailsSucessPayload
): FetchOneSidedEventDetailsSucess {
  return {
    type: actionTypes.FETCH_EVENTS_DETAILS_ONE_SIDED_SUCCESS,
    payload,
  };
}

function fetchTwoSidedEventDetailsDataSucess(
  payload: any
): FetchTwoSidedEventDetailsDataSucess {
  return {
    type: actionTypes.FETCH_EVENTS_DETAILS_TWO_SIDED_DATA_SUCCESS,
    payload,
  };
}

function fetchTwoSidedEventDetailsTableSucess(
  payload: TwoSidedTablePayload
): FetchTwoSidedEventDetailsTableSucess {
  return {
    type: actionTypes.FETCH_EVENTS_DETAILS_TWO_SIDED_TABLE_SUCCESS,
    payload,
  };
}

export function clearDetailsData(): ClearDetailsData {
  return {
    type: actionTypes.CLEAR_DETAILS_DATA,
  };
}

export function setFilterModel(payload: FilterModel): SetFilterModel {
  return {
    type: actionTypes.SET_FILTER_MODEL,
    payload,
  };
}

export function setActiveStatusFilters(
  payload: StatusType[]
): SetActiveStatusFilters {
  return {
    type: actionTypes.SET_ACTIVE_STATUS_FILTERS,
    payload,
  };
}

export function updateTableSortModel(
  payload: TableSortModel
): UpdateTableSortModel {
  return {
    type: actionTypes.UPDATE_TABLE_SORT_MODEL,
    payload,
  };
}

export function fetchUserInformationSucess(
  payload: UserInformationResponse
): fetchUserInformationSucess {
  return {
    type: actionTypes.FETCH_USER_INFORMATION_SUCCESS,
    payload,
  };
}

export function fetchFeaturesSuccess(
  payload: FeaturesResponse
): FetchFeaturesSuccess {
  return {
    type: actionTypes.FETCH_FEATURES_SUCCESS,
    payload,
  };
}

export function fetchEventsDetailsFailed(
  error: ResponseError
): fetchEventsDetailsFailed {
  return {
    type: actionTypes.FETCH_EVENTS_DETAILS_FAILED,
    payload: error,
  };
}

export function updateWorkflowStatusSuccess(): UpdateWorkflowStatusSuccess {
  return {
    type: actionTypes.UPDATE_WORKFLOW_STATUS_SUCCESS,
  };
}

export function updateWorkflowStatusError(
  error: ResponseError
): UpdateWorkflowStatusError {
  return {
    type: actionTypes.UPDATE_WORKFLOW_STATUS_ERROR,
    payload: error,
  };
}

export function clearWorkflowStatusState(): ClearWorkflowStatusState {
  return {
    type: actionTypes.CLEAR_WORKFLOW_STATUS_STATE,
  };
}

/**
 *
 * Async actions
 *
 */

export function fetchMatchingManagerData(params: any) {
  return (dispatch: Dispatch<IAction<any>>): void => {
    svc.fetchMatchingManagerData(dispatch, params).then((payload: any) => {
      dispatch(fetchMatchingManagerSuccess(payload));
      dispatch(setFilters(payload));
    });
  };
}

// Not in use but api is working, use if needed
// export function fetchEventFilters(params: any) {
//   return (dispatch: Dispatch<IAction<{ filterBy: FilterBy}>>) =>
//     svc.fetchEventFilters(dispatch, params);
// }

export function fetchOneSidedEventDetails(params: FetchEventDetailsParams) {
  return (
    dispatch: Dispatch<
      IAction<FetchOneSidedEventDetailsSucessPayload | ResponseError>
    >
  ): void => {
    svc
      .fetchOneSidedEventDetails(dispatch, params)
      .then((payload) => {
        dispatch(fetchOneSidedEventDetailsSucess(payload));
      })
      .catch((error: ResponseError) => {
        dispatch(fetchEventsDetailsFailed(error));
      });
  };
}

type TwoSidedTableAction = IAction<TwoSidedTablePayload | ResponseError>;
type TwoSidedDataAction = (dispatch: Dispatch<TwoSidedTableAction>) => void;

function fetchTwoSidedEventDetailsData(
  data: TwoSidedTablePayload
): TwoSidedDataAction {
  return (dispatch: Dispatch<TwoSidedTableAction>): void => {
    svc
      .fetchTwoSidedEventDetailsData(data)
      .then((payload) => {
        dispatch(fetchTwoSidedEventDetailsDataSucess(payload));
      })
      .catch((error: ResponseError) => {
        dispatch(fetchEventsDetailsFailed(error));
      });
  };
}

export function fetchTwoSidedEventDetailsTable(
  params: FetchEventDetailsParams
) {
  return (
    dispatch: Dispatch<TwoSidedTableAction | TwoSidedDataAction>
  ): void => {
    svc
      .fetchTwoSidedEventDetailsTable(dispatch, params)
      .then((payload: TwoSidedTablePayload) => {
        dispatch(fetchTwoSidedEventDetailsData(payload));
        dispatch(fetchTwoSidedEventDetailsTableSucess(payload));
      })
      .catch((error: ResponseError) => {
        dispatch(fetchEventsDetailsFailed(error));
      });
  };
}

export function fetchUserInformation() {
  return (dispatch: Dispatch<IAction<UserInformationResponse>>): void => {
    svc.fetchUserInformation(dispatch).then((payload) => {
      dispatch(fetchUserInformationSucess(payload));
    });
  };
}

export function fetchFeatures() {
  return (dispatch: Dispatch<IAction<FeaturesResponse>>): void => {
    svc.fetchFeatures(dispatch).then((payload) => {
      dispatch(fetchFeaturesSuccess(payload));
    });
  };
}

export function updateWorkflowStatus(params: UpdateWorkflowStatusParams) {
  return (
    dispatch: Dispatch<IAction<UpdateWorkflowStatusSuccess | ResponseError>>
  ) => {
    svc
      .updateWorkflowStatus(dispatch, params)
      .then(() => {
        dispatch(updateWorkflowStatusSuccess());
      })
      .catch((error: ResponseError) => {
        dispatch(updateWorkflowStatusError(error));
      });
  };
}
