
import { actionTypes } from './actions';
import { Map } from 'immutable';

export const initialState = Map({
  configData: {
    rowData: [],
    columnDefs: [],
    configButtons: [],
    userActionInfo: '',
    modalMessage: '',
  },
  overviewData: {
    rowData: [],
    columnDefs: [],
  },
  auditData: {
    rowData: [],
    columnDefs: [],
  },
  selectors: [],
  modalData: {},
});

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.FETCH_CONFIG_LIST_STARTED:
      return state;
    case actionTypes.FETCH_CONFIG_LIST_SUCCESS:
      return state.withMutations(s => {
        s.set('configData', {
          rowData: payload.table.rows,
          columnDefs: payload.table.columnDefs,
          configButtons: payload.configButtons,
          userActionInfo: payload.userActionInfo ? payload.userActionInfo : '',
          modalMessage: '',
        });
        s.set('selectors', payload.filterByFields);
      });
    case actionTypes.FETCH_FILTERS_SUCCESS:
      return state.withMutations(s => {
        s.set('selectors', payload.filterByFields);
      });
    case actionTypes.FETCH_ADDITIONAL_DETAILS_SUCCESS:
      return state.withMutations(s => {
        s.set('modalData', payload);
      });
    case actionTypes.UPDATE_STATUS_MESSAGE:
      return state.withMutations(s => {
        s.set('configData', {
          ...s.get('configData'),
          userActionInfo: payload,
        });
      });
    case actionTypes.UPDATE_MODAL_MESSAGE:
      return state.withMutations(s => {
        s.set('configData', {
          ...s.get('configData'),
          modalMessage: payload,
        });
      });
    case actionTypes.FETCH_OVERVIEW_LIST_SUCCESS:
      return state.set('overviewData', {
        rowData: payload.table.rows,
        columnDefs: payload.table.columnDefs,
      });
    case actionTypes.FETCH_AUDIT_LIST_SUCCESS:
      return state.set('auditData', {
        rowData: payload.table.rows,
        columnDefs: payload.table.columnDefs,
      });
    default:
      return state;
  }
};