import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as styledComponents from './styles';

class ErrorModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: props.isOpen
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.props.onClick();
  }


  render() {
    const { title } = this.props;
    return (
      <styledComponents.ModalContainer>
        <styledComponents.StyledErrorModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel={title} >
          <styledComponents.CloseButton onClick={this.closeModal}>
            <styledComponents.StyledCloseSVG />
          </styledComponents.CloseButton>
          {this.props.children}
        </styledComponents.StyledErrorModal>
      </styledComponents.ModalContainer>
    );
  }
}

ErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

ErrorModal.defaultProps = {
  isOpen: true,
  onClick: _ => _,
};

export { ErrorModal };
