import React, {Component} from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { getApiId } from 'utils/apiUtils';

import { apiNames as uiConfigApiNames, apiOperatonIds as uiConfigApiOperatonIds } from 'constants/api/uiConfigApi';
import { apiNames as uiApiNames, apiOperatonIds as uiApiOperatonIds } from 'constants/api/uiApi';
import { apiNames as authApiNames, apiOperatonIds as authApiOperatonIds } from 'constants/api/authApi';

import { requestFailedForSpecificApi } from 'actions/common/apiActions';
import { redirectToLogout } from 'pirumconnect/storage/local/oldToken';
import authManager, { shouldUseNewSSO } from 'pirumconnect/auth/AuthManager';
import { ErrorManager, errorTypes, errorButtons } from 'common/error';

import { redirectToModeSelect } from 'pirumconnect/actions';
import TokenStorage from "pirumconnect/storage/local/tokens";

const initApiNames = {
  USER_INFO_API_NAME: getApiId(uiApiNames.USER, uiApiOperatonIds.USER_INFO),
  CLIENT_LIST_API_NAME: getApiId(uiConfigApiNames.CLIENT_GROUPED),
  PLATFORM_TREE_API_NAME: getApiId(uiConfigApiNames.MENU, uiConfigApiOperatonIds.MENU),
  TOKENS_API_NAME: getApiId(authApiNames.AUTH, authApiOperatonIds.TOKENS),
  AUTHORIZATION_URL_NAME: getApiId(authApiNames.AUTH, authApiOperatonIds.AUTHORIZATION_URL),
};

class InitErrorBoundary  extends Component {
  constructor(props) {
    super(props);
    this.state = { componentError: null };
  }

  static getDerivedStateFromError(error) {
    return { componentError: error };
  }

  componentDidCatch(error, info) {
    ErrorManager.sendErrorReport(error, info);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.error && !isEqual(nextProps.error, this.props.error)) {
      ErrorManager.sendErrorReport(nextProps.error, nextProps.error.errorMessage);
      this.props.requestFailedForSpecificApi(nextProps.error);
    }
  }

  isInitApiError(apiError) {
    if (!apiError) {
      return false;
    }

    switch(apiError.apiId) {
      case initApiNames.USER_INFO_API_NAME:
      case initApiNames.CLIENT_LIST_API_NAME:
      case initApiNames.PLATFORM_TREE_API_NAME:
      case initApiNames.TOKENS_API_NAME:
      case initApiNames.AUTHORIZATION_URL_NAME:
        return true;
      default:
        return false;
    }
  }

  errorsIncludesInitApiError(errors) {
    return errors && !!Object.keys(errors).find(this.isInitApiError);
  }

  render() {
    const { componentError } = this.state;
    const { error:apiError, errors, initalisationRuntimeError, goToModeSelect } = this.props;
    let errorComponent;

    /* The component error has priority over api error because the former can potentially be caused by the latter trying to render. */
    if (componentError || initalisationRuntimeError) {
      errorComponent = componentError
        ? ErrorManager.createError( errorTypes.DISPLAY_ERROR, componentError, [], true, false)
        : ErrorManager.createError( errorTypes.DISPLAY_ERROR, initalisationRuntimeError, [], true, true);
    }

    const pirumUserWithNewSSO = shouldUseNewSSO() && TokenStorage.isPirumUser();
    const errorIsInitError = this.isInitApiError(apiError);
    const errorsHasInitError = this.errorsIncludesInitApiError(errors);

    // TODO: PC-852 Reinstate once UAT IdP flow is fixed on BAML's side for PirumConnect
    // if (TokenStorage.isTokenEmpty() && TokenStorage.isIdpSession()) {
    //   errorComponent = ErrorManager.createError(
    //     errorTypes.IDP_SESSION_ENDED,
    //     '',
    //     [{label: errorButtons.GO_BACK_TO_IDP_LOGIN, onClick: authManager.redirectToIdpLogin}],
    //     true,
    //     false,
    //   );
    // }
    if (pirumUserWithNewSSO && (errorIsInitError || errorsHasInitError)) {
      errorComponent = ErrorManager.createError( 
        errorTypes.DISPLAY_ERROR, 
        apiError, 
        [{label: errorButtons.GO_TO_MODE_SELECT, onClick: goToModeSelect}]
      );
    } else if (errorIsInitError) {
      errorComponent = apiError.getStatus() === 401
        ? ErrorManager.createError( 
          errorTypes.UNAUTHORIZED_USER, 
          apiError, 
          [{label: errorButtons.CLOSE, onClick: !shouldUseNewSSO() ? redirectToLogout : authManager.clearTokensAndRedirectToExternalLogout}]
        )
        : ErrorManager.createError( errorTypes.DISPLAY_ERROR, apiError, [] );
    }


    return (
      <React.Fragment>
        {errorComponent}
        {this.props.children}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.app.api.error,
    errors: state.app.api.errors,
    initalisationRuntimeError: state.app.init.initalisationRuntimeError,
  };
};

const wrapper = connect(mapStateToProps, {
  requestFailedForSpecificApi,
  goToModeSelect: redirectToModeSelect,
})(InitErrorBoundary );

export {
  wrapper as InitErrorBoundary 
};