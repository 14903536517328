import React from "react";
import * as styledComponents from "./styles";

interface CheckboxProps {
  checked?: boolean;
  active?: boolean;
  glowOnChecked?: boolean;
  indeterminate?: boolean;
  width?: number;
  height?: number;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  active = false,
  glowOnChecked = true,
  indeterminate = false,
  width = 14,
  height = 14,
}) => (
  <styledComponents.CheckboxContainer
    checked={checked}
    glowOnChecked={glowOnChecked}
    $indeterminate={indeterminate}
  >
    <styledComponents.Tick
      height={height}
      width={width}
      checked={checked && !indeterminate}
      disabled={!active}
      $indeterminate={indeterminate}
    />
    <styledComponents.Checkbox
      height={height}
      width={width}
      checked={checked}
      active={active}
      $indeterminate={indeterminate}
    />
  </styledComponents.CheckboxContainer>
);

export default Checkbox;
