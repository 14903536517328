import services from 'services/services';
import {apiNames, apiOperatonIds} from 'constants/api/queryApi';
import { parseFilters, prepareFiltersRequest } from 'utils/parseUtils';

export function fetchLimitReconciliation(dispatch, params) {
  const filterBy = params.filterBy ? prepareFiltersRequest(params) : [];
  const request = {
  	filterBy
  };
  return services.query(apiNames.LIMIT_RECONCILIATION, apiOperatonIds.LIMIT_RECONCILIATION)(dispatch, { request })
    .then(res => ({
      ...res,
      ...parseFilters(res.filterByFields),
    }));
}

export function fetchLimitReconciliationFilter(dispatch, params) {
  const filterBy = params.filterBy ? prepareFiltersRequest(params) : [];
  const request = {
  	filterBy
  };
  return services.query(apiNames.LIMIT_RECONCILIATION, apiOperatonIds.LIMIT_RECONCILIATION_FILTER)(dispatch, { request })
    .then(res => ({
      ...parseFilters(res.filterByFields),
    }));
}

export function fetchLimitReconciliationSpreadsheet(dispatch, params) {
  const filterBy = params.filterBy ? prepareFiltersRequest(params) : [];
  const request = {
  	filterBy
  };

  return services.query(apiNames.LIMIT_RECONCILIATION, apiOperatonIds.LIMIT_RECONCILIATION_SPREADSHEET)(dispatch, { request })
    .then(result => result);
}
