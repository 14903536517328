import React from "react";
import LoadingSuspense from "common/LoadingSuspense";

const Component = React.lazy(() => import("./DashboardPage"));

const LoadableDashboardPage = () => (
  <LoadingSuspense>
    <Component />
  </LoadingSuspense>
);

export default LoadableDashboardPage;
