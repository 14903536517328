import { StyleKey } from "common/tables/Grid/types";
import { SortModel, SortState, TableSortModel } from "common/types/grid";
import { LimitOffsetPagination } from "common/types/pagination";
import { RepoColDef, RepoRow } from "repo/common/Grid/types";
import { Filter } from "repo/common/types";
import { StatusType } from "repo/models/EventStatus";
import { DataViewData } from "./EventDetails/TwoSided/DataView";
import { TableDetailColDef } from "./EventDetails/TwoSided/TableView/Table/types";
import { ColDefFields } from "./MatchingManagerPage/Table/config";
import { RepoTimelineRow } from "../common/Grid/types";
import { CommentInfo } from "common/Comments/types";
import {
  ConversationDeletes,
  MessageMetadataDelete,
  MessageMetadataMessage,
} from "fos/trm/services/comments";
import {
  FetchMessagesResponse,
  ConversationMessages,
} from "fos/trm/services/comments";

export enum RecOrientation {
  oneSided = "1",
  twoSided = "2",
}

export enum GroupedStatus {
  Grouped = "grouped",
  NonGrouped = "nongrouped",
}

export enum MatchingManagerViews {
  MatchingManager = "matching_manager",
  EventDetailsOneSided = "eventDetailsOneSided",
  EventDetailsTwoSided = "eventDetailsOneTwoSided",
}

export enum RecType {
  REAL_TIME = "RTTrade",
  OVERNIGHT = "Trade",
}

export interface WorkflowStatusCount {
  status: StatusType;
  title: string;
  count: number;
  active: boolean;
}

export type BreaksDictionary = Record<number, string[]>;

export interface DetailsTableData {
  columnDefs: RepoColDef[];
  rows: RepoRow[];
}
export interface TimelineTableData {
  columnDefs: RepoColDef[];
  rows: RepoTimelineRow[];
}

export enum TwoSidedSummary {
  Trade = "trade",
  PairOff = "pairOff",
}

export interface TwoSidedDetailsTableData
  extends Omit<DetailsTableData, "rows" | "columnDefs"> {
  breaks: BreaksDictionary;
  columnDefs: TableDetailColDef[];
  key: TwoSidedSummary;
  rowData: RepoRow[];
  title: string;
}

export type DetailsTwoSidedTables = {
  [key in TwoSidedSummary]: DetailsTwoSidedTable;
};

export interface DetailsTwoSidedTable {
  breaks: BreaksDictionary;
  emptyColumns: string[];
  header: DetailsHeader;
  table: DetailsTableData;
  title: string;
}

export interface EventDetailsPeriods {
  realTime: string;
  endOfDay?: string;
}

export interface FetchEventDetailsParams {
  itemRef: string;
  period: string; // TODO: remove this property as part of RPO-572
  periods: EventDetailsPeriods;
  isGrouped: boolean; // TODO: remove this property as part of RPO-572
  recType?: RecType;
}

export interface FetchTimeLineMessagesRequest {
  commentInfo: CommentInfo;
}

export interface StatusMessages {
  context: string;
  metadata: StatusMetadata;
}

export interface StatusMetadata {
  epochTime: number;
  fullName: string;
  repoEventDate: string;
  repoEventSubType: string;
  repoEventType: string;
  status: string;
  time: string;
  timestamp: string;
  tradeRef: string;
}

export interface TimelineConversationDeletes extends ConversationDeletes {
  metadata: MessageMetadataDelete & { epochTime: number };
}

export interface TimelineConversationMessages extends ConversationMessages {
  context: string;
  metadata: MessageMetadataMessage & { epochTime: number };
}

export type TimelineMessage =
  | StatusMessages
  | TimelineConversationMessages
  | TimelineConversationDeletes;

export interface FetchTimeLineMessagesResponse extends FetchMessagesResponse {
  timelineMessages: TimelineMessage[];
}

export interface WorkflowItems {
  itemRef: string;
  currentWorkflowStatus: StatusType;
  newWorkflowStatus: StatusType;
}

export interface UpdateWorkflowStatusParams {
  queryParams: {
    clientIds: number[];
  };
  createdBy: string;
  items: WorkflowItems[];
}

export interface DetailsHeader {
  counterparty: string;
  itemRef: string;
  period: string;
  secCusip?: string;
  secDescription: string;
  secIsin?: string;
  secSedol?: string;
  status: StatusType;
  subEventStatus: string;
}

export interface OvernightData {
  containsOvernight: boolean;
  periods: string[];
}

export type RepoPeriodDictionary = Map<string | number, RepoPeriodData>;

export type EventDetailsToggles = {
  breaksOnly: boolean;
  hideEmptyFields: boolean;
}[];

export interface RepoPeriodData {
  detailsData: DetailsOneSidedForm | DataViewData;
  detailsTableData: TwoSidedDetailsTableData[];
  header: DetailsHeader;
  toggles: EventDetailsToggles;
}

interface SubGroupField {
  field: {
    fieldMetaData: {
      description: string;
      fieldName: string;
      maxLength: number;
      title: string;
      type: string;
      styleKey: StyleKey;
      validator: {
        pattern: string;
        errorMessage: string;
      };
    };
    isHighlighted?: boolean;
  };
  fieldValue: string;
  fieldType: string;
}

interface FieldSubGroup {
  fields: SubGroupField[];
  title: string;
}

export interface FieldGroup {
  title: string;
  subgroups: FieldSubGroup[];
}

export interface DetailsOneSidedForm {
  fieldGroups: FieldGroup[];
  title: string;
}

export interface MatchingManagerTable {
  columnDefs: RepoColDef[];
  rows: RepoRow[];
}

export interface MatchingManagerDataResponse {
  filterByFields: Filter[];
  pagination: LimitOffsetPagination;
  table: MatchingManagerTable;
  totalStatusCount: {
    active: boolean;
    count: number;
  };
  workflowStatusCounts: WorkflowStatusCount[];
}

export type RepoPermissions =
  | "repo:view"
  | "repo:force"
  | "repo:update"
  | "repo:support";

export interface UserInformation {
  isSubscribed: boolean;
  repoPermission: RepoPermissions;
}

export interface UserInformationResponse {
  userInformation: UserInformation;
}

export type RepoFeatures = FeaturesResponse;

export interface FeaturesResponse {
  excelDownload: boolean;
}

export interface MatchingManagerUrlParams {
  groupedStatus: GroupedStatus;
  itemRef: string;
  period: string;
  realTimeRecOrientation: RecOrientation;
  realTimePeriod: string;
  recOrientation: RecOrientation;
}

export type FiltersKeys = Extract<
  ColDefFields,
  | "securityId"
  | "securityDescription"
  | "contractId"
  | "tradeRef"
  | "contractCurrency"
>;

export interface FiltersValues {
  filter: string;
  type: string;
  filterType: string;
}

export type FilterModel = {
  [key in FiltersKeys]?: FiltersValues;
};

export type { SortState, SortModel, TableSortModel };
