import React from 'react';

const disclaimer = (
  <React.Fragment>
    <p>Persons who access the material made available by Pirum Systems Limited on this website agree to the following:</p>
    <h3>Local Legal Restrictions</h3>
    <p>You are responsible for complying with the laws of the jurisdiction from which you are accessing this site and you agree that you will not access or use the information on this site in violation of such laws.</p>
    <h3>Use of the Website</h3>
    <p>The entire content of the SBLREX website (the Website) is subject to copyright with all rights reserved. You may download or print out a hard copy of individual pages and/or sections of the Website, provided that you do not remove any copyright or other proprietary notices. Any downloading or otherwise copying from the Website will not transfer title to any software or material to you. You may not reproduce (in whole or in part), transmit (by electronic means or otherwise), modify, link into or use for any public or commercial purpose the Website without the prior written permission of Pirum Systems Limited. Your access to SBLREX will be revoked if you do not login for 90 consecutive days. Access can be reinstated by your User Administrator. User Administrators will have their access revoked if they do not login for 365 days.</p>
    <h3>No Offer or Relationship</h3>
    <p>Nothing on the Website should be construed as a solicitation or offer, or recommendation to engage in any transaction. Your use of the Website and its contents does not create a business or professional services relationship between you and Pirum Systems Limited beyond the terms of your Service Contract with Pirum Systems Limited for the use of the Website.</p>
    <h3>No Reliance</h3>
    <p>Pirum Systems Limited makes no representation that the information contained on the Website is accurate, reliable or complete. The Website is subject to change without notice.</p>
    <h3>No Warranty</h3>
    <p>The information contained on the Website is provided without any warranty of any kind, either express or implied.</p>
    <h3>Limitations of Liability</h3>
    <p>In no event, including (without limitation) negligence, will Pirum Systems be liable for any loss or damage of any kind, including (without limitation) any direct, special indirect or consequential damages, even if expressly advised of the possibility of such damages, arising out of or in connection with the access of, use of, performance of, browsing in or linking to other sites from the Website.
    Certain laws and regulations impose liabilities which cannot be disclaimed. This disclaimer does not constitute a waiver or limitation of any rights a person may have under such laws and/or regulations.</p>
    <h3>Linked Websites</h3>
    <p>When you access some links on the Website you may leave the Website. Pirum Systems Limited has not reviewed the websites linked to this website and does not endorse or accept any responsibility for the content of such websites nor the products, services or other items offered through such websites.</p>
    <h3>Submitting Information</h3>
    <p>Any material or information transmitted to or posted to the Website by any means will be treated within the terms of your Service Contract with Pirum Systems Limited, and may be disseminated, stored or used by Pirum Systems Limited for any purpose whatsoever including but not limited to developing, and marketing products & services incorporating such ideas, concepts or techniques.</p>
    <h3>Cookie Policy</h3>
    <p>Pirum Systems Limited uses cookies for essential operational reasons and to provide usage statistics to Google Analytics.</p>
    <p>We may collect information about your computer, including your IP address, operating system and browser type, for system administration and in order to create reports.</p>
    <p>Google Analytics is a web analytics tool that helps website owners understand how visitors engage with their website.</p>
    <p>A cookie is a small text file that is placed on your computer, mobile phone, or other device when you visit a website. The cookie will help website providers to recognise your device the next time you visit their website. There are other similar technologies such as pixel tags, web bugs, web storage and other similar files and technologies that can also do the same thing as cookies. Pirum Systems Limited uses the term "cookies" in this policy to refer to cookies and all such similar technologies that collect information in this way.</p>
    <p>Most web browsers allow you to manage your cookie preferences. You can set your browser to refuse cookies, or delete certain cookies. Generally you should also be able manage similar technologies in the same way that you manage cookies, using your browsers preferences.</p>
    <p>Google Analytics collects information anonymously. It reports website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our site. For more information on opting out of being tracked by Google Analytics across all websites you use, visit this Google page <a href="http://tools.google.com/dlpage/gaoptout">tools.google.com/dlpage/gaoptout</a>.</p>
    <p>For more information about the use of cookies visit <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>, <a href="http://www.youronlinechoices.eu">www.youronlinechoices.eu</a> (Europe) or <a href="http://www.aboutads.info/choices">www.aboutads.info/choices</a> (United States).</p>
    <p>If you have any questions or comments about our use of cookies, please contact us at <a href="mailto:support@pirum.com">support@pirum.com</a></p>
  </React.Fragment>
);

const privacy = (
  <React.Fragment>
    <p>
    Pirum Systems Ltd is committed to protecting the privacy and security of your personal information and we will always treat you and your data with the respect you deserve.</p>
    <p>This Privacy Notice covers how we collect, use, store and disclose the personal data that we hold and your rights in respect of that data. It applies to our clients and persons our clients have authorised or directed us to interact with (including counterparties) in the course of providing services. Accordingly, references to “you” in this Notice means, as the context shall require, any such person and any individuals representing your organisation.
    </p>
    <h3>The information we collect from you</h3>
    <p>Personal individual information means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data). We will typically collect, store and use all or some of the following categories of personal information about you:
    </p>
    <ul>
      <li>contact details such as name, title, work addresses, work telephone numbers, work email addresses and other business information.</li>
    </ul>
    <h3>How the information is collected</h3>
    <p>We collect personal information directly from you through our website, from your appointed administrator representing you or by direct personal request. Some information may be collected from publicly available third party sources.</p>
    <h3>What we use your personal information for</h3>
    <p>We will use the personal information for the administration of our relationship with our client, in order to provide services in accordance with the contract with our client. We will also use personal information to comply with requirements that we are required or recommended to undertake, whether by statute or by our regulator.</p>
    <h3>Failure to provide information</h3>
    <p>If you fail to provide certain information when requested, we may not be able to perform the contract we have entered into with our client.</p>
    <h3>Your duty to inform us of changes</h3>
    <p>It is important that the personal information we hold about you is accurate and current, so please let us know if your information changes.</p>
    <h3>Disclosure/Data Sharing</h3>
    <p>We may have to share your data with third parties, including third-party service providers (including contractors and designated agents); other entities in the group; in the context of a sale of the business; or with a regulator or to otherwise comply with the law; our insurers and/or professional advisers to manage risks and/or legal disputes.</p>
    <p>We do this where necessary to provide services in accordance with our contract with our client; where required by law; or where we have another legitimate interest in doing so.</p>
    <p>We require third parties to respect the security of your data and to treat it in accordance with the law.</p>
    <h3>Transfers of data outside of the EU</h3>
    <p>We may transfer the personal information we collect about you outside the EU in order to perform our contract with our client. We use Microsoft Office 365 and NetSuite which covers our email servers and client relationship management systems. Further information is available from the Data Protection Officer (email: dpo@pirum.com).</p>
    <h3>Data retention</h3>
    <p>We store personal information for a maximum of 10 years following the termination of our contract with you.</p>
    <h3>Your Rights</h3> 
    <p>Your rights in connection with personal information.</p>
    <p>
    Under certain circumstances, by law you have the right to:
    </p>
    <ul>
      <li><b>Request access</b> to your personal information (commonly known as a “data subject access request”). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.</li>
      <li>Request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.</li>
      <li>Request erasure of your personal information. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).</li>
      <li>Object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground.</li>
      <li>Request the restriction of processing of your personal information. This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.</li>
      <li>Request the transfer of your personal information to another party.</li>
    </ul>
    <h3>Complaints & Questions</h3>
    <p>If you have any questions about this privacy notice or how we handle your personal information, please contact the Data Protection Officer (email: dpo@pirum.com)</p>
    <p>If you are not satisfied by our response you also have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (email: casework@ico.org.uk)</p>
    <h3>Changes to This Privacy Notice</h3>
    <p>We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal information. This privacy notice is effective as from 25 May 2018.</p>
  </React.Fragment>
);

export default {
  disclaimer,
  privacy,
};