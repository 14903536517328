import { table as fakerTable } from "./faker.mocks";
// import { table as customTable } from "./custom-data.mocks";

export const getTableResponse = (statuses: string[], useFaker: boolean) => {
  // const table = (useFaker ? fakerTable : customTable) as typeof customTable;
  const table = fakerTable;

  if (Array.isArray(statuses) && statuses.length) {
    return {
      ...table,
      table: {
        ...table.table,
        rows: table.table.rows.filter(
          ({
            cells: {
              status: { arrayValue },
            },
          }) => {
            return arrayValue.some((value) => statuses.includes(value));
          }
        ),
      },
    };
  }

  return table;
};
