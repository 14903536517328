export default (function () {
  require("react-app-polyfill/ie11");
  require("react-app-polyfill/stable");
  require("core-js/features/string/starts-with");
  require("core-js/features/array/find");
  require("core-js/features/array/fill");
  require("core-js/features/array/includes");
  require("core-js/features/array/flat");
  require("core-js/features/number/is-nan");
  require("core-js/features/symbol/iterator.js");
  require("core-js/features/set");
  require("core-js/features/string/repeat");
  require("core-js/es/symbol");
  require("core-js/es/object");

  if (typeof window.CustomEvent !== "function") {
    window.CustomEvent = require("custom-event");
  }
})();
