import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { fetchFeatures, fetchUserInformation } from "./actions";
import DetailsPage from "./EventDetails/Loadable";
import MatchingManagerPage from "./MatchingManagerPage/Loadable";
import { MatchingManagerUrlParams } from "./types";

const MatchingManager: React.FC = () => {
  const dispatch = useDispatch();

  const params = useParams<MatchingManagerUrlParams>();

  const { groupedStatus, itemRef, period, recOrientation } = params;

  useEffect(() => {
    dispatch(fetchUserInformation());
    dispatch(fetchFeatures());
  }, [dispatch]);

  return groupedStatus || itemRef || period || recOrientation ? (
    <DetailsPage {...params} />
  ) : (
    <MatchingManagerPage />
  );
};

export default MatchingManager;
