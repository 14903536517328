export { default as auth } from "./pirumconnect-auth.swagger.json";
export { default as query } from "./query.swagger.json";
export { default as sso } from "./sso.swagger.json";
export { default as ui } from "./ui.swagger.json";
export { default as sblrex } from "./sblrex.swagger.json";
export { default as recReader } from "./reconciliation-reader.swagger.json";
export { default as uiConfig } from "./pirumconnect-uiconfig.swagger.json";
export { default as coacs } from "./coacs.swagger.json";
export { default as collateralProfile } from "./collateral-profile.swagger.json";
export { default as ruleExpr } from "./rec-ruleexpr.swagger.json";
export { default as repo } from "./affirmation-workflow.swagger.json";
export { default as trm } from "./trm-reader.swagger.json";
export { default as tlm } from "./tlm-reader.swagger.json";
export { default as comments } from "./comments-reader.swagger.json";
export { default as rdstore } from "./rdstore-reader.swagger.json";
export { default as uiConfigStore } from "./pirumconnect-uiconfig-store.swagger.json";
