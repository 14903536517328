import jwtDecode from 'jwt-decode';
import appLogger from "pirumconnect/logger";
import * as authServices from 'pirumconnect/auth/services';
import isEqual from "lodash.isequal";

import {
  revokeUserAuthorization,
  revokeUserAuthentication,
} from 'pirumconnect/actions';

import {
  createAutoLogoutChecker,
  isLoggedIn,
  isLoggedOut,
} from "pirumconnect/storage/local/oldToken";

import { sendToConnected } from "pirumconnect/storage/local/connectToStorage";

import TokenFormatter from "pirumconnect/storage/local/TokenFormatter";
import UserActivityManager from 'pirumconnect/auth/UserActivityManager';
import TokenStorage from 'pirumconnect/storage/local/tokens';
import QueryParams from "pirumconnect/storage/local/QueryParams";
import store from 'pirumconnect/store';

import { LOGOUT_CHECK_INTERVAL } from "constants/app";
import {
  ID_TOKEN_NAME,
  IMP_TOKEN_NAME,
} from "pirumconnect/storage/constants";

const NEW_SSO_CONFIG = {
  path:'/pirum/sso/auth',
  query:  'code=',
};

class Authorization {
  constructor() {
    this.isRedirectingExternally = false;

    this.clearTokensAndRedirectToExternalLogout = this.clearTokensAndRedirectToExternalLogout.bind(this);
    this.handleHalfTimeActivity = this.handleHalfTimeActivity.bind(this);

    this.userActivityManager = new UserActivityManager(this.handleHalfTimeActivity);
  }

  handleHalfTimeActivity() {
    this.refreshToken();
  }

  // TODO: This is for old login sso, remove from here when required
  checkOldLogin() {
    if ( isLoggedIn() ) {
      createAutoLogoutChecker(LOGOUT_CHECK_INTERVAL);
    } else {
      if (!isLoggedOut()){
        appLogger.debug({ where: 'ReactRouting.checkLogin', what: 'User not logged in, redirect to logout', vars: {psSE: localStorage.getItem('psSE')} });
        //Note, the logout is not performed here. So, the Unauthorised error message has chance to show up and the rediretion will happen on closing the popup.
      }
    }
  }

  getKeycloakIdCodeFromQuery() {
    const queries = window.location.search.split('&');
    const codeParam = queries.find(param => param.match(new RegExp(`(^|\\?)${NEW_SSO_CONFIG.query}`)));
    return codeParam ? codeParam.split("=")[1] : '';
  }

  isNewSSOURL() {
    return window.location.pathname === NEW_SSO_CONFIG.path || !!this.getKeycloakIdCodeFromQuery();
  }

  getOriginURL() {
    if(this.isNewSSOURL()) {
      return window.location.origin + window.location.pathname;
    }
    return window.location.origin + '/';
  }

  checkAuthServiceErrorResponse(store, action) {
    // 401 Unauthorized responses will cause the user to be kicked out.
    if (action.payload
      && action.payload.error
      && (action.payload.error.getStatus() === 401)) {
      return authManager.clearTokensAndRedirectToExternalLogout();
    }
  }
  
  redirectToIdpLogin() {
    /*
     * PC-835 Once redirected to their SSO we should clear out any knowledge of an SSO session existing.
     * Once the user logs in at their provider again (they may still have an active session there) they
     * will proceed through the application login process as normal.
     */
    const loginLink = TokenStorage.getSSOSessionStatus().idpLoginLink;
    TokenStorage.removeSSOSessionStatus();
    window.location.replace(loginLink);
  }

  setTokenInLocalStorage(response) {
    /*
     * As it turns out, when using the impersonation endpoint for Keycloak, two tokens are returned instead of an
     * expected three. These two tokens are the access token and a refresh token, missing the expected IdToken.
     * The access token contains the impersonated user information we want to use, and what we should send onto the API
     * for permission evaluation.
     */
    const impTokenWrapper = response[IMP_TOKEN_NAME];
    const tokenType = impTokenWrapper ? IMP_TOKEN_NAME : ID_TOKEN_NAME;
    const token = (impTokenWrapper && impTokenWrapper.access_token) || response[ID_TOKEN_NAME];
    if(token) {
      TokenStorage.setToken(TokenFormatter.format(jwtDecode(token)), tokenType);
      this.userActivityManager.scheduleActivityDetection();
    }
    return !TokenStorage.isTokenEmpty();
  }

  async redirectToExternalLogin() {
    const res = await authServices.fetchLoginURL(store.dispatch, { redirectUrl: this.getOriginURL() });
    window.location.replace(res.authUrl);
  }

  async clearTokensAndRedirectToExternalLogout() {
    let response;
    try {
      response = await authServices.fetchLogoutURL(store.dispatch, { redirectUrl: window.location.origin + NEW_SSO_CONFIG.path });
      store.dispatch(revokeUserAuthentication());
      store.dispatch(revokeUserAuthorization());
      TokenStorage.removeAllTokens();
      QueryParams.removeQueryParams();
      if(window.aptrinsic) {
        window.aptrinsic('reset');
      }
      window.location.replace(response.logoutUrl);
    } catch (error) {
      appLogger.fatalException({
        where: 'clearTokensAndRedirectToExternalLogout',
        what: 'logout exception',
      }, error);
    }
  }

  async fetchToken(code) {
    let response;
    try {
      response = await authServices.fetchToken(store.dispatch, { code, redirectUrl: this.getOriginURL() });
    } catch (error) {
      appLogger.fatalException({
        where: 'fetchToken',
        what: 'fetch token exception',
      }, error);
      return this.clearTokensAndRedirectToExternalLogout();
    }
    return this.setTokenInLocalStorage(response);
  }

  async refreshToken() {
    let response;
    try {
      response = await authServices.fetchRefreshToken(store.dispatch);
    } catch (error) {
      appLogger.fatalException({
        where: 'refreshToken',
        what: 'refresh token exception',
      }, error);
      return this.clearTokensAndRedirectToExternalLogout();
    }
    return this.setTokenInLocalStorage(response);
  }

  async resetSelectedModeAttributes() {
    // This logic is only required for Pirum users
    let success;
    try {
      if(TokenStorage.isPirumUser()) {
        if (TokenStorage.isImpersonationMode()) {
          try {
            success = await authServices.stopImpersonation(store.dispatch);
            TokenStorage.removeImpToken();
          } catch (error) {
            appLogger.fatalException({
              where: 'resetSelectedModeAttributes',
              what: 'stop impersonation exception',
            }, error);
            return this.clearTokensAndRedirectToExternalLogout();
          }
        }
        try {
          success = await authServices.deselectSupportClient(store.dispatch);
        } catch (error) {
          appLogger.fatalException({
            where: 'resetSelectedModeAttributes',
            what: 'leave support exception',
          }, error);
          return this.clearTokensAndRedirectToExternalLogout();
        }
        // We refresh the token here to get a Pirum client ID in our JWT, allowing clientList and userList calls
        try {
          success = await authManager.refreshToken();
        } catch (error) {
          appLogger.fatalException({
            where: 'resetSelectedModeAttributes',
            what: 'refresh failed',
          }, error);
          return this.clearTokensAndRedirectToExternalLogout();
        }
        // Clear out any previously selected client IDs
        QueryParams.removeQueryParams();
        // 'Authorization' that is, a selected client or user is then revoked until the Pirum user decides again how to proceed
        store.dispatch(revokeUserAuthorization());
      }
    } catch (error) {
      appLogger.fatalException({
        where: 'resetSelectedModeAttributes',
        what: 'Pirum user evaluation failed',
      }, error);
    }
    return success;
  }

  async obtainUserToken() {
    const keycloakIdentityCode = this.getKeycloakIdCodeFromQuery();
    if (!keycloakIdentityCode && TokenStorage.isTokenEmpty()) {
      // TODO: PC-852 Reinstate once BAML fix their issue with PirumConnect IdP flow
      // if(TokenStorage.isIdpSession()) {
      //   // We should not attempt to go through the normal flow if we've logged in via an IdP previously
      //   return this.clearTokensAndRedirectToExternalLogout();
      // } else {
      //   // No auth code in our url and no token? Go get a code from Keycloak
      //   return this.redirectToExternalLogin();
      // }
      // TODO: Remove the duplicate code below once PC-852 is done
      // No auth code in our url and no token? Go get a code from Keycloak
      return this.redirectToExternalLogin();
    }
    let success = false;
    if (TokenStorage.isTokenEmpty()) {
      // If we've got a code and no token, we'll need to fetch a token from Keycloak using our auth code
      success = await this.fetchToken(keycloakIdentityCode);
    } else if (!TokenStorage.hasTokenExpired()) {
      if (TokenStorage.hasPassedHalfwayToExpiry()) {
        success = await this.refreshToken();
      } else {
        this.userActivityManager.scheduleActivityDetection();
        success = true;
      }
    }
    return success;
  }
}

const authManager = new Authorization();
export default authManager;

export function updateQueryParams(queryParamsUpdate) {
  try {
    const queryParams = QueryParams.getQueryParams();

    const selectAllClientIdsOnInit = !queryParamsUpdate.clientIds && (!queryParams.clientIds || queryParams.jti !== queryParamsUpdate.jti);

    const newQueryParams = {...queryParams, ...queryParamsUpdate};

    // if selected client list has changed clear out the session store
    const doSessionStoreClear = !isEqual(queryParams.clientIds, queryParamsUpdate.clientIds);

    QueryParams.setQueryParams({
      jti: newQueryParams.jti,
      clientIds: newQueryParams.clientIds,
      selectAllClientIdsOnInit
    });

    if ( doSessionStoreClear ) {
      // Note: this sessionStore clear MUST be next to the setItem call,
      // otherwise we will end up with concurrent execution issues with fetch
      sessionStorage.clear();
    }

    sendToConnected(newQueryParams);
  }
  catch (error) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export function shouldUseNewSSO() {
  /*
  * Currently we know that we're in the new SSO flow by an injected div with 'react-settings'
  * Please see server-php/lib/CWS/App::getReactSettingsHtml
  * Besides that, once we've logged in we will know that we're in the new SSO if we have
  * a new token in local storage.
  */
  const reactSettingsElement = document.getElementById('react-settings');
  if (reactSettingsElement) {
    const toggleNewSSO = reactSettingsElement.getAttribute('data-toggle-new-sso');
    if ((toggleNewSSO === 'partial' && authManager.isNewSSOURL())
      || (toggleNewSSO === 'true')
      || !TokenStorage.isTokenEmpty()) {
      return true;
    }
  }
  return false;
}