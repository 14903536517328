function sortObjectByKeys<T extends Record<string, unknown>>(
  obj: T,
  compareFn?: (a: string, b: string) => number
): T {
  if (obj === null) return obj;
  return Object.assign(
    {},
    ...Object.keys(obj)
      .sort(compareFn)
      .map((key) => ({ [key]: obj[key] }))
  );
}

function groupArrayOfObjectsByKey<T extends Record<keyof T, unknown>>(
  list: T[],
  indexFn: ((item: T) => string) | string
): Record<string, T[]> {
  const map = new Map();
  list.forEach((item) => {
    const key = typeof indexFn === "function" ? indexFn(item) : indexFn;
    if (!key) return;
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Object.fromEntries(map);
}

function formatObjectKeys<T extends Record<keyof T, unknown>>(
  obj: T,
  keyFormatter: (value: unknown) => unknown
): T {
  const map = new Map();
  Object.entries(obj).forEach(([key, item]) => {
    const formattedKey = keyFormatter ? keyFormatter(key) : key;
    const collection = map.get(formattedKey);
    if (!collection) {
      map.set(formattedKey, item);
    } else {
      // if there are duplicate keys place items in array
      if (!Array.isArray(collection)) map.set(formattedKey, [collection]);
      map.get(formattedKey).push(item);
    }
  });
  return Object.fromEntries(map);
}

export { formatObjectKeys, groupArrayOfObjectsByKey, sortObjectByKeys };
