import services from '../services';
import PlatformDetailsAdapter from './PlatformDetailsAdapter';
import { apiNames, apiOperatonIds } from 'constants/api/uiConfigApi';
import { getSessionArgs } from "pirumconnect/storage/local/oldToken";
import { shouldUseNewSSO } from "pirumconnect/auth/AuthManager";
import QueryParams from 'pirumconnect/storage/local/QueryParams';

// Menu tree uses all selected clients from storage, where as other API calls always use the active valid client IDs
const getQueryParams = () => {
  if(shouldUseNewSSO()) {
    return QueryParams.getRequiredQueryParams(["clientIds"]);
  }
  return getSessionArgs(["clientIds"]);
};

export function getPlatformDetails(dispatch) {
  return services.uiConfig(apiNames.MENU, apiOperatonIds.MENU)(dispatch, { request: { keepSingleClientTokens: true, queryParams: getQueryParams() } })
    .then(response =>
      new PlatformDetailsAdapter().parse(response.menuTree)
    );
}