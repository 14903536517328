import * as actionTypes from 'constants/actionTypes';

export const initialState = {
  userInfo: {},
  activeValidClientIds: [],
};

export const userAccount = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.ACCOUNT_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case actionTypes.ACCOUNT_UPDATE_CLIENT_IDS:
      return {
        ...state,
        counterPartyList: initialState.counterPartyList,
        selectedCounterPartyIds: initialState.selectedCounterPartyIds
      };

    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload
      };

    case actionTypes.GET_USER_INFO_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case actionTypes.UPDATE_COUNTER_PARTY_ACTIVE_IDS_SUCESS:
      return {
        ...state,
        selectedCounterPartyIds: action.payload
      };

    case actionTypes.SET_ACTIVE_VALID_CLIENT_IDS:
      return {
        ...state,
        activeValidClientIds: action.payload
      };

    default:
      return state;
  }
};
