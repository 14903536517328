import * as svc from '../services';
import { setFilters } from 'actions/common/filtersActions';

export const types = {
  FETCH_SBLREX_BREAKS_TREND_SUCCESS: 'FETCH_SBLREX_BREAKS_TREND_SUCCESS',
  FETCH_SBLREX_BREAKS_COUNTERPARTY_SUCCESS: 'FETCH_SBLREX_BREAKS_COUNTERPARTY_SUCCESS',
};

const fetchBreaksTrendSuccess = payload => ({
  type: types.FETCH_SBLREX_BREAKS_TREND_SUCCESS,
  payload
});

const fetchBreaksCounterpartySuccess = payload => ({
  type: types.FETCH_SBLREX_BREAKS_COUNTERPARTY_SUCCESS,
  payload
});



/**
 *
 * Async actions
 *
 */
export function fetchBreaksTrend(fetchCount, islaGroup, partyIds) {
  return (dispatch) => {
    const monthsCount = 12;
    return svc.fetchBreaksTrend(dispatch, fetchCount, islaGroup, partyIds, monthsCount)
      .then((payload) => {
        dispatch(setFilters(payload, false));
        dispatch(fetchBreaksTrendSuccess(payload));
      });
  };
}

export function fetchBreaksCounterparty(fetchCount, islaGroup, partyIds) {
  return (dispatch) => {
    return svc.fetchBreaksCounterparty(dispatch, fetchCount, islaGroup, partyIds)
      .then((payload) => {
        dispatch(setFilters(payload, false));
        dispatch(fetchBreaksCounterpartySuccess(payload));
      });
  };
}
