
import services from 'services/services';
import ScheduleViewerAdapter from './servicesAdapter';
import { apiNames, apiOperatonIds } from 'constants/api/queryApi';


/**
 * Obtaining data from remote SERVER
 */
export function fetchScheduleViewer(dispatch, params) {
  const adapter = new ScheduleViewerAdapter();
  const request = ScheduleViewerAdapter.prepareRequest(params);
  const operatonId = params.endpoint ? params.endpoint : apiOperatonIds.COLLATERAL_PROFILE_VIEWER;
  return services.query(apiNames.COLLATERAL_PROFILE_VIEWER, operatonId, true)(dispatch, {request})
    .then(result => {
      return adapter.parseData(result);
    });
}
