import styled, { css, keyframes } from 'styled-components/macro';
import theme from 'styled-theming';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadingContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: auto;
`;

const spinRule = css`
  ${spin} 1s infinite linear
`;

const loader = styled.div`
  animation: ${spinRule};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  ${theme('mode', {
    dark: css`
      border-top: 4px solid rgba(255, 255, 255, 0.2);
      border-right: 4px solid rgba(255, 255, 255, 0.2);
      border-bottom: 4px solid rgba(255, 255, 255, 0.2);
      border-left: 4px solid #FFF;
    `,
    light: css`
      border-top: 4px solid #bbc7d4;
      border-right: 4px solid #bbc7d4;
      border-bottom: 4px solid #bbc7d4;
      border-left: 4px solid #32374c;
    `,
  })}
`;

// This is used in one case for the List component where the spinner is
// appearing on the right side of the screen and isn't 'centered'.
// It was previously passed down a prop className 'loading' which was css as below.
const loadingPosition = styled.div`
  ${props => props.listLoad && css`
    position: absolute;
    right: 0;
    z-index: topLevel;
  `}
`;

const styledComponents = {
  loadingContainer,
  loadingPosition,
  loader,
};

export { styledComponents };