import FileSaver from 'file-saver';
import * as svc from './services';
import { setFilters } from 'actions/common/filtersActions';


export const actionTypes = {
  FETCH_SECURITY_ELIGIBILITY_SUCCESS: 'FETCH_SECURITY_ELIGIBILITY_SUCCESS',
};

const fetchSecurityEligibilitySuccess = payload => ({
  type: actionTypes.FETCH_SECURITY_ELIGIBILITY_SUCCESS,
  payload,
});

export function fetchEligibilityChecker(params, clearPrevious=true) {
  return (dispatch) => {
    return svc.fetchEligibilityChecker(dispatch, params)
      .then((payload) => {
        dispatch(fetchSecurityEligibilitySuccess(payload));
        dispatch(setFilters(payload, clearPrevious));
      });
  };
}

export function fetchEligibilityCheckerFilters(params, clearPrevious=true) {
  return (dispatch) => {
    return svc.fetchEligibilityChecker(dispatch, params);
  };
}

export function fetchEligibilityCheckerSpreadsheet(params, clearPrevious=true) {
  return (dispatch) => {
    return svc.fetchEligibilityCheckerSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}
