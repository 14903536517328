export enum apiNames {
  MATCHING_MANAGER = "matchingManager",
  EVENT_DETAILS = "eventDetails",
  EVENT_TIMELINE = "eventTimeline",
  USER_INFORMATION = "userInformation",
  WORKFLOW_STATUS = "workflowStatus"
}
  
export enum apiOperationIds {
  MATCHING_MANAGER_TABLE = "matchingManagerTable",
  EVENT_DETAILS_TWO_SIDED = "eventDetailsTwoSided",
  EVENT_DETAILS_ONE_SIDED = "eventDetailsOneSided",
  EVENT_TIMELINE_WITH_MESSAGES = "eventTimelineWithMessages",
  FEATURES = "features",
  FILTERS = "filters",
  USER_INFORMATION = "userInformation",
  WORKFLOW_STATUS_UPDATE = "workflowStatusUpdate"
}
