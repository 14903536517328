import { RepoColDef } from "repo/common/Grid/types";
import { StyleKeyConfig } from "repo/matchingManager/styleKeyConfig";

export const addStyleKeysToColumnDefs = (
  columnDefs: RepoColDef[] = [],
  config: StyleKeyConfig
): RepoColDef[] => {

  return (
    columnDefs.map((colDef: RepoColDef) => {
      const field = colDef.field;
      if (!config[field])
        return colDef;

      const styleKey = config[field];
      return { ...colDef, styleKey };
    })
  );
};
