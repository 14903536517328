import * as actionTypes from 'constants/actionTypes';

export const initialState = {
  pending: {},
  errors: {},
  error: null,
};

export const api = (state = initialState, action) => {
  let apiId;

  switch(action.type) {
    case actionTypes.API_REQUEST_ABOUT_TO_SEND:
      apiId = action.payload.apiId;
      const pending = {...state.pending};
      pending[apiId] = action.payload.requestId;//Store current request id per API (apiId).
      const errors = {...state.errors};
      if (errors[apiId] !== undefined){
        delete errors[apiId];//Remove previous API error, if any.
      }

      return {
        ...state,
        pending,
        errors,
        error: null,
      };

    case actionTypes.API_REQUEST_RESOLVED:
      apiId = action.payload.apiId;
      if (state.pending[apiId] === action.payload.requestId) {
        const pending = {...state.pending};
        delete pending[apiId];

        return {
          ...state,
          pending,
        };
      }
      return state;//It's old request. So, ignore it by returning unchanged state.

    case actionTypes.API_REQUEST_FAILED:
      apiId = action.payload.apiId;
      if (state.pending[apiId] === action.payload.requestId) {//Ignore any errors of the previous requests.
        const pending = {...state.pending};
        delete pending[apiId];
        const errors = {...state.errors};
        errors[apiId] = action.payload.error;
        const error = action.payload.error;

        return {
          ...state,
          pending,
          errors,
          error,
        };
      }

      return state;//It's old request. So, ignore it by returning unchanged state.

    case actionTypes.CLEAR_API_ERROR:
      return {
        ...state,
        error: null,
      };


    default:
      return state;
  }
};
