import * as svc from './services';
import FileSaver from 'file-saver';
import { setFilters } from '../../actions/common/filtersActions';


export const actionTypes = {
  FETCH_PROJECTIONS_SUCCESS: 'ccs/projections/FETCH_PROJECTIONS_SUCCESS',
  UPDATE_VALUE_FORMAT: 'ccs/projections/UPDATE_VALUE_FORMAT',
  FETCH_INTRADAY_SUCCESS: 'ccs/projections/FETCH_INTRADAY_SUCCESS',
  FETCH_INTRADAY_TIMELINE_SUCCESS: 'ccs/projections/FETCH_INTRADAY_TIMELINE_SUCCESS',
};

/**
 *
 * Sync actions
 *
 */
const fetchProjectionsDataSuccess = payload => ({
  type: actionTypes.FETCH_PROJECTIONS_SUCCESS,
  payload
});

const fetchIntradayDataSuccess = payload => ({
  type: actionTypes.FETCH_INTRADAY_SUCCESS,
  payload
});

const fetchIntradayTimelineDataSuccess = payload => ({
  type: actionTypes.FETCH_INTRADAY_TIMELINE_SUCCESS,
  payload
});

export const updateValueFormat = payload => ({
  type: actionTypes.UPDATE_VALUE_FORMAT,
  payload,
});

/**
 *
 * Async actions
 *
 */
export function fetchProjections(params, saveSelection = true) {
  return dispatch => {
    return svc.fetchProjections(dispatch, params)
      .then(payload => {
        dispatch(setFilters(payload, saveSelection));
        dispatch(fetchProjectionsDataSuccess(payload));
      });
  };
}

export function fetchIntraday(params, saveSelection = true) {
  return dispatch => {
    return svc.fetchIntraday(dispatch, params)
      .then(payload => {
        dispatch(setFilters(payload, saveSelection));
        dispatch(fetchIntradayDataSuccess(payload));
      });
  };
}

export function fetchIntradayTimeline(params) {
  return dispatch => {
    return svc.fetchIntradayTimeline(dispatch, params)
      .then(payload => {
        dispatch(fetchIntradayTimelineDataSuccess(payload));
      });
  };
}

export function fetchIntradaySpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchIntradaySpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function fetchProjectionsSpreadsheet(params) {
  return (dispatch) => {
    return svc.fetchProjectionsSpreadsheet(dispatch, params)
      .then((payload) => {
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}

export function fetchProjectionsFilters(params) {
  return (dispatch) => {
    return svc.fetchProjectionsFilters(dispatch, params);
  };
}

export function fetchIntradayFilters(params) {
  return (dispatch) => {
    return svc.fetchIntradayFilters(dispatch, params);
  };
}

export function fetchViewLink(params, viewId, operationId) {
  return (dispatch) => {
    return svc.fetchViewLink(dispatch, params, viewId, operationId)
      .then((payload) => {
        //Assumes all links are for exporting.
        const filename = payload.headers['content-disposition'].match(/".*"/gi)[0].replace(/"/g, '');
        FileSaver.saveAs(payload.data, `${filename}.xlsx`);
      });
  };
}