import {  getBreakTypeColorById } from '../../utils/colorUtils';
import { parseFilters } from '../../utils/parseUtils';


class BaseBreaksAdapter {
  constructor() {
    this.reasonBand = [];
  }

  parseResponseData(apiResponse) {
    const { filtersList, filterBy, filtersSummary } = parseFilters(apiResponse.filterByFields);
    const dataArray = !apiResponse.data ? []
      : Array.isArray(apiResponse.data) ? apiResponse.data
        : Object.keys(apiResponse.data);

    if(!!apiResponse.datakeys.reason_band) {
      this.reasonBand = apiResponse.datakeys.reason_band.map(it => ({
        ...it,
        color: getBreakTypeColorById(it.colorKey).bg
      }));

    }

    return {
      dataSet: apiResponse,
      reasonBand: this.reasonBand,
      emptyData: dataArray.length === 0,
      filtersList, 
      filterBy,
      filtersSummary,
      filterByFields: apiResponse.filterByFields,
    };
  }

}

export default BaseBreaksAdapter;
