import * as actionTypes from 'constants/actionTypes';

import authManager from 'pirumconnect/auth/AuthManager';
import TokenStorage from 'pirumconnect/storage/local/tokens';
import QueryParams from "pirumconnect/storage/local/QueryParams";

import store from 'pirumconnect/store';

import { PIRUM_MODE_SELECT_URL } from "pirumconnect/storage/constants";

import {
  getClientList,
  updateClientIds,
} from 'actions/common/userAccountActions';
import { getPlatformDetails } from 'actions/common/platformDetailsActions';
import {
  unconnectStorageLocal,
  connectStorageLocal,
} from 'pirumconnect/storage/local/connectToStorage';

import { gainSightIdentify } from "../utils/gainsight";

const initialiseMainPageCompleted = payload => ({
  type: actionTypes.FETCH_APP_INIT_DATA_COMPLETED,
  payload,
});

const userAuthenticationCompleted = () => ({
  type: actionTypes.AUTHENTICATE_USER_COMPLETED,
});

export const userAuthorizationCompleted = () => ({
  type: actionTypes.AUTHORIZE_USER_COMPLETED,
});

export const revokeUserAuthorization = () => ({
  type: actionTypes.REVOKE_USER_AUTHORIZATION,
});

export const revokeUserAuthentication = () => ({
  type: actionTypes.REVOKE_USER_AUTHENTICATION,
});

const handleLocalStorageUpdate = async () => {
  const dispatch = store.dispatch;
  await authManager.obtainUserToken();
  if(!authManager.isRedirectingExternally) {
    await getClientList()(dispatch);
    getPlatformDetails()(dispatch);
  }
};

const getAllClientIDs = (state) => {
  return state.app.userAccount.groups.reduce((acc, groups) => {
    const groupClientsIds = groups.clients ? groups.clients.map(it => it.clientId) : [];
    acc.push(...groupClientsIds);
    return acc;
  }, []);
};

export function startAuthProcess() {
  return async (dispatch) => {
    const success = await authManager.obtainUserToken();
    if (success) {
      dispatch(userAuthenticationCompleted());
      if(!TokenStorage.isPirumUser() || TokenStorage.isPirumUserAuthorized()) {
        dispatch(userAuthorizationCompleted());
      }
    }
  };
}

export function initialiseMainPageNewSSO() {
  return async (dispatch, getState) => {
    if (authManager.isRedirectingExternally) return;
    await getClientList()(dispatch);
    if(QueryParams.getQueryParams().selectAllClientIdsOnInit) {
      await updateClientIds(getAllClientIDs(getState()))(dispatch);
    }
    try {
      await getPlatformDetails()(dispatch);
      connectStorageLocal(handleLocalStorageUpdate);
      dispatch(initialiseMainPageCompleted());
    } catch(err) {
      dispatch(initialiseMainPageCompleted(err));
    }
    gainSightIdentify();
  };
}

export function redirectToModeSelect() {
  return async () => {
    await authManager.resetSelectedModeAttributes();
    window.location.replace(PIRUM_MODE_SELECT_URL);
  };
}

export function destroyMainPage() {
  unconnectStorageLocal(handleLocalStorageUpdate);
}
