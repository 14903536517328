import { EventActions } from "repo/models/EventAction";
import {
  ActionTypes,
  ClearMatchingModalState,
  CloseMatchingModal,
  OpenMatchingModal,
  SelectedEvents,
  SetEventAction,
  SetMatchingModalGrouped,
  SetMatchingModalNonGrouped,
  SetMatchingModalSelectedEvents,
} from "./types";

export function setMatchingModalGrouped(): SetMatchingModalGrouped {
  return {
    type: ActionTypes.SET_MATCHING_MODAL_GROUPED,
  };
}

export function setMatchingModalNonGrouped(): SetMatchingModalNonGrouped {
  return {
    type: ActionTypes.SET_MATCHING_MODAL_NONGROUPED,
  };
}

export function openMatchingModal(): OpenMatchingModal {
  return { type: ActionTypes.OPEN_MATCHING_MODAL };
}

export function closeMatchingModal(): CloseMatchingModal {
  return {
    type: ActionTypes.CLOSE_MATCHING_MODAL,
  };
}

export function clearMatchingModalState(): ClearMatchingModalState {
  return {
    type: ActionTypes.CLEAR_MATCHING_MODAL_STATE,
  };
}

export function setEventAction(payload: EventActions): SetEventAction {
  return {
    type: ActionTypes.SET_EVENT_ACTION,
    payload,
  };
}

export function setMatchingModalSelectedEvents(
  payload: SelectedEvents[]
): SetMatchingModalSelectedEvents {
  return {
    type: ActionTypes.SET_MATCHING_MODAL_SELECTED_EVENTS,
    payload,
  };
}
