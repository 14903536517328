import styled, { css } from "styled-components";
import { fontSize, fontFamily } from "../../../styles/vars";
import Button from "common/buttons/Button";
import { ReactComponent as InfoSVG } from "assets/images/icon-info.svg";
import {
  PIRUM_BLUEGREY_1,
  PIRUM_GREEN,
  PIRUM_LIGHT_DEFAULT_TEXT,
} from "../../../styles/colors/brandColors";

export const Header = styled.h1`
  font-size: ${fontSize.XXXXL};
  font-family: ${fontFamily.LIGHT};
  margin: 10px 0;
  user-select: none;
  color: ${PIRUM_LIGHT_DEFAULT_TEXT[100]};
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  div:first-of-type {
    cursor: pointer;
  }
`;

export const LabelText = styled.p`
  margin: 20px 0;
  font-size: ${fontSize.L};
  white-space: pre-line;
  color: ${PIRUM_LIGHT_DEFAULT_TEXT[100]};
  > span {
    line-height: 24px;
    > strong {
      font-family: ${fontFamily.BOLD};
    }
  }
  > strong {
    font-family: ${fontFamily.BOLD};
  }
`;

export const CheckboxesContainer = styled.div`
  min-height: 150px;
  max-height: 550px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 30px;
  max-width: clamp(90%, 750px, 50%);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionButton = styled(Button)`
  min-width: 80px;
  justify-content: center;
  margin: 0 60px;
  padding: 30px;
  background-color: ${PIRUM_BLUEGREY_1[100]};

  &:hover {
    background-color: rgba(89, 96, 121, 0.8);
  }

  > svg {
    margin-right: 20px;
  }
`;

export const PrimaryActionButton = styled(Button)`
  min-width: 80px;
  justify-content: center;
  margin: 0 60px;
  padding: 30px;
  background-color: ${PIRUM_GREEN[100]};

  &:hover {
    background-color: rgba(114, 183, 103, 0.8);
  }

  > svg {
    margin-right: 20px;
  }
`;

export const WarningContainer = styled.div<{ $allEventsUnselected: boolean }>(
  ({ $allEventsUnselected }) => css`
    display: flex;
    flex: 1 1 auto;
    margin: 10px 0;
    font-size: ${fontSize.L};
    align-items: center;
    justify-content: center;
    visibility: ${$allEventsUnselected ? "visible" : "hidden"};
    color: #ffa000;
  `
);

export const InfoIcon = styled(InfoSVG)`
  align-items: flex-end;
  margin-right: 10px;
  vertical-align: text-top;
  min-width: 20px;
  min-height: 20px;
`;

export const WarningText = styled.div`
  font-size: ${fontSize.L};
  margin: 10px 0;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  max-width: 97%;
`;
