/*
  Please see README on how to use this file
 */

export const PIRUM_ALTERNATING_GREY = {
  100: "#2C3241",
};
export const PIRUM_BLUEGREY_1 = {
  100: "#596079",
  70: "#484E64",
  40: "#383C4F",
  20: "#2D3142",
};
export const PIRUM_BLUEGREY_2 = {
  100: "#3E4356",
  70: "#353A4b",
  40: "#2D3141",
};
export const PIRUM_BLUEGREY_3 = {
  100: "#4E5264",
};
export const PIRUM_GREY_1 = {
  100: "#989BA5",
};
export const PIRUM_GREY_2 = {
  100: "#E8ECF0",
};
export const PIRUM_DEFAULT_LINK = {
  100: "#03BDE5",
};
export const PIRUM_DEFAULT_LINK_HOVER = {
  100: "#72B767",
};
export const PIRUM_DEFAULT_SELECT_HOVER = {
  100: "#3297FD",
};
export const PIRUM_DEFAULT_LINK_VISITED = {
  100: "#FFFFFF",
};
export const PIRUM_DEFAULT_TEXT = {
  100: "#FFFFFF",
};
export const PIRUM_GREEN = {
  100: "#72B767",
  40: "#3a5347",
};
export const PIRUM_GREY_DDD = {
  100: "#DDDDDD",
};
export const PIRUM_LIGHT_GREY_1 = {
  100: "#E8E8E8",
};
export const PIRUM_MAIN_GREY = {
  100: "#777777",
};
export const PIRUM_ORANGE = {
  100: "#C36F1A",
  40: "#654329",
};
export const PIRUM_YELLOW = {
  100: "#FFE61E",
  30: "#64602E",
};
export const PIRUM_MAGENTA = {
  100: "#ED0083",
  30: "#5F1B4C",
};
export const PIRUM_UI_BG_1 = {
  100: "#32374C",
};
export const PIRUM_UI_BG_2 = {
  100: "#222635",
};
export const PIRUM_UI_BG_3 = {
  100: "#FFFFFF",
};
export const PIRUM_UI_GB_4 = {
  100: "#FBFCFC",
};
export const PIRUM_UI_BG_3_GRADIENT = {
  from: "#32374C",
  to: "#222635",
};
export const PIRUM_UI_BG_4 = {
  100: "#373E4E",
};
export const PIRUM_UI_ALERT = {
  100: "#FDD400",
  50: "#FFFDF6",
};
export const PIRUM_COVERED_BLUE = {
  100: "#0D7FBE",
};
export const PIRUM_UNDERCOVERED_RED = {
  100: "#E23333",
};
export const ERROR_RED = {
  100: "#E23333",
  50: "#FFF8F7",
};
export const PIRUM_OVERCOVERED_YELLOW = {
  100: "#D0AB1B",
};
export const PIRUM_LONGBOX_MINT = {
  100: "#45C583",
  40: "rgba(69, 197, 131, 0.4)",
};
export const PIRUM_LOCKUP_LONGBOX_GREEN = {
  100: "#629536",
};
export const PIRUM_LIGHT_HOVER = {
  100: "#F3F5F7",
};
export const PIRUM_LIGHT_DEFAULT_TEXT = {
  100: "#32374C",
};
export const PIRUM_BREAK_BG = {
  100: "#F8E9E9",
};
export const PIRUM_BREAK_BORDER = {
  100: "#BD2624",
  20: "rgba(189, 38, 36, 0.2)",
};
export const PIRUM_INFO_BG = {
  100: "#FCFCFC",
};
export const PIRUM_INFO_BORDER = {
  100: "#AAB8C6",
};
export const PIRUM_BG_ERROR = {
  100: "#BF2525",
};
export const PIRUM_NOTIFICATION_FATAL = {
  100: "#FFA000",
};
export const PIRUM_DIVIDER_GREY = {
  100: "#A4A8AF",
};
export const PIRUM_PRIVATE_BLUE = {
  100: "#5AA5FF",
};
export const PIRUM_OFF_WHITE_BG = {
  100: "#F5F8FD",
};
export const PIRUM_ICON_GREY = {
  100: "#B7BCC7",
};
export const PIRUM_ICON_BLUE = {
  100: "#44C0FE",
};
export const PIRUM_OFF_CONTROL_BG_GREY = {
  100: "#F9F9F9",
};
export const PIRUM_OFF_CONTROL_GREY = {
  100: "#D4D8DF",
};
export const PIRUM_LIGHT_PILL_BG = {
  100: "#FAFCFE",
};

// TODO: update brand color names
export const PIRUM_GREEN_PILL = {
  100: "#48C45E",
  10: "#EDF5EA",
};
export const PIRUM_INDIGO_PILL = {
  100: "#4B0082",
  10: "#F1DEFF",
};
export const PIRUM_CELESTIAL_PILL = {
  100: "#488DCA",
  10: "#ECF3FA",
};
export const PIRUM_RED_PILL = {
  100: "#E23333",
  10: "#F8E9E9",
};

export const GREY_BLUE = {
  100: "#8CA0B3",
  20: "#E8ECF0",
};

export const HEX_OPACITY = {
  0: "00",
  10: "1a",
  20: "33",
  30: "4d",
  40: "66",
  50: "80",
  60: "99",
  70: "b3",
  80: "cc",
  90: "e6",
  100: "ff",
};
