import { apiNames, apiOperationIds } from "constants/api/repoApi";
import { Dispatch } from "react";
import services from "services/services";
import { parseFilters, prepareFiltersRequest } from "utils/parseUtils";
import {
  FetchOneSidedEventDetailsSucessPayload,
  IAction,
  TwoSidedTablePayload,
  UpdateWorkflowStatusSuccess,
} from "./actions";
import {
  FeaturesResponse,
  FetchEventDetailsParams,
  FetchTimeLineMessagesRequest,
  FetchTimeLineMessagesResponse,
  MatchingManagerDataResponse,
  UpdateWorkflowStatusParams,
  UserInformationResponse,
} from "./types";
import { prepareTwoSidedEventDetailsData } from "repo/helpers";
import { groupArrayOfObjectsByKey } from "utils/objUtils";
import moment from "moment";

function prepareRequest(params: any) {
  const filterBy = prepareFiltersRequest(params);
  return {
    ...params,
    filterBy,
  };
}

function parseAPIResponse(result: any) {
  return {
    ...result,
    ...parseFilters(result.filterByFields),
  };
}

export function fetchMatchingManagerData(
  dispatch: Dispatch<IAction<MatchingManagerDataResponse>>,
  params: any
): Promise<unknown> {
  const request = prepareRequest(params);
  return (services as any)
    .repo(apiNames.MATCHING_MANAGER, apiOperationIds.MATCHING_MANAGER_TABLE)(
      dispatch,
      { request }
    )
    .then((res: MatchingManagerDataResponse) => parseAPIResponse(res));
}

export function fetchOneSidedEventDetails(
  dispatch: Dispatch<IAction<FetchOneSidedEventDetailsSucessPayload>>,
  params: FetchEventDetailsParams
): Promise<FetchOneSidedEventDetailsSucessPayload> {
  return services.repo<
    { request: FetchEventDetailsParams },
    FetchOneSidedEventDetailsSucessPayload
  >(
    apiNames.EVENT_DETAILS,
    apiOperationIds.EVENT_DETAILS_ONE_SIDED,
    true
  )(dispatch, { request: params });
}

export function fetchTwoSidedEventDetailsData(
  data: TwoSidedTablePayload
): Promise<unknown> {
  const twoSidedEventDetailsData = prepareTwoSidedEventDetailsData(data);

  return Promise.resolve(twoSidedEventDetailsData);
}

export function fetchTwoSidedEventDetailsTable(
  dispatch: Dispatch<IAction<TwoSidedTablePayload>>,
  params: FetchEventDetailsParams
): Promise<TwoSidedTablePayload> {
  return services.repo<
    { request: FetchEventDetailsParams },
    TwoSidedTablePayload
  >(
    apiNames.EVENT_DETAILS,
    apiOperationIds.EVENT_DETAILS_TWO_SIDED,
    true
  )(dispatch, { request: params });
}

export function fetchUserInformation(
  dispatch: Dispatch<IAction<UserInformationResponse>>
): Promise<UserInformationResponse> {
  return (services as any).repo(
    apiNames.USER_INFORMATION,
    apiOperationIds.USER_INFORMATION
  )(dispatch, {});
}

export const fetchFeatures = (
  dispatch: Dispatch<IAction<FeaturesResponse>> = (_) => _
): Promise<FeaturesResponse> => {
  return services.repo<null, FeaturesResponse>("Features", apiOperationIds.FEATURES, true)(dispatch);
};

export function updateWorkflowStatus(
  dispatch: Dispatch<IAction<UpdateWorkflowStatusSuccess>>,
  params: UpdateWorkflowStatusParams
): Promise<UpdateWorkflowStatusSuccess> {
  return services.repo<
    { request: UpdateWorkflowStatusParams },
    UpdateWorkflowStatusSuccess
  >(
    apiNames.WORKFLOW_STATUS,
    apiOperationIds.WORKFLOW_STATUS_UPDATE,
    true
  )(dispatch, { request: params });
}

export function fetchTimelineMessages(
  params: FetchTimeLineMessagesRequest,
  dispatch: Dispatch<unknown> = (_) => _
): Promise<FetchTimeLineMessagesResponse> {
  const keyFormatter = (key: number) =>
    typeof key === "number" ? moment(key).format("YYYY-MM-DD") : key;

  return services
    .repo<
      { request: FetchTimeLineMessagesRequest },
      FetchTimeLineMessagesResponse
    >(
      apiNames.EVENT_TIMELINE,
      apiOperationIds.EVENT_TIMELINE_WITH_MESSAGES,
      true
    )(dispatch, { request: params })
    .then((res) => {
      const conversationMessages = groupArrayOfObjectsByKey(
        res.timelineMessages,
        (item) => keyFormatter(item.metadata.epochTime)
      );

      return {
        ...res,
        conversationMessages: conversationMessages as any,
      };
    });
}

// Not in use but api is working, use if needed
// export function fetchEventFilters(dispatch: any, params: any): { filterBy: FilterBy } {
//   return (services as any).repo(apiNames.MATCHING_MANAGER, apiOperationIds.FILTERS)(dispatch, { request: params })
//     .then((res: any) => parseAPIResponse(res));
// }
