import { JL } from 'jsnlog';

const logger = JL();

const setMinLevel = level => logger.setOptions( { level: level } );

const createLogFn = fn => (x, e) => {
  // Note: try-catch to make sure the logger function itself never throws any exception
  try { return fn.call(logger, x, e); }
  catch (e) {
    // TODO: after CWS-932 done, add back the console.error(e) here
  }
};

export const DEBUG = JL.getDebugLevel();
export const INFO  = JL.getInfoLevel();
export const WARN  = JL.getWarnLevel();
export const ERROR = JL.getErrorLevel();
export const FATAL = JL.getFatalLevel();

export default {
  init: () => setMinLevel( DEBUG ),
  setMinLevel: setMinLevel,
  debug: createLogFn(logger.debug),
  info:  createLogFn(logger.info),
  warn:  createLogFn(logger.warn),
  error: createLogFn(logger.error),
  fatal: createLogFn(logger.fatal),
  fatalException: createLogFn(logger.fatalException),
};
