import Swagger from "swagger-client";
import * as specs from "./specs";
import * as apiActions from "../actions/common/apiActions";
import store from "pirumconnect/store";
import { getApiId, generateUniqueId } from "utils/apiUtils";
import { updateSessionFromHeaders } from "../pirumconnect/storage/local/oldToken";
import { shouldUseNewSSO } from "pirumconnect/auth/AuthManager";
import { getActiveValidClientIds } from "pirumconnect/storage/activeValidClientIds";
import { gainSightEvent } from 'utils/gainsight';
import appLogger from "../pirumconnect/logger";

const getPendingRequestId = (apiId) => {
  const { app } = store.getState();
  return app && app.api && app.api.pending && app.api.pending[apiId];
};

/**
 * Sends REQUEST to specific endpoint and returns the response Promise.
 * The swagger client maps the api endpoints to functions. The function will
 * be accessible at "client.api.operationId".
 * @param  {Function} dispatch    Redux store dispatcher reference
 * @param  {String}   apiName         The api name, comes from the tag on the api endpoint
 * @param  {String}   operationId The api function, name comes from the operationID on the endpoint;
 *                                Note, if not set, it defaults to the api name.
 * @param  {Object}   args        request arguments
 * @return {Function}             Swagger client handler
 */
const sendApiRequest = (
  dispatch,
  apiName,
  operationId,
  args,
  apiId,
  requestId
) => {
  /**
   * Swagger client handler
   * @param  {Function} client The swagger client
   * @return {Promise}         Response Promise
   */
  return (client) => {
    const operation = operationId || apiName;
    const request = args.request || {};
    if (client.definitions.QueryRequestParams && !request.queryParams) {
      request.queryParams = { clientIds: getActiveValidClientIds() };
    }
    return client[apiName][operation]({ ...args, request });
  };
};

/**
 * API RESPONSE handler
 * The response returned by the swagger client incorporates the full http
 * response details, we need to parse and get the data from it.
 * The response structure:
 * {
 *   data:       json string(!) -> need to parse
 *   headers:    object
 *   method:     string
 *   obj:        object         -> already parsed
 *   status:     integer
 *   statusText: json string
 *   url:        string
 * }
 * @param  {[type]} dispatch [description]
 * @return {[type]}          [description]
 */
const handleApiResponse = (dispatch, apiId, requestId) => {
  return (response) => {
    // resolve the response data, see RESPONSE section in comment
    const { headers, data } = response;
    const responseData = typeof data === "string" ? response.obj : { data };

    if (
      getPendingRequestId(apiId) &&
      getPendingRequestId(apiId) !== requestId
    ) {
      //is this a response to an old request?
      //The error is thrown to make this response unsuccessful and to be handled by handleApiError
      //Note, that the error responses to old requests are ignored in the reducer.
      throw new Error(
        `API RESPONSE handler: the handled response for ${apiId} is NOT the latest one!`
      );
    }

    dispatch && dispatch(apiActions.requestResolved(apiId, requestId));

    if (!shouldUseNewSSO()) {
      updateSessionFromHeaders(headers);
    }
    /* 
      GainSight Event: API Call
      Event Data Body: { system: String, apiName: String, operationId: String }
    */
   try {
    const [apiName, operationId] = apiId.split("-");
    const gainSightBody = {
      apiName: apiName,
      operationId: operationId,
      system:"PirumConnect"
    };
    gainSightEvent(["API Call", gainSightBody]);
   } catch(error) {
    appLogger.error({
      where: 'servicesHandleApiResponse',
      what: 'gainsight event track',
    }, error);
   }
    
    return {...responseData, apiId, requestId, headers};
  };
};

const handleApiError = (dispatch, apiId, requestId, suppressError) => {
  return (error) => {
    logApiError(apiId, error);
    dispatch &&
      dispatch(
        apiActions.requestFailed(apiId, requestId, error, suppressError)
      );
    dispatch && dispatch(apiActions.requestResolved(apiId, requestId));
    throw error;
  };
};

function logApiError(apiId, error) {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const [apiName, operationId] = apiId.split("-");
  // eslint-disable-next-line no-console
  console.error(
    "This documentation may help you to debug this error: https://confluence.pirum.com/display/FEC/Swagger+and+Services",
    `apiName: ${apiName} `,
    `operation: ${operationId}`,
    "\n",
    error
  );
}

/*
 * Creates a service based on a swagger spec
 *
 * Uses 'swagger-client' javascript lib to create the swagger client
 * from the spec
 *
 * NOTE: the swagger client utilizes Promises to handle data fetching
 *       delays, so the created swagger Promise will eventually
 *       resolve into the client object. Same for all the function calls later,
 *       they return Promises as well (which is ok, redux-promise will
 *       handle them, so the result of the function call can be dispatched
 *       as payload)
 *
 * Calling the service:
 *   import services from 'services';
 *   services[serviceName](api)(args);
 * Note:
 *   1st function returns a specific service Clousure with API name, id, 'operationId'
 *       and 'requestId' per each serviceName (Swagger's 'specName', ex:'query', 'sso', etc.);
 *   2nd function returns a specific request Promise.
 */
const services = {};
Object.keys(specs).forEach((specName) => {
  const hostMatch = /^([^.]+\.)?([^.]*app|qa[^.]*)\.([^:]*)(:\d+)?$/;
  const hostReplacement = "api.$2.$3$4";

  // Creates a Swagger client for each specs
  const swaggerClient = new Swagger({
    specName,
    specs,
    spec: {
      ...specs[specName],
      host:
        process.env.REACT_APP_SERVICE_HOST ||
        (hostMatch.test(window.location.host) &&
          window.location.host.replace(hostMatch, hostReplacement)) ||
        "",
      schemes: [window.location.protocol === "http:" ? "http" : "https"],
    },
    usePromise: true,
    enableCookies: true,
  });

  services[specName] = (apiName, operationId, useDefaultErrorHandler) => {
    const apiId = getApiId(apiName, operationId);
    const requestId = generateUniqueId();
    return (dispatch, args = {}) => {
      dispatch && dispatch(apiActions.requestSent(apiId, requestId));
      return swaggerClient
        .then(
          sendApiRequest(dispatch, apiName, operationId, args, apiId, requestId)
        )
        .then(handleApiResponse(dispatch, apiId, requestId))
        .catch(
          handleApiError(dispatch, apiId, requestId, useDefaultErrorHandler)
        );
    };
  };
});

export default services;
